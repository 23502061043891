import { generateResolver, Icon, Notification, STATUS_TYPES, yup } from 'dyl-components';
import React, { useEffect } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Header, Label, List } from 'semantic-ui-react';
import AccountOptions from 'shared/forms/AccountOptions';
import ContactOptions from 'shared/forms/ContactOptions';
import { CustomGroupUtils, PhoneUtil, StringUtils, ValidationUtils } from 'utils';

import contactsActions from 'actions/contacts';
import accountActions from 'actions/account';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import moduleInformationActions from 'actions/module_information';
import customFieldsGroupActions from "actions/custom_fields_group";
import contactSequenceActions from 'actions/contact_sequence';
import sequencesActions from 'actions/sequences';
import contactActions from 'actions/contact';
import sequenceTasksActions from 'actions/sequence_tasks';
import quoteActions from 'actions/quote';
import PHONE_TYPE_OPTIONS from 'shared/constants/PHONE_TYPE_OPTIONS';
import EMAIL_TYPE_OPTIONS from 'shared/constants/EMAIL_TYPE_OPTIONS';

const MoveModuleForm = ({ onCloseModulePanel }) => {
    const { account_stage, isCheckingDuplicates, isMovingModule, module_owner, selectedSequenceId, sequenceToApply, isReadingSequenceContactTasks } = useSelector(state => {
        const pipeline = state.module_information.pipeline;
        return ({
            account_stage: pipeline?.module,
            isCheckingDuplicates: state.contact_duplicates.isCheckingDuplicates,
            isMovingModule: state.module_information.pipelineBeingMoved || state.contacts.isCreating || state.account.isLinkingContactsToAccount || state.sequences.isAddingContactToSequence || state.contact_sequence.isRemovingSequence || state.quote.opportunityBeingUpdated,
            module_owner: pipeline?.contact?.id,

            isReadingSequenceContactTasks: state.sequence_tasks.isReadingSequenceContactTasks,
            selectedSequenceId: state.sequence_tasks.selectedSequenceId,
            sequenceToApply: pipeline?.stages?.find(stage => stage.id === pipeline?.pipeline_stage_id)?.sequence_id
        });
    });

    const { formState: { isDirty, isValid, errors }, control, trigger, setValue, handleSubmit, watch, clearErrors, setError } = useForm({
        mode: 'onChange',
        defaultValues: {
            contactToReceiveModule: [{
                first_name: '',
                last_name: '',
                suffix: '',
                birthday: '',
                phones: [{ value: '', type: PHONE_TYPE_OPTIONS.keys.CELL, main: true }],
                emails: [{ value: '', type: EMAIL_TYPE_OPTIONS.keys.WORK, main: true }],
                website: '',
                interests: [],
                department: '',
                job_title: '',

                address_label: 'Business',
                street: '',
                apartmentUnitOrFloor: '',
                city: '',
                state: '',
                zip: '',

                account_id: null,

                business_name: '',
                industry_sector: null,
                sub_industry: null,
                employee_size: 0,
                annual_revenue: '',
                products_sold: '',

                household_name: '',
                household_type: null,
                household_members: 0,
                number_of_children: 0,
                annual_household_income: '',

                group: []
            }],

            account: null
        },
        resolver: generateResolver({
            contactToReceiveModule: yup.array().of(yup.object().shape(ValidationUtils.NEW_CONTACT_VALIDATIONS, ['phones', 'emails']))
        })
    });

    const [watchedContacts] = watch(['contactToReceiveModule']);

    const { fields: contactToReceiveModule, update: updateContact } = useFieldArray({
        control,
        name: 'contactToReceiveModule'
    });

    const onSelectContact = (contact, index) => {
        const emails = contact.email?.email ? [{ value: contact.email.email, type: contact.email.email_type }] : [{ value: '', type: EMAIL_TYPE_OPTIONS.keys.WORK, main: true }];
        const phones = contact.phone?.phone ? [{ value: contact.phone.phone, type: contact.phone.phone_type }] : [{ value: '', type: PHONE_TYPE_OPTIONS.keys.CELL, main: true }];
        updateContact(index, {
            ...watchedContacts[index],
            contact_id: contact.id,
            first_name: contact.first_name || '',
            last_name: contact.last_name || '',
            new: contact.id ? false : contact.new,
            account_id: contact?.account ? contact?.account?.id || undefined : null,
            phones,
            emails,
        });
        setValue('account', null);
    }

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(customFieldsGroupActions.getContactStandardGroup({ group_name: 'person' }));
    }, [dispatch]);

    const onCreateContacts = (contacts, account_id) => {
        return dispatch(contactsActions.createContact(contacts, { account_id }));
    }

    const onLinkContactsToAccount = (contacts, account_id) => {
        return dispatch(accountActions.linkContacts(account_id, contacts))
    }

    const [params] = useSearchParams();

    const pipeline_id = params.get('module_id');

    const transferModule = async (to) => {
        if (selectedSequenceId) {
            await dispatch(contactSequenceActions.removeFromContact(module_owner));
        }
        if (Boolean(sequenceToApply)) {
            try {
                await dispatch(contactSequenceActions.removeFromContact(to));
            } catch (e) {
                console.log(e);
            }
            await dispatch(sequencesActions.addToSequence({ contact_id: to }, null, sequenceToApply));
        }
        return dispatch(moduleInformationActions.transfer(pipeline_id, {
            from_person_id: module_owner,
            to_person_id: to
        }));
    }

    const location = useLocation();
    const { contact_id } = useParams();

    const refresh = () => {
        const query = new URLSearchParams(params);
        const query_string = query.toString();
        navigate(`${location.pathname}?module_id=${pipeline_id}`);
        navigate(`${location.pathname}${query_string ? `?${query_string}` : ''}`);
        onCloseModulePanel();
        if (contact_id) {
            dispatch(contactActions.readContact(module_owner));
            dispatch(sequenceTasksActions.readContactTasks(module_owner));
        }
    }

    const onMoveModule = async data => {
        const contacts = data.contactToReceiveModule;

        try {
            const idOfReceivingContact = await (async () => {
                const isNewContact = contacts[0].contact_id === undefined;
                if (isNewContact) {
                    const newContacts = await onCreateContacts(contacts.map(contact => ({
                        custom_data: {
                            fields: CustomGroupUtils.extractPersonFields(contact),
                            children: {
                                ...CustomGroupUtils.extractBusinessAndHouseholdDetails(contact)
                            }
                        },
                        date_of_birth: contact.birthday,
                        email: contact.emails.filter(emailItem => emailItem.value).map(({ value: email, main, type }) => ({
                            main,
                            email,
                            email_type: type
                        })),
                        first_name: contact.first_name,
                        last_name: contact.last_name,
                        suffix: contact.suffix,
                        influence: "unknown",
                        location: [
                            {
                                street: contact.street,
                                additional_street: contact.apartmentUnitOrFloor,
                                city: contact.city,
                                state: contact.state,
                                zip: contact.zip,
                                label: contact.address_label
                            }
                        ],
                        phone: contact.phones.filter((phone) => phone.value).map(({ value, main, type }) => ({
                            main,
                            phone: PhoneUtil.getUnformatted(value),
                            phone_type: type
                        })),
                        job_title: contact.job_title,
                        website: contact.website
                    })), data.account?.id);
                    await transferModule(newContacts[0]);
                    return newContacts[0];
                }
                await transferModule(contacts[0].contact_id);
                if (data.account?.id) {
                    await onLinkContactsToAccount(contacts.map(contact => ({
                        person_id: contact.contact_id,
                        primary_person: false
                    })), data.account?.id);
                    await dispatch(quoteActions.updateQuoteAccountId(pipeline_id, { account_id: data.account?.id }));
                }
                return contacts[0].contact_id;
            })();
            Notification.alert(`Successfully transferred ${account_stage} module!`, STATUS_TYPES.SUCCESS);
            refresh();
            window.open(`/contact/${idOfReceivingContact}`, '_blank', 'noopener,noreferrer');
        } catch (e) {
            console.log(e);
            Notification.alert(`Failed to transfer ${account_stage} module!`, STATUS_TYPES.ERROR);
        }
    }

    const hasErrors = Object.keys(errors).length > 0;

    return (
        <Form loading={isMovingModule || isReadingSequenceContactTasks} noValidate>
            <Header color='primary'>
                Move {StringUtils.capitalize(account_stage)} <div className='PhoneEmailInfo'>
                    <Label pointing='right' color='black'>Moving {account_stage === 'lead' ? `a lead` : `an opportunity`} will cancel all future activities</Label>
                    <Icon className='fas fa-circle-info' color='primary' />
                </div>
            </Header>
            <List divided verticalAlign='middle'>
                {contactToReceiveModule.map((contact, index) => (
                    <List.Item>
                        <Form.Field
                            control={'div'}
                            label='Contact Name'
                            key={contact.id}
                            required
                        >
                            <ContactOptions
                                contact={contact}
                                control={control}
                                index={index}
                                checkIfContactCanBeSelected={(contact) => { return contact.current_stage === undefined; }}
                                isOptionAlreadySelected={() => { return false; }}
                                onSelectContact={onSelectContact}
                                hasPrimaryToggle={false}
                                clearErrors={clearErrors}
                                name='contactToReceiveModule'
                                setError={setError}
                                selectedContactWidth={16}
                                trigger={trigger}
                            />
                        </Form.Field>
                    </List.Item>
                ))}
            </List>
            {(watchedContacts[0].new || watchedContacts[0].account_id === undefined) && [
                <Header as='h3' color='primary'>Account</Header>,
                <Controller
                    name='account'
                    control={control}
                    render={({ field: { name, value, onChange } }) => (
                        <Form.Field
                            name={name}
                            value={value}
                            control={AccountOptions}
                            label='Link to Existing Account'
                            onChange={(_, { value }) => {
                                onChange({ target: { name, value } });
                            }}
                        />
                    )}
                />
            ]}
            <Button floated='right' disabled={!isDirty || !isValid || isCheckingDuplicates || hasErrors || isMovingModule} primary onClick={handleSubmit(onMoveModule)} loading={isMovingModule}>Save</Button>
        </Form>
    )
}

export default MoveModuleForm;
