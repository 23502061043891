import { Centrifuge } from 'centrifuge';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import routes from 'actions/routes';
import basePath from 'actions/basePath';
import notificationsActions from 'actions/notifications';
import permissionsActions from 'actions/permissions';
import userActions from 'actions/user';
import authActions from 'actions/auth/auth';
import roleActions from 'actions/role';
import { NotificationInfo, PhoneCallMessage } from 'dyl-components';
import axiosInstance from 'actions/axiosInstance';
import NotificationPopupModals from "shared/modals/NotificationPopUpModals";
import SendEmailModal from "shared/modals/SendEmailModal";
import AddTaskModal from "shared/modals/AddTaskModal";
import AddEventModal from "shared/modals/AddEventModal";
import types from "actions/call/types.js";
import office_view from 'actions/office_view';
import leftSidebar from 'actions/left_sidebar_ui';
import contactsActions from 'actions/contacts';
import { useNavigate } from 'react-router-dom';
import appActions from 'actions/app/app';


const CHANNELS = {
    permission: 'perm',
    chat: 'chat',
    notification: 'notify',
    voip: 'voip',
}

const WebsocketConnection = ({phone}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const { customer_id, user_id } = useSelector(state => state.auth);
    const { 
        notificationCalls,
        calls,
        savedSettingsRoute,
        currentCall
    } = useSelector((state) => {
        const userPhoneCreds = state.officeView.creds.extension;
        const calls = state.officeView.calls;
        const notificationCalls = [];
        for (const key in calls) {
            if (calls.hasOwnProperty(key)) {
                if (state.officeView.extensions[userPhoneCreds]?.includes(key)) {
                    notificationCalls.push(calls[key])
                }
            }
        }
        return {
            notificationCalls,
            calls,
            savedSettingsRoute: state.app.savedSettingsRoute,
            currentCall: state.officeView.currentCall       
        }
    })

    const [ messageQueue, setMessageQueue ] = useState([])
    let messageQueueCheck = [];

    const getTokenWS = async () => {
        let { ws_token } = await dispatch(authActions.check()) || {};

        //check whether we persit conneciton token
        if(!ws_token){
            try {
                const tokenInfo = await axiosInstance.instance1.get(`${routes.WEBSOCKET}/connect`)
                ws_token = tokenInfo.data.feToken;
            } catch(e){
                console.log("|+|Websocket Connection Failure",e)
            }
        } 
        return ws_token
    }

    const centrifuge = new Centrifuge(`${basePath.VIEW_HOST}`, {
        debug: true,
        getToken: () => getTokenWS()
    });

    const onDismissNotification = (id) => {
        let incomingNotification = messageQueueCheck;
        setTimeout(() => {
            const indexToRemove = incomingNotification.findIndex(notification => notification.notification_id === id);
            incomingNotification.splice(indexToRemove, 1);

            const notification = document.getElementsByClassName(`Notification--${id}`);
            if(notification.length === 1){
                notification[0].remove();
                setMessageQueue(incomingNotification);
            }
        }, (7000)); //Time Interval to Dismiss incoming message
    }

    useEffect(() => {
            if(!customer_id && !user_id){
                return;
            }

            centrifuge.on('connect', (context) => {
                console.log("|+|Connect Context",context);
            });
            centrifuge.on('error', (e) => {
                console.log('|+|Centrifuge Error: ',e);
            });
            centrifuge.on('disconnect', (context) => {
                console.log("|+|Centrifuge Disconnect",context);
            })
            
            centrifuge.connect()

            //notifications
            const notificationSub = centrifuge.newSubscription(`${CHANNELS.notification}_${customer_id}_${user_id}`);
            notificationSub.on('subscribing', (msg) => {
                // console.log('|+|Notification Subscribing...', msg);
            });
            notificationSub.on('subscribed', (msg) => {
                console.log('|+|Notification Subscribed', msg);
            });
            notificationSub.on('unsubscribed', (msg) => {
                // console.log('|+|Notification Unsubscribed', msg);
            });
            notificationSub.on('publication', (msg) => {
                const message = msg.data;
                dispatch(notificationsActions.hasUnReadNotifications());
                dispatch(notificationsActions.onReadNotificationCenter());
                setMessageQueue(prevQueue => [...prevQueue, message]);
                messageQueueCheck.push(message) 
                onDismissNotification(message.notification_id);
            });
            notificationSub.subscribe();

            //permissions
            const permissionsSub = centrifuge.newSubscription(`${CHANNELS.permission}_${customer_id}_${user_id}`);
            permissionsSub.on('subscribing', (msg) => {
                // console.log('|+|Permission Subscribing...', msg);
            });
            permissionsSub.on('subscribed', (msg) => {
                console.log('|+|Permission Subscribed', msg);
            });
            permissionsSub.on('unsubscribed', (msg) => {
                // console.log('|+|Permission Unsubscribed', msg);
            });
            permissionsSub.on('publication', (msg) => {
                switch(msg.data.type) {
                    case 'update':
                        //get appPermissions
                        dispatch(permissionsActions.readPermissions());
                        //get userPermissions
                        dispatch(roleActions.readUserRole(msg.data.access_role_id));
                        //Refresh userProfile 
                        dispatch(userActions.viewUserProfile(msg.data.user_id));
                        break;
                    case 'delete':
                        //Check authentication and kick user out
                        dispatch(authActions.check()); 
                        break;
                    default:
                        console.log("|+|Message Error: ", msg)
                        break;
                } 
            });
            permissionsSub.subscribe();

            //VOIP
            const voipSub = centrifuge.newSubscription(`${CHANNELS.voip}_${customer_id}`);
            voipSub.on('subscribing', (msg) => {
                // console.log('|+|VOIP Subscribing...', msg);
            });
            voipSub.on('subscribed', (msg) => {
                console.log('|+|VOIP Subscribed', msg);
            });
            voipSub.on('unsubscribed', (msg) => {
                // console.log('|+|VOIP Unsubscribed', msg);
            });
            voipSub.on('publication', (msg) => {
                console.log("|+|VOIP_Message: ", msg);
                switch (msg.data.event) {
                    case 'channel_bridge':
                        dispatch({type: types.CONNECT_CALL, call: msg.data.message, leg: msg.data.leg_uuid})
                        break;
                    case 'channel_hangup':
                        dispatch({type: types.END_CALL, call: msg.data.message, leg: msg.data.leg_uuid})
                        break;
                    case 'channel_create':
                        dispatch({type: types.NEW_CALL, call: msg.data.message, leg: msg.data.leg_uuid})
                        break;
                    case 'call_recording':
                        dispatch(office_view.callRecording('CALL_RECORDING', msg.data.data));
                        break;
                    default:
                        console.log("|+|Message Error: ", msg)
                        break;
                }
            });
            voipSub.subscribe();

            //unMount
            return () => {
                if(centrifuge){
                    centrifuge.disconnect();
                    centrifuge.setToken(null)
                    console.log("|+|Centrifuge Disconnect")
                }
            }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customer_id, user_id]);

    useEffect(() => {
        if (messageQueue.length === 0) {
          return; // Return if no messages to process.
        }
        const message = messageQueue[messageQueue.length - 1];
        NotificationInfo.alert(messageQueue, () => onOpenNotification(message.external_id, message.notification_type, message.notification_id), true);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messageQueue]);

    const onClosePhoneNotifications = () => {
        dispatch(leftSidebar.onSetActiveSecondarySidebar("view"))
        dispatch(office_view.onClosePhoneNotifications("CLOSE_CALL_NOTIFICATIONS"))
    }

    const onAnswerCall = (uuid, channel_uuid) => {
        if(savedSettingsRoute !== ""){
            //Settings Page
            navigate(savedSettingsRoute);
            dispatch(appActions.onSaveSettingsRoute(""));
            dispatch(office_view.actionButtons('CALL_ACTION_BUTTONS', { hangup: false, vmDrop: false, transfer: false, hold: false, mute: false, answer: false }));
        }
        if(notificationCalls.length > 1){
            //onHold current phone call
            const currentCallAnswered = notificationCalls.find(call => call.uuid === currentCall);
            phone.current.phoneHoldButtonPressed(currentCallAnswered?.direction === "outbound" ? currentCallAnswered?.sip_call_id : currentCallAnswered?.channel_uuid);
            //update view with anwsered call
            dispatch(office_view.currentCall('CURRENT_CALL', uuid));
        }
        dispatch(leftSidebar.onSetActiveSecondarySidebar("view"));
        dispatch(office_view.onClosePhoneNotifications("CLOSE_CALL_NOTIFICATIONS"));
        phone.current.Answer(channel_uuid);
    }

    const onIgnoreCall = (uuid, channel_uuid) => {
        // phone.current.HangUp(); //May add in future, incase BE endpoint fails
        dispatch(office_view.hangup({ pbx_hostname: calls[uuid].pbx, channel_uuid }));
        if(notificationCalls.length === 1){
            dispatch(office_view.ignore('CALL_IGNORE', true))
            dispatch(contactsActions.removeViewLookup());
        }
    }
   
    useEffect(() => {
        const displayedMessages = notificationCalls.filter((call)=> call.display && call.direction === "inbound");
        PhoneCallMessage.alert(displayedMessages, onClosePhoneNotifications, onAnswerCall, onIgnoreCall);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notificationCalls]);


    const {
        onToggleModal,
        taskModalOpen,
        setTaskModalOpen,
        control,
        watch,
        isValid,
        isDirty,
        trigger,
        setValue,
        getValues,
        reset,
        isAllowedToModify,
        eventBeingEdited,
        handleSubmitEvent,
        resetField,
        taskControl,
        taskWatch,
        taskIsValid,
        taskIsDirty,
        taskTrigger,
        taskSetValue,
        taskGetValues,
        taskClearErrors,
        taskSetError,
        taskReset,
        taskBeingEdited,
        loadTask,
        addTask,
        state,
        setState,
        onDeleteTask,
        onUpdateTask,
        handleSubmit,
        task_labels,
        organizer_id,
        organizer,
        organizer_email,
        eventModalOpen,
        emailModalOpen,
        emailData,
        person_id,
        onDelete,
        onUpdate,
        setEventModalOpen,
        onOpenNotification,
        current_user,
        setNotificationContactName
    } = NotificationPopupModals();

    return (
        <>
            <SendEmailModal
                open={emailModalOpen}
                onClose={() => { if(emailModalOpen){onToggleModal("email", 0)} }}
                contact_id={person_id}
            />
            <AddTaskModal
                open={taskModalOpen}
                onClose={() => setTaskModalOpen(false)}
                state={state}
                control={taskControl}
                watch={taskWatch}
                isValid={taskIsValid}
                isDirty={taskIsDirty}
                trigger={taskTrigger}
                setValue={taskSetValue}
                getValues={taskGetValues}
                clearErrors={taskClearErrors}
                setError={taskSetError}
                reset={taskReset}
                taskBeingEdited={taskBeingEdited}
                loadTask={loadTask}
                addTask={addTask}
                setState={setState}
                onDelete={onDeleteTask}
                onUpdate={onUpdateTask}
                task_labels={task_labels}
                organizer_id={organizer_id}
                organizer={organizer}
                organizer_email={organizer_email}
                handleSubmit={handleSubmit}
                onRefresh={() => {
                    setTaskModalOpen(false);
                }}
                email={emailData} 
            />
            <AddEventModal
                open={eventModalOpen}
                onClose={() => setEventModalOpen(false)}
                onEdit={handleSubmitEvent(onUpdate)}
                onDeleteEvent={onDelete}
                eventBeingEdited={eventBeingEdited}
                selected_users={[current_user]}
                isAllowedToModify={isAllowedToModify}
                control={control}
                watch={watch}
                isValid={isValid}
                isDirty={isDirty}
                trigger={trigger}
                setValue={setValue}
                getValues={getValues}
                reset={reset}
                resetField={resetField}
                setNotificationContactName={setNotificationContactName}
                displayAllContacts
            />
        </>
    )
}

export default WebsocketConnection;

