import { ButtonLink, Modal, NestedDropdown, Notification, STATUS_TYPES, VALIDATORS, VoicemailPlayback, generateResolver, yup } from "dyl-components";
import React, { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { Form, Header, Icon, Popup } from "semantic-ui-react";
import './index.scss'
import { useDispatch, useSelector } from "react-redux";
import { PhoneUtil } from "utils";
import pbxConfigActions from "actions/pbx_config";
import PromptOption from "./subcomponents/PromptOption";
import FullScreenModalPopUp from "shared/FullScreenModalPopUp";

const EXCLUDED_PARENTS = ["call_queue", "personal_voicemail", "conference_room", "destination_group", "parking_lot", "routing_rule"];

const Content = ({ onClose, onReload, isEditing, onDirtyChange }) => {
    const [destinationOptions, setDestinationOptions] = useState([]);
    const [audioOptions, setAudioOptions] = useState([])
    const [extensionsOptions, setExtensionsOptions] = useState([]);
    const [playAudioOptions, setPlayAudioOptions] = useState([]);
    const [labelError, setLabelError] = useState(false)
    const attemptsOptions = Array.from({ length: 10 }, (_, idx) => ({ key: idx + 1, value: idx + 1, text: idx + 1 }))
    const dispatch = useDispatch();

    const {
        isCreatingIvrPrompt,
        destinations,
        isReadingDestinations,
        soundOptions,
        isReadingSoundOptions,
        ivr,
        sound,
        isReadingSound,
        isReadingUnusedExtensions,
        unusedExtensions,
    } = useSelector((state) => state.pbx_config);

    const defaultPromptOption = {
        digit: null,
        sound_id: "none",
        destination_parent: "",
        destination_id: null
    }

    const defaultValues = {
        sound_id: isEditing && ivr?.sound?.sound_id ? ivr?.sound?.sound_id : null,
        label: isEditing && ivr?.label ? ivr?.label : "",
        alias: isEditing && ivr?.alias ? ivr?.alias : null,
        after_destination_parent: isEditing && ivr?.after_destination?.type ? ivr?.after_destination?.type : "hang_up",
        after_destination_id: isEditing && ivr?.after_destination?.destination_id ? ivr?.after_destination?.destination_id : "hang_up",
        attempts: isEditing && ivr?.attempts ? ivr?.attempts : 1,
        local_extensions: isEditing && ivr?.local_extensions ? ivr?.local_extensions : false,
        options: isEditing && ivr?.options ? ivr?.options.map((option) => {
            return {
                digit: option.digit || null,
                sound_id: option.sound?.sound_id || "none",
                destination_parent: option.destination?.type || "",
                destination_id: option.destination?.destination_id || null
            }
        }) : []
    }

    const [excludedDigits, setExcludedDigits] = useState(isEditing && ivr?.options ? ivr.options.map((option) => {
        const { digit } = option;
        return digit
    }) : []);

    const { control, formState, formState: { isValid, isDirty }, handleSubmit, getValues, setError, watch } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: generateResolver({
            sound_id: yup.mixed().required("This field is required"),
            label: VALIDATORS.TEAM_NAME().required("This field is required"),
            after_destination_id: yup.mixed().required("This field is required"),
            options: yup.array().optional().of(
                yup.object().shape({
                    digit: yup.mixed().required("This field is required"),
                    destination_id: yup.number().required("This field is required"),
                    destination_parent: yup.string().nullable(),
                    sound_id: yup.mixed().nullable()
                })
            )
        })
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: "options"
    })

    const watchedAudio = watch("sound_id");

    const onPromptOption = () => append(defaultPromptOption);

    const onAdd = async (data) => {
        try {
            const { after_destination_id, attempts, options, ...rest } = data

            const optionsCopy = [...options].map((option) => {
                const { sound_id, ...rest } = option;
                return {
                    ...rest,
                    ...(sound_id !== "none" ? { sound_id } : {}),
                }
            })

            const payload = {
                ...rest,
                ...(after_destination_id !== "hang_up" ? { after_destination_id } : {}),
                attempts: attempts || 1,
                options: optionsCopy
            }
            await dispatch(pbxConfigActions.createIvr(payload))
            onReload();
            onClose();
            Notification.alert('IVR Prompt added successfully!', STATUS_TYPES.SUCCESS);
        } catch (e) {
            console.log(e);
            Notification.alert('Failed to add IVR Prompt', STATUS_TYPES.ERROR);
        }
    }

    const onEdit = async (data) => {
        try {
            const { after_destination_id, attempts, options, ...rest } = data

            const optionsCopy = [...options].map((option) => {
                const { sound_id, ...rest } = option;
                return {
                    ...rest,
                    ...(sound_id !== "none" ? { sound_id } : {}),
                }
            })

            const payload = {
                ...rest,
                ...(after_destination_id !== "hang_up" ? { after_destination_id } : {}),
                attempts: attempts || 1,
                options: optionsCopy
            }

            await dispatch(pbxConfigActions.updateIvr(ivr.id, payload))
            onReload();
            onClose();
            Notification.alert('IVR Prompt updated successfully!', STATUS_TYPES.SUCCESS);
        } catch (e) {
            console.log(e);
            Notification.alert('Failed to update IVR Prompt', STATUS_TYPES.ERROR);
        }
    }

    const onRemove = (index) => {
        remove(index);
    }

    const onCheckDuplicatedName = async () => {
        const label = getValues("label");
        try {
            if ((!isEditing) || (isEditing && ivr?.label !== label)) {
                const isDuplicated = await dispatch(pbxConfigActions.isDuplicatedName({label, type: "ivr"}));
                if (isDuplicated) {
                    setError("label", { type: "unique", message: "Name already exists!" })
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const excludeDigit = (prevDigit, digit) => {
        setExcludedDigits((prevValues) => {
            return [...prevValues, digit].filter((digit) => digit !== prevDigit)
        })
    }

    useEffect(() => {
        if (destinations) {
            const destinationsAux = [...PhoneUtil.DESTINATION_TYPES_OPTIONS];
            const destinationOptionsAux = destinationsAux.map((destination) => {
                const { key } = destination;
                const options = destinations[key]?.map(({destination_id, label}) => (
                    {key: destination_id, value: destination_id, text: label}
                )) || []
                return {...destination, options};
            })
            setDestinationOptions(destinationOptionsAux);
        }
    }, [destinations]);

    useEffect(() => {
        if (soundOptions) {
            const { ivr } = soundOptions
            const optionsAux = ivr?.map(({name, id}) => ({
                key: id,
                value: id,
                text: name
            })) || []
            setAudioOptions(optionsAux)
        } else {
            setAudioOptions([])
        }
    }, [soundOptions]);

    useEffect(() => {
        if (unusedExtensions) {
            let optionsAux = unusedExtensions?.map((extension) => ({
                key: extension,
                value: extension,
                text: extension
            })) || []

            if (isEditing && ivr?.alias) {
                optionsAux = PhoneUtil.insertExtension(ivr.alias, optionsAux);
            }

            setExtensionsOptions(optionsAux)
        } else {
            setExtensionsOptions([])
        }
    }, [isEditing, unusedExtensions, ivr?.alias]);

    useEffect(() => {
        setLabelError(!!formState?.errors?.label)
    }, [formState])

    useEffect(() => {
        dispatch(pbxConfigActions.getDestinations());
        dispatch(pbxConfigActions.getUnusedExtensions({ type: "alias" }));
        dispatch(pbxConfigActions.getSoundOptions({category: "general"})).then((response) => {
            const { general } = response || {}
            const optionsAux = general?.map(({id, name}) => ({
                key: id,
                value: id,
                text: name
            })) || [];
            setPlayAudioOptions(optionsAux)
            dispatch(pbxConfigActions.getSoundOptions({category: "ivr"}))
        })
    }, [dispatch])

    useEffect(() => {
        if (watchedAudio) {
            dispatch(pbxConfigActions.getSound(watchedAudio))
        }
    }, [dispatch, watchedAudio])
    
    useEffect(() => {
        if (onDirtyChange) {
            onDirtyChange(isDirty);
        }
        // eslint-disable-next-line
    }, [isDirty])
    return (
        <>
            <Modal.Header>
                {isEditing ? "Edit " : "Create "} IVR Prompt
            </Modal.Header>
            <Modal.Content>
                <Form noValidate loading={isCreatingIvrPrompt}>
                    <div className="IvrPromptsModal__form">
                        <div className="IvrPromptsModal__controllerContainer">
                            <Controller
                                name='sound_id'
                                control={control}
                                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                    <Form.Select
                                        label={{
                                            children: (
                                                <span>
                                                    IVR Audio
                                                    <Popup
                                                        trigger={<Icon style={{ float: 'right', marginTop: '0.25em' }} name='fas fa-info-circle' color='primary' />}
                                                        inverted
                                                        content="Allowed files include .wav and .mp3"
                                                    />
                                                </span>
                                            )
                                        }}
                                        placeholder="Select existing audio file"
                                        options={audioOptions}
                                        className="IvrPrompts__ruleField"
                                        loading={isReadingSoundOptions || isReadingSound}
                                        onChange={async (_, { value }) => {
                                            try {
                                                onChange({ target: { name, value } });
                                            } catch (error) {
                                                onChange({ target: { name, value } });
                                            }
                                        }}
                                        error={error?.message}
                                        value={value}
                                        required
                                        clearable
                                    />
                                )}
                            />
                            <Form.Field style={{flex: 4}} />
                        </div>
                        {!!watchedAudio && !!sound && (
                            <div className="IvrPromptsModal__controllerContainer">
                                <div style={{flex: 3, margin: "7px 0px 20px 0px"}}>
                                    <VoicemailPlayback
                                        className={`audio-playback-${sound.id}`}
                                        fileName={sound.label}
                                        link={sound.url}
                                        border
                                    />
                                </div>
                                <Form.Field style={{flex: 4}} />
                            </div>
                        )}
                        <div className="IvrPromptsModal__controllerContainer">
                            <Controller
                                name='label'
                                control={control}
                                style={{flex: 1}}
                                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                    <Form.Input
                                        name={name}
                                        value={value}
                                        onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                        label="Prompt Name"
                                        placeholder="Type name"
                                        required
                                        error={error?.message}
                                        className="IvrPrompts__ruleField"
                                        onBlur={onCheckDuplicatedName}
                                    />
                                )}
                            />
                            <Controller
                                name='alias'
                                control={control}
                                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                    <Form.Select
                                        label="Internal Extension"
                                        placeholder="Select extension"
                                        options={extensionsOptions}
                                        className="IvrPrompts__ruleField"
                                        onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                        value={value}
                                        error={error?.message}
                                        loading={isReadingUnusedExtensions}
                                        clearable
                                        selectOnBlur={false}
                                    />
                                )}
                            />
                            <Controller
                                name="after_destination_parent"
                                control={control}
                                render={({ field: { name: parentName, value: parentValue, onChange: onParentChange } }) => (
                                    <Controller
                                        name="after_destination_id"
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { name: childName, value: childValue, onChange: onChildChange } = field;
                                            return (
                                                <Form.Field
                                                    control={NestedDropdown}
                                                    child_value={childValue}
                                                    parent_value={parentValue}
                                                    loading={isReadingDestinations}
                                                    nested_options={[
                                                        {key: "hang_up", value: "hang_up", text: "Hang Up", options: [], isSelectable: true},
                                                        ...destinationOptions
                                                    ]}
                                                    onChange={(_, { parent_value, child_value }) => {
                                                        onParentChange({ target: { name: parentName, value: parent_value } });
                                                        onChildChange({ target: { name: childName, value: child_value || parent_value } });
                                                    }}
                                                    placeholder="Select destination"
                                                    display_parent
                                                    selection
                                                    label="If Nothing Pressed"
                                                    pointing='top'
                                                    required
                                                    error={error?.message}
                                                    className="IvrPrompts__ruleField"
                                                    excluded_parents={EXCLUDED_PARENTS}
                                                />
                                            )
                                        }}
                                    />
                                )}
                            />
                        </div>
                        <div className="IvrPromptsModal__controllerContainer">
                            <Controller
                                name='attempts'
                                control={control}
                                render={({ field: { name, value, onChange } }) => (
                                    <Form.Select
                                        label="Attempts"
                                        placeholder="Select attempts"
                                        options={attemptsOptions}
                                        className="IvrPrompts__ruleField"
                                        onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                        value={value}
                                        selectOnBlur={false}
                                        clearable
                                    />
                                )}
                            />
                            <Controller
                                name='local_extensions'
                                control={control}
                                style={{marginBottom: 0}}
                                render={({ field: { name, value, onChange } }) => (
                                    <Form.Checkbox
                                        label="Enable direct extensions"
                                        className="IvrPrompts__ruleField"
                                        toggle
                                        onChange={(_, { checked }) => { onChange({ target: { name, value: checked } }) }}
                                        checked={value}
                                    />
                                )}
                            />
                            <Form.Field />
                        </div>
                        <div className="IvrPromptsModal__rulesContainer">
                            <Header as={"h3"} color="primary">Prompt Options</Header>
                            <div>
                                {fields.map((_, index) => (
                                    <PromptOption
                                        control={control}
                                        isReadingDestinations={isReadingDestinations}
                                        destinationOptions={destinationOptions}
                                        index={index}
                                        onRemove={onRemove}
                                        excludeDigit={excludeDigit}
                                        excludedDigits={excludedDigits}
                                        playAudioOptions={playAudioOptions}
                                        isReadingSoundOptions={isReadingSoundOptions}
                                        excluded_parents={EXCLUDED_PARENTS}
                                    />
                                ))}
                            </div>
                            <ButtonLink onClick={onPromptOption}>
                                <Icon className="fa-solid fa-plus IvrPromptsModal__blackIcon"/> Prompt Option
                            </ButtonLink>
                        </div>
                    </div>
                </Form>
            </Modal.Content>
            <Modal.Actions
                hasSaveButton
                onSave={handleSubmit(isEditing ? onEdit : onAdd)}
                saveDisabled={!isValid || !isDirty || labelError}
            />
        </>
    )
}

const IVRPromptModal = ({ open, onClose, onReload, isEditing }) => {
    const [isDirty, setIsDirty] = useState(false);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    return (
        <React.Fragment>
            <Modal open={open} onClose={() => {
                isDirty
                    ? setIsConfirmationOpen(true)
                    : onClose();
            }}>
                <Content onClose={onClose} onReload={onReload} isEditing={isEditing} onDirtyChange={(dirty) => setIsDirty(dirty)} />
            </Modal>
            <FullScreenModalPopUp
                header={"Changes not saved"}
                subheader={"Are you sure you want to exit?"}
                isOpen={isConfirmationOpen}
                onConfirm={() => {
                    onClose();
                    setIsConfirmationOpen(false);
                    setIsDirty(false)
                }}
                onFormClose={() => setIsConfirmationOpen(false)}
                closeIcon={false}
            />
        </React.Fragment>
    )
}

export default IVRPromptModal;
