const initialState = {
    calls: {},
    isReadingCalls: false,
    isHangingUp: false,
    
    incomingCalls: [],
    recentCalls: [],
    numberOfConferenceRooms: 0,
    departments: [],
    extensions: {},
    queues: [],
    callerInActionsView: null,
    actionsViewVisible: false,
    isActiveCall: false,

    selectedTeamsTab: "pinned",
    currentCall: null,
    isReadingCreds: false,
    creds: {},
    callState: 'idle',
    contactLeg: null, 
    userLeg: null,
    currentPBX: null,
    pbx: null,
    duration: 0,
    callRecordings: [],
    currentCallRecording: null,
    currentCallPhoneNumber: null,
    callDirection: null,
    isIgnore: false,
    cnam: '',
    selectedTab: 0,
    actionButtons: {
        hangup: false,
        vmDrop: false,
        transfer: false,
        hold: false,
        mute: false,
        answer: false,
    }

}

export default initialState;
