import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Icon } from "semantic-ui-react";
import { CustomGroupUtils } from "utils";

import { useParams } from "react-router-dom";

import QuoteBuilderModal from "shared/modals/QuoteBuilderModal";
import AdminSettings from "./AdminSettings";
import CustomerData from "./CustomerData";
import userActions from "actions/user";

import './FundedCustomerData.scss';
import Slider from "react-slick";
import AddUserModal from "shared/modals/AddUserModal";
import subscriptionActions from "actions/subscription";

const CarouselControl = ({ left, ...props }) => {
    return <Icon onClick={props.onClick} style={{ ...props.style, display: "block" }} className={`${props.className} fad fa-chevron-${left ? "left" : "right"}`} />
}

const FundedCustomerData = () => {
    const { account_id } = useParams();

    const primary_contact = useSelector(state => state.account.account?.primary_contact?.id);
    const account = useSelector(state => state.account.account);

    const custom_data = useSelector(state => CustomGroupUtils.groupAndFlatten(state.account.account?.custom_data || {}));
    const fields = custom_data.fields;

    const phones = useSelector(state => state.contact_phone.phone ? state.contact_phone.phone.slice(0) : []);

    const emails = useSelector(state => state.contact_email.email ? state.contact_email.email.slice(0) : []);
    const mainEmails = emails.filter(email => email.main);
    const nonMainEmails = emails.filter(email => !email.main);
    
    const locations = useSelector(state => (state.account.account?.locations.slice(0) || []));

    const socials = useSelector(state => state['account'].social_media);

    const industryOptions = useSelector(state => CustomGroupUtils.getFieldOptions(state.account.account?.custom_data?.fields || [], 'industry_sector'));
    
    const [industryValue] = `${fields?.industry_sector || ''}`?.split(',').map(value => Number(value)) || ['', ''];

    const industry = industryOptions.find(option => option.value === industryValue);
    
    const [isCreatingUser, setIsCreatingUser] = useState(false);

    const { fund_type } = useSelector(
        (state) => state.account.account?.customer || {}
    );

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(userActions.getSummarizedUsers(account_id));
        dispatch(subscriptionActions.checkIfAccountHasOpenSeats(account_id));
    }, [dispatch, account_id]);

    const [activeSlide, setActiveSlide] = useState(0);

    return (
        <Grid.Row>
            <Grid.Column>
                <Slider 
                    beforeChange={(_, i) => {setActiveSlide(i)}}
                    className="FundedCustomerData slider-container"
                    slidesToShow={1} 
                    slidesToScroll={1}
                    accessibility={false}
                    nextArrow={activeSlide === 0 ? <CarouselControl /> : <div />}
                    prevArrow={activeSlide === 1 ? <CarouselControl left /> : <div />}
                    dots
                    infinite={false}
                    draggable={false}
                    customPaging={i => (
                        <Icon color="grey" className={`fas fa-circle${activeSlide !== i ? " FundedCustomerData__dot-inactive" : ""}`} />
                    )}
                >
                    <div tabIndex={-1} className="FundedCustomerData__customer">
                        <Grid>
                            <CustomerData />
                        </Grid>
                    </div>
                    <div tabIndex={-2} className="FundedCustomerData__admin">
                        <AdminSettings onGenerateAccount={() => { setIsCreatingUser(true) }} />
                    </div>
                </Slider>
                {/** The Add User modal is placed here since the fields do not work if it's inside the carousel */}
                {isCreatingUser && (
                    <AddUserModal
                        open={isCreatingUser}
                        onClose={() => {setIsCreatingUser(false)}}
                        customer
                        contact_id={primary_contact}
                        company={{
                            account_id: Number(account_id),
                            industry: industry?.value,
                            name: account.name,
                            ein: fields?.federal_employer_identification_number,
                            email: mainEmails[0]?.email || nonMainEmails[0].email,
                            fiscal_year_start: fields?.accounting_fiscal_year,
                            locations,
                            phones: phones.map(phone => {
                                const phone_type = phone.phone_type?.toLowerCase();
                                return ({
                                    ...phone,
                                    phone_type: phone_type === "fax" ? "work_fax" : phone_type
                                });
                            }),
                            social_medias: socials,
                            trial: fund_type === 'trial', // TODO: get this info
                            demo: fund_type === 'demo',
                            website: fields?.website
                        }}
                    />
                )}
                <QuoteBuilderModal account_id={account_id} />
            </Grid.Column>
        </Grid.Row>
    )
};

export default FundedCustomerData;
