import { ButtonLink, Modal, Notification, STATUS_TYPES, VALIDATORS, generateResolver, yup } from "dyl-components";
import React, { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { Form, Header, Icon, Popup } from "semantic-ui-react";
import './index.scss'
import { useDispatch, useSelector } from "react-redux";
import { PhoneUtil } from "utils";
import pbxConfigActions from "actions/pbx_config";
import Destination from "./subcomponents/Destination";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import FullScreenModalPopUp from "shared/FullScreenModalPopUp";

const EMPTY_ITEM = {
    destination_parent: null,
    destination_id: null,
    ring_time: 30
}

const Content = ({ onClose, onReload, isEditing, onDirtyChange }) => {

    const dispatch = useDispatch();

    const [destinationOptions, setDestinationOptions] = useState([]);
    const [hasEndOfLine, setHasEndOfLine] = useState(false);

    const { destinations: destinationTypes, isReadingDestinations, isSavingDestinationGroups, destinationGroup } = useSelector((state) => state.pbx_config);

    const mapGroupItems = () => {
        return destinationGroup?.items?.map(({destination_id, label, ring_time, type}) => ({
            destination_id,
            label,
            ring_time,
            destination_parent: type
        }))
    }

    const { control, formState: { isValid, isDirty }, handleSubmit, setValue, getValues, setError } = useForm({
        mode: 'onChange',
        defaultValues: {
            label: isEditing && !!destinationGroup ? destinationGroup.label : "",
            items: isEditing && !!destinationGroup ? mapGroupItems() : [EMPTY_ITEM]
        },
        resolver: generateResolver({
            label: VALIDATORS.TEAM_NAME().required("This field is required"),
            items: yup.array().of(yup.object().shape({
                destination_id: yup.number().nullable(true).test('is-first-required', 'First destination is required', (value, context) => {
                    const index = Number(context?.path?.split("[")?.[1]?.[0]);
                    if (index === 0) {
                        return !!value;
                    }
                    return true;
                })
            }))
        })
    });

    const { fields, append, remove, move, insert } = useFieldArray({
        control,
        name: "items"
    })

    const onAddDestination = () => {
        if (hasEndOfLine) {
            insert(fields.length - 1, EMPTY_ITEM);
        } else {
            append(EMPTY_ITEM)
        }
    }

    const formatData = (data) => {
        const { items } = {...data};
        const filteredItems = items?.filter(({destination_id}) => destination_id).map(({destination_id, ring_time}) => ({
            destination_id,
            ring_time
        }))
        return {
            ...data,
            items: filteredItems,
        }
    }

    const onAdd = async (data) => {
        try {
            const payload = formatData(data);
            await dispatch(pbxConfigActions.addDestinationGroups([payload]))
            onReload();
            onClose();
            Notification.alert('Destination Group added successfully!', STATUS_TYPES.SUCCESS);
        } catch (e) {
            console.log(e);
            Notification.alert('Failed to add Destination Group', STATUS_TYPES.ERROR);
        }
    }

    const onEdit = async (data) => {
        try {
            const { id } = destinationGroup;
            const payload = formatData(data);
            await dispatch(pbxConfigActions.editDestinationGroup(id, payload))
            onReload();
            onClose();
            Notification.alert('Destination Group updated successfully!', STATUS_TYPES.SUCCESS);
        } catch (e) {
            console.log(e);
            Notification.alert('Failed to update Destination Group', STATUS_TYPES.ERROR);
        }
    }

    const onRemove = (index) => {
        remove(index);
    }

    const onRemoveEndOfLine = () => {
        setHasEndOfLine(false);
        remove(fields.length - 1)
    }

    const onDragEnd = (result) => {
        const { destination, source } = result;
        const { index: destinationIndex } = destination || {};
        const { index: sourceIndex } = source || {};
        if (sourceIndex !== undefined && destinationIndex !== undefined) {
            move(sourceIndex, destinationIndex);
        }
    }

    const onCheckDuplicatedName = async () => {
        const label = getValues("label");
        try {
            if(!isEditing || (isEditing && destinationGroup.label !== label)){
                const isDuplicated = await dispatch(pbxConfigActions.isDuplicatedName({label, type: "destination_group"}));
                if (isDuplicated) {
                    setError("label", { type: "unique", message: "Name already exists!" })
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    
    useEffect(() => {
        if (destinationTypes) {
            const destinationTypesAux = [...PhoneUtil.DESTINATION_TYPES_OPTIONS];
            const destinationOptionsAux = destinationTypesAux.map((destinationType) => {
                const { key } = destinationType; 
                const options = destinationTypes[key]?.map(({destination_id, label}) => (
                    {key: destination_id, value: destination_id, text: label}
                )) || []
                return {...destinationType, options};
            })
            setDestinationOptions(destinationOptionsAux);
        }
    }, [destinationTypes, hasEndOfLine])

    useEffect(() => {
        dispatch(pbxConfigActions.getDestinations());
    }, [dispatch])

    useEffect(() => {
        const groupHasEndOfLine = () => {
            const { items } = destinationGroup;
            return !!PhoneUtil.END_OF_LINE_TYPES.includes(items?.at(-1)?.type);
        }

        if (isEditing && !!destinationGroup && groupHasEndOfLine()) {
            setHasEndOfLine(true);
        }
    }, [destinationGroup, isEditing])

    useEffect(() => {
        if (onDirtyChange) {
            onDirtyChange(isDirty);
        }
        // eslint-disable-next-line
    }, [isDirty])

    return (
        <>
            <Modal.Header>
                {isEditing ? "Edit " : "Create "} Destination Group
                <Popup
                    trigger={<Icon style={{ float: 'right', marginTop: '0.1em', marginRight: 15 }} name='fas fa-info-circle' color='primary' size="small" />}
                    content='Destination group ring duration overrides default ring duration.'
                    inverted
                    position='top right' 
                />
            </Modal.Header>
            <Modal.Content>
                <Form noValidate loading={isSavingDestinationGroups}>
                    <div className="DestinationGroupModal__form">
                        <div className="DestinationGroupModal__controllerContainer">
                            <Controller
                                name='label'
                                control={control}
                                style={{flex: 1}}
                                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                    <Form.Input
                                        name={name}
                                        value={value}
                                        onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                        label="Name"
                                        placeholder="Type name"
                                        required
                                        error={error?.message}
                                        onBlur={onCheckDuplicatedName}
                                    />
                                )}
                            />
                        </div>
                        <div className="DestinationGroupModal__destinationsContainer">
                            <Header as="h3" color="primary">
                                Destinations
                            </Header>
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="droppable-1" type="DESTINATION">
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                        >
                                            {fields.map((_, index) => {
                                                const isDisplayed = !hasEndOfLine || index !== fields.length - 1
                                                return isDisplayed && (
                                                    <Draggable draggableId={`DESTINATION-${index}`} index={index}>
                                                    {(provided) => (
                                                        <Destination 
                                                            control={control}
                                                            isReadingDestinations={isReadingDestinations}
                                                            destinationOptions={destinationOptions}
                                                            index={index}
                                                            isDeleteVisible={fields.length > 1}
                                                            onRemove={onRemove}
                                                            setValue={setValue}
                                                            onMove={move}
                                                            setHasEndOfLine={setHasEndOfLine}
                                                            destinationCount={fields.length}
                                                            provided={provided}
                                                            hasEndOfLine={hasEndOfLine}
                                                        />
                                                    )}
                                                    </Draggable>
                                                )
                                            })}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                            {hasEndOfLine && (
                                <Destination 
                                    control={control}
                                    index={fields.length - 1}
                                    isEndOfLine
                                    isReadingDestinations={isReadingDestinations}
                                    destinationOptions={destinationOptions}
                                    isDeleteVisible={fields.length > 1}
                                    onRemove={onRemoveEndOfLine}
                                    setValue={setValue}
                                    setHasEndOfLine={setHasEndOfLine}
                                />
                            )}
                            <ButtonLink onClick={onAddDestination}>
                                <Icon className="fa-solid fa-plus DestinationGroupModal__blackIcon"/> Add New Destination
                            </ButtonLink>
                        </div>
                    </div>
                </Form>
            </Modal.Content>
            <Modal.Actions 
                hasSaveButton
                onSave={handleSubmit(isEditing ? onEdit : onAdd)}
                saveDisabled={!isValid || !isDirty}
                saveOptions={{ loading: false }}
            />  
        </>
    )
}

const DestinationGroupModal = ({ open, onClose, onReload, isEditing }) => {
    const [isDirty, setIsDirty] = useState(false);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    return (
        <React.Fragment>
            <Modal open={open} onClose={() => {
                isDirty
                    ? setIsConfirmationOpen(true)
                    : onClose();
            }} >
            <Content onClose={onClose} onReload={onReload} isEditing={isEditing} onDirtyChange={(dirty) => setIsDirty(dirty)}/>
        </Modal>
            <FullScreenModalPopUp
                header={"Changes not saved"}
                subheader={"Are you sure you want to exit?"}
                isOpen={isConfirmationOpen}
                onConfirm={() => {
                    onClose()
                    setIsConfirmationOpen(false);
                    setIsDirty(false)
                }}
                onFormClose={() => setIsConfirmationOpen(false)}
                closeIcon={false}
            />
        </React.Fragment>
    )
}

export default DestinationGroupModal;
