import React, { useEffect } from 'react';
import { Sidebar } from 'semantic-ui-react';

import { OfficeView, Favorites, Recents } from 'pages';
import { ChatRoom, CloseableContent } from 'dyl-components';
import { useDispatch, useSelector } from 'react-redux';
import officeView from 'actions/office_view';

import './index.scss';

export default function SecondarySideBar({
    isSidebarOpen,
    isActiveSecondarySidebar,
    onCloseSecondarySidebar,
    phone
}) {
    const dispatch = useDispatch();

    const { 
            callState,
            calls,
            currentCall
    } = useSelector((state) => {
        return {
            callState: state.officeView.callState,
            calls: state.officeView.calls,
            currentCall: state.officeView?.currentCall,
        }
    })
    
    useEffect(() => {
        let tickerId;
        if(callState === "active"){
            tickerId = setInterval(() => {
                dispatch(officeView.currentCallTicker('CALL_CURRENT_TICKER', calls[currentCall]?.uuid));
            }, 1000);
        }
        return () => clearInterval(tickerId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [callState, dispatch]);

    return (
        <React.Fragment>
            <Sidebar
                animation='push'
                icon='labeled'
                vertical
                visible={isActiveSecondarySidebar('favorites')}
                className={`SideBar2 SideBar2--${isSidebarOpen ? 'open' : 'closed'}`}
                style={{ width: 380 }}
            >
                <div className='SidebarContainer'>
                    <CloseableContent onClose={onCloseSecondarySidebar}>
                        {isActiveSecondarySidebar("favorites") ? <Favorites /> : <></>}
                    </CloseableContent>
                </div>
            </Sidebar>

            <Sidebar
                animation='push'
                icon='labeled'
                vertical
                visible={isActiveSecondarySidebar('recents')}
                className={`SideBar2 SideBar2--${isSidebarOpen ? 'open' : 'closed'}`}
                style={{ width: 380 }}
            >
                <div className='SidebarContainer'>
                    <CloseableContent onClose={onCloseSecondarySidebar}>
                        {isActiveSecondarySidebar("recents") ? <Recents /> : <></>}
                    </CloseableContent>
                </div>
            </Sidebar>

            <Sidebar
                animation='push'
                icon='labeled'
                vertical
                visible={isActiveSecondarySidebar('view')}
                className={`SideBar2 SideBar2--wide SideBar2--${isSidebarOpen ? 'open' : 'closed'}`}>

                    {isActiveSecondarySidebar("view") ? <OfficeView isActive={isActiveSecondarySidebar('view')} onClose={onCloseSecondarySidebar} phone={phone} /> : <></>}
            </Sidebar>

            <Sidebar
                animation='push'
                icon='labeled'
                vertical
                visible={isActiveSecondarySidebar('chat')}
                className={`SideBar2 SideBar2--wide SideBar2--${isSidebarOpen ? 'open' : 'closed'}`}>
                <ChatRoom />
            </Sidebar>
        </React.Fragment>
    )
}
