import React, { useState, useEffect } from 'react';
import { RichTextEditor, STATUS_TYPES, TEXT_EDITOR_MODES, FileInput, ControlledPopup, Notification } from "dyl-components";
import { Button, Form, Icon, List, ListItem, Dropdown, Segment, Grid, Input } from 'semantic-ui-react';
import { Controller } from 'react-hook-form';
import { DateTimeUtils } from 'dyl-components';
import { useDispatch, useSelector } from 'react-redux';
import relationshipsActions from 'actions/relationships';
import userActions from 'actions/user';

import './index.scss';
import DateOptions from './subcomponents/DateOptions';
import AttendeesOptions from './subcomponents/AttendeesOptions';
import LocationOptions from './subcomponents/LocationOptions';
import EventTemplates from './EventTemplates';
import { Person } from 'dyl-components';
import FileUtils, { FILE_CATEGORIES } from 'utils/FileUtils';

const EventForm = ({
    dropzoneRef = React.createRef(),
    editorRef = React.createRef(),
    event_labels,
    event_templates = EventTemplates,
    isSaving,

    eventBeingEdited = null,

    isAllowedToModify,
    contacts,

    onSave,
    onUpdate,
    onDelete,
    isDeleting,
    isReading,

    control,
    watch,
    isValid,
    isDirty,
    trigger,
    setValue,
    getValues,
    reset,

    isModal,
    setNotificationContactName,
    displayAllContacts = false,
    toolbarClassName
}) => {
    const [includeCallInfo, toggleCallInfo] = useState(false);
    const [editorMode, setEditorMode] = useState(TEXT_EDITOR_MODES.RICH_TEXT);

    const isReadOnly = !isAllowedToModify;
    const isNewEvent = eventBeingEdited?.id === undefined;
    const dispatch = useDispatch();
    const { user_id } = useSelector((state) => state.auth);

    let { tz, user_name, user_email, contact_name, organizer_id } = useSelector(state => {
        const contact_name = `${state.contact.contact?.first_name} ${state.contact.contact?.last_name}`;
        return {
            tz: state.auth.timezone,
            user_name: state.auth.name,
            user_email: state.user.userProfile?.email,
            contact_name,
            organizer_id: state.user.userProfile?.user_id
        }
    });
    
    const onSwitchToHTML = () => {
        setEditorMode(TEXT_EDITOR_MODES.HTML);
    }

    const onSwitchToRichText = () => {
        setEditorMode(TEXT_EDITOR_MODES.RICH_TEXT);
    }

    const onSubmit = async () => {
        if (onSave) {
            if (await onSave({...getValues(), contact_name, organizer_id})) {
                reset();
            };
        }
    }

    const onSaveEdit = () => {
        if (onUpdate) {
            onUpdate({...getValues(), contact_name});
        }
    }

    const onDeleteEvent = () => {
        onDelete(eventBeingEdited.id);
    }

    

    useEffect(() => {
        if (eventBeingEdited != null && eventBeingEdited.id) {
            const { conference_line, phone_number, pin } = eventBeingEdited;
            if (conference_line || phone_number || pin) {
                toggleCallInfo(true);
            } else {
                toggleCallInfo(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventBeingEdited]);

    useEffect(() => {
        const getLoggedUserProfile = async () => {
            await dispatch(userActions.viewUserProfile(user_id));
        }
        getLoggedUserProfile();
    }, [dispatch, user_id])

    const watchContacts = watch("contacts");
    // populate related_to options from contact
    const [relationOptions, setRelationOptions] = useState([])
    const loadRelatedOptions = (contactName) => {
        const [persons] = getValues(['contacts'])
        if (persons?.length > 0 && watchContacts.length > 0) {
            isModal && setNotificationContactName(contactName);
            dispatch(relationshipsActions.getContactRelations({ person_id: persons || [] }))
                .then((relations) => {
                    const options = relations.map(
                        ({ related_type, related_id, related_name }, key) => {
                            const value = `${related_type}-${related_id}`;
                            const [first,] = related_name.split("-");
                            const isLead = first === "Lead "

                            const className_name = {
                                customer: 'fas fa-user-crown',
                                customer_pipeline: 'fas fa-crown',
                                pipeline_stage: isLead ? 'fas fa-address-card' : 'fas fa-filter-circle-dollar'
                            }[related_type] || '';
                            const text = <><Icon className={className_name} />{related_name}</>;
                            return { key: related_id, value, text }
                        }
                    );
                    setRelationOptions(options);

                })
                .catch((err) => console.log(err))
        } else {
            setRelationOptions([]);
        }
    }
    // populate related_to options on page load
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(loadRelatedOptions, [watchContacts]);

    const [template, setTemplate] = useState("");

    const minDate = isNewEvent ? DateTimeUtils.getCurrentDate(DateTimeUtils.WORD_DATE_FORMAT, false, false) : (
        DateTimeUtils.isBeforeOrOnCurrentDate(eventBeingEdited.start_date, DateTimeUtils.WORD_DATE_FORMAT) ? eventBeingEdited.start_date : (
            DateTimeUtils.getCurrentDate(DateTimeUtils.WORD_DATE_FORMAT, false, false)
        )
    );

    const renderEventLabelOptions = () => {
        const { label, label_name, start_date } = getValues();
        if (label && DateTimeUtils.isBeforeOrOnCurrentDate(DateTimeUtils.formatEpoch(start_date, DateTimeUtils.DATETIME_FORMAT), DateTimeUtils.DATETIME_FORMAT) && event_labels.findIndex(({ value }) => label === value) === -1) {
            return [...event_labels, { key: label, value: label, text: label_name }];
        }
        return event_labels;
    }

    return (
        <Grid className='EventFormBody' stackable>
            <Grid.Row columns='equal' className='EventFormBody__Row'>
                <Grid.Column>
                    <Form loading={isSaving || isReading} as={List}>
                        <Controller
                            name='name'
                            value={''}
                            control={control}
                            render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                <ListItem
                                    icon={
                                        <Icon name='calendar' color='black' />
                                    }
                                    description={(
                                        <Form.Input
                                            label='Event Name'
                                            onChange={onChange}
                                            name={name}
                                            value={value}
                                            error={error && error.message && {
                                                content: error.message,
                                                pointing: 'below'
                                            }}
                                            required
                                            placeholder="Type event name"
                                            disabled={isReadOnly}
                                        />
                                    )}
                                />
                            )}
                        />
                        <Form.Group widths='equal' className="Event__row">
                            <Controller
                                name='label'
                                control={control}
                                render={({ field: { onChange, value, name } }) => (
                                    <Form.Select
                                        label='Event Label'
                                        value={value}
                                        name={name}
                                        onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                                        options={renderEventLabelOptions()}
                                        placeholder="Select label"
                                        selectOnBlur={false}
                                        clearable
                                        disabled={isReadOnly}
                                    />
                                )}
                            />
                            <Controller
                                name='related_to'
                                control={control}
                                render={({ field: { onChange, value, name } }) => (
                                    <Form.Select
                                        label={<label><Icon name={'arrows alternate horizontal'} />Related To</label>}
                                        value={value}
                                        name={name}
                                        onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                                        clearable
                                        placeholder="Select"
                                        options={relationOptions}
                                        selectOnBlur={false}
                                        disabled={isReadOnly || relationOptions.length === 0}
                                    />
                                )}
                            />
                        </Form.Group>

                        <DateOptions
                            control={control}
                            all_day={eventBeingEdited?.all_day || false}
                            minDate={minDate}
                            trigger={trigger}
                            isReadOnly={isReadOnly}
                            setValue={setValue}
                            getValues={getValues}
                            watch={watch}
                            tz={tz}
                            id={eventBeingEdited?.id || null}
                        />

                        <ListItem
                            icon='user'
                            className='Event__organizer'
                            description={(
                                <Form.Field control={Segment} label="Organizer" size='small' required disabled={isReadOnly}>
                                    <Person
                                        username={isNewEvent ? user_name : eventBeingEdited.organizer}
                                        email={isNewEvent ? user_email : eventBeingEdited.organizer_email}
                                        unsetFontSize={isModal}
                                    />
                                </Form.Field>
                            )}
                        />

                        <AttendeesOptions
                            control={control}
                            attendees={eventBeingEdited?.attendees}
                            isModal={isModal}
                            trigger={trigger}
                            contacts={contacts}
                            isReadOnly={isReadOnly}
                            id={eventBeingEdited?.id || null}
                            onContactsChange={loadRelatedOptions}
                            usersSelected={eventBeingEdited?.id ? eventBeingEdited.usersSelected : []}
                            contactsSelected={eventBeingEdited.contactsSelected}
                            user_id={user_id}
                            displayAllContacts={displayAllContacts}
                        />

                        <LocationOptions
                            control={control}
                            includeCallInfo={includeCallInfo}
                            toggleCallInfo={toggleCallInfo}
                            isReadOnly={isReadOnly}
                        />

                        {/* Remove until post BETA
                        <ListItem
                            icon={
                                <Icon name='bell' color='black' />
                            }
                            description={(
                                <Form.Field className="Event__notification" type="button" status={STATUS_TYPES.DEFAULT} control={ButtonLink} label="Notifications" disabled={isReadOnly}>
                                    + Add Notification
                                </Form.Field>
                            )}
                        /> */}

                        <ListItem
                            className='Event__description'
                            icon={
                                <Icon name='align left' color='black' />
                            }
                            description={(
                                <Controller
                                    name='content'
                                    control={control}
                                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                        <Form.Field
                                            control={RichTextEditor}
                                            label='Description'
                                            onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                            value={value}
                                            toolbarClassName={toolbarClassName}
                                            allowSwitchToHTML
                                            onSwitchToHTML={onSwitchToHTML}
                                            onSwitchToRichText={onSwitchToRichText}
                                            mode={editorMode}
                                            editorRef={editorRef}
                                            size='small'
                                            disabled={isReadOnly}
                                            otherControls={[
                                                <Dropdown
                                                    name='template'
                                                    options={event_templates}
                                                    selectOnBlur={false}
                                                    clearable
                                                    onChange={(_, { value }) => {
                                                        setTemplate(value);
                                                        onChange({ target: { name, value } })
                                                    }}
                                                    placeholder="Insert Event Template"
                                                    basic
                                                    className='Event__template-selector ql-custom-dropdown'
                                                    value={template}
                                                    icon={<Icon name={template ? 'times' : 'sort'} />}
                                                />
                                            ]}
                                            error={error && error.message && {
                                                content: error.message,
                                                pointing: 'below'
                                            }}
                                        />
                                    )}
                                />
                            )}
                        />

                        <ListItem
                            icon={
                                <Icon name='attach' color='black' /> 
                            }
                            description={(
                                <Controller
                                    name="attachments"
                                    control={control}
                                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                            <Form.Field
                                                className='Event__attachments-section'
                                                control={Input}
                                                label={<div>
                                                    <Icon onClick={() => { dropzoneRef.current.open(); }} color='blue' name='plus circle' link /> <b>Attach {value.length > 0 && 'more '}files</b>
                                                </div>}
                                                error={error && error.message && {
                                                    pointing: 'below'
                                                }}
                                                disabled={isReadOnly}
                                            >
                                            <Segment style={{width: '100%', padding: 0}} basic>
                                            <div className='Event__attachments'>
                                                <FileInput
                                                    onChange={(_, { value: new_value }) => {
                                                        onChange({
                                                            target: {
                                                                name, value: [
                                                                    ...value,
                                                                    ...new_value.filter(new_file => (
                                                                        value.findIndex(file => file.path === new_file.path) === -1
                                                                    ))
                                                                ]
                                                            }
                                                        });
                                                    }}
                                                    onRemove={(_, { value }) => { onChange({ target: { name, value } }); }}
                                                    onReject={(rejected) => {
                                                        if (rejected.length > 0) {
                                                            Notification.alert("File type must be .pdf.", STATUS_TYPES.ERROR, true);
                                                        }
                                                    }}
                                                    files={value}
                                                    name="files"
                                                    accept="application/pdf"
                                                    icon="file pdf outline"
                                                    size="mini"
                                                    dropzoneRef={dropzoneRef}
                                                    showIcon
                                                    disabled={isReadOnly}
                                                    hasBrowse
                                                />
                                            </div>
                                            <i>Remaining: {FileUtils.getRemainingSize(value, FILE_CATEGORIES.EMAIL)} MB </i> {error?.message && `(${error.message})`}
                                            </Segment>
                                        </Form.Field>
                                    )}
                                />
                                
                            )}
                        />
                    </Form>

                </Grid.Column>
            </Grid.Row>
            {isModal ? null : (
                <Grid.Row columns='equal' className='FormTab__Row'>
                    <Grid.Column textAlign='left' className='EventFormFooter'>
                        {(!isDeleting && !(eventBeingEdited?.id && !isAllowedToModify)) && (<Button loading={isSaving} disabled={!isValid || !isDirty || isSaving} onClick={isNewEvent ? onSubmit : onSaveEdit} content='Save' primary />)}
                        {(eventBeingEdited?.id && isAllowedToModify && !isSaving) && (
                            <ControlledPopup
                                trigger={<Button loading={isDeleting} disabled={isDeleting} negative>Delete</Button>}
                                onConfirm={onDeleteEvent}
                            />
                        )}
                    </Grid.Column>
                </Grid.Row>
            )}

        </Grid>
    )
};

export default EventForm;
