import React, { useEffect, useState } from 'react';
import { Segment, Form, Portal, Header, Breadcrumb } from 'semantic-ui-react';
import { DateTimeUtils, DatesRangeInput, NestedDropdown, Notification, STATUS_TYPES, VALIDATORS, generateResolver, yup, Button } from 'dyl-components';

import { Controller, useForm } from 'react-hook-form';
import SettingsFooter from 'dyl-components/molecules/SettingsFooter';
import useWidthListener from 'shared/SettingsFooter/useWidthListener';
import useWindowWidth from 'shared/SettingsFooter/useWindowWidth';
import { MathUtils, StringUtils } from 'utils';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import campaignCategoryActions from 'actions/campaign_category';
import { FIELD_TYPE, FIELD_TYPE_CONTROL } from 'shared/CustomData/DynamicField';
import campaignsActions from 'actions/campaigns';
import ConfirmModal from 'shared/confirmation/ConfirmModal';
import CustomPrompt from 'shared/confirmation/CustomPrompt';
import { useConfirm } from 'shared/confirmation/useConfirm';
import applyCurrencySchema from 'shared/schemas/products/applyCurrencySchema';

const CAMPAIGN_CONVERSION_VALUES = ['lead', 'opportunity', 'customer', 'custom'];

const Campaign = ({
    id,

    name,
    shared_type,
    description,
    campaign_conversion,

    period,
    category,
    subcategory,

    expected_responses,
    expected_revenue,
    budgeted_cost
}) => {
    const width = useWidthListener("settingsSidebar");
    const windowWidth = useWindowWidth();

    const dispatch = useDispatch();

    const { categories, isSaving, isCheckingDuplicate } = useSelector(state => ({
        categories: state.campaign_category.categories.map(category => ({
            key: category.id,
            value: category.id,
            text: category.name,
            options: (
                category?.campaign_subcategories?.map(type => ({
                    key: type.id,
                    value: type.id,
                    text: type.name
                })) || []
            )
        })) || [],
        isSaving: state.campaigns.isCreatingCampaign || state.campaigns.campaignBeingUpdated,
        isCheckingDuplicate: state.campaigns.isCheckingDuplicate
    }))

    const [typingTimeout, setTypingtimeout] = useState(null);

    const NAME_VALIDATOR = yup.string().no_whitespace_only().no_excessive_whitespaces().maxlength(64).simple_alphanumeric();
    const { formState: { isValid, isDirty, dirtyFields }, control, handleSubmit, setError, clearErrors, resetField } = useForm({
        mode: 'onChange',
        defaultValues: {
            shared_type: shared_type || 'personal',
            name: name || '',
            description: description || '',

            period: period || '',
            category: category || null,
            subcategory: subcategory || null,

            expected_responses: expected_responses || null,
            expected_revenue: expected_revenue || null,
            budgeted_cost: budgeted_cost || null,
            campaign_conversion: campaign_conversion
        },
        resolver: generateResolver({
            shared_type: yup.mixed().oneOf(['personal', 'company']).required('This field is required'),
            name: NAME_VALIDATOR.required('This field is required'),
            description: VALIDATORS.DESCRIPTION(),
            campaign_conversion: yup.string().oneOf(CAMPAIGN_CONVERSION_VALUES).required('This field is required'),
            period: yup.string().when("shared_type", {
                is: value => value === 'company',
                then: schema => schema.required('This field is required').test('valid_range', 'Please enter valid start date and end date', function (value) {
                    if (!!!value) {
                        return true;
                    }
                    const [start_date, end_date] = value.split(' - ');
                    return DateTimeUtils.isValid(start_date, DateTimeUtils.WORD_DATE_FORMAT) && DateTimeUtils.isValid(end_date, DateTimeUtils.WORD_DATE_FORMAT);
                }).test('cannot_select_past_dates', 'Start date should not be a past date', function (value) {
                    if (!!!value || id) {
                        return true;
                    }
                    const [start_date] = value.split(' - ');
                    const currentDate = DateTimeUtils.getCurrentDate(DateTimeUtils.WORD_DATE_FORMAT);
                    return !DateTimeUtils.isValid(start_date, DateTimeUtils.WORD_DATE_FORMAT) || !DateTimeUtils.dateIsBeforeAnotherDate(start_date, currentDate, "days", DateTimeUtils.WORD_DATE_FORMAT)
                }).test('end_date_cannot_be_before_start_date', 'End date should be later than start date', function (value) {
                    if (!!!value) {
                        return true;
                    }
                    const [start_date, end_date] = value.split(' - ');
                    if (!DateTimeUtils.isValid(start_date, DateTimeUtils.WORD_DATE_FORMAT) || !DateTimeUtils.isValid(end_date, DateTimeUtils.WORD_DATE_FORMAT)) {
                        return true;
                    }
                    return !DateTimeUtils.dateIsBeforeAnotherDate(end_date, start_date, "days", DateTimeUtils.WORD_DATE_FORMAT);
                })
            }),
            expected_responses: yup.lazy(value => value === null || value === '' ? yup.string().nullable(true) : yup.number().max(32767, "Exceeded maximum number of expected responses")),
            expected_revenue: yup.lazy(value => value === null || value === '' ? yup.string().nullable(true) : applyCurrencySchema(yup.number(), "Please enter a valid revenue", 0)),
            budgeted_cost: yup.lazy(value => value === null || value === '' ? yup.string().nullable(true) : applyCurrencySchema(yup.number(), "Please enter a valid cost", 0))
        })
    });

    const navigate = useNavigate();

    const onSave = async (data) => {
        try {
            const { name, description, shared_type, campaign_conversion } = data;
            const payload = {
                name,
                description,
                campaign_conversion,
                ...(shared_type === 'company' ? (() => {
                    const { period, category: campaign_category_id, subcategory: campaign_subcategory_id, expected_responses, expected_revenue, budgeted_cost } = data;
                    const [start_time, end_time] = period.split(' - ');
                    const has_company = {
                        campaign_category_id,
                        campaign_subcategory_id,
                        expected_responses: expected_responses === '' ? 0 : expected_responses,
                        expected_revenue: expected_revenue === '' ? 0 : expected_revenue,
                        budgeted_cost: budgeted_cost === '' ? 0 : budgeted_cost,
                        start_time: DateTimeUtils.getUnixTime(start_time, DateTimeUtils.WORD_DATE_FORMAT),
                        end_time: DateTimeUtils.getUnixTime(end_time, DateTimeUtils.WORD_DATE_FORMAT)
                    };
                    if (id) {
                        if (!dirtyFields.category) {
                            delete has_company.campaign_category_id
                        }
                        if (!dirtyFields.subcategory) {
                            delete has_company.campaign_subcategory_id
                        }
                        if (!dirtyFields.period) {
                            delete has_company.start_time;
                            delete has_company.end_time;
                        }
                        if (!dirtyFields.expected_responses) {
                            delete has_company.expected_responses
                        }
                        if (!dirtyFields.expected_revenue) {
                            delete has_company.expected_revenue
                        }
                        if (!dirtyFields.budgeted_cost) {
                            delete has_company.budgeted_cost
                        }
                        if (Object.keys(has_company).length === 0) {
                            return {};
                        }
                    }
                    return {
                        has_company
                    }
                })() : {})
            }
            if (!dirtyFields.name) {
                delete payload.name
            };
            if (!dirtyFields.description) {
                delete payload.description
            }
            await (id ?
                dispatch(campaignsActions.updateCampaign(Number(id), payload)) :
                dispatch(campaignsActions.createCampaign(payload))
            );
            Notification.alert(`Successfully ${id ? 'updated' : 'created'} ${shared_type} campaign!`, STATUS_TYPES.SUCCESS);
            navigate('/campaigns', { replace: true, state: { saved: true } });
        } catch (e) {
            console.log(e);
            if (e?.Code !== 409) {
                Notification.alert(`Failed to ${id ? 'update' : 'create'} ${shared_type} campaign`, STATUS_TYPES.ERROR);
            } else {
                Notification.alert(`Campaign name already exists!`, STATUS_TYPES.ERROR);
                setError('name', {
                    type: 'unique_campaign',
                    message: 'Campaign name already exists!'
                });
            }
        }
    }

    const campaignConversionField = (
        <Controller
            control={control}
            name="campaign_conversion"
            render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                <Form.Select
                    label='Campaign Conversion'
                    disabled={id !== undefined}
                    required
                    value={value}
                    onChange={(_, { value }) => {
                        onChange({ target: { name, value } })
                    }}
                    error={error?.message}
                    placeholder='Select Campaign Conversion'
                    options={CAMPAIGN_CONVERSION_VALUES.map(value => ({
                        key: value,
                        value,
                        text: StringUtils.capitalize(value)
                    }))}
                    selectOnBlur={false}
                />
            )}
        />
    )

    const { isConfirmed } = useConfirm();

    CustomPrompt(null, isDirty, isConfirmed, 'Changes not saved', 'Are you sure you want to exit?');

    return (
        <>
            <ConfirmModal />
            <Segment basic disabled={isSaving} loading={isSaving}>
                <Breadcrumb>
                    <Breadcrumb.Section as={Link} to='/campaigns'>
                        Campaigns
                    </Breadcrumb.Section>
                    <Breadcrumb.Divider />
                    <Breadcrumb.Section>
                        {id ? `Edit "${name}"` : "Create Campaign"}
                    </Breadcrumb.Section>
                </Breadcrumb>
                <Header as='h1' color='primary'>
                    {id ? 'Edit' : 'Create'} Campaign
                </Header>
                <Controller
                    control={control}
                    name='shared_type'
                    render={({ field: { name: sharedTypeName, value: sharedTypeValue, onChange: sharedTypeOnChange }, fieldState: { error: sharedTypeError } }) => (
                        <Form noValidate>
                            <Form.Group widths={4}>
                                <Form.Select
                                    label='Shared Type'
                                    disabled={id !== undefined}
                                    required
                                    value={sharedTypeValue}
                                    onChange={(_, { value }) => {
                                        sharedTypeOnChange({ target: { name: sharedTypeName, value } });
                                        if (value === 'personal') {
                                            resetField('expected_responses');
                                            resetField('budgeted_cost');
                                            resetField('expected_revenue');
                                            resetField('period');
                                            resetField('category');
                                            resetField('subcategory');
                                        }
                                    }}
                                    error={sharedTypeError?.message}
                                    placeholder='Select Shared Type'
                                    options={[
                                        {
                                            key: 'personal', value: 'personal', text: 'Personal'
                                        },
                                        {
                                            key: 'company', value: 'company', text: 'Company'
                                        }
                                    ]}
                                    selectOnBlur={false}
                                />
                                <Controller
                                    control={control}
                                    name='name'
                                    render={({ field: { name: fieldName, value, onChange }, fieldState: { error } }) => (
                                        <Form.Input
                                            label='Campaign Name'
                                            required
                                            value={value}
                                            onChange={async (_, { value }) => {
                                                if (typingTimeout) {
                                                    clearTimeout(typingTimeout)
                                                }
                                                onChange({ target: { name: fieldName, value } });
                                                const isValid = await NAME_VALIDATOR.isValid(value);
                                                if (isValid && value !== '') {
                                                    setTypingtimeout(setTimeout(async () => {
                                                        await clearErrors(fieldName);
                                                        if (!id || value !== name) {
                                                            const duplicate = (await dispatch(campaignsActions.checkDuplicate({ campaign_name: value })))?.id;
                                                            if (duplicate) {
                                                                await setError("name", {
                                                                    type: 'unique_campaign',
                                                                    message: 'Campaign name already exists!'
                                                                })
                                                            }
                                                        }
                                                    }, 150));
                                                }
                                            }}
                                            error={error?.message}
                                            loading={isCheckingDuplicate}
                                            placeholder='Enter Campaign Name'
                                        />
                                    )}
                                />
                                <Controller
                                    control={control}
                                    name='description'
                                    render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                        <Form.Input
                                            label='Description'
                                            value={value}
                                            onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                            error={error?.message}
                                            placeholder='Enter Description'
                                        />
                                    )}
                                />
                                {sharedTypeValue === 'personal' && campaignConversionField}
                                {sharedTypeValue === 'company' && (
                                    <Controller
                                        control={control}
                                        name='period'
                                        render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                            <Form.Field
                                                control={DatesRangeInput}
                                                label='Start/End Date'
                                                value={value}
                                                onChange={(_, { value }) => {
                                                    const newValue = (() => {
                                                        if (id) {
                                                            const [start_date] = period.split(' - ');
                                                            const [, end_date] = value?.split(' - ');
                                                            if (end_date) {
                                                                return `${start_date} - ${end_date}`;
                                                            }
                                                            return `${start_date} - `;
                                                        }
                                                        return value;
                                                    })();
                                                    onChange({ target: { name, value: newValue } })
                                                }}
                                                error={error?.message}
                                                placeholder='Select Start/End Date'
                                                clearable
                                                required
                                                dateFormat={DateTimeUtils.WORD_DATE_FORMAT}
                                                minDate={DateTimeUtils.getCurrentDate(DateTimeUtils.WORD_DATE_FORMAT)}
                                            />
                                        )}
                                    />
                                )}
                            </Form.Group>
                            {sharedTypeValue === 'company' && (
                                <Form.Group widths={4}>
                                    <Controller
                                        name='category'
                                        control={control}
                                        render={({ field: { name: parentName, value: parentValue, onChange: parentOnChange } }) => (
                                            <Controller
                                                name='subcategory'
                                                control={control}
                                                render={({ field: { name, value, onChange } }) => (
                                                    <Form.Field>
                                                        <label>Campaign Category/Subcategory</label>
                                                        <NestedDropdown
                                                            allowedToSelectParentOnly
                                                            child_value={value}
                                                            parent_value={parentValue}
                                                            loading={false}
                                                            nested_options={categories}
                                                            onChange={(_, { parent_value, child_value }) => {
                                                                parentOnChange({ target: { name: parentName, value: parent_value } });
                                                                onChange({ target: { name, value: child_value } });
                                                            }}
                                                            placeholder={`Select Category/Subcategory`}
                                                            display_parent
                                                            selection
                                                        />
                                                    </Form.Field>
                                                )}
                                            />
                                        )}
                                    />
                                    {campaignConversionField}
                                </Form.Group>
                            )}
                            {sharedTypeValue === 'company' && [
                                <Header as='h2' color='primary'>
                                    Expected Campaign Success
                                </Header>,
                                <Form.Group widths={4}>
                                    <Controller
                                        control={control}
                                        name='expected_responses'
                                        render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                            <Form.Field
                                                control={FIELD_TYPE_CONTROL[FIELD_TYPE.NUMBER]}
                                                label='Expected Responses'
                                                value={value}
                                                onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                                error={error?.message}
                                                placeholder='Enter Expected Responses'
                                                type='number'
                                            />
                                        )}
                                    />
                                    <Controller
                                        control={control}
                                        name='expected_revenue'
                                        render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                            <Form.Field
                                                control={FIELD_TYPE_CONTROL[FIELD_TYPE.CURRENCY]}
                                                label='Expected Revenue'
                                                value={value}
                                                onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                                error={error?.message}
                                                placeholder='Enter Expected Revenue'
                                                type='number'
                                                min='0'
                                            />
                                        )}
                                    />
                                    <Controller
                                        control={control}
                                        name='budgeted_cost'
                                        render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                            <Form.Field
                                                control={FIELD_TYPE_CONTROL[FIELD_TYPE.CURRENCY]}
                                                label='Budgeted Cost'
                                                value={value}
                                                onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                                error={error?.message}
                                                placeholder='Enter Budgeted Cost'
                                                type='number'
                                                min='0'
                                            />
                                        )}
                                    />
                                </Form.Group>
                            ]}
                        </Form>
                    )}
                />
            </Segment>
            <Portal open>
                <SettingsFooter
                    style={{ width: MathUtils.calculatePercentage(windowWidth, windowWidth - width) }}
                    className={`Webform__menu`}
                    rightOptions={[
                        <Button as={Link} disabled={isSaving} basic status='cancel' to='/campaigns'>Cancel</Button>,
                        <Button loading={isSaving} onClick={handleSubmit(onSave)} disabled={!isValid || !isDirty || isSaving || isCheckingDuplicate} primary>
                            Save
                        </Button>
                    ]}
                />
            </Portal>
        </>
    );
};

const CampaignContainer = () => {
    const { id } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        if (id) {
            dispatch(campaignsActions.readCampaign(id));
        }
        dispatch(campaignCategoryActions.getCampaignCategoryOptions())
    }, [id, dispatch]);

    const {
        name,
        shared_type,
        description,
        period,
        category,
        subcategory,
        campaign_conversion,

        expected_responses,
        expected_revenue,
        budgeted_cost,

        isReadingCampaign
    } = useSelector(state => {
        const { name, description, is_company_campaign: companyCampaignDetails, campaign_conversion } = state.campaigns.campaign || {
            name: '',
            description: '',
            campaign_conversion: null,
            is_company_campaign: null
        }
        const isCompanyCampaign = companyCampaignDetails && Object.keys(companyCampaignDetails).length > 0;
        const start_date = isCompanyCampaign ? DateTimeUtils.changeFormat(DateTimeUtils.convertUnixTimeToDate(companyCampaignDetails.start_time), "", DateTimeUtils.WORD_DATE_FORMAT, 'UTC') : '';
        const end_date = isCompanyCampaign ? DateTimeUtils.changeFormat(DateTimeUtils.convertUnixTimeToDate(companyCampaignDetails.end_time), "", DateTimeUtils.WORD_DATE_FORMAT, 'UTC') : '';
        const period = isCompanyCampaign ? `${start_date} - ${end_date}` : '';
        return ({
            isReadingCampaign: state.campaigns.isReadingCampaign,

            shared_type: isCompanyCampaign ? 'company' : 'personal',
            name: name || '',
            description: description || '',

            period,
            category: companyCampaignDetails?.campaign_category?.campaign_category_id || null,
            subcategory: companyCampaignDetails?.campaign_category?.campaign_subcategory_id || null,

            expected_responses: companyCampaignDetails?.expected_responses || null,
            expected_revenue: companyCampaignDetails?.expected_revenue || null,
            budgeted_cost: companyCampaignDetails?.budgeted_cost || null,
            campaign_conversion: campaign_conversion || null
        });
    });

    if (isReadingCampaign) {
        return <Segment loading basic />
    }

    if (!id) {
        return <Campaign />
    }

    return (
        <Campaign
            budgeted_cost={budgeted_cost}
            category={category}
            subcategory={subcategory}
            shared_type={shared_type}
            description={description}
            expected_responses={expected_responses}
            expected_revenue={expected_revenue}
            id={id}
            name={name}
            period={period}
            campaign_conversion={campaign_conversion}
        />
    )
}

export default CampaignContainer;
