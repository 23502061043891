import React from 'react';
import { Form, Button } from 'semantic-ui-react';

import SearchableMultiSelection from './subcomponents/SearchableMultiSelection';
import masterAccountActions from 'actions/master_account';
import { Controller, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { Notification, STATUS_TYPES, generateResolver, VALIDATORS, Modal } from 'dyl-components';
import { useNavigate } from 'react-router-dom';

const mapDispatchToProps = dispatch => ({
    onCreateMasterAccount: (payload) => {
        return dispatch(masterAccountActions.create(payload));
    },
    onLinkAccountsToMasterAccount: (id, payload) => {
        return dispatch(masterAccountActions.linkAccounts(id, payload));
    }
});

const mapStateToProps = state => ({
    isCreatingMasterAccount: state.master_account.isCreatingMasterAccount,
    isLinkingAccounts: state.master_account.isLinkingAccounts
})

const MasterAccountForm = ({
    onCreateMasterAccount,
    isCreatingMasterAccount,
    close,

    master_account,
    onLinkAccountsToMasterAccount,
    isLinkingAccounts,
    refreshFromLinking
}) => {
    const { control, formState: { isValid, isDirty }, getValues, setError } = useForm({
        mode: 'onChange',
        defaultValues: {
            name: master_account?.name || '',
            accounts: []
        },
        resolver: generateResolver({
            name: VALIDATORS.MASTER_NAME().no_whitespace_only().required('This field is required')
        })
    });

    const navigate = useNavigate();

    const createMasterAccount = async () => {
        const { name, accounts } = getValues();
        try {
            const { id } = await onCreateMasterAccount({ name, account_id: accounts.map(account => account._id) });
            Notification.alert('Successfully created master account!', STATUS_TYPES.SUCCESS);
            close();
            navigate(`/master_account/${id}`);
        } catch (e) {
            if (e?.code !== 409) {
                Notification.alert(`Failed to create master account`, STATUS_TYPES.ERROR);
            } else {
                Notification.alert(`Master account already exists`, STATUS_TYPES.ERROR);
                setError("name", { type: "unique", message: "Name already exists!" });
            }
        }
    }

    const linkAccountsToMasterAccount = async () => {
        const { accounts } = getValues();
        try {
            await onLinkAccountsToMasterAccount(master_account.id, accounts.map(account => account._id));
            Notification.alert(`Successfully linked accounts to ${master_account.name}!`, STATUS_TYPES.SUCCESS);
            close();
            refreshFromLinking();
        } catch (e) {
            Notification.alert(`Failed to link accounts to ${master_account.name}`, STATUS_TYPES.ERROR);
        }
    }

    return (
        <React.Fragment>
            <Modal.Header>
                {master_account ? 'Nest Existing Account(s)' : 'Add New'}
            </Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Group>
                        <Controller
                            name='name'
                            control={control}
                            render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                <Form.Input
                                    label='Master Account Name'
                                    width={8}
                                    required
                                    placeholder='Type Master Account Name'
                                    name={name}
                                    value={value}
                                    error={error && error.message}
                                    onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                    disabled={master_account}
                                />
                            )}
                        />
                    </Form.Group>
                    <Controller
                        name='accounts'
                        control={control}
                        render={({ field: { name, value, onChange } }) => (
                            <SearchableMultiSelection 
                                selections={value}
                                onSelect={(account) => {
                                    onChange({ target: { name, value: [...value, account] } });
                                }}
                                onRemove={(id) => {
                                    onChange({ target: { name, value: value.filter(account => account._id !== id) } })
                                }}
                            />
                        )}
                    />
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={master_account ? linkAccountsToMasterAccount : createMasterAccount} disabled={!isValid || !isDirty || isCreatingMasterAccount || isLinkingAccounts} loading={isCreatingMasterAccount || isLinkingAccounts} primary>Save</Button>
            </Modal.Actions>
        </React.Fragment>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(MasterAccountForm);
