import React from 'react';
import { Icon, Button } from 'semantic-ui-react';
import { ButtonLink } from 'dyl-components';

import LocationsForm from 'shared/forms/LocationsForm';
import LocationUtils from 'utils/LocationUtils';
import { RecordUtils } from 'utils';

const AddLocationsSection = ({
    control,
    locations,
    onCloseAddLocationsSection,
    onAddLocations,
    onAddToArray,
    isCreating,
    dataTabLink,
    location_type,
    isValid = true,
    updateLocation,
    watchedLocations
}) => (
    <div>
        <LocationsForm
            location_type={location_type}
            locations={locations}
            onCreateMode={true}
            DataTabLink={dataTabLink}
            control={control}
            AddLocationButton={
                <ButtonLink className='AddLocationsSection__buttonLinks' onClick={() => onAddToArray(LocationUtils.getLocationModel(location_type))}>
                    <Icon name='plus circle' /> <span style={{ fontSize: 10 }}> Add additional {location_type} location </span>
                </ButtonLink>
            }
            updateMain={(onChange, property = 'main') => {
                RecordUtils.updateMain(watchedLocations, updateLocation, onChange, property);
            }}
        />
        <div style={{ paddingLeft: 45 }}>
            <Button basic color='red' onClick={onCloseAddLocationsSection} disabled={isCreating}>
                Cancel
            </Button>
            <Button disabled={!isValid} primary onClick={onAddLocations} loading={isCreating}>
                Add
            </Button>
        </div>
    </div>
);

export default AddLocationsSection;
