import { ButtonLink, Icon, InnerRuleContainer } from 'dyl-components';
import { Controller } from 'react-hook-form';
import { Divider, Form, Header } from 'semantic-ui-react';
import MasterSecondarySourceOptions from 'shared/forms/MasterSecondarySourceOptions';
import ProviderDataRecordOptions from 'shared/forms/ProviderDataRecordOptions';

export const Criteria = ({ control, sourceOptions, parentName, index, onClearCriteria, onRemoveCriteria, isOnly, isReadingCampaignList, campaignList, isReadingProductInterests, product_interests, isProductInterestDisable }) => {
    if (index === undefined || index < 0) {
        return null;
    }

    const criteriaName = `${parentName}.conditions[${index}]`

    return (
        <>
            <div style={{margin: "0px 40px 0px 40px"}}>
                <InnerRuleContainer>
                    <div style={{display: 'flex'}}>
                        <div style={{flex: 1}}>
                            <Header as='h4'>Criteria</Header>
                        </div>
                        <div style={{flex: 5, display: 'flex', gap: 35}}>
                            <div style={{flex: 1}}>
                                <Controller
                                    control={control}
                                    name={`${criteriaName}.master_source`}
                                    render={({ field: { onChange: onMasterSourceChange, value: masterSourceValue, name: masterSourceName } }) =>
                                        <Controller
                                            control={control}
                                            name={`${criteriaName}.secondary_source_id`}
                                            render={({ field: { onChange, value, name } }) => 
                                                <Form.Field
                                                    control={MasterSecondarySourceOptions}
                                                    value={value}
                                                    parentValue={masterSourceValue}
                                                    nested_options={sourceOptions}
                                                    onChange={(_, { parent_value: master_source, child_value: secondary_source }) => {
                                                        onChange({ target: { name, value: secondary_source } })
                                                        onMasterSourceChange({ target: { name: masterSourceName, value: master_source } })
                                                    }}
                                                    placeholder='Select Master and Secondary sources'
                                                    label='Master Source/Secondary Source'
                                                    className='AssignmentCriteria__sourceField'
                                                />
                                            }
                                        />
                                    } 
                                />
                                <Controller
                                    control={control}
                                    name={`${criteriaName}.campaign_converted_id`}
                                    render={({ field: { onChange, value, name } }) =>
                                        <Form.Select
                                            name={name}
                                            value={value}
                                            onChange={(_, { value }) => {
                                                onChange({ target: { name, value } })
                                            }}
                                            placeholder='Select Campaign Converted'
                                            label='Campaign Converted'
                                            loading={isReadingCampaignList}
                                            options={campaignList.map((campaign) => ({
                                                text: campaign.name,
                                                value: campaign.id,
                                                key: campaign.id
                                            }))}
                                            clearable
                                            
                                        />
                                    } 
                                />
                            </div>
                            <div style={{flex: 1}}>
                                <Controller
                                    control={control}
                                    name={`${criteriaName}.data_provider_id`}
                                    render={({ field: { onChange: onDataProviderChange, value: dataProviderValue, name: dataProviderName } }) =>
                                        <Controller
                                            control={control}
                                            name={`${criteriaName}.data_record_id`}
                                            render={({ field: { onChange, value, name } }) => 
                                                <Form.Field
                                                    control={ProviderDataRecordOptions}
                                                    value={value}
                                                    parentValue={dataProviderValue}
                                                    onChange={(_, { parent_value: data_provider, child_value: data_record }) => {
                                                        onChange({ target: { name, value: data_record } })
                                                        onDataProviderChange({ target: { name: dataProviderName, value: data_provider } })
                                                    }}
                                                    placeholder='Select Data Provider and Data Record'
                                                    label='Data Provider/Data Record'
                                                    className='AssignmentCriteria__sourceField'
                                                />
                                            }
                                        />
                                    } 
                                />
                                <Controller
                                    control={control}
                                    name={`${criteriaName}.product_interests`}
                                    render={({ field: { onChange, value, name } }) =>
                                        <Form.Select
                                            multiple
                                            name={name}
                                            value={value}
                                            disabled={isProductInterestDisable}
                                            onChange={(_, { value }) => {
                                                onChange({ target: { name, value } })
                                            }}
                                            placeholder='Select Product Interests'
                                            label='Product Interests'
                                            options={product_interests.map((interest) => ({
                                                text: interest.name,
                                                key: interest.id,
                                                value: interest.id
                                            }))}
                                            loading={isReadingProductInterests}
                                        />
                                    } 
                                />
                            </div>
                        </div>
                    </div>
                </InnerRuleContainer>
            </div>
            <div>
                <ButtonLink onClick={onClearCriteria} status='primary'>
                    <Icon link size='large' className='fa-solid fa-circle-xmark' /> Clear all
                </ButtonLink>
                <ButtonLink onClick={onRemoveCriteria} status='error'>
                    <Icon link size='large'  className='fas fa-circle-minus' /> Remove
                </ButtonLink>
            </div>
            {isOnly && <Divider />}
        </>
    )
}
