import React from "react";
import { DateTimeUtils, Person } from "dyl-components";
import { useDispatch, useSelector } from "react-redux";
import { VALIDATORS } from "dyl-components";
import userActions from "actions/user";
import { Controller, useController, useFormContext } from "react-hook-form";
import {
    Dropdown,
    Form,
    Grid,
    Icon,
    Label,
    List,
    ListItem,
    Select,
} from "semantic-ui-react";
import { STATES } from "shared/constants/STATES";
import UserSearchField from "shared/forms/UserSearchField";
import { StringUtils } from "utils";
import contactDuplicatesActions from 'actions/contact_duplicates';
import "./UserForm.scss";
import { Link } from "react-router-dom";

const UserForm = ({ customer, contact_id }) => {
    const {
        roles,
        contactOptions,
        teams,
        isReadingTeams,
        isReadingRoles,
        isFindingUserByEmail,
    } = useSelector((state) => ({
        isFindingUserByEmail: state.user.isFindingUserByEmail,
        isCreating:
            state.users.isCreatingUser ||
            state.auth.isCreatingUser ||
            state.team.teamWhereUsersAreBeingAdded ||
            state.company.isCreatingCompany,
        roles: state.roles.roles
            .filter(({ owner }) => {
                if (customer) {
                    return owner;
                }
                return !owner;
            })
            .map(({ id, name }) => ({
                key: id,
                value: id,
                text: StringUtils.capitalize(name),
            })),
        isReadingRoles: state.roles.isReadingRoles,
        teams: state.teams.teams
            .filter(({ deleted }) => !deleted)
            .map(({ id, name }) => ({
                key: id,
                value: id,
                text: name,
            })),
        isReadingTeams: state.teams.isReadingTeams,
        contactOptions: state.account.contactsForPinning.map((contact) => {
            const { first_name, last_name, phone, email, location } = contact;
            const name = `${first_name} ${last_name}`;

            return {
                key: contact.id,
                value: contact.id,
                text: name,
                content: <Person username={name} email="" isUsernameOnly />,
                first_name,
                last_name,
                phone: phone.phone,
                email: email.email,
                location,
            };
        }),
    }));

    const dispatch = useDispatch();

    const { control, setError, reset } = useFormContext();

    const { field: contactIdField } = useController({
        control,
        name: "contact_id",
    });

    const contactField = (() => {
        if (!customer) {
            return null;
        }
        const content = contactOptions.find(
            ({ value }) => value === contactIdField.value
        )?.content;
        return (
            <Grid.Row>
                <Grid.Column>
                    <ListItem
                        header={
                            <b>
                                Contact Name{" "}
                                <Icon size="tiny" name="asterisk" color="red" />
                            </b>
                        }
                        description={
                            <Form.Dropdown
                                placeholder="Select Contact"
                                selectOnBlur={false}
                                selection
                                options={contactOptions}
                                name="contact_id"
                                onChange={(_, { value }) => {
                                    contactIdField.onChange({
                                        target: {
                                            name: contactIdField.name,
                                            value,
                                        },
                                    });
                                    const contact = contactOptions.find(
                                        (contact) => contact.value === value
                                    );
                                    reset({
                                        cell_number: contact.phone,
                                        email: contact.email,
                                        first_name: contact.first_name,
                                        last_name: contact.last_name,
                                        street: contact?.location?.street,
                                        suite: contact?.location
                                            ?.additional_street,
                                        city: contact?.location?.city,
                                        state: contact?.location?.state,
                                        zipcode: contact?.location?.zip,
                                    });
                                }}
                                text={content}
                                fluid
                                value={contactIdField.value}
                            />
                        }
                    />
                </Grid.Column>
            </Grid.Row>
        );
    })();

    const checkIfEmailAlreadyInUse = async ({ name, value }) => {
        if (value !== "" && (await VALIDATORS.EMAIL_ADDRESS().isValid(value))) {
            const { customer_id, user_id } = await dispatch(
                userActions.findByEmail("", {
                    email: value,
                })
            );
            const hasNoDuplicates = !(customer_id && user_id);
            if (!hasNoDuplicates) {
                await setError(name, {
                    type: "unique_email",
                    message: "Email already exists",
                });
            }
            if (customer) {
                const duplicateEmails = await dispatch(contactDuplicatesActions.checkDuplicates({ emails: [value] }));
                const possibleDuplicate = duplicateEmails[0]?.contact_id;
                const hasNoDuplicates = possibleDuplicate === undefined || Number(possibleDuplicate) === Number(contact_id);
                if (!hasNoDuplicates) {
                    await setError(name, {
                        type: 'unique_email',
                        message: (
                            <Label color='red'>Email matches an <Link style={{ color: "#2B78FF" }} target={"_blank"} to={`/contact/${possibleDuplicate}`}>existing record</Link></Label>
                        )
                    });
                }
            }
        }
    };

    return (
        <Grid>
            {contactField}
            <Grid.Row columns={2}>
                <Grid.Column>
                    <List relaxed size="small" as={Form}>
                        <ListItem
                            icon={<Icon name="user" color="black" />}
                            header={
                                <b>
                                    First Name{" "}
                                    <Icon
                                        size="tiny"
                                        name="asterisk"
                                        color="red"
                                    />
                                </b>
                            }
                            description={
                                <Controller
                                    control={control}
                                    name="first_name"
                                    render={({
                                        field: { name, value, onChange },
                                        fieldState: { error },
                                    }) => (
                                        <Form.Input
                                            value={value}
                                            onChange={(_, { value }) => {
                                                onChange({
                                                    target: {
                                                        name,
                                                        value,
                                                    },
                                                });
                                            }}
                                            name="first_name"
                                            error={error?.message}
                                            fluid
                                            required
                                        />
                                    )}
                                />
                            }
                        />
                        <ListItem
                            icon={<Icon />}
                            header={
                                <b>
                                    Last Name{" "}
                                    <Icon
                                        size="tiny"
                                        name="asterisk"
                                        color="red"
                                    />
                                </b>
                            }
                            description={
                                <Controller
                                    control={control}
                                    name="last_name"
                                    render={({
                                        field: { name, value, onChange },
                                        fieldState: { error },
                                    }) => (
                                        <Form.Input
                                            value={value}
                                            onChange={(_, { value }) => {
                                                onChange({
                                                    target: {
                                                        name,
                                                        value,
                                                    },
                                                });
                                            }}
                                            name="last_name"
                                            error={error?.message}
                                            fluid
                                            required
                                        />
                                    )}
                                />
                            }
                        />
                        <ListItem
                            icon={<Icon name="sign in" color="black" />}
                            header={
                                <b>
                                    Email{" "}
                                    <Icon
                                        size="tiny"
                                        name="asterisk"
                                        color="red"
                                    />
                                </b>
                            }
                            description={
                                <Controller
                                    control={control}
                                    name="email"
                                    render={({
                                        field: { name, value, onChange },
                                        fieldState: { error },
                                    }) => (
                                        <Form.Input
                                            value={value}
                                            required
                                            loading={isFindingUserByEmail}
                                            onChange={async (_, { value }) => {
                                                await onChange({
                                                    target: {
                                                        name,
                                                        value,
                                                    },
                                                });
                                                checkIfEmailAlreadyInUse({ name, value });
                                            }}
                                            fluid
                                            name="email"
                                            error={error?.message}
                                        />
                                    )}
                                />
                            }
                        />
                        <ListItem
                            icon={<Icon name="map marker" color="black" />}
                            header="Street Address"
                            description={
                                <Controller
                                    control={control}
                                    name="street"
                                    render={({
                                        field: { name, value, onChange },
                                        fieldState: { error },
                                    }) => (
                                        <Form.Input
                                            value={value}
                                            onChange={(_, { value }) => {
                                                onChange({
                                                    target: {
                                                        name,
                                                        value,
                                                    },
                                                });
                                            }}
                                            name="street"
                                            error={error?.message}
                                            placeholder="Street address or P.O. box"
                                            fluid
                                            required
                                        />
                                    )}
                                />
                            }
                        />
                        <ListItem
                            icon={<Icon />}
                            header="Apt. suite, unit building, floor, etc."
                            description={
                                <Controller
                                    control={control}
                                    name="suite"
                                    render={({
                                        field: { name, value, onChange },
                                        fieldState: { error },
                                    }) => (
                                        <Form.Input
                                            value={value}
                                            onChange={(_, { value }) => {
                                                onChange({
                                                    target: {
                                                        name,
                                                        value,
                                                    },
                                                });
                                            }}
                                            name="suite"
                                            error={error?.message}
                                            fluid
                                            required
                                        />
                                    )}
                                />
                            }
                        />
                        <ListItem
                            icon={<Icon />}
                            header="City"
                            description={
                                <Controller
                                    control={control}
                                    name="city"
                                    render={({
                                        field: { name, value, onChange },
                                        fieldState: { error },
                                    }) => (
                                        <Form.Input
                                            value={value}
                                            onChange={(_, { value }) => {
                                                onChange({
                                                    target: {
                                                        name,
                                                        value,
                                                    },
                                                });
                                            }}
                                            name="city"
                                            error={error?.message}
                                            placeholder="City"
                                            fluid
                                            required
                                        />
                                    )}
                                />
                            }
                        />
                        <ListItem
                            icon={<Icon />}
                            header={
                                <b>
                                    State{" "}
                                    <Icon
                                        size="tiny"
                                        name="asterisk"
                                        color="red"
                                    />
                                </b>
                            }
                            description={
                                <Grid>
                                    <Grid.Column width="8">
                                        <Controller
                                            control={control}
                                            name="state"
                                            render={({
                                                field: {
                                                    name,
                                                    value,
                                                    onChange,
                                                },
                                            }) => (
                                                <Dropdown
                                                    selection
                                                    search
                                                    fluid
                                                    onChange={(
                                                        _,
                                                        { value }
                                                    ) => {
                                                        onChange({
                                                            target: {
                                                                name,
                                                                value,
                                                            },
                                                        });
                                                    }}
                                                    value={value}
                                                    name="state"
                                                    required
                                                    options={STATES.map(
                                                        (state) => ({
                                                            ...state,
                                                            value: state.key,
                                                        })
                                                    )}
                                                    placeholder="Select a state"
                                                />
                                            )}
                                        />
                                    </Grid.Column>
                                    <Grid.Column
                                        width="8"
                                        style={{ paddingTop: 0 }}
                                    >
                                        <ListItem
                                            header="Zip Code"
                                            description={
                                                <Controller
                                                    control={control}
                                                    name="zipcode"
                                                    render={({
                                                        field: {
                                                            name,
                                                            value,
                                                            onChange,
                                                        },
                                                        fieldState: { error },
                                                    }) => (
                                                        <Form.Input
                                                            value={value}
                                                            onChange={(
                                                                _,
                                                                { value }
                                                            ) => {
                                                                onChange({
                                                                    target: {
                                                                        name,
                                                                        value,
                                                                    },
                                                                });
                                                            }}
                                                            name="zipcode"
                                                            error={
                                                                error?.message
                                                            }
                                                            placeholder="Postal Code"
                                                            fluid
                                                            required
                                                        />
                                                    )}
                                                />
                                            }
                                        />
                                    </Grid.Column>
                                </Grid>
                            }
                        />
                    </List>
                </Grid.Column>
                <Grid.Column>
                    <List relaxed size="small" as={Form}>
                        <ListItem
                            icon={<Icon name="text telephone" color="black" />}
                            header="Work Number"
                            description={
                                <Grid>
                                    <Grid.Column width="10">
                                        <Controller
                                            control={control}
                                            name="work_number"
                                            render={({
                                                field: {
                                                    name,
                                                    value,
                                                    onChange,
                                                },
                                                fieldState: { error },
                                            }) => (
                                                <Form.Input
                                                    value={value}
                                                    required
                                                    onChange={(
                                                        _,
                                                        { value }
                                                    ) => {
                                                        onChange({
                                                            target: {
                                                                name,
                                                                value,
                                                            },
                                                        });
                                                    }}
                                                    name="work_number"
                                                    fluid
                                                    error={error?.message}
                                                    placeholder="xxxxxxxxxx"
                                                />
                                            )}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width="6">
                                        <span class="tempHeader">Ext.</span>
                                        <Controller
                                            control={control}
                                            name="extension"
                                            render={({
                                                field: {
                                                    name,
                                                    value,
                                                    onChange,
                                                },
                                                fieldState: { error },
                                            }) => (
                                                <Form.Input
                                                    value={value}
                                                    onChange={(
                                                        _,
                                                        { value }
                                                    ) => {
                                                        onChange({
                                                            target: {
                                                                name,
                                                                value,
                                                            },
                                                        });
                                                    }}
                                                    name="extension"
                                                    fluid
                                                    error={error?.message}
                                                />
                                            )}
                                        />
                                    </Grid.Column>
                                </Grid>
                            }
                        />
                        <ListItem
                            icon={
                                <Icon name="mobile alternate" color="black" />
                            }
                            header="Cell Number"
                            description={
                                <Controller
                                    control={control}
                                    name="cell_number"
                                    render={({
                                        field: { name, value, onChange },
                                        fieldState: { error },
                                    }) => (
                                        <Form.Input
                                            value={value}
                                            required
                                            onChange={(_, { value }) => {
                                                onChange({
                                                    target: {
                                                        name,
                                                        value,
                                                    },
                                                });
                                            }}
                                            name="cell_number"
                                            fluid
                                            error={error?.message}
                                            placeholder="xxxxxxxxxx"
                                        />
                                    )}
                                />
                            }
                        />
                        <ListItem
                            icon={<Icon name="clock outline" color="black" />}
                            header={
                                <b>
                                    Timezone{" "}
                                    <Icon
                                        size="tiny"
                                        name="asterisk"
                                        color="red"
                                    />
                                </b>
                            }
                            description={
                                <Controller
                                    control={control}
                                    name="timezone"
                                    render={({
                                        field: { name, value, onChange },
                                    }) => (
                                        <Select
                                            search
                                            value={value}
                                            options={DateTimeUtils.generateTimezoneOptions()}
                                            required
                                            onChange={(_, { value }) => {
                                                onChange({
                                                    target: { name, value },
                                                });
                                            }}
                                            name="timezone"
                                            fluid
                                            placeholder="Select Time Zone"
                                        />
                                    )}
                                />
                            }
                        />
                        <ListItem
                            icon={<Icon name="address book" color="black" />}
                            header={<b>Job Title</b>}
                            description={
                                <Controller
                                    control={control}
                                    name="job_title"
                                    render={({
                                        field: { name, value, onChange },
                                        fieldState: { error },
                                    }) => (
                                        <Form.Input
                                            value={value}
                                            onChange={(_, { value }) => {
                                                onChange({
                                                    target: { name, value },
                                                });
                                            }}
                                            name="job_title"
                                            fluid
                                            error={error?.message}
                                        />
                                    )}
                                />
                            }
                        />
                        <ListItem
                            icon={<Icon name="clipboard" color="black" />}
                            header="Reports to"
                            description={
                                <Controller
                                    control={control}
                                    name="report_to"
                                    render={({
                                        field: { name, value, onChange },
                                    }) => (
                                        <Form.Field
                                            control={UserSearchField}
                                            excluded_users={[]}
                                            onChange={(_, { value }) => {
                                                onChange({
                                                    target: { name, value },
                                                });
                                            }}
                                            placeholder="Select User"
                                            display_selected_user
                                            value={value}
                                        />
                                    )}
                                />
                            }
                        />
                        <ListItem
                            icon={<Icon name="fas fa-users" color="black" />}
                            header="Team(s)"
                            description={
                                <Controller
                                    control={control}
                                    name={"teams"}
                                    render={({
                                        field: { name, value, onChange },
                                    }) => (
                                        <Dropdown
                                            multiple
                                            selection
                                            fluid
                                            onChange={(_, { value }) => {
                                                onChange({
                                                    target: { name, value },
                                                });
                                            }}
                                            name="teams"
                                            options={teams}
                                            loading={isReadingTeams}
                                            placeholder="Select Team(s)"
                                            value={value}
                                        />
                                    )}
                                />
                            }
                        />
                        <ListItem
                            icon={<Icon name="id card" color="black" />}
                            header={
                                <b>
                                    Profile Type{" "}
                                    <Icon
                                        size="tiny"
                                        name="asterisk"
                                        color="red"
                                    />
                                </b>
                            }
                            description={
                                <Controller
                                    control={control}
                                    name={"profile_type"}
                                    render={({
                                        field: { name, value, onChange },
                                    }) => (
                                        <Select
                                            onChange={(_, { value }) => {
                                                onChange({
                                                    target: { name, value },
                                                });
                                            }}
                                            name="profile_type"
                                            options={roles}
                                            value={value}
                                            fluid
                                            selection
                                            loading={isReadingRoles}
                                            required
                                            placeholder="Select Profile Type"
                                            {...(customer
                                                ? { open: false }
                                                : {})}
                                        />
                                    )}
                                />
                            }
                        />
                    </List>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default UserForm;
