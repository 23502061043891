import React from 'react';
import { Grid, Icon, Form, Label } from 'semantic-ui-react';
import { ButtonLink, STATUS_TYPES, VALIDATORS } from 'dyl-components';

import { Controller } from 'react-hook-form';
import { Link } from 'react-router-dom';
import EMAIL_TYPE_OPTIONS from 'shared/constants/EMAIL_TYPE_OPTIONS';

const RecordEmailsForm = ({
    emails,
    control,
    onRemove,

    updateMain,

    checkDuplicates,
    setError,
    trigger,
    contact_id,

    allowNoEmails,

    fieldNamePrefix = ''
}) => (
    <Grid className='RecordInfo' columns='equal'>
        {emails.map((email, index) => (
            <Controller
                key={email.id}
                name={`${fieldNamePrefix}emails[${index}].value`}
                control={control}
                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                    <>
                        <Grid.Row columns='equal' verticalAlign='middle'>
                            <Grid.Column>
                                <Controller
                                    name={`${fieldNamePrefix}emails[${index}].type`}
                                    control={control}
                                    defaultValue={email.type}
                                    render={({ field: { name, value, onChange } }) => (
                                        <Form.Dropdown
                                            selection
                                            name={name}
                                            value={value}
                                            onChange={(_, { value }) => {
                                                onChange({ target: { name, value } });
                                            } }
                                            placeholder='Type'
                                            options={EMAIL_TYPE_OPTIONS.getOptions()}
                                            selectOnBlur={false} />
                                    )} />
                            </Grid.Column>
                            <Grid.Column>
                                <Form.Input
                                    name={name}
                                    value={value}
                                    onChange={async (_, { value }) => {
                                        await onChange({ target: { name, value } });
                                        if (trigger) {
                                            trigger(`${fieldNamePrefix}phones`);
                                            trigger('emails');
                                        }
                                        if (value !== '' && (await (VALIDATORS.EMAIL_ADDRESS().isValid(value)))) {
                                            const duplicateEmails = (await checkDuplicates({ emails: [value] }));
                                            const possibleDuplicate = duplicateEmails[0]?.contact_id;
                                            const hasNoDuplicates = possibleDuplicate === undefined || Number(possibleDuplicate) === Number(contact_id);
                                            if (!hasNoDuplicates) {
                                                await setError(name, {
                                                    type: 'unique_email',
                                                    message: (
                                                        <Label color='red'>Email matches an <Link style={{ color: "#2B78FF" }} target={"_blank"} to={`/contact/${possibleDuplicate}`}>existing record</Link></Label>
                                                    )
                                                });
                                            }
                                        }
                                    } }
                                    placeholder='Enter email'
                                    error={Boolean(error?.message)} />
                            </Grid.Column>
                            <Grid.Column width={6}>
                                <div>
                                    <Controller
                                        name={`${fieldNamePrefix}emails[${index}].main`}
                                        control={control}
                                        render={({ field: { name, value, onChange } }) => (
                                            <Form.Radio
                                                name={name}
                                                checked={value}
                                                onChange={(_, { checked: value }) => {
                                                    updateMain(() => {
                                                        onChange({ target: { name, value } });
                                                    });
                                                } }
                                                label='Main' />
                                        )} />
                                </div>
                                {(allowNoEmails || emails.length > 1) && (
                                    <Form.Field>
                                        <ButtonLink style={{ marginLeft: '-0.5em' }} size='small' status={STATUS_TYPES.ERROR} onClick={() => { onRemove(index); } }>
                                            <Icon className='fas fa-trash-can' /> Delete
                                        </ButtonLink>
                                    </Form.Field>
                                )}
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column textAlign='center'>
                                <Form.Field
                                    control={'div'}
                                    error={error && error.message} />
                            </Grid.Column>
                        </Grid.Row>
                    </>
                )}
            />
        ))}
    </Grid>
);

export default RecordEmailsForm;
