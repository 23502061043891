import React from 'react'
import { Menu } from 'semantic-ui-react'

// import EmailsDropdown from 'shared/EmailsDropdown';  not getting rid of this as it may be needed was the Outlook integration is working
// import SelfReportedEmailTab from 'shared/SelfReportedEmailTab';

import { Link, NavLink, useLocation, useParams } from 'react-router-dom';

const ContactAccountTabs = () => {
  const { contact_id } = useParams();

  const { pathname } = useLocation();

  const tab = pathname.split("/")[3];

  return (
    <Menu size='small' borderless pointing secondary color={"primary"} stackable>
      <Menu.Item as={Link} active={tab === undefined} name='Overview' to={`/contact/${contact_id}`} />
      <Menu.Item as={NavLink} name='Data' to={`/contact/${contact_id}/data`} />
      <Menu.Item as={NavLink} name='Lead' to={`/contact/${contact_id}/lead`} />
      <Menu.Item as={NavLink} name='Opportunity' to={`/contact/${contact_id}/opportunity`} />
      <Menu.Item as={NavLink} name='Campaign' to={`/contact/${contact_id}/campaign`} />
      <Menu.Item as={NavLink} name='Timeline' to={`/contact/${contact_id}/timeline`} />
      <Menu.Item as={NavLink} name='Texts' to={`/contact/${contact_id}/texts`} />
      <Menu.Item as={NavLink} name='Emails' to={`/contact/${contact_id}/emails`} />
      <Menu.Item as={NavLink} name='Events' to={`/contact/${contact_id}/events`} />
      <Menu.Item as={NavLink} name='Tasks' to={`/contact/${contact_id}/tasks`} />
      <Menu.Item as={NavLink} name='Notes' to={`/contact/${contact_id}/notes`} />
      <Menu.Item as={NavLink} name='Uploads' to={`/contact/${contact_id}/uploads`} />
    </Menu>
  )
}

export default ContactAccountTabs;
