import subscriptionActions from "actions/subscription";
import { DateTimeUtils } from "dyl-components";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Header, Segment, Statistic, StatisticLabel, StatisticValue } from "semantic-ui-react";
import { MathUtils, StringUtils } from "utils";

const PlanDetails = () => {
    const dispatch = useDispatch();
    const customer_id = useSelector(state => state.auth.customer_id);

    useEffect(() => {
        dispatch(subscriptionActions.getPlan(customer_id, { isAdmin: true }));
    }, [dispatch, customer_id])

    const plan = useSelector(state => state.subscription.plan);
    const isReadingPlan = useSelector(state => state.subscription.isReadingPlan);

    return (
        <Segment loading={isReadingPlan}>
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <Header color="primary">Plan Details</Header>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns="equal">
                    <Grid.Column>
                        <Statistic>
                            <StatisticLabel>Current Plan</StatisticLabel>
                            <StatisticValue></StatisticValue>
                        </Statistic>
                    </Grid.Column>
                    <Grid.Column>
                        <Statistic size="mini">
                                <StatisticLabel>Billed</StatisticLabel>
                            <StatisticValue>{StringUtils.capitalize(plan?.plan?.term || plan?.plan?.trial_term)}</StatisticValue>
                        </Statistic>
                    </Grid.Column>
                    <Grid.Column>
                        <Statistic size="mini">
                            <StatisticLabel>Renewal Amount</StatisticLabel>
                            <StatisticValue>${StringUtils.formatDecimal(`${MathUtils.roundDecimals(plan?.amount || 0)}`, true)}</StatisticValue>
                        </Statistic>
                    </Grid.Column>
                    <Grid.Column>
                        <Statistic size="mini">
                            <StatisticLabel>Next Invoice</StatisticLabel>
                            <StatisticValue>{plan?.next_invoice && DateTimeUtils.changeFormat(plan?.next_invoice, DateTimeUtils.CALENDAR_API_DATE_FORMAT, DateTimeUtils.WORD_DATE_FORMAT)}</StatisticValue>
                        </Statistic>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    )
}

export default PlanDetails;
