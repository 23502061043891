import React, { useState } from 'react';
import { TabAccordion } from 'dyl-components';
import EventLabels from './EventLabels';

const Events = ({handleFormDirty}) => {
    const [active, setActive] = useState(-1);

    const onChangeTab = (_, { index }) => {
        setActive(prevActive => (prevActive === index ? -1 : index));
    };

    const items = [
        {
            title: 'Event Labels',
            description: `Create custom labels as a sub-category for your events.`,
            content: <EventLabels handleFormDirty={handleFormDirty} />
        }
    ];

    return <TabAccordion header={'Events'} items={items} active={active} onChangeTab={onChangeTab} />;
};

export default Events;
