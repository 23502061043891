import React, { useEffect, useState  } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Icon, Header, Grid, Container, Segment, Loader } from 'semantic-ui-react';
import { TimelineTabHeader, TimelineTabForm, STATUS_TYPES, Notification } from 'dyl-components';

import { DateTimeUtils } from 'dyl-components';
import emailActions from 'actions/email';
import EmailThread from './EmailThread';
import EmailTimeLine from './EmailTimeLine';
import EmailViewBody from 'shared/SelfReportedEmailTab/subcomponents/EmailViewBody';
import FirstEmail from './FirstEmail';
import IntegrationBody from 'shared/SelfReportedEmailTab/subcomponents/IntegrationBody';
import selfReportActions from 'actions/self_report';
import oauthActions from 'actions/oauth';
import accountActions from 'actions/account';
import taskActions from 'actions/task';

import sendEmailActions from 'actions/send_email';
import EmailTaskBody from './EmailTaskBody';

const NewMessage = ({ contact, account, contactLocation, activeIntegration, toggleSidePanel, isSidePanelOpen, readEmails, isCheckingEmail, contact_id }) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(sendEmailActions.readContact(contact_id));
        dispatch(sendEmailActions.getLocations(contact_id));
    }, [contact_id, dispatch]);

    return (
        isCheckingEmail ?
            <Loader active />
            :
            activeIntegration?.active ?
                <Grid.Column className="EmailTab__EmailForm">
                    <FirstEmail
                        toggleSidePanel={toggleSidePanel}
                        isSidePanelOpen={isSidePanelOpen}
                        readEmails={readEmails}
                        contact_id={contact_id}
                        contact={contact}
                        account={account}
                        contactLocation={contactLocation}
                        toolbarClassName={"email_tab_toolbar"}
                    />
                </Grid.Column>
                :
                <IntegrationBody />
    )
}

const ExistingMessage = ({ activeMessage, onToggleEmailComplete, readEmails }) => {
    const { type } = activeMessage || 'unknown type';
    const { body = '', completed = '', created = '', destination = '', id = '', notes = '', source = '', subject = '' } = activeMessage;
    const date = DateTimeUtils.formatEpoch(created, DateTimeUtils.WORD_DATE_FORMAT);
    const time = DateTimeUtils.formatEpoch(created, DateTimeUtils.TIME_FORMAT);

    let ExistingBody = <h2>Default Holder</h2>

    // Setup the Component to load when an email item is clicked 
    switch (type) {
        case 'log': {

            const logData = {
                time,
                date,
                body,
                notes,
                onChange: () => { console.log('|+|+ fired onChange LOg') },
                destination,
                source,
                onToggleEmailComplete,
                id,
                completed
            };

            ExistingBody = <Grid.Column className="EmailTab__LogForm">
                <EmailViewBody {...logData} />
            </Grid.Column>

        }
            break;

        case 'task': {
            return <EmailTaskBody activeMessage={activeMessage} />
        }
        case "email-gmail": {
            const { from, threads } = activeMessage;

            const emailData = {
                threads,
                subject,
                isCollapsed: false,
                isSidePanelOpen: true,
                emailAddress: from?.email,
                onToggleCollapse: () => { },
                onCloseSidePanel: () => { },
                onOpenSidePanel: () => { }
            };

            ExistingBody = <Grid.Column className="EmailTab__EmailForm"><EmailThread {...emailData} readEmails={readEmails} /></Grid.Column>

        }
            break;
        default:
            return;
    }
    return ExistingBody
}

const EmailContainer = () => {
    const { account_id } = useParams();
    const dispatch = useDispatch();

    const { allEmails, isGettingAllEmails } = useSelector(state => state.email);
    const contact_id = useSelector(state => Number(state.contact.contact_id));
    const contactInfo = useSelector(state => state.contact);
    const account = useSelector(state => state.account);
    const contactLocation = useSelector(state => state.contact.contact?.locations);
    const { isReadingIntegrations, activeIntegration } = useSelector(state => state.oauth_integrations);
    
    const [activeItem, setActiveItem] = useState('all');
    const [activeMessage, setActiveMessage] = useState(null);


    let allAccountEmails = {}; //if account, pull account and associate contacts emails
    let contact, accountContactIds;
    if(account_id){ 
        let accountEmails = account.account?.emails;
        allAccountEmails = accountEmails ? accountEmails.map(email => email.email) : [];
        accountContactIds = account?.contactIds.length > 0 ? account.contactIds.map(contact => contact.id) : [1]; //fallback to contact_id 1 to hack when account, if no contact tasks, will trigger filtering on start and end date 1990 on front-end proxy 
    } else {
        const { email, phone } = contactInfo;
        const { first_name, last_name, suffix, primary_location_id } = contactInfo.contact;
        contact = { contact: { first_name, last_name, suffix, primary_location_id }, email, phone };
        allAccountEmails = contactInfo.email ? contactInfo.email.slice(0).map(({ email }) => email) : [];
    }

    

    const filterEmail = (activeItem) => {

    let filterOption;

    // This switch was trimmed and currently can be a ternary ... but as we know, there will be other values coming in in the future
    switch (activeItem) {

        case 'recieved':
            filterOption = 'inbound'
            break;
        default:
            filterOption = 'outbound'
            break;
    }

    return filterOption;
    };

    const readEmails = () => {
        const { id, email } = activeIntegration || -1;
        const to = allAccountEmails.length > 0 ? allAccountEmails.join() : '';
        const queryParameters = {
            start: DateTimeUtils.getLast(2, 'month'),
            end: DateTimeUtils.getCurrentDate(null, true, false),
            integration_id: id,
            contact_email: [to],
            contact_id: account_id ? accountContactIds : [contact_id], //only for emails task and email logging
            integrationEmail: email, //determine direction for integrated email
            account: account_id ? 'isAccount' : null,  //hack to filter on on timestamp if account has no tasks associated, to return no results. See line 139 EmailService in front-end proxy repo.
            account_id
        }
        dispatch(emailActions.getAll(queryParameters));
    }

    const getActiveMessage = async (id, onComplete, completed) => {
        let message = null;
        if (id) {
            message = allEmails?.find(email => email.id === id) || null;
            if (message) {
                if (onComplete) {
                    message.completed = completed
                }
                if (message.type === 'task') {
                    const task = await dispatch(taskActions.readTask(id))
                    const { assigned_by, attachments } = task;
                    message = {
                        ...message,
                        attachments,
                        assigned_by,
                    }
                }
            }
            setActiveMessage(message);
        }
    }


    const selectMessage = ({ id = 'unknown', onComplete, completed }) => {
        getActiveMessage(id, onComplete, completed);
    }

    const filterCount = (allEmails = []) => {
        //quick and dirty count
        const allEms = allEmails.length || 0;
        const sentEms = allEmails.filter(email => email.direction === 'outbound').length || 0;
        const recievedEms = allEmails.filter(email => email.direction === 'inbound').length || 0;

        const TITLES_COUNTS = [
            { title: 'all', count: allEms },
            { title: 'sent', count: sentEms },
            { title: 'received', count: recievedEms }
        ];

        return TITLES_COUNTS

    }

    const handleItemClick = (_, { name }) => {
        setActiveItem(name)
    }

    const newEmailSelection = () => {
        // Used to do a fresh pull
        selectMessage('');
        readEmails();
    }

    const onToggleEmailComplete = async (id, completed) => {
        try {
            await dispatch(selfReportActions.toggleEmailCompletion(id, { completed: !completed }))
            Notification.alert(!completed ? 'Marked complete!' : 'Marked incomplete.', STATUS_TYPES.SUCCESS, true);
            selectMessage({ id, onComplete: true, completed: !completed });
         
        } catch (e) {
            Notification.alert('Failed to toggle completion.', STATUS_TYPES.SUCCESS, true);
            console.log(e);
        }
    }

    useEffect(() => {
        if(account_id){
            dispatch(accountActions.readContactsForPinning(Number(account_id), { page: 1, limit: 100 }))
        }
        dispatch(oauthActions.getIntegrations({ scopes: 'email' }));
    }, [account_id, dispatch]);

    useEffect(() => {
        if(activeIntegration){
            readEmails();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeIntegration]);

    const TITLES_COUNTS = filterCount(allEmails);
    const type = (activeMessage) ? activeMessage?.type : '';
    const titleHeader = (!type.includes('email')) ? type.charAt(0).toUpperCase() + type.slice(1) : '';
    const isActiveIntegration = activeIntegration?.email !== undefined;
    const emailFilter = activeItem === 'all' ? allEmails : allEmails.filter(email => email.direction === filterEmail(activeItem));
    return (
        <Container className="IntegratedEmails" fluid>
            <TimelineTabForm
                timelineHeader={
                    <Header className='EmailsTab__Header' as='h2'>
                        <Icon disabled={!isActiveIntegration} style={{opacity: !isActiveIntegration ? '50%' : '100%'}} className='TimelineTab__add' name='plus' color='blue' link circular inverted size='tiny' onClick={newEmailSelection} />
                        Email
                    </Header>
                }
                timelineSubHeader={
                    <TimelineTabHeader
                        activeItem={activeItem}
                        titleCounts={TITLES_COUNTS}
                        onClick={handleItemClick}
                    />
                }
                timelineBody={

                    isGettingAllEmails ?
                        <Segment loading />
                        :
                        <EmailTimeLine
                            allEmails={emailFilter}
                            onChangeActiveMessage={selectMessage}
                            activeIntegration={activeIntegration}
                        />
                }
                formHeader={
                    (activeMessage &&
                        <h2>{titleHeader}</h2>
                    )
                }
                formBody={<>


                    {!activeMessage ?

                        <NewMessage
                            activeIntegration={activeIntegration}
                            readEmails={readEmails}
                            isCheckingEmail={isGettingAllEmails || isReadingIntegrations}
                            contact_id={contact_id}
                            contact={contact}
                            account={account}
                            contactLocation={contactLocation}
                        />
                        :
                        <ExistingMessage
                            activeMessage={activeMessage}
                            onToggleEmailComplete={onToggleEmailComplete}
                            readEmails={readEmails}
                        />}

                </>
                }
                //primary-table-6
                color='#214BD6'
                loading={isGettingAllEmails}
            />
        </Container>
    )
}

export default EmailContainer;
