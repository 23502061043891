import { Modal, Notification, STATUS_TYPES, VALIDATORS, generateResolver, yup } from "dyl-components";
import React, { useEffect, useMemo, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { Form, Segment } from "semantic-ui-react";
import './index.scss'
import { useDispatch, useSelector } from "react-redux";
import { PhoneUtil } from "utils";
import pbxConfigActions from "actions/pbx_config";
import SharedSection from "./subcomponents/SharedSection";
import ForwardingUserSection from "./subcomponents/ForwardingUserSection";
import ForwardingCompanySection from "./subcomponents/ForwardingCompanySection";
import FullScreenModalPopUp from "shared/FullScreenModalPopUp";


const Content = ({ onClose, onReload, onDirtyChange }) => {
    const [destinationOptions, setDestinationOptions] = useState([]);
    const [forwardingAudioOptions, setForwardingAudioOptions] = useState([]);
    const [isNumberFocused, setIsNumberFocused] = useState(false);
    const [isNumberDuplicated, setIsNumberDuplicated] = useState(false);
    const dispatch = useDispatch();

    const { destinations, external_number } = useSelector((state) => state.pbx_config);

    const defaultUser = useMemo(() => ({
        user_id: null,
        extension: "",
    }), [])

    const defaultForward = useMemo(() => ({
        after_destination_id: "hang_up",
        use_caller_id: false,
        no_answer_destination_parent: "hang_up",
        cid_display: "",
        ringtime: 30,
        sound_id: "none",
        type: "user",
        view_display: false
    }), [])

    const { control, formState, handleSubmit, watch, setValue, getValues, setError, reset } = useForm({
        mode: 'onChange',
        defaultValues: {
            phone: external_number?.phone || "",
            label: external_number?.label || "",
            shared: external_number?.shared || false,
            forwarding: external_number?.forward || false,
            forward: (external_number?.forward && {
                after_destination_id: external_number.forward?.after_destination?.destination_id || "hang_up",
                no_answer_destination_parent: external_number.forward?.after_destination?.type || "hang_up",
                ringtime: external_number.forward?.ringtime || 30,
                type: external_number.forward?.type || "user",
                sound_id: external_number.forward?.sound?.sound_id || "none",
                use_caller_id: ! external_number.forward?.cid_display,
                cid_display: external_number.forward?.cid_display || "",
                view_display: external_number.forward?.view_display || false,
            }) || null,
            users: external_number?.users.map(({ user_id, extension }) => ({
                user_id,
                extension
            })) || []
        },
        resolver: generateResolver({
            phone: VALIDATORS.PHONE_NUMBER().required('This field is required'),
            users: yup.array().when("shared", {
                is: (shared) => shared,
                then: () => yup.array().min(1).of(yup.object().shape({
                    user_id: yup.number().required('This field is required'),
                    extension: yup.number().nullable().transform((value, originalValue) => originalValue === "" ? null : value).typeError("This field is numeric")
                })),
                otherwise: () => yup.array().when(["forwarding", "forward"], {
                    is: (forwarding, forward) => {
                        if (forwarding) {
                            return forward?.type === "user";  
                        }
                        return false;
                    },
                    then: () => yup.array().length(1).of(yup.object().shape({
                        user_id: yup.number().required('This field is required'),
                        extension: yup.number().nullable().transform((value, originalValue) => originalValue === "" ? null : value).typeError("This field is numeric")
                    }))
                }),
            }),
            forward: yup.object().nullable().when("forwarding", {
                is: (forwarding) => forwarding,
                then: () => yup.object().shape({
                    ringtime: yup.number().required('This field is required'),
                    after_destination_id: yup.mixed().required('This field is required'),
                    cid_display: yup.string().when("use_caller_id", {
                        is: (use_caller_id) => !use_caller_id,
                        then: () => VALIDATORS.CALLER_ID_DISPLAY(),
                    })
                }),
            })
        })
    });

    const [watchedShared, watchedForwarding, watchedForwardingType, watchedUseCallerId, watchedPhone] = watch(["shared", "forwarding", "forward.type", "forward.use_caller_id", "phone"]);

    const { isValid, isDirty } = formState

    const { fields, append, remove } = useFieldArray({
        control,
        name: "users"
    })

    const onAddUser = () => append(defaultUser);

    const formatPayload = (data) => {
        const dataCopy = {...data};
        const { forward, phone, users, ...rest } = dataCopy;
        const { use_caller_id, cid_display, sound_id,  after_destination_id, ...forwardRest } = forward || {};
        const sanitizedPhone = PhoneUtil.sanitizePhoneNumber(phone);
        return {
            ...rest,
            ...(dataCopy.forwarding && forward ? {
                forward: {
                    ...forwardRest,
                    ...(! use_caller_id ? {cid_display} : {}),
                    ...(sound_id !== "none" ? {sound_id} : {}),
                    ...(after_destination_id !== "hang_up" ? {after_destination_id} : {}),
                }
            } : {}),
            phone: sanitizedPhone,
            users: users.map((user) => ({...user, extension: user.extension ? `${user.extension}` : null}))
        };
    }

    const onAdd = async (data) => {
        try {
            const payload = formatPayload(data);
            await dispatch(pbxConfigActions.createExternalNumber(payload))
            reset()
            onDirtyChange(false);
            onClose();
            onReload();
            Notification.alert('External number added successfully!', STATUS_TYPES.SUCCESS);
        } catch (e) {
            console.log(e);
            Notification.alert('Failed to add External number', STATUS_TYPES.ERROR);
        }
    }

    const onEdit = async (data) => {
        try {
            const payload = formatPayload(data);
            await dispatch(pbxConfigActions.updateExternalNumber(external_number.phone, payload))
            reset()

            onDirtyChange(false);
            onClose();
            onReload();
            Notification.alert('External number edited successfully!', STATUS_TYPES.SUCCESS);
        } catch (e) {
            console.log(e);
            Notification.alert('Failed to edit External number', STATUS_TYPES.ERROR);
        }
    }

    const onCheckDuplicatedNumber = async () => {
        const phone = getValues("phone");
        try {
            if (!(external_number?.phone === phone)) {
                const exists = await dispatch(pbxConfigActions.uniqueExternalNumber(phone));
                if (exists?.exists) {
                    setError("phone", { type: "unique", message: "Number is already in use" })
                    setIsNumberDuplicated(true);
                } else {
                    setIsNumberDuplicated(false)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const onDeleteUser = (idx) => {
        remove(idx);
    }

    const handleOnBlur = async () => {
        if (!external_number) {
            setIsNumberFocused(false);
            await onCheckDuplicatedNumber();
        }
    }

    const handleOnFocus = () => {
        if (!external_number) {
            setIsNumberFocused(true);
        }
    }

    useEffect(() => {
        if (destinations) {
            const destinationsAux = [...PhoneUtil.DESTINATION_TYPES_OPTIONS];
            const destinationOptionsAux = destinationsAux.map((destination) => {
                const { key } = destination; 
                const options = destinations[key]?.map(({destination_id, label}) => (
                    {key: destination_id, value: destination_id, text: label}
                )) || []
                return {...destination, options};
            })
            setDestinationOptions(destinationOptionsAux);
        }
    }, [destinations]);

    useEffect(() => {
        dispatch(pbxConfigActions.getDestinations());
        dispatch(pbxConfigActions.getSoundOptions({category: "general"})).then((response) => {
            const { general } = response || {}
            const optionsAux = general?.map(({id, name}) => ({
                key: id,
                value: id,
                text: name
            })) || [];
            setForwardingAudioOptions(optionsAux)
        })
    }, [dispatch])

    useEffect(() => {
        if (watchedUseCallerId) {
            setValue("forward.cid_display", watchedPhone,{
                shouldDirty:false
            })
        }
    }, [watchedUseCallerId, watchedPhone, setValue])

    useEffect(() => {
        onDirtyChange(isDirty);
    }, [isDirty, onDirtyChange])

    return (
        <>
            <Modal.Header>
                {external_number ? "Edit " : "Create "} External Number
            </Modal.Header>
            <Modal.Content>
                <Form noValidate loading={false}>
                    <div className="ExternalNumberModal__form">
                        <div className="ExternalNumberModal__controllerContainer">
                            <Controller
                                name='phone'
                                control={control}
                                style={{flex: 1}}
                                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                    <Form.Input
                                        name={name}
                                        value={isNumberFocused ? (value) : (
                                            value !== ""  ? PhoneUtil.formatPhoneNumber(value) : value
                                        )}
                                        onChange={(_, { value }) => {
                                            onChange({ target: { name, value } }) 
                                        }}
                                        label="Number"
                                        placeholder="Type number"
                                        required
                                        className="ExternalNumbers__ruleField"
                                        onBlur={handleOnBlur}
                                        onFocus={handleOnFocus}
                                        error={error?.message}
                                        readOnly={!!external_number}
                                    />
                                )}
                            />
                            <Controller
                                name='label'
                                control={control}
                                style={{flex: 1}}
                                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                    <Form.Input
                                        name={name}
                                        value={value}
                                        onChange={(_, { value }) => { 
                                            onChange({ target: { name, value } })
                                        }}
                                        label="Label"
                                        placeholder="Type label"
                                        error={error?.message}
                                        className="ExternalNumbers__ruleField"
                                    />
                                )}
                            />
                        </div>
                        {(external_number === null || external_number?.shared) && (
                            <div className="ExternalNumberModal__controllerContainer" style={{marginBottom: 20}}>
                                <Controller
                                    name='shared'
                                    control={control}
                                    style={{flex: 1}}
                                    render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                        <Form.Checkbox
                                            name={name}
                                            checked={value}
                                            onChange={(_, { checked }) => { 
                                                if (checked) {
                                                    setValue("forwarding", false);
                                                    setValue("users", [defaultUser]);
                                                } else {
                                                    setValue("users", []);
                                                }
                                                onChange({ target: { name, value: checked }})
                                            }}
                                            label="Shared"
                                            toggle
                                            disabled={!!external_number}
                                        />
                                    )}
                                />
                            </div>
                        )}
                        {(external_number === null || external_number?.forward) && (
                            <div className="ExternalNumberModal__checkboxContainer">
                                <Controller
                                    name='forwarding'
                                    control={control}
                                    style={{flex: 1}}
                                    render={({ field: { name, value, onChange } }) => (
                                        <Form.Checkbox
                                            name={name}
                                            checked={value}
                                            onChange={(_, { checked }) => {
                                                if (checked) {
                                                    setValue("shared", false);
                                                    setValue("forward", defaultForward)
                                                    setValue("users", [defaultUser]);
                                                } else {
                                                    setValue("forward", null);
                                                    setValue("users", []);
                                                }
                                                onChange({ target: { name, value: checked } })
                                            }}
                                            label="Forwarding"
                                            toggle
                                            disabled={!!external_number}
                                        />
                                    )}
                                />
                                <Controller
                                    name='forward.type'
                                    control={control}
                                    style={{flex: 1}}
                                    render={({ field: { name, value, onChange } }) => (
                                        <>
                                            <Form.Checkbox
                                                name={name}
                                                checked={value === "user"}
                                                onChange={() => { 
                                                    setValue("forward.use_caller_id", false);
                                                    setValue("forward.after_destination_id", "hang_up");
                                                    setValue("forward.no_answer_destination_parent", "hang_up");
                                                    setValue("forward.cid_display", "");
                                                    setValue("forward.ringtime", 30);
                                                    setValue("forward.sound_id", "none");
                                                    setValue("forward.view_display", false);
                                                    setValue("users", [defaultUser]);
                                                    onChange({ target: { name, value: "user" } })
                                                }}
                                                label="User"
                                                disabled={!watchedForwarding}
                                                radio
                                            />
                                            <Form.Checkbox
                                                name={name}
                                                checked={value === "company"}
                                                onChange={() => { 
                                                    setValue("forward.use_caller_id", false);
                                                    setValue("forward.after_destination_id", "hang_up");
                                                    setValue("forward.no_answer_destination_parent", "hang_up");
                                                    setValue("forward.cid_display", "");
                                                    setValue("forward.ringtime", 30);
                                                    setValue("forward.sound_id", "none");
                                                    setValue("forward.view_display", false);
                                                    setValue("users", []);
                                                    onChange({ target: { name, value: "company" } })
                                                }}
                                                label="Company"
                                                disabled={!watchedForwarding}
                                                radio
                                            />
                                        </>
                                    )}
                                />
                            </div>
                        )}
                        {watchedShared && (
                            <SharedSection fields={fields} control={control} onAddUser={onAddUser} onDeleteUser={onDeleteUser} external_number={external_number} setValue={setValue}/>
                        )}
                        {watchedForwarding && watchedForwardingType === "user" && (
                            <ForwardingUserSection  control={control} forwardingAudioOptions={forwardingAudioOptions} destinationOptions={destinationOptions} external_number={external_number} setValue={setValue}/>
                        )}
                        {watchedForwarding && watchedForwardingType === "company" && (
                            <ForwardingCompanySection  control={control} forwardingAudioOptions={forwardingAudioOptions} destinationOptions={destinationOptions} watchedUseCallerId={watchedUseCallerId} />
                        )}
                    </div>
                </Form>
            </Modal.Content>
            <Modal.Actions 
                hasSaveButton
                onSave={handleSubmit(external_number ? onEdit : onAdd)}
                saveDisabled={!isValid || !isDirty || isNumberDuplicated}
            />  
        </>
    )
}

const ExternalNumbersModal = ({ open, onClose, onReload }) => {
    const [isDirty, setIsDirty] = useState(false);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const closeModal = () => {
        onClose();
    };
    const isReadingExternalNumber = useSelector((state) => state.pbx_config.isReadingExternalNumber);
    return (
        <React.Fragment>
            <Modal size={"small"} open={open} onClose={()=>{isDirty ? setIsConfirmationOpen(true):onClose();}}>
                {isReadingExternalNumber ? <Modal.Content><Segment basic loading /></Modal.Content> : open && <Content onClose={closeModal} onReload={onReload} onDirtyChange={(dirty) => setIsDirty(dirty)} />}
            </Modal>
            <FullScreenModalPopUp
                header={"Changes not saved"}
                subheader={"Are you sure you want to exit?"}
                isOpen={isConfirmationOpen}
                onConfirm={() => {
                    onClose();
                    setIsConfirmationOpen(false);
                    setIsDirty(false)
                }}
                onFormClose={() => setIsConfirmationOpen(false)}
                closeIcon={false}
            />
        </React.Fragment>
    )
}

export default ExternalNumbersModal;
