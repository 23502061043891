const initialState = {
    call_history: [],
    activeCalls: [],
    isReadingCallHistory: false,
    isReadingActiveCalls: false,
    allMarked: false,

    recent_calls: [],
    isReadingRecentCalls: false,
    recent_call: null,
    isReadingRecentCall: false
}

export default initialState;
