import { StringUtils } from "utils";

const EMAIL_TYPE_OPTIONS = {
    keys: {
        WORK: "work",
        PERSONAL: "personal",
        OTHER: "other"
    },
    getOptions: function() {
        return Object.keys(this.keys).map(type => ({
            key: this.keys[type],
            value: this.keys[type],
            text: StringUtils.capitalize(this.keys[type])
        }))
    }
}

export default EMAIL_TYPE_OPTIONS;
