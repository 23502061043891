import React, {useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, Segment } from 'semantic-ui-react';
import Avatar from 'react-avatar';
import usersActions from 'actions/users';

import './UserSearchField.scss';

const resultRenderer = ({ first_name, last_name, email }) => {
    const name = first_name || last_name ? `${first_name || ''} ${last_name || ''}` : "Unknown Name";
    return (
        <div style={{ verticalAlign: 'middle' }}>
            <Avatar name={name} maxInitials={2} round size='3em' /> <div style={{ display: "inline-block", paddingLeft: "1em" }}>
                <div><b>{name}</b></div>
                <div>{email}</div>
            </div>
        </div>
    )
}

const selectionRenderer = ({ user }) => {
    if (user) {
        const { first_name, last_name, email } = user;
        const username = `${first_name} ${last_name}`;
        return (
            <div>
                <Segment basic style={{ padding: 0, margin: 0 }} size='tiny' className="UserSearchField__user UserSearchField_ReportsTo_user">
                    <Avatar name={username} maxInitials={2} round size='3em' className='UserSearchField__noFontSize' />
                    <div className="UserSearchField__information">
                        <div><span className="SelectedUser__name"><b>{username}</b></span></div>
                        <div><span className="SelectedUser__email"><b>{email}</b></span></div>
                    </div>
                </Segment>
            </div>
        ) 
    }

    return null;
}

const SingleUserSearchField = ({
    onFocusSearch,
    onChange,
    upward,
    placeholder,
    name,
    value,
}) => {
    const [focused, setFocused] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [options, setOptions] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);

    const dispatch = useDispatch();

    const { users, isSearchingUsers } = useSelector((state) => ({
        users: state.users.userOptions,
        isSearchingUsers: state.users.isReadingUsers,
    }))

    const onUserSearchChange = (e, data) => {
        const search = data.value || data.searchQuery;
        setSearchValue(search);
    }

    const onSelectUser = (e, { result }) => {
        if (!result) {
            setSearchValue("");
            onChange(e, { name, value: '' })
        } else {
            const { user_id } = result;
            onChange(e, { name: name, value: user_id })
        }
    }

    const onSearchUsers = useCallback((queryParameters) => {
        dispatch(usersActions.readUserOptions(queryParameters));
    }, [dispatch])

    useEffect(() => {
        onSearchUsers({limit: 500});
    }, [onSearchUsers])

    useEffect(() => {
        if (users) {
            const optionsAux = users.filter(({ user_id }) => {
                return user_id !== value
            }).map(user => ({
                key: user.user_id,
                value: user.user_id,
                text: `${user.first_name} ${user.last_name} ${user.email}`,
                content: (resultRenderer({ ...user }))
            }))
            setOptions(optionsAux);

            if (value) {
                const userSearch = users.find(({ user_id }) => user_id === value);
                setSelectedUser(userSearch);
            } else {
                setSelectedUser(null);
            }
        } else {
            setSelectedUser(null);
            setOptions([]);
        }
    }, [value, users, searchValue])

    return (
        <div className='UserSearchField__dropdown__close-flex'>
            <Dropdown
                upward={upward}
                loading={isSearchingUsers}
                onSearchChange={onUserSearchChange}
                options={!isSearchingUsers && options}
                onOpen={() => {
                    setFocused(true);
                    onUserSearchChange(null, { searchQuery: '' });
                }}
                search={focused}
                searchQuery={focused ? searchValue : ''}
                onChange={(e, { value }) => {
                    onSelectUser(null, { result: users.find(({ user_id }) => user_id === value) }); 
                    setFocused(false);
                }}
                placeholder={placeholder}
                fluid
                onClose={() => { setFocused(false) }}
                selection
                selectOnBlur={false}
                trigger={focused ? <div></div> : selectionRenderer({user: selectedUser})}
                focus={focused}
                onFocus={onFocusSearch}
                value={value}
            />
        </div>
    );
};

export default SingleUserSearchField;
