import React, {  } from "react";
import { Form, Grid, Icon } from "semantic-ui-react";

import { AccountInfo } from "./AccountInfo";
import { ButtonLink } from "dyl-components";
import { Link, useParams } from "react-router-dom";

const Details = ({
    atLeastOneExpanded,
    collapseAllData,
    expandAllData,
    info,
}) => {
    const { account_id } = useParams();
    return [
        <Grid.Row>
            <Grid.Column>
                <Grid className="ContactInfo">
                    <Grid.Row>
                        <Grid.Column>
                            <ButtonLink
                                onClick={
                                    atLeastOneExpanded
                                        ? collapseAllData
                                        : expandAllData
                                }
                            >
                                {atLeastOneExpanded ? "Collapse" : "Expand"} All
                                Data{" "}
                                <Icon
                                    className={`fas fa-circle-chevron-${
                                        atLeastOneExpanded ? "up" : "down"
                                    }`}
                                />
                            </ButtonLink>{" "}
                            |{" "}
                            <ButtonLink>
                                <Link to={`/account/${account_id}/data`}>
                                    View All Data
                                </Link>
                            </ButtonLink>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Grid.Column>
        </Grid.Row>,
        <Grid.Row>
            <Grid.Column>
                <Form>
                    <Grid columns={4}>
                        <Grid.Column>
                            <AccountInfo />
                        </Grid.Column>
                        {info}
                    </Grid>
                </Form>
            </Grid.Column>
        </Grid.Row>,
    ];
};

export default Details;
