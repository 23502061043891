const initialState = {
    isAuthenticatingUser: true,
    authenticateUserMessage: null,
    
    isLoggingOutUser: false,
    logOutUserMessage: null,
    currentlyActiveUsername: null,
    currentlyActiveUserId: null,
    isGettingIP: false,

    isCreatingUser: false,
    user_id: null,
    account_id: null,
    name: null,
    dyl_employee: false,
    access_role_id: null,
    access_role: null,
    temporary_password: false,

    isRecoveringPassword: false,
    isResettingPassword: false,
    isVerifyingCode: false,
    

    //Permission Deprecicate
    // isCheckingPermission: false,
    // isPermitted: false,
    userPermissions: [],
    isUpdatingRole: false,
    isSigningTerms: false,
    signed_terms: true
}

export default initialState;
