import React, { useEffect, useState } from "react";
import { Form } from "semantic-ui-react";
import './index.scss'
import { Controller } from "react-hook-form";
import { PhoneUtil } from "utils";
import { ButtonLink } from "dyl-components";

const ExternalNumberCodeForm = ({ control, verifyingPhone = {}, isVerifyingExternalNumberCode, onCallAgain, formState }) => {
    const [isCallAgainDisabled, setIsCalledAgainDisabled] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setIsCalledAgainDisabled(false)
        }, (45000))
    }, [])

    const handleCallAgain = () => {
        onCallAgain()
        setIsCalledAgainDisabled(true)
        setTimeout(() => {
            setIsCalledAgainDisabled(false)
        }, (45000))
    }

    return (
        <Form noValidate loading={false}>
            <div style={{marginBottom: 18}}>
                <p>
                    <strong>
                        An inbound call with the 6-digit code will be made to {PhoneUtil.formatPhoneNumber(verifyingPhone.phone)}
                    </strong>
                </p>
            </div>
            <div style={{display: 'flex', gap: 20, justifyContent: 'center', marginBottom: 18}}>
                <Controller
                    name='code'
                    control={control}
                    render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                        <Form.Input
                            size={"huge"}
                            name={name}
                            value={value}
                            className="ExternalNumbers__hugeField"
                            onChange={(_, { value }) => {
                                onChange({ target: { name, value } }) 
                            }}
                        />
                    )}
                />
            </div>
            <div style={{display: 'flex', gap: 5, marginBottom: 8}}>
                <span>Didn't recieve code?</span>
                <ButtonLink onClick={handleCallAgain} disabled={isVerifyingExternalNumberCode || isCallAgainDisabled}>
                    <span>Call again</span>
                </ButtonLink>
            </div>
            <div style={{display: 'flex', gap: 5}}>
                <span>Code expires in 10 minutes.</span>
            </div>
        </Form>
    )
}

export default ExternalNumberCodeForm;
