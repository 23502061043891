import React from "react";
import { useSelector } from "react-redux";

import { useParams } from "react-router-dom";

import QuoteBuilderModal from "shared/modals/QuoteBuilderModal";
import FundedCustomerData from "./FundedCustomerData";
import CustomerData from "./CustomerData";
import AccountData from "./AccountData";

export const AccountStandardData = () => {
    const { account_id } = useParams();
    const isDyl = useSelector(state => state.auth.dyl_employee);
    const { customer_status, fund_type } = useSelector(
        (state) => state.account.account?.customer || {}
    );

    if (customer_status) {
        if (fund_type && fund_type !== "unfunded" && isDyl) {
            return (
                <FundedCustomerData />
            )
        }
        return (
            <>
                <CustomerData />
                <QuoteBuilderModal account_id={account_id} />
            </>
        );
    }
    return <AccountData />;
};
