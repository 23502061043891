import React, { useState, useEffect } from "react";
import { EditableLabels, Notification, STATUS_TYPES } from "dyl-components";
import { connect } from "react-redux";
import eventsActions from "actions/events";
import eventActions from "actions/event";

const EventLabelsContainer = ({
    onReadEventLabels,
    event_labels,
    onAddEventLabels,
    onUpdateEventLabel,
    onDeleteEventLabel,
    onCheckEventLabelDuplicate,
    isLoading,
    handleFormDirty,
}) => {
    const [eventLabels, setEventLabels] = useState([]);
    const [key, setKey] = useState("EVENT_LABELS");

    useEffect(() => {
        onReadEventLabels().then(() => {
            setEventLabels(event_labels);
            resetKey();
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setEventLabels(event_labels);
    }, [event_labels]);

    const resetKey = () => {
        setKey("");
        setTimeout(() => {
            setKey("EVENT_LABELS");
        }, 0);
    };

    const onSave = async (newLabels) => {
        const labelsToAdd = newLabels
            .filter(({ id }) => !id)
            .map(({ value }) => ({
                name: value[0].replace(/\s+/g, " ").trim(),
            }));

        if (labelsToAdd.length === 0) return;

        try {
            await onAddEventLabels(labelsToAdd);
            Notification.alert(
                "Successfully saved the event labels!",
                STATUS_TYPES.SUCCESS
            );
            setEventLabels([
                ...eventLabels,
                ...labelsToAdd.map((label) => ({
                    id: null,
                    value: [label.name],
                })),
            ]);

            await onReadEventLabels();
            resetKey();
        } catch (error) {
            console.error(error);
            Notification.alert(
                "Failed to create event labels",
                STATUS_TYPES.ERROR
            );
        }
    };

    const onUpdate = async (id, name) => {
        try {
            await onUpdateEventLabel(id, name);
            Notification.alert(
                "Successfully updated the event label!",
                STATUS_TYPES.SUCCESS
            );
            setEventLabels(
                eventLabels.map((label) =>
                    label.id === id ? { ...label, value: [name] } : label
                )
            );
        } catch (error) {
            Notification.alert(
                error.Code === 409
                    ? "Event label already exists"
                    : "Failed to update event label",
                STATUS_TYPES.ERROR
            );
        }
    };

    const onRemove = async (id) => {
        try {
            await onDeleteEventLabel(id);
            Notification.alert(
                "Successfully deleted the event label!",
                STATUS_TYPES.SUCCESS
            );
            setEventLabels(eventLabels.filter((label) => label.id !== id));
        } catch (error) {
            console.error(error);
            Notification.alert(
                "Failed to delete event label",
                STATUS_TYPES.ERROR
            );
        }
    };

    const onCheckDuplicate = async (value) => {
        try {
            const { id, name } = await onCheckEventLabelDuplicate(value);
            if (!id || !name) throw new Error();
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

    return (
        <EditableLabels
            key={key}
            name="event_labels"
            addLabel="Add Event Label"
            info="Edit will reflect on all events. Delete will remove the label from all future events."
            fields={[
                {
                    header: "Event Labels",
                    type: "input",
                    placeholder: "Type event label",
                    editable: true,
                },
            ]}
            items={eventLabels}
            onSave={onSave}
            onUpdate={onUpdate}
            onRemove={onRemove}
            isLoading={isLoading}
            onCheckDuplicate={onCheckDuplicate}
            onFormDirty={handleFormDirty}
        />
    );
};

const mapStateToProps = (state) => ({
    event_labels: state.events.event_labels.map(({ name, id }) => ({
        id,
        value: [name],
    })),
    isLoading:
        state.events.isReadingEventLabels ||
        state.events.isAddingEventLabels ||
        state.event.isDeletingEventLabel ||
        state.event.isUpdatingEventLabel,
});

const mapDispatchToProps = (dispatch) => ({
    onReadEventLabels: () => dispatch(eventsActions.readEventLabels()),
    onAddEventLabels: (labels) =>
        dispatch(eventsActions.addEventLabels(labels)),
    onUpdateEventLabel: (id, name) =>
        dispatch(eventActions.updateEventLabel(id, { name })),
    onDeleteEventLabel: (id) => dispatch(eventActions.deleteEventLabel(id)),
    onCheckEventLabelDuplicate: (label) =>
        dispatch(
            eventActions.checkEventLabelDuplicate(null, { event_label: label })
        ),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EventLabelsContainer);
