import { Modal, Notification, STATUS_TYPES } from "dyl-components";
import React, { useEffect, useState } from "react";
import { Button, Label } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import { PhoneUtil } from "utils";
import VerifyExternalNumberForm from "shared/forms/VerifyExternalNumberForm";
import { useDispatch, useSelector } from "react-redux";
import pbxConfigActions from "actions/pbx_config";
import ExternalNumberCodeForm from "shared/forms/ExternalNumberCodeForm";
import './index.scss'

const VerifyExternalNumberModal = ({ open, onClose, verifyingPhone, onReload }) => {
    const [codeMode, setCodeMode] = useState(false);
    const [hasError, setHasError] = useState(false);

    const { control, reset, handleSubmit, setError, formState } = useForm({
        mode: 'onChange',
        defaultValues: {
            phone: null,
            method: "voice",
            extension: "",
            code: ""
        },
    })

    const { isVerifyingExternalNumberCode } = useSelector((state) => state.pbx_config)

    const dispatch = useDispatch();

    const handleClick = (data) => {
        setCodeMode(true);
        dispatch(pbxConfigActions.getExternalVerificationCode({phone: data.phone}))
    }

    const handleVerification = async (data) => {
        try {
            const result = await dispatch(pbxConfigActions.verifyExternalNumberForm(data.phone, {code: data.code}));
            if (result) {
                Notification.alert("Successfully verified number!", STATUS_TYPES.SUCCESS);
                onClose();
                onReload();
            } else {
                setError("code", {type: "custom", message: "Invalid Code. Please try again."});
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        setCodeMode(false);
        reset({
            phone: verifyingPhone?.phone,
            method: "voice",
            extension: "",
            code: "",
        })
    }, [verifyingPhone, reset])

    useEffect(() => {
        if (formState?.errors?.code) {
            setHasError(true)
        } else {
            setHasError(false)
        }
    }, [formState])

    return (
        <Modal open={open} onClose={onClose} size={"tiny"}>
            <Modal.Header>
                Verify {PhoneUtil.formatPhoneNumber(verifyingPhone?.phone)}
            </Modal.Header>
            <Modal.Content>
                {codeMode ? (
                    <ExternalNumberCodeForm
                        control={control}
                        verifyingPhone={verifyingPhone}
                        isVerifyingExternalNumberCode={isVerifyingExternalNumberCode}
                        onCallAgain={handleSubmit(handleClick)} 
                        formState={formState}
                    />
                ) :(
                    <VerifyExternalNumberForm control={control} verifyingPhone={verifyingPhone} />
                )}
            </Modal.Content>
            <Modal.Actions>
                {hasError && (
                    <Label pointing={"right"} className="ExternalNumbers__errorLabel">{ formState?.errors?.code?.message || "" }</Label>
                )}
                <Button 
                    disabled={isVerifyingExternalNumberCode}
                    primary
                    onClick={handleSubmit(codeMode ? handleVerification : handleClick)}
                >
                    {codeMode ? "Verify" : "Request Code"}
                </Button>
            </Modal.Actions>  
        </Modal>
    )
}

export default VerifyExternalNumberModal;
