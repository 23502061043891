import productCategoryActions from "actions/product_category";
import { Notification, STATUS_TYPES, VALIDATORS, generateResolver } from "dyl-components";
import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import ProductCategoryForm from "shared/forms/ProductCategoryForm";

const AddProductCategory = ({ refresh, onClose, onDirtyChange }) => {
    const methods = useForm({
        mode: "onChange",
        defaultValues: {
            name: "",
            description: "",
        },
        resolver: generateResolver({
            name: VALIDATORS.SIMPLE_INPUT()
                .noemoji()
                .minlength(2)
                .maxlength(100)
                .no_whitespace_only()
                .required("This field is required"),
            description: VALIDATORS.DESCRIPTION()
        }),
    });

    const dispatch = useDispatch();
    
    const onSave = async (data) => {
        try {
            await dispatch(productCategoryActions.addProductCategory([data]));
            Notification.alert("Succesfully created product category!", STATUS_TYPES.SUCCESS);
            methods.reset();
            onClose();
            if (refresh) {
                refresh();
            }
        } catch (e) {
            console.log(e);
            Notification.alert("Failed to create product category", STATUS_TYPES.ERROR);
        }
    }

    const isSaving = useSelector(state => state.product_category.isCreating);

    useEffect(() => {
        if (onDirtyChange) {
            onDirtyChange(methods.formState.isDirty);
        }
        // eslint-disable-next-line
    }, [methods.formState.isDirty])

    return (
        <React.Fragment>
        <FormProvider {...methods}>
            <ProductCategoryForm onSave={onSave} isSaving={isSaving} />
        </FormProvider>
        </React.Fragment>
    );
};

export default AddProductCategory;
