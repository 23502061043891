import { RuleContainer } from 'dyl-components';
import { Header } from 'semantic-ui-react';
import { Conditions } from './Conditions';
import { Criteria } from './Criteria';

export const SegmentsSection = ({
    control,
    parentName,
    segmentIndex,
    isBeingAdded,
    movedSegments,
    resetAdded,
    resetMoved,
    segmentId,
    trigger,
    compositeFields,
    watchedSegments,
    onChangeCompositeField,
    onAddAdditionalField,
    criteriaIndex,
    onClearCriteria,
    onRemoveCriteria,
    isOnly,
    isReadingCampaignList,
    campaignList,
    isReadingProductInterests,
    product_interests,
    sourceOptions,
    setActiveSegment,
    activeSegment,
    onRemoveAdditionalField,
    segmentsToAdd,
    isEditing,
    setSegmentsToAdd,
    setValue,
    watch
}) => {
    const isProductInterestDisable = ["opportunity","customer"]?.includes(watch("converts_to"))
    return (
        <RuleContainer
            onClick={() => {
                setActiveSegment(segmentIndex)
            }}
            isActive={segmentIndex === activeSegment}
            content={
                <>
                    <Header>Segment</Header>
                    <Criteria 
                        control={control} 
                        sourceOptions={sourceOptions}
                        parentName={parentName}
                        index={criteriaIndex}
                        onClearCriteria={onClearCriteria}
                        onRemoveCriteria={onRemoveCriteria}
                        isOnly={isOnly}
                        isReadingCampaignList={isReadingCampaignList}
                        campaignList={campaignList}
                        isReadingProductInterests={isReadingProductInterests}
                        product_interests={product_interests}
                        isProductInterestDisable={isProductInterestDisable}
                    />
                    {watchedSegments?.[segmentIndex]?.conditions?.length === 0 && (
                        <Header textAlign='center'>
                            <Header.Subheader textAlign='center' style={{ marginTop: 20 }}>
                                <strong>No Conditions Added</strong>
                                <div>Add a Condition by clicking on the + icon on the panel</div>
                            </Header.Subheader>
                        </Header>
                    )}
                    <div>
                        <Conditions
                            control={control}
                            parentName={parentName}
                            segmentIndex={segmentIndex}
                            isBeingAdded={isBeingAdded}
                            movedSegments={movedSegments}
                            resetAdded={resetAdded}
                            resetMoved={resetMoved}
                            segmentId={segmentId}
                            trigger={trigger}
                            compositeFields={compositeFields}
                            watchedSegments={watchedSegments}
                            onChangeCompositeField={onChangeCompositeField}
                            onAddAdditionalField={onAddAdditionalField}
                            onRemoveAdditionalField={onRemoveAdditionalField}
                            segmentsToAdd={segmentsToAdd}
                            isEditing={isEditing}
                            setSegmentsToAdd={setSegmentsToAdd}
                            setValue={setValue}
                            watch={watch}
                        />
                    </div>
                </>
            }
        />
    )
}
