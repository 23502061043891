import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, generateResolver, yup, STATUS_TYPES, Notification } from 'dyl-components';
import { useDispatch, useSelector } from 'react-redux';

import { STATES } from 'shared/constants/STATES';
import TerritoryForm from 'shared/forms/TerritoryForm';

import territoryActions from 'actions/territory';
import { Segment } from 'semantic-ui-react';
import FullScreenModalPopUp from 'shared/FullScreenModalPopUp';

const EditTerritoryContent = ({ id, refresh, onClose, onDirtyChange }) => {
    const territory = useSelector(state => state.territory.territory);
    const { control, formState: { isValid, isDirty }, handleSubmit, clearErrors, setError, setValue, watch } = useForm({
        mode: 'onChange',
        defaultValues: {
            name: territory?.name || '',
            states: territory?.state || [],
            counties: territory?.county?.map(county => `${county.county} (${county.state})`) || [],
            zips: territory?.zip || [],
            teams: territory?.teams || []
        },
        resolver: generateResolver({
            name: yup.string().no_whitespace_only().no_excessive_whitespaces().maxlength(64).simple_alphanumeric().required('This field is required'),
            states: yup.array().of(yup.string().oneOf(STATES.map(({ key }) => key))).test('at_least_one_criterion_required', 'There should be at least one criteria for a territory', function (value) {
                return value.length || this.parent.zips?.length || this.parent.counties?.length;
            }),
            counties: yup.array().of(yup.string()).test('at_least_one_criterion_required', 'There should be at least one criteria for a territory', function (value) {
                return value.length || this.parent.zips?.length || this.parent.states?.length;
            }),
            zips: yup.array().of(yup.string()).test("valid_zip", "Please remove all previously used or invalid zip codes.", zips => {
                if (zips.length) {
                    return zips.every(zip => /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zip));
                }
                return true;
            }).test('at_least_one_criterion_required', 'There should be at least one criteria for a territory', function (value) {
                return value.length || this.parent.states?.length || this.parent.counties?.length;
            })
        })
    });

    const dispatch = useDispatch();

    const onSave = async (data) => {
        const {
            name,
            states: state,
            teams,
            zips: zip,
            counties
        } = data;

        try {
            await dispatch(territoryActions.updateTerritory(id, {
                name,
                state,
                teams,
                zip,
                county: counties.map(county => {
                    const [countyName, state] = county.split('(');
                    return {
                        county: countyName.trim(),
                        state: state.trim().replace(')', '')
                    }
                })
            }));
            Notification.alert('Successfully updated territory!', STATUS_TYPES.SUCCESS);
            if (refresh) {
                refresh();
            }
            onClose();
        } catch (e) {
            console.log(e);
            Notification.alert('Failed to update territory', STATUS_TYPES.ERROR);
        }
    }

    useEffect(() => {
        if (onDirtyChange) {
            onDirtyChange(isDirty);
        }
        // eslint-disable-next-line
    }, [isDirty])

    const isUpdatingTerritory = useSelector(state => state.territory.isUpdatingTerritory);
    const isCheckingDuplicate = useSelector(state => state.territory.isCheckingDuplicate);
    const isCheckingValidity = useSelector((state) => state.territory.isCheckingValidity);

    return [
        <Modal.Content>
            <TerritoryForm
                control={control}
                loading={isUpdatingTerritory}
                clearErrors={clearErrors}
                setError={setError}
                id={id}
                defaultZipOptions={territory?.zip?.map(zip => ({
                    key: zip,
                    value: zip,
                    text: zip
                }))}
                setValue={setValue}
                watch={watch}
            />
        </Modal.Content>,
        <Modal.Actions
            hasSaveButton
            saveDisabled={!isValid || !isDirty || isUpdatingTerritory || isCheckingDuplicate || isCheckingValidity}
            onSave={handleSubmit(onSave)}
            saveOptions={{ loading: isUpdatingTerritory || isCheckingDuplicate || isCheckingValidity }}
        />
    ]
}

const EditTerritoryModal = ({ onClose, open, id, refresh }) => {
    const [isDirty, setIsDirty] = useState(false);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        if (id) {
            dispatch(territoryActions.getTerritory(id));
        }
    }, [dispatch, id]);

    const isReadingTerritory = useSelector(state => state.territory.isReadingTerritory);

    if (!id) {
        return null;
    }

    return (
        <React.Fragment>
            <Modal
                size='large'
                onClose={() => {
                    isDirty
                        ? setIsConfirmationOpen(true)
                        : onClose();
                }}
                open={open}
            >
                <Modal.Header>
                    Edit Territory
                </Modal.Header>
                {isReadingTerritory ? (
                    <Modal.Content>
                        <Segment basic loading>

                        </Segment>
                    </Modal.Content>
                ) : (
                    <EditTerritoryContent
                        id={id}
                        refresh={refresh}
                        onClose={onClose}
                        onDirtyChange={(dirty) => setIsDirty(dirty)}
                    />
                )}
            </Modal>
            <FullScreenModalPopUp
                header={"Changes not saved"}
                subheader={"Are you sure you want to exit?"}
                isOpen={isConfirmationOpen}
                onConfirm={() => {
                    onClose();
                    setIsConfirmationOpen(false);
                    setIsDirty(false)
                }}
                onFormClose={() => setIsConfirmationOpen(false)}
                closeIcon={false}
            />
        </React.Fragment>
    );
}

export default EditTerritoryModal;
