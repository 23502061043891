import React from 'react';
import { FileInput, STATUS_TYPES, Notification, generateResolver, Button } from 'dyl-components';
import { Form, Icon, Segment, Input, Header } from 'semantic-ui-react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import FileUtils, { FILE_CATEGORIES } from 'utils/FileUtils';
import { useDispatch, useSelector } from 'react-redux';
import uploadActions from 'actions/upload';
import importActions from 'actions/import_data';
import './index.scss';

const UploadFiles = ({
    dropzoneRef = React.createRef(),
    closeModal
}) => {    
    const [params] = useSearchParams();

    const dispatch = useDispatch();
    const { contact_id, account_id } = useParams();
    
    const { isProfileUploads, created_user_id } = useSelector(state => { 
        return {
            isProfileUploads: state.import_data.isProfileUploads,
            created_user_id: state.auth.user_id
        }
    });

    const { control, getValues, handleSubmit, formState: { isValid }, watch } = useForm({
        mode: 'onChange',
        resolver: generateResolver({
            attachments: FileUtils.getFilesizeValidator(FILE_CATEGORIES.DOCUMENT)
        })
    });

    const onReload = () => {
        dispatch(importActions.readProfileUploads({
            ...(contact_id ? { contact_id: [contact_id] } : ''),
            ...(account_id ? { account_id: account_id } : ''), 
            ...Object.fromEntries(params), 
            limit: 6
        }))
    }

    const onSubmit = async () => {
        const files = getValues()?.attachments;
        const toUpload = files.filter(file => file.id === undefined);
        const uploadedFiles = await dispatch(uploadActions.uploadFiles(toUpload, FILE_CATEGORIES.DOCUMENT));
        const newFiles = toUpload.map((_, idx) => {
            return {
                name: files[idx].name,
                file_id: uploadedFiles[idx],
                size: files[idx].size,
                ...(contact_id ? { contact_id: Number(contact_id) } : ''),
                ...(account_id ? { account_id: Number(account_id) } : ''),
                created_user_id
            }
        })
        const plural = newFiles.length > 1 ? 's' : '';
        try {
            await dispatch(importActions.uploadProfile(newFiles));
            Notification.alert(`Successfully uploaded file${plural}!`, STATUS_TYPES.SUCCESS);
            closeModal();
            onReload();
        } catch (e) {
            console.log(e);
            if(e.Code === 409){
                Notification.alert(`Unable to add file${plural} with same name.`, STATUS_TYPES.ERROR);
            } else {
                Notification.alert(`Failure to upload file${plural}.`, STATUS_TYPES.ERROR);
            }
        }
    }
    
    return (
        <div className='UploadFiles'>
            <Header color='primary'>Upload File</Header>
            <Form noValidate className='UploadFilesForm'>
                <Form.Group>
                    <Controller
                        name="attachments"
                        defaultValue={[]}
                        control={control}
                        render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                            <Form.Field
                                control={Input}
                                error={error && error.message && {
                                    pointing: 'below'
                                }}
                                className='Template__attachments-section'
                            >
                                <Segment style={{ width: '100%' }}>
                                    <div>
                                        <Icon
                                            onClick={() => { dropzoneRef.current.open() }} 
                                            color='blue' name='plus circle' link /> <b>Attach {value.length > 0 && 'more '}files</b>
                                    </div>
                                    <div className='Template__attachments' style={{ marginBottom: 10 }}>
                                        <FileInput
                                            onChange={(_, { value: new_value }) => {
                                                onChange({
                                                    target: {
                                                        name, value: [
                                                            ...value,
                                                            ...new_value.filter(new_file => (
                                                                value.findIndex(file => file.path === new_file.path) === -1
                                                            ))
                                                        ]
                                                    }
                                                })
                                            }}
                                            onRemove={(_, { value }) => { onChange({ target: { name, value } }) }}
                                            onReject={(rejected) => {
                                                if (rejected.length > 0) {
                                                    Notification.alert("File type must be .pdf, .png, .jpg, or .jpeg", STATUS_TYPES.ERROR, true);
                                                }
                                            }}
                                            files={value}
                                            name="files"
                                            accept="application/pdf, image/png, image/jpeg, image/jpg"
                                            icon="file pdf outline"
                                            size="mini"
                                            dropzoneRef={dropzoneRef}
                                            showIcon
                                            hasBrowse
                                            multiIcon
                                        />
                                    </div>
                                    <i>Remaining: {FileUtils.getRemainingSize(value, FILE_CATEGORIES.DOCUMENT)} MB </i> {error?.message && `(${error.message})`}
                                </Segment>
                            </Form.Field>
                        )}
                    />
                </Form.Group>
                <Button floated='right' 
                    loading={isProfileUploads} 
                    color='primary' 
                    onClick={handleSubmit(onSubmit)} 
                    disabled={!isValid || isProfileUploads || watch("attachments").length === 0}
                >Upload</Button>
            </Form>
        </div>
    )
}

export default UploadFiles;
