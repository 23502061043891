import { Button, Notification, STATUS_TYPES, SettingsFooter } from 'dyl-components';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Form, Grid, Header, Icon, Popup, Portal } from 'semantic-ui-react';
import useWidthListener from 'shared/SettingsFooter/useWidthListener';
import useWindowWidth from 'shared/SettingsFooter/useWindowWidth';
import { MathUtils } from 'utils';
import pbxConfigActions from 'actions/pbx_config';
import { useDispatch, useSelector } from 'react-redux';
import { useBlocker } from 'shared/confirmation/UseBlocker';
import FullScreenModalPopUp from 'shared/FullScreenModalPopUp';

const Preferences = () => {
    const width = useWidthListener("settingsSidebar");
    const windowWidth = useWindowWidth();
    const dispatch = useDispatch();

    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [pendingNavigation, setPendingNavigation] = useState(null);

    const { control, handleSubmit, reset, formState: { isValid, isDirty }} = useForm({
        mode: 'onChange',
        defaultValues: {
            vm_exit: false,
            dnd_on_mute: false,
            cid_routing: false,
            cid_routing_transfer: false,
        }
    });

    const { preferences, isReadingPreferences, isUpdatingPreferences } = useSelector((state) => state.pbx_config)

    const onSave = async (data) => {
        try {
            await dispatch(pbxConfigActions.updatePreferences(null, data));
            await dispatch(pbxConfigActions.getPreferences());
            Notification.alert("Preferences saved successfully", STATUS_TYPES.SUCCESS);
        } catch (error) {
            console.log(error);
            Notification.alert("Failed to save preferences", STATUS_TYPES.ERROR);
        }
    }

    const cancelEdit = () => {
        reset(preferences)
    }
    
    const handleConfirmLeave = () => {
        setIsConfirmationOpen(false);
        cancelEdit();
        if (pendingNavigation) {
            pendingNavigation.retry();
        }
    };

    useEffect(() => {
        dispatch(pbxConfigActions.getPreferences());
    }, [dispatch]);

    useEffect(() => {
        if(!!preferences) {
            reset(preferences)
        }
    }, [preferences, reset]);

        useBlocker(
            (tx) => {
                if (isDirty  && !isConfirmationOpen) {
                    setPendingNavigation(tx);
                    setIsConfirmationOpen(true);
                } else {
                    tx.retry();
                }
            },
            isDirty 
        );

    return(
        <React.Fragment>
            <Form loading={isReadingPreferences || isUpdatingPreferences}>
                <Grid>
                    <Grid.Row>
                        <Grid.Column>
                            <Header as="h2" color='primary'>
                                Voicemail Exit
                            </Header>
                            <div style={{display: 'flex', gap: 10}}>
                                <Controller
                                    name='vm_exit'
                                    control={control}
                                    render={({ field: { name, onChange, value } }) => (
                                        <Form.Checkbox
                                            toggle
                                            checked={value}
                                            onChange={(_, { checked }) => { 
                                                onChange({ target: { name, value: checked } }) 
                                            }}
                                            style={{ transform: 'scale(0.8)' }}
                                        />
                                    )}
                                />
                                <div>Enable users to be transferred to the desired endpoint when pressing 9 during voicemail instructions</div>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Header as="h2" color='primary'>
                                DND on Mute
                            </Header>
                            <div style={{display: 'flex', gap: 10}}>
                                <Controller
                                    name='dnd_on_mute'
                                    control={control}
                                    render={({ field: { name, onChange, value } }) => (
                                        <Form.Checkbox
                                            toggle
                                            checked={value}
                                            onChange={(_, { checked }) => { 
                                                onChange({ target: { name, value: checked } }) 
                                            }}
                                            style={{ transform: 'scale(0.8)' }}
                                        />
                                    )}
                                />
                                <div>For Grandstream phones, the mute key will toggle auto rejecting all inbound calls</div>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Header as="h2" color='primary'>
                                Contact Inbound Routing
                                <Popup
                                    trigger={<Icon size='small' className='fas fa-circle-info' color='primary' style={{marginLeft: 10, marginBottom: 5}}/>}
                                    content={"Ability to enable custom call routing for accounts and contact records that call numbers you host with DYL."}
                                    inverted
                                    hoverable
                                />
                            </Header>
                            <div style={{display: 'flex', gap: 10}}>
                                <Controller
                                    name='cid_routing'
                                    control={control}
                                    render={({ field: { name, onChange, value } }) => (
                                        <Form.Checkbox
                                            toggle
                                            checked={value}
                                            onChange={(_, { checked }) => { 
                                                onChange({ target: { name, value: checked } }) 
                                            }}
                                            style={{ transform: 'scale(0.8)' }}
                                        />
                                    )}
                                />
                                <div>Enable caller ID routing customization</div>
                            </div>
                            <div style={{display: 'flex', gap: 10}}>
                                <Controller
                                    name='cid_routing_transfer'
                                    control={control}
                                    render={({ field: { name, onChange, value } }) => (
                                        <Form.Checkbox
                                            toggle
                                            checked={value}
                                            onChange={(_, { checked }) => { 
                                                onChange({ target: { name, value: checked } }) 
                                            }}
                                            style={{ transform: 'scale(0.8)' }}
                                        />
                                    )}
                                />
                                <div>Enable default destination if no answer occurs</div>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Portal open>
                    <SettingsFooter
                        style={{ width: MathUtils.calculatePercentage(windowWidth, windowWidth - width) }}
                        className={`Webform__menu`}
                        rightOptions={[
                            <Button onClick={() => {
                                isDirty ? setIsConfirmationOpen(true) : cancelEdit()
                            }} status={'cancel'} basic>Cancel</Button>,
                            <Button
                                onClick={handleSubmit(onSave)}
                                disabled={!isValid || !isDirty}
                            >
                                Save
                            </Button>,
                        ]}
                    />
                </Portal>
            </Form>
            <FullScreenModalPopUp
                header={"Changes not saved"}
                subheader={"Are you sure you want to exit?"}
                isOpen={isConfirmationOpen}
                onConfirm={handleConfirmLeave}
                onFormClose={() => setIsConfirmationOpen(false)}
                closeIcon={false}
            />
        </React.Fragment> 
    );
}

export default Preferences;