import { ButtonLink, DateTimeUtils, Modal, NestedDropdown, Notification, STATUS_TYPES, VALIDATORS, generateResolver, yup } from "dyl-components";
import React, { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { Form, Header, Icon, Popup } from "semantic-ui-react";
import './index.scss'
import { useDispatch, useSelector } from "react-redux";
import { PhoneUtil } from "utils";
import pbxConfigActions from "actions/pbx_config";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Timeframe from "./subcomponents/Timeframe";
import FullScreenModalPopUp from "shared/FullScreenModalPopUp";

const EXCLUDED_PARENTS = ["routing_rule", "call_queue", "conference_room", "parking_lot"];

const Content = ({ onClose, onReload, isEditing, onDirtyChange }) => {
    const [destinationOptions, setDestinationOptions] = useState([]);
    const dispatch = useDispatch();

    const { isSavingCallRoutingRules, destinations, isReadingDestinations, callRoutingRule } = useSelector((state) => state.pbx_config);
    
    const defaultTimeframe = {
        days: [],
        destination_parent: null,
        destination: null,
        isBeingDeleted: false,
        start_time: null,
        end_time: null
    }

    const dateToText = (date) => DateTimeUtils.formatEpochTimezone(date, DateTimeUtils.TIME_FORMAT);
    
    const defaultValues = {
        routing_rule_name: isEditing ? callRoutingRule?.label || "" : "",
        holiday_schedule: null,
        after_hours: isEditing ? callRoutingRule?.destination?.destination_id : null,
        after_hours_parent: isEditing ? callRoutingRule?.destination?.type : null,
        timeframes: isEditing ? callRoutingRule?.destination_routes?.data?.map(({days, destination, end, start}) => {
            return {
                days,
                destination: destination?.destination_id,
                destination_parent: destination?.type,
                start_time: dateToText(start), 
                end_time: dateToText(end),
                isBeingDeleted: false,
            }
        }) || [defaultTimeframe] : [defaultTimeframe],
    }

    const { control, formState, handleSubmit, setValue, getValues, setError, watch, trigger } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: generateResolver({
            routing_rule_name: VALIDATORS.TEAM_NAME().required("This field is required"),
            after_hours: yup.string().required("This field is required"),
            timeframes: yup.array().of(
                yup.object().shape({
                    days: yup.array().when("isBeingDeleted", {
                        is: (isBeingDeleted) => isBeingDeleted === false,
                        then: () => yup.array().of(
                            yup.string().required("This field is required")
                        ).min(1, "At least one day is required"),
                        otherwise: () => yup.array().nullable()
                    }),
                    destination: yup.string().when("isBeingDeleted", {
                        is: (isBeingDeleted) => isBeingDeleted === false,
                        then: () => yup.string().required("This field is required"),
                        otherwise: () => yup.string().nullable()
                    }),
                    start_time: yup.string().when("isBeingDeleted", {
                        is: (isBeingDeleted) => isBeingDeleted === false,
                        then: () => yup.string().required("This field is required"),
                        otherwise: () => yup.string().nullable()
                    }),
                    end_time: yup.string().when("isBeingDeleted", {
                        is: (isBeingDeleted) => isBeingDeleted === false,
                        then: () => yup.string().required("This field is required").test("is-after", "Invalid time", (value, context) => {
                            const { parent: { start_time } } = context;
                            return DateTimeUtils.dateIsBeforeAnotherDate(start_time, value)
                        }),
                        otherwise: () => yup.string().nullable()
                    }),
                    isBeingDeleted: yup.boolean(),
                })
            )
        })
    });

    const { isValid, isDirty } = formState

    const watchedTimeframes = watch("timeframes");

    const { fields, append, update, move } = useFieldArray({
        control,
        name: "timeframes"
    })

    const onAddTimeframe = () => append(defaultTimeframe);

    const formatPayload = (data) => {
        const { after_hours, routing_rule_name, timeframes } = data;
        const payload = {
            destination_id: Number(after_hours),
            label: routing_rule_name,
            routes: timeframes.filter(({isBeingDeleted}) => !isBeingDeleted).map(({destination, days, start_time, end_time}) => {
                return {
                    start: DateTimeUtils.convertTimeToSeconds(start_time, DateTimeUtils.TIME_FORMAT),
                    end: DateTimeUtils.convertTimeToSeconds(end_time, DateTimeUtils.TIME_FORMAT),
                    destination_id: Number(destination),
                    days: days.map((day) => Number(day))
                }
            })
        }
        return isEditing ? payload : [payload];
    }

    const onAdd = async (data) => {
        try {
            const payload = formatPayload(data);
            await dispatch(pbxConfigActions.createRoutingRules(payload))
            onReload();
            onClose();
            Notification.alert('Call Routing Rule added successfully!', STATUS_TYPES.SUCCESS);
        } catch (e) {
            console.log(e);
            Notification.alert('Failed to add Call Routing Rule', STATUS_TYPES.ERROR);
        }
    }

    const onEdit = async (data) => {
        try {
            const { id } = callRoutingRule;
            const payload = formatPayload(data);
            await dispatch(pbxConfigActions.updateRoutingRule(id, payload))
            onReload();
            onClose();
            Notification.alert('Call Routing Rule updated successfully!', STATUS_TYPES.SUCCESS);
        } catch (e) {
            console.log(e);
            Notification.alert('Failed to update Call Routing Rule', STATUS_TYPES.ERROR);
        }
    }

    const onRemove = (index) => {
        const fieldAux = {...fields[index], isBeingDeleted: true}
        update(index, fieldAux);
    }

    const onCheckDuplicatedName = async () => {
        const routing_rule_name = getValues("routing_rule_name");
        try {
            if ((!isEditing) || (isEditing && callRoutingRule?.label !== routing_rule_name)) {
                const isDuplicated = await dispatch(pbxConfigActions.isDuplicatedName({label: routing_rule_name, type: "routing_rule"}));
                if (isDuplicated) {
                    setError("routing_rule_name", { type: "unique", message: "Name already exists!" })
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const onDragEnd = (result) => {
        const { destination, source } = result;
        const { index: destinationIndex } = destination || {};
        const { index: sourceIndex } = source || {};
        if (sourceIndex !== undefined && destinationIndex !== undefined) {
            move(sourceIndex, destinationIndex);
        }
    }

    const getNotDeletedTimeframeCount = () => watchedTimeframes?.filter((timeframe) => !timeframe.isBeingDeleted)?.length || 0;

    useEffect(() => {
        if (destinations) {
            const destinationsAux = [...PhoneUtil.DESTINATION_TYPES_OPTIONS];
            const destinationOptionsAux = destinationsAux.map((destination) => {
                const { key } = destination; 
                const options = destinations[key]?.map(({destination_id, label}) => (
                    {key: destination_id, value: destination_id, text: label}
                )) || []
                return {...destination, options};
            })
            setDestinationOptions(destinationOptionsAux);
        }
    }, [destinations]);

    useEffect(() => {
        dispatch(pbxConfigActions.getDestinations());
    }, [dispatch])

    useEffect(() => {
        if (onDirtyChange) {
            onDirtyChange(isDirty);
        }
        // eslint-disable-next-line
    }, [isDirty])

    return (
        <>
            <Modal.Header>
                {isEditing ? "Edit " : "Create "} Call Routing Rule
            </Modal.Header>
            <Modal.Content>
                <Form noValidate loading={isSavingCallRoutingRules}>
                    <div className="CallRoutingRuleModal__form">
                        <div className="CallRoutingRuleModal__controllerContainer">
                            <Controller
                                name='routing_rule_name'
                                control={control}
                                style={{flex: 1}}
                                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                    <Form.Input
                                        name={name}
                                        value={value}
                                        onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                        label="Routing Rule Name"
                                        placeholder="Type routing rule name"
                                        required
                                        error={error?.message}
                                        className="CallRoutingRules__ruleField"
                                        onBlur={onCheckDuplicatedName}
                                    />
                                )}
                            />
                            <Controller
                                name='holiday_schedule'
                                control={control}
                                style={{flex: 1}}
                                render={() => (
                                    <Form.Select
                                        readOnly
                                        label={{
                                            children: (
                                                <span>
                                                    Holiday Schedule
                                                    <Popup
                                                        trigger={<Icon style={{ float: 'right', marginTop: '0.25em' }} name='fas fa-info-circle' color='primary' />}
                                                        inverted
                                                        content="Add a custom holiday under the Schedule Settings."
                                                    />
                                                </span>
                                            )
                                        }}
                                        placeholder="Select holiday schedule"
                                        options={[]}
                                        className="CallRoutingRules__ruleField"
                                    />
                                )}
                            />
                            <Controller
                                name="after_hours_parent"
                                control={control}
                                render={({ field: { name: parentName, value: parentValue, onChange: onParentChange } }) => (
                                    <Controller 
                                        name="after_hours"
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { name: childName, value: childValue, onChange: onChildChange } = field;
                                            return (
                                                <Form.Field
                                                    control={NestedDropdown}
                                                    child_value={childValue}
                                                    parent_value={parentValue}
                                                    loading={isReadingDestinations}
                                                    nested_options={destinationOptions}
                                                    onChange={(_, { parent_value, child_value }) => {
                                                        onParentChange({ target: { name: parentName, value: parent_value } });
                                                        onChildChange({ target: { name: childName, value: child_value } });
                                                    }}
                                                    placeholder="Select destination"
                                                    display_parent
                                                    selection
                                                    label="After Hours"
                                                    pointing='top'
                                                    required
                                                    error={error?.message}
                                                    className="CallRoutingRules__ruleField"
                                                    excluded_parents={EXCLUDED_PARENTS}
                                                />
                                            )
                                        }}
                                    />
                                )}
                            />
                        </div>
                        <div className="CallRoutingRuleModal__rulesContainer">
                            <Header as="h3" color="primary">
                                Timeframes
                            </Header>
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="droppable-1" type="RULE">
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                        >
                                            {fields.map((_, index) => {
                                                return <Draggable draggableId={`RULE-${index}`} index={index}>
                                                    {(provided) => (
                                                        <Timeframe 
                                                            control={control}
                                                            isReadingDestinations={isReadingDestinations}
                                                            destinationOptions={destinationOptions}
                                                            index={index}
                                                            isDeleteVisible={getNotDeletedTimeframeCount() > 1}
                                                            onRemove={onRemove}
                                                            setValue={setValue}
                                                            onMove={move}
                                                            destinationCount={fields.length}
                                                            provided={provided}
                                                            watch={watch}
                                                            trigger={trigger}
                                                            formState={formState}
                                                            excluded_parents={EXCLUDED_PARENTS}
                                                        />
                                                    )}
                                                </Draggable>
                                            })}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                            <ButtonLink onClick={onAddTimeframe}>
                                <Icon className="fa-solid fa-plus CallRoutingRuleModal__blackIcon"/> Add New Timeframe
                            </ButtonLink>
                        </div>
                    </div>
                </Form>
            </Modal.Content>
            <Modal.Actions 
                hasSaveButton
                onSave={handleSubmit(isEditing ? onEdit : onAdd)}
                saveDisabled={!isValid || !isDirty}
            />  
        </>
    )
}

const CallRoutingRuleModal = ({ open, onClose, onReload, isEditing }) => {
    const [isDirty, setIsDirty] = useState(false);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    return (
        <React.Fragment>

            <Modal open={open} onClose={() => {
                isDirty
                    ? setIsConfirmationOpen(true)
                    : onClose();
            }} size={"large"}>
                <Content onClose={onClose} onReload={onReload} isEditing={isEditing}  onDirtyChange={(dirty) => setIsDirty(dirty)} />
            </Modal>
            <FullScreenModalPopUp
                header={"Changes not saved"}
                subheader={"Are you sure you want to exit?"}
                isOpen={isConfirmationOpen}
                onConfirm={() => {
                    onClose();
                    setIsConfirmationOpen(false);
                    setIsDirty(false)
                }}
                onFormClose={() => setIsConfirmationOpen(false)}
                closeIcon={false}
            />
        </React.Fragment>
    )
}

export default CallRoutingRuleModal;
