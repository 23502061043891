import { generateResolver, Step, yup, Notification, STATUS_TYPES, VALIDATORS, TableLoader } from 'dyl-components';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFieldArray, useForm } from 'react-hook-form';
import { Form, Portal } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import DataMapping from './DataMapping';
import MappingTable from './MappingTable';
import Provider from './Provider';
import { DataMappingFooter } from './DataMappingFooter';
import useWidthListener from 'shared/SettingsFooter/useWidthListener';
import useWindowWidth from 'shared/SettingsFooter/useWindowWidth';
import leadIngestionActions from 'actions/lead_ingestion';
import leadProviderActions from 'actions/lead_provider';
import customFieldsActions from 'actions/custom_fields';
import pipelineActions from 'actions/pipeline';
import { MathUtils } from 'utils';
import { default as STEPS } from './Steps';
import ImportSetupFields from './ImportSetupFields';
import { ObjectUtils, AccountUtils, StringUtils, ValidationUtils } from '../../utils';
import { DUPLICATE_OPTIONS, DYL_FIELDS_SCHEMA } from './DYLFieldsConstants';
import './index.scss';
import { useConfirm } from 'shared/confirmation/useConfirm';
import CustomPrompt from 'shared/confirmation/CustomPrompt';
import ConfirmModal from 'shared/confirmation/ConfirmModal';

const DataMappingContainer = () => {
    const { id } = useParams();
    const parse_id = id;

    const { isConfirmed } = useConfirm();

    const { isReadingProviderRecordNames, providerRecordNames = [], isReadingEmail } = useSelector(state => state.lead_ingestion);
    const [email, setEmail] = useState(null);
    const [post, setPost] = useState(null);
    const [provider, setProvider] = useState(null);
    const [providerNameErrorMessage, setProviderNameErrorMessage] = useState('');
    const [isProviderNameValid, setIsProviderNameValid] = useState(true);
    const [dataRecordErrorMessage, setDataRecordErrorMessage] = useState('');
    const [isDataRecordNameValid, setIsDataRecordNameValid] = useState(true);

    const dispatch = useDispatch();

    const width = useWidthListener("settingsSidebar");
    const windowWidth = useWindowWidth();
    const { pipelineCategories, isReadingPipelineCategories } = useSelector((state, stage) => {
        const categories = state.pipeline.categories;
		const pcategories  = categories.length === 1 ?
                categories[0].stages :
                categories.find((pipeline) => pipeline.primary)?.stages;
		if (pcategories && !pcategories?.find(st => st.id === provider?.mappings?.pipeline_stage_id)) {
			let sname;
			categories.forEach(cat => {
				const stage = cat.stages.find(stage => stage.id === provider?.mappings?.pipeline_stage_id);
				if (stage) {
					sname = stage.name;
				}
			})
			if (sname) {
				pcategories.unshift({
					name: sname,
					id: provider?.mappings?.pipeline_stage_id
				})
			}
		}
        return {
            pipelineCategories: pcategories,
            isReadingPipelineCategories: state.pipeline.isReadingCategories,
        }
    });
    const fieldOptions = useSelector(state => {
        return ObjectUtils.formatFields(state.custom_fields.mapping_fields)
    });
    

	let stageName = useSelector((state) => {
		if (!pipelineCategories?.find(stage => stage.id === provider?.mappings?.pipeline_stage_id)) {
			const categories = state.pipeline.categories;
			let sname;
			categories.forEach(cat => {
				const stage = cat.stages.find(stage => stage.id === provider?.mappings?.pipeline_stage_id);
				if (stage) {
					sname = stage.name;
					return false;
				}
			})
			return sname;
		}
		return "" 
	});

    const { control: importSetupControl, formState: { isValid: isImportSetupValid, isDirty: isImportSetupDirty }, watch, setValue, getValues, trigger: setupTrigger } = useForm({
        mode: 'onChange',
        defaultValues: {
            module: null,
            duplicate_option: null,
            match_type: ['email', 'full_name', 'phone'],
            stage: null
        },
        resolver: generateResolver({
            module: yup.mixed().oneOf(['Lead', 'Contact', 'Opportunity', 'Customer']),
            duplicate_option: yup.mixed().required('This field is required').oneOf(DUPLICATE_OPTIONS.map(option => option.value)),
            stage: yup.mixed().when("module", {
                is: (stage) => stage !== 'Contact',
                then: schema => schema.required('This field is required'),
                otherwise: schema => schema.nullable(true)
            }),
        })
    });
	const isMappingDuplicated = (name) => {
		const { provider_name } = getDataMappingValues();
        const query = { name };
        if(!provider_name){
            setIsProviderNameValid(false);
            setProviderNameErrorMessage("Provider name is required")
        }
		return dispatch(leadProviderActions.isExistingMapping(provider_name, query)).then(resp => {
            if(!resp){
                setIsDataRecordNameValid(true);
            } else {
                setIsDataRecordNameValid(false);
                setDataRecordErrorMessage("Data Record name already exists");
            }
		})
	}

	const dataMappingResolver = post ?  {
		fields: DYL_FIELDS_SCHEMA
	} : {
		provider_name: yup.mixed().required('This field is required'),
		data_record_name: VALIDATORS.TEAM_NAME().required('This field is required'), 
		fields: DYL_FIELDS_SCHEMA
	};
    const { control: dataMappingControl, formState: { isValid: isDataMappingValid, isDirty: isDataMappingFormDirty }, setValue: setDataMappingValue, getValues: getDataMappingValues, watch: dataMappingWatch, trigger: dataMappingTrigger } = useForm({
        mode: 'onChange',
        defaultValues: {
            interests: [],
            group: 'none',
            campaign: 'none',
            fields: [],
            product_interests: null,
            provider_name: null,
            data_record_name: null,
            differentiator: 'none',
            differentiator_field_name: null,
        },
        resolver: generateResolver(dataMappingResolver)
    });
    const { fields, update: updateField } = useFieldArray({
        control: dataMappingControl,
        name: 'fields'
    });
    const [watchedFields, watchedDifferentiator] = dataMappingWatch(['fields', 'differentiator']);
    const [module, duplicate_option] = watch(['module', 'duplicate_option']);

    const interestsSelectedAsDylField = watchedFields.findIndex(field => field.dyl_field === "interests") !== -1;
    const isFieldSkipped = !(fields.length === dataMappingWatch()?.fields.filter((field) => field.skipped).length);
    const productInterestsSelectedAsDylField = watchedFields.findIndex(field => field.dyl_field === "product_interests") !== -1;
    const isNextStepEnabled = () => {
        return isDataMappingValid && isImportSetupValid && (isImportSetupDirty || isDataMappingFormDirty) && isFieldSkipped && ValidationUtils.isMinimumFields(dataMappingWatch()).isValid;
    }

    const clearStage = () => {
        setValue('stage', null);
    }

    const createEmailMapping = async () => {
		let formattedFields = {};
        const {
            provider_name,
            data_record_name,
            differentiator,
            differentiator_field_name,
            fields
        } = getDataMappingValues();
        const { stage, match_type, duplicate_option } = getValues();
        for (let i = 0; i < fields.length; i++) {
            formattedFields[fields[i].name] = {
                default: fields[i].default_value,
                overwrite: fields[i].duplicateProcess === 'replace',
                skipped: fields[i].skipped,
                value: fields[i].dyl_field
            }
        }
        const pipelineStage = pipelineCategories?.find((pipelineStage) => (pipelineStage.name === stage)).id || provider?.mappings?.pipeline_stage_id || null;
		let differentiator_field_value = fields?.find(fld => fld.name === differentiator_field_name)?.value;

        const diff = differentiator === 'subject' ?
            { field: email.subject, subject: true, value: email.subject }
            : differentiator === 'field' ?
                { field: differentiator_field_name, subject: false, value: differentiator_field_value }
                : null;
        const providerPayload = {
            method: "Email",
            module,
            name: data_record_name,
            differentiator: diff,
            mappings: {
                mappings: formattedFields,
                pipeline_stage_id: pipelineStage,
                duplicate_fields: match_type,
                on_duplicate: duplicate_option,
            }
        };

        try {
			if (!provider || (diff && ! provider?.differentiator)) {
				await dispatch(leadProviderActions.createProviderMapping(providerPayload, { post_id: parse_id }, provider_name))
			} else {
				await dispatch(leadProviderActions.updateMappingFields(provider.id, providerPayload, { post_id: parse_id }))
			}
            Notification.alert('Successfully added data mappings!', STATUS_TYPES.SUCCESS);
        } catch (e) {
            console.log(e);
            Notification.alert('Failed to map', STATUS_TYPES.ERROR);
        }
    }

    const createPostMapping = async () => {
        let formattedFields = {};
        const { stage, match_type, duplicate_option } = getValues();
        const { fields } = getDataMappingValues();
        for (let i = 0; i < fields.length; i++) {
            formattedFields[fields[i].name] = {
                default: fields[i].default_value,
                overwrite: fields[i].duplicateProcess === 'replace',
                skipped: fields[i].skipped,
                value: fields[i].dyl_field
            }
        }
        const pipelineStage = pipelineCategories?.find((pipelineStage) => (pipelineStage.name === stage)).id || provider?.mappings?.pipeline_stage_id || null;
        const payload = {
            module,
            name: provider?.name,
            mappings: {
                mappings: formattedFields,
                pipeline_stage_id: pipelineStage,
                duplicate_fields: match_type,
                on_duplicate: duplicate_option,
            }
        }
        try {
            await dispatch(leadIngestionActions.updateMappingFields(provider.id, payload, { post_id: parse_id }))
            Notification.alert('Successfully added data mappings!', STATUS_TYPES.SUCCESS);
        } catch (e) {
            console.log(e);
            Notification.alert('Failed to map', STATUS_TYPES.ERROR);
        }
    }

    useEffect(() => {
        if (parse_id) {
            dispatch(leadIngestionActions.readLeadIngestion(parse_id))
                .then(async response => {
                    let fields = [];
                    const dataFields = response?.data || {};
                    const validFields = response?.valid_fields || {};
                    const isEmail = (response?.creation_type === 'email');
                    const provider = response?.provider.provider_mapping;
                    const mappings = provider?.mappings?.mappings || {};
                    const postModule = provider?.module;
                    const mod = module || postModule;
                    const pipelineStage = AccountUtils.getModulePipeline(mod);
                    setProvider(provider);
                    //set email or post and provider
					let seen = {}
                    Object.entries(dataFields).forEach(([key, value]) => {
						seen[key] = true
                        fields.push({
                            name: key,
                            flatten_path: mappings[key]?.value,
                            value: value || '',
                            valid_field: !!validFields[key],
                            default_value: mappings[key]?.default || '',
                            duplicateProcess:  mappings[key]?.overwrite ? 'replace' : 'add',
                            skipped: !! mappings[key]?.skipped,
                            options: ''
                        })
                    })
					Object.keys(mappings).forEach(key => {
						if (!seen[key]) {
							fields.push({
								name: key,
								flatten_path: mappings[key]?.value,
								value: '',
								default_value: mappings[key]?.default || '',
								duplicateProcess:  mappings[key]?.overwrite ? 'replace' : 'add',
								skipped: mappings[key]?.skipped,
							})
						}
					})
					fields.sort((a, b) => {
					  if (a.name< b.name) {
					    return -1;
					  } else if (a.name > b.name) {
						return 1;
					  }
					  return 0;
					})
                    if(fields.length !== 0){
                        setDataMappingValue('fields', fields);
                        dataMappingTrigger('fields');
                    }
                    if (isEmail) {
                        setEmail(response?.email);
                    }
                    if (!isEmail) {
                        setPost(response?.post);
                        setValue('post_code', response?.post.code);
                        setValue('module', provider?.module);
                        setValue('duplicate_option', provider?.mappings?.on_duplicate);
                        dataMappingTrigger('duplicate_option');
                        setValue('match_type', provider?.mappings?.duplicate_fields)
                        setDataMappingValue('provider_name', response?.provider.name);
                        setDataMappingValue('data_record_name', provider?.name);
                    }
                    if (isEmail) {
                        if (provider?.mappings?.duplicate_fields) {
                            setValue('match_type', provider?.mappings?.duplicate_fields)
                        }
                        if (provider?.mappings?.on_duplicate) {
                            setValue('duplicate_option', provider?.mappings?.on_duplicate);
							dataMappingTrigger('duplicate_option');
                        }
                        if (provider?.module) {
                            setValue('module', provider?.module);
                        }
                        if (response?.provider?.name) {
                            setDataMappingValue('provider_name', response?.provider?.id);
                        }
                        if (provider?.name) {
                            setDataMappingValue('data_record_name', provider?.name);
                        }
						if (provider?.differentiator) {
							if (provider?.differentiator?.subject) {
								setDataMappingValue('differentiator', 'subject');
							} else {
								setDataMappingValue('differentiator', 'field');
								setDataMappingValue('differentiator_field_name', provider?.differentiator?.field);
							}
						}
                    }
                    if (pipelineStage) {
                        dispatch(pipelineActions.getPipelineCategories({ account_stage: pipelineStage }))
                            .then(async response => {
								const stages = response.length === 1 ? response[0].stages : response.find((pipeline) => pipeline.primary)?.stages
								setValue('stage', stages?.find((pipelineStage) => (pipelineStage.id === provider?.mappings?.pipeline_stage_id))?.name || '');
                            });
                    }
                    dispatch(customFieldsActions.readMappingFields({ module: mod === 'Contact' || mod === undefined ? 'person' : mod?.toLowerCase() }))
                        .then((res) => {
                            const fields = getDataMappingValues("fields");
							const formattedFields = ObjectUtils.formatFields(res);
							fields.forEach((field, idx) => {
                                //incoming standard fields match w/ dyl_field 
								const currentField = formattedFields.find(option => option.key === field.flatten_path)
                                if (currentField) {
									fields[idx].dyl_field = currentField.value;
									fields[idx].field_type = currentField.field_type;
									fields[idx].options = currentField.options;
									return;
								}
								const matchingField = formattedFields.find(option =>
									(option?.field_type === "phone" ?
										StringUtils.sanitizeTerminology(StringUtils.formatSearch(StringUtils.sanitizeString(field?.name.toLowerCase()))).includes("phone") : true)
									&& StringUtils.sanitizeTerminology(StringUtils.formatSearch(StringUtils.sanitizeString(field?.name.toLowerCase())))
										.includes(StringUtils.sanitizeAppendedValue(option?.text?.toLowerCase())) && !option?.disabled
								);
								if (matchingField) {
									fields[idx].dyl_field = matchingField.value;
									fields[idx].field_type = matchingField.field_type;
									fields[idx].options = matchingField?.options ? matchingField?.options : false;
								}
                            })
                            setDataMappingValue('fields', fields);
                            dataMappingTrigger('fields');
							setupTrigger('module')
                        })
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, parse_id, module]);

    useEffect(() => {
        dispatch(leadIngestionActions.readAllProviderRecordNames({ limit: 1000 }));
    }, [dispatch]);
    const importFields = [
        <Form.Field control='div' label='Source'>
            <div>
                Email
            </div>
            <small style={{ display: 'block' }}>
                {`From: ${email?.from}`}
            </small>
            <small>
                Subject: <span className={watchedDifferentiator === 'subject' ? 'SubjectDifferentiator' : ''}>{email?.subject}</span>
            </small>
        </Form.Field>
        ,
        <Form.Field control='div' label='Source'>
            <div>
                Post
            </div>
            <small>
                {`Code: ${post?.code}`}
            </small>
        </Form.Field>
        ,
        <ImportSetupFields
            control={importSetupControl}
            duplicate_option={duplicate_option}
            module={module}
            disable_module={post}
            clearStage={clearStage}
            pipelineCategories={pipelineCategories}
			stageName={stageName}
            isReadingPipelineCategories={isReadingPipelineCategories}
        />
    ];
    if (post) { importFields.splice(0, 1) } //Remove email source
    if (!post) { importFields.splice(1, 1) } //Remove post source
    
    CustomPrompt(null, isImportSetupDirty || isDataMappingFormDirty, isConfirmed, 'Changes not saved', 'Are you sure you want to exit?');

    return (
        <React.Fragment>
            <ConfirmModal/>
            <Step.Group horizontal>
                {STEPS.map((step, index) => (
                    <Step key={step.title} completed={index < 1} active={index === 1} title={step.title}>
                        {step.icon}
                    </Step>
                ))}
            </Step.Group>
            <Provider
                dataMappingControl={dataMappingControl}
				dataMappingTrigger={dataMappingTrigger}
                post={post}
                providerRecordNames={providerRecordNames}
                isReadingProviderRecordNames={isReadingProviderRecordNames}
                isMappingDuplicated={isMappingDuplicated}
                isProviderNameValid={isProviderNameValid}
                setIsProviderNameValid={setIsProviderNameValid}
                providerNameErrorMessage={providerNameErrorMessage}
                isDataRecordNameValid={isDataRecordNameValid}
                dataRecordErrorMessage={dataRecordErrorMessage}
            />
            <DataMapping
                dataMappingControl={dataMappingControl}
                module={module}
                interestsSelectedAsDylField={interestsSelectedAsDylField}
                productInterestsSelectedAsDylField={productInterestsSelectedAsDylField}
                importSetupFields={importFields}
                mappingTable={isReadingEmail ?
                    <TableLoader />
                    : (
                        <MappingTable
                            fields={fields}
                            isMerging={duplicate_option === 'merge' || duplicate_option === 'merge_only'}
                            dataMappingControl={dataMappingControl}
                            module={module}
                            updateField={updateField}
                            watchedFields={watchedFields}
                            watchedDifferentiator={watchedDifferentiator}
                            fieldOptions={fieldOptions}
                            trigger={dataMappingTrigger}
                        />
                    )}
            />
            <Portal open>
                <DataMappingFooter
                    isReadingEmail={isReadingEmail}
                    isMinimumFields={ValidationUtils.isMinimumFields(dataMappingWatch())}
                    parseId={parse_id}
                    post={post}
                    createPostMapping={createPostMapping}
                    createEmailMapping={createEmailMapping}
                    isNextStepEnabled={isNextStepEnabled}
                    width={MathUtils.calculatePercentage(windowWidth, windowWidth - width)}
                />
            </Portal>
        </React.Fragment>
    )
};


export default DataMappingContainer;
