import React, { useCallback, useEffect, useState } from 'react';
import Table from './subcomponents/Table';
import { Notification, STATUS_TYPES, TableWithHeader } from 'dyl-components';
import Toolbar from './subcomponents/Toolbar';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ExternalNumbersModal from 'shared/modals/ExternalNumbersModal';
import pbxConfigActions from 'actions/pbx_config';
import { useDispatch } from 'react-redux';
import VerifyExternalNumberModal from 'shared/modals/VerifyExternalNumberModal';

const EXTERNAL_NUMBERS_URL = "/settings/phone-management/numbers/external-numbers";

const ExternalNumbers = () => {
    const navigate = useNavigate();
    const [params] = useSearchParams();
    const searchQuery = params.get('search') || '';
    const [search, setSearch] = useState(searchQuery);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isVerifyModalOpen, setIsVerifyModalOpen] = useState(false);
    const [verifyingPhone, setVerifyingPhone] = useState(null);
    const dispatch = useDispatch();

    const onChangeSearch = (_, { value }) => {
        setSearch(value);
    }
    
    const cancelFunction = () => {
        setSearch("");
        const query = new URLSearchParams(params);
        query.delete('search');
        const query_string = query.toString();
        navigate(`${EXTERNAL_NUMBERS_URL}${query_string ? `?${query_string}` : ''}`,);
    }

    const onSearchSubmit = (value) => {
        const query = new URLSearchParams(params);
        query.set('search', value.trim());
        query.set('page', 1);
        const query_string = query.toString();
        navigate(`${EXTERNAL_NUMBERS_URL}${query_string ? `?${query_string}` : ''}`);
    }

    const onPageChange = (_, { activePage }) => {
        const query = new URLSearchParams(params);
        query.set('page', activePage);
        const query_string = query.toString();
        navigate(`${EXTERNAL_NUMBERS_URL}${query_string ? `?${query_string}` : ''}`);
    }

    const [filters, setFilters] = useState({
        shared: [],
        type: [],
        user_id: params.get('user_id')?.split(','),
        forwarding: [],
        timeout_destination: params.get('timeout_destination')?.split(',')
    })

    const onFilter = async (_, { name, value }) => {
        setFilters({
            ...filters,
            [name]: value
        });
    }

    const onReload = useCallback(() => {
        dispatch(pbxConfigActions.getExternalNumbers(params));
        dispatch(pbxConfigActions.getExternalUsers());
    }, [dispatch, params])

    const selectVerifyingPhone = (number) => {
        setVerifyingPhone(number);
        setIsVerifyModalOpen(true);
    }

    const onSelectExternalNumber = async (phone) => {
        try {
            dispatch(pbxConfigActions.getExternalNumber(phone));
            setIsModalOpen(true);
        } catch (error) {
            Notification.alert('Failed to load external number', STATUS_TYPES.ERROR);
        }
    }

    const handleClose = async () => {
        await dispatch(pbxConfigActions.removeExternalNumber());
        setIsModalOpen(false);
    }

    const onDelete = async (phone) => {
        try {
            await dispatch(pbxConfigActions.deleteExternalNumber(phone));
            onReload();
            Notification.alert("Sucessfully deleted external number", STATUS_TYPES.SUCCESS);
        } catch (error) {
            console.log(error)
            Notification.alert("Failed to delete external", STATUS_TYPES.ERROR);
        }
    }

    const handleVerifyClose = () => {
        setVerifyingPhone(null);
        setIsVerifyModalOpen(false);
    }

    useEffect(() => {
        const query = new URLSearchParams(params);
        if (search.trim()) {
            query.set('search', search);
        } else {
            query.delete('search');
        }
        const { shared, type, user_id, forwarding, timeout_destination } = filters;
        if (shared?.length > 0 && shared?.length < 2) {
            query.set('shared', shared.join(','));
        } else {
            query.delete('shared');
        }
        if (type?.length > 0 && type?.length < 2) {
            query.set('type', type.join(','));
        } else {
            query.delete('type');
        }
        if (user_id?.length > 0) {
            query.set('user_id', user_id.join(','));
        } else {
            query.delete('user_id');
        }
        if (forwarding?.length > 0 && forwarding?.length < 2) {
            query.set('forwarding', forwarding.join(','));
        } else {
            query.delete('forwarding');
        }
        if (timeout_destination?.length > 0) {
            query.set('timeout_destination', timeout_destination.join(','));
        } else {
            query.delete('timeout_destination');
        }
        const query_string = query.toString();
        navigate(`${EXTERNAL_NUMBERS_URL}${query_string ? `?${query_string}` : ''}`,);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters])

    useEffect(() => {
        onReload();
    }, [onReload])

    return (
        <div className='ExternalNumbers'>
            <TableWithHeader
                header={(
                    <Toolbar
                        search={searchQuery}
                        onChangeSearch={onChangeSearch}
                        onSearchSubmit={onSearchSubmit}
                        cancelFunction={cancelFunction}
                        onOpenModal={setIsModalOpen}
                    />
                )}
                table={
                    <Table
                        onFilter={onFilter}
                        onPageChange={onPageChange}
                        onSelectExternalNumber={onSelectExternalNumber}
                        onDelete={onDelete}
                        selectVerifyingPhone={selectVerifyingPhone}
                        selectedShared={filters.shared}
                        selectedType={filters.type}
                        selectedForwarding={filters.forwarding}
                    />
                }
            />
            <ExternalNumbersModal open={isModalOpen} onClose={handleClose} onReload={onReload} />
            <VerifyExternalNumberModal open={isVerifyModalOpen} onClose={handleVerifyClose} verifyingPhone={verifyingPhone} onReload={onReload} />
        </div>
    )
}

export default ExternalNumbers;
