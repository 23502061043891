import React from 'react';
import { Form, Header } from 'semantic-ui-react';

import UserSearchField from 'shared/forms/UserSearchField';

import { Controller } from 'react-hook-form';

const TeamForm = ({
    control,
    excluded_users = [],
    isSaving,
    name,
    show_in_office_view,
    users,
    selected_users_details = [],
    header,
}) => {
    return (
       <>
            <Form loading={isSaving}>
                {header && (
                    <Header as='h2'>
                        {header}
                    </Header>
                )}
                <Form.Group>
                    <Controller
                        name="name"
                        control={control}
                        defaultValue={name || ''}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Form.Input
                                width={8}
                                onChange={onChange}
                                value={value}
                                label={'Team Name'}
                                error={error && error.message}
                                required
                            />
                        )}
                    />
                    <Form.Field>
                        <Form.Field label="Office View" />
                        <Controller
                            name="show_in_office_view"
                            control={control}
                            defaultValue={show_in_office_view || false}
                            render={({ field: { onChange, value } }) => (
                                <Form.Checkbox
                                    label={<label><span className='non-bold'>Show</span></label>}
                                    checked={value}
                                    onChange={(_, { name, checked }) => { onChange({ target: { name, value: checked } }); }}
                                    toggle
                                />
                            )}
                        />
                    </Form.Field>
                </Form.Group>
                <Form.Group>
                    <Controller
                        name="users"
                        control={control}
                        defaultValue={users || []}
                        render={({ field: { onChange, value } }) => (
                            <Form.Field
                                width={8}
                                control={UserSearchField}
                                excluded_users={[...value, ...excluded_users]}
                                onChange={(_, { name, value: newValue }) => { onChange({ target: { name, value: newValue } }) }}
                                multiple
                                value={value}
                                label="Add users to team"
                                placeholder="Select Users"
                                selected={selected_users_details}
                            />
                        )}
                    />
                </Form.Group>
            </Form>
        </>
    );
};

export default TeamForm;
