import { DateInput, DateTimeUtils, LinkedAccount, Modal } from "dyl-components";
import { Controller, useController, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { Dropdown, Form, Grid, Icon } from "semantic-ui-react";
import TermField from "./TermField";
import { useContext, useState } from "react";
import {
    ACTIONS,
    QuoteBuilderContext,
} from "shared/context/QuoteBuilderProvider";

const PaymentInformation = ({ onChangeSelectedPaymentMethod }) => {
    const { control } = useFormContext();
    const order = useSelector((state) => state.order.order);
    const [isTermTypesInfoShowing, setIsTermTypesInfoShowing] = useState(false);
    const { field: termField } = useController({
        name: "term",
        control,
    });

    const [termValue, termSubValue] = (termField.value || "").split("/");

    const dueOnField = (() => {
        const control = termValue !== "net" ? DateInput : Dropdown;
        const properties = {
            control,
            readOnly: true,
            value:
                termValue !== "net"
                    ? termValue !== "trial"
                        ? DateTimeUtils.getCurrentDate(
                              DateTimeUtils.WORD_DATE_FORMAT
                          )
                        : DateTimeUtils.changeFormat(
                              DateTimeUtils.getNext(
                                  Number(termSubValue),
                                  "day",
                                  false,
                                  true
                              ),
                              DateTimeUtils.DATE_FORMAT,
                              DateTimeUtils.WORD_DATE_FORMAT
                          )
                    : "net",
            ...(termValue !== "net"
                ? {
                      dateFormat: DateTimeUtils.WORD_DATE_FORMAT,
                  }
                : {
                      options: [
                          {
                              key: "net",
                              value: "net",
                              text: "Net",
                          },
                      ],
                      selection: true,
                  }),
        };
        return (
            <Form.Field label="Due On" control="div">
                <Form.Field {...properties} />
            </Form.Field>
        );
    })();

    const { quoteBuilderConfig } = useContext(QuoteBuilderContext);

    const { action } = quoteBuilderConfig;

    const fund_type = useSelector(
        (state) => state.order.order?.account?.fund_type
    );

    return (
        <>
            <Form.Group widths={3}>
                <Controller
                    control={control}
                    name="invoice_name"
                    render={({
                        field: { name, value, onChange },
                        fieldState: { error },
                    }) => (
                        <Form.Input
                            label="Invoice Name"
                            value={value}
                            name={name}
                            onChange={(_, { value }) => {
                                onChange({ target: { name, value } });
                            }}
                            error={error?.message}
                        />
                    )}
                />
            </Form.Group>
            <Form.Group widths={"equal"}>
                <Form.Field label="Customer Name" required control="div">
                    <Dropdown
                        placeholder="Select Customer"
                        selectOnBlur={false}
                        selection
                        options={[]}
                        value={null}
                        fluid
                        required
                        {...(order?.account
                            ? {
                                  text: (
                                      <LinkedAccount
                                          account={order?.account?.name}
                                          maxWidth={"8.25em"}
                                      />
                                  ),
                              }
                            : {})}
                        readOnly
                    />
                </Form.Field>
                <Form.Field label="Date" control="div">
                    <DateInput
                        dateFormat={DateTimeUtils.WORD_DATE_FORMAT}
                        value={DateTimeUtils.getCurrentDate(
                            DateTimeUtils.WORD_DATE_FORMAT
                        )}
                        readOnly
                    />
                </Form.Field>
                {termValue !== "default" && termValue !== "demo" && dueOnField}
            </Form.Group>
            <Form.Group widths={3}>
                <Controller
                    control={control}
                    name="term"
                    render={({ field: { name, value, onChange } }) => (
                        <Form.Field
                            disabled={
                                fund_type === "trial" ||
                                fund_type === "demo" ||
                                action === ACTIONS.DEMO
                            }
                            control={TermField}
                            label={
                                <label>
                                    Term
                                    <Icon
                                        onClick={() => {
                                            setIsTermTypesInfoShowing(true);
                                        }}
                                        style={{ float: "right" }}
                                        name="fas fa-info-circle"
                                        color="primary"
                                        link
                                    />
                                </label>
                            }
                            required
                            value={value}
                            onChange={(_, { value }) => {
                                onChange({ target: { name, value } });
                                const [termValue] = (value || "").split("/");
                                if (termValue === "demo") {
                                    onChangeSelectedPaymentMethod(null);
                                }
                            }}
                            isFunded={fund_type === "funded"}
                        />
                    )}
                />
            </Form.Group>
            <Modal
                open={isTermTypesInfoShowing}
                onClose={() => {
                    setIsTermTypesInfoShowing(false);
                }}
            >
                <Modal.Header>Term Types</Modal.Header>
                <Modal.Content>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <strong>Default:</strong> Default takes into
                                account of different billing schedules
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <strong>Trial:</strong> Trial with credit card
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <strong>Net 15, 30, or 60:</strong> Payment
                                expected within 15, 30, or 60 days after the
                                invoice date
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Modal.Content>
            </Modal>
        </>
    );
};

export default PaymentInformation;
