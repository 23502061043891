import { CloseableContent } from 'dyl-components';
import React from 'react';
import { Sidebar, Menu } from 'semantic-ui-react';

import './index.scss';
import ModuleInformation from './ModuleInformation';

const RightSidebar = ({ open, onClose }) => {
    return (
        <Sidebar
            vertical
            direction='right'
            visible={open}
            animation='push'
            as={Menu}
            className='RightSidebar'
        >
            <CloseableContent onClose={onClose}>
                <div style={{ paddingTop: '2em' }}>
                    {open && (
                        <ModuleInformation onClose={onClose} />
                    )}
                </div>
            </CloseableContent>
        </Sidebar>
    );
};

export default RightSidebar;
