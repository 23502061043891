import React, { useState, useEffect } from "react";
import { EditableLabels, Notification, STATUS_TYPES } from "dyl-components";
import { connect } from "react-redux";
import tasksActions from "actions/tasks";
import taskActions from "actions/task";
import task_typesActions from "actions/task_types";

const TaskLabels = ({
    onReadTaskLabels,
    onReadTaskTypes,
    task_labels,
    onAddTaskLabels,
    onUpdateTaskLabel,
    onDeleteTaskLabel,
    onCheckTaskLabelDuplicate,
    taskTypes,
    isLoading,
    handleFormDirty,
}) => {
    const [taskLabels, setTaskLabels] = useState([]);
    const [key, setKey] = useState("TASK_LABELS");

    useEffect(() => {
        Promise.all([onReadTaskLabels(), onReadTaskTypes()]).then(() => {
            setTaskLabels(task_labels);
            resetKey();
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setTaskLabels(task_labels);
    }, [task_labels]);

    const resetKey = () => {
        setKey("");
        setTimeout(() => setKey("TASK_LABELS"), 0);
    };

    const onSave = async (newLabels) => {
        const labelsToAdd = newLabels
            .filter(({ id }) => !id)
            .map(({ value }) => ({
                name: value[0].trim().replace(/\s+/g, " "),
                task_type_ids: value[1].map((task_type_id) => ({
                    task_type_id,
                })),
            }));

        if (labelsToAdd.length === 0) return;

        try {
            await onAddTaskLabels(labelsToAdd);
            Notification.alert(
                "Successfully saved the task labels!",
                STATUS_TYPES.SUCCESS
            );
            setTaskLabels([
                ...taskLabels,
                ...labelsToAdd.map((label) => ({
                    id: null,
                    value: [
                        label.name,
                        label.task_type_ids.map((t) => t.task_type_id),
                    ],
                })),
            ]);
            await onReadTaskLabels();
            resetKey();
        } catch (error) {
            console.error(error);
            Notification.alert(
                "Failed to create task labels",
                STATUS_TYPES.ERROR
            );
        }
    };

    const onUpdate = async (id, name) => {
        try {
            await onUpdateTaskLabel(id, name);
            Notification.alert(
                "Successfully updated the task label!",
                STATUS_TYPES.SUCCESS
            );
            setTaskLabels(
                taskLabels.map((label) =>
                    label.id === id
                        ? { ...label, value: [name, label.value[1]] }
                        : label
                )
            );
        } catch (error) {
            Notification.alert(
                error.Code === 409
                    ? "Task label already exists"
                    : "Failed to update task label",
                STATUS_TYPES.ERROR
            );
        }
    };

    const onRemove = async (id) => {
        try {
            await onDeleteTaskLabel(id);
            Notification.alert(
                "Successfully deleted the task label!",
                STATUS_TYPES.SUCCESS
            );
            setTaskLabels(taskLabels.filter((label) => label.id !== id));
        } catch (error) {
            console.error(error);
            Notification.alert(
                "Failed to delete task label",
                STATUS_TYPES.ERROR
            );
        }
    };

    const onCheckDuplicate = async (value) => {
        try {
            const { id, name } = await onCheckTaskLabelDuplicate(value);
            if (!id || !name) throw new Error();
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

    const getCorrectedTaskTypeName = (task_type_name) => {
        if (task_type_name?.includes("Task")) {
            task_type_name = task_type_name.split(" ").slice(0, -1).join(" ");
        }
        return task_type_name === "To Do" ? "To-Do" : task_type_name;
    };

    const getTaskTypeId = (task_type_name) => {
        const correctedName = getCorrectedTaskTypeName(task_type_name);
        return taskTypes.find(({ name }) => name === correctedName)?.id;
    };

    const generateTaskTypeOptions = () => {
        const taskTypesList = ["To Do", "Call", "Text", "Email"];
        return taskTypesList.map((taskType) => {
            const value = getTaskTypeId(taskType);
            return { key: value, value, text: taskType };
        });
    };

    const fields = [
        {
            header: "Task Labels",
            type: "input",
            placeholder: "Type task label",
            editable: true,
        },
        {
            header: "Task Types",
            type: "checkbox_dropdown",
            placeholder: "Select task type(s)",
            options: generateTaskTypeOptions(),
            editable: false,
        },
    ];

    return (
        <EditableLabels
            key={key}
            name="task_labels"
            addLabel="Add Task Label"
            info="Edit will reflect on all tasks. Delete will remove the label from all future tasks."
            fields={fields}
            items={taskLabels}
            onSave={onSave}
            onUpdate={onUpdate}
            onRemove={onRemove}
            isLoading={isLoading}
            onCheckDuplicate={onCheckDuplicate}
            onFormDirty={handleFormDirty}
        />
    );
};

const mapStateToProps = (state) => ({
    task_labels:
        state.tasks.task_labels?.map(({ name, id, task_types }) => ({
            id,
            value: [name, task_types.map(({ id }) => id)],
        })) || [],
    taskTypes: state.task_types.taskTypes || [],
    isLoading:
        state.tasks.isReadingTaskLabels ||
        state.tasks.isAddingTaskLabels ||
        state.task.isDeletingTaskLabel ||
        state.task.isUpdatingTaskLabel,
});

const mapDispatchToProps = (dispatch) => ({
    onReadTaskLabels: () => dispatch(tasksActions.readTaskLabels()),
    onAddTaskLabels: (task_labels) =>
        dispatch(tasksActions.addTaskLabels(task_labels)),
    onUpdateTaskLabel: (task_label_id, name) =>
        dispatch(taskActions.updateTaskLabel(task_label_id, { name })),
    onDeleteTaskLabel: (task_label_id) =>
        dispatch(taskActions.deleteTaskLabel(task_label_id)),
    onCheckTaskLabelDuplicate: (label) =>
        dispatch(taskActions.checkTaskLabelDuplicate(null, { label })),
    onReadTaskTypes: () => dispatch(task_typesActions.readTaskTypes()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskLabels);
