import { Button, Form, Grid, Header, Icon, Segment } from "semantic-ui-react";
import PaymentInformation from "./PaymentInformation";
import {
    DateTimeUtils,
    Modal,
    Notification,
    STATUS_TYPES,
    Step,
} from "dyl-components";
import { Controller, useFormContext } from "react-hook-form";
import { useContext, useState } from "react";
import { QuoteBuilderContext } from "shared/context/QuoteBuilderProvider";
import PaymentMethod from "./PaymentMethod";

import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import invoiceActions from "actions/invoice";
import accountActions from "actions/account";
import paymentActions from "actions/payment";
import { useParams } from "react-router-dom";

const STEPS = [
    {
        icon: <Icon className="fas fa-box-dollar" size="large" />,
        title: "Order",
        completed: true,
    },
    {
        icon: <Icon className="fas fa-file-invoice-dollar" size="large" />,
        title: "Checkout",
        active: true,
    },
];

const Payment = ({ account_id }) => {
    const {
        formState: { control, isValid, isDirty },
        handleSubmit,
    } = useFormContext();
    const {
        onEditOrder,
        quoteBuilderConfig,
        onViewInvoice,
        onRefreshAccountInformation,
    } = useContext(QuoteBuilderContext);

    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);

    const onChangeSelectedPaymentMethod = (method) => {
        setSelectedPaymentMethod(method);
    };

    const isViewingAccount = useParams()?.account_id;

    const dispatch = useDispatch();
    const order_id = quoteBuilderConfig?.id;

    const paymentMethods = useSelector(
        (state) => state.payment.profile?.paymentProfiles || []
    );
    const customer_profile_id = useSelector(
        (state) => state.payment.profile?.customerProfileId
    );

    const opportunity_id = useSelector(
        (state) => state.order.order?.opportunity_id
    );
    const account_fund_type = useSelector(
        (state) => state.order.order?.account?.fund_type
    );

    const onProcess = async (data) => {
        const isDemo = data.term === "demo";
        const otherInfo = (() => {
            if (isDemo) {
                return {};
            }
            if (selectedPaymentMethod === "custom-ach") {
                return {
                    custom: data.custom_ach,
                };
            }
            const paymentInfo = paymentMethods.find(
                (paymentMethod) =>
                    data.payment_method ===
                    paymentMethod.customerPaymentProfileId
            );
            const { creditCard, bankAccount } = paymentInfo.payment;
            const payment = (() => {
                if (creditCard) {
                    const { cardType, issuerNumber, ...otherInfo } = creditCard;
                    return {
                        creditCard: otherInfo,
                    };
                }
                return {
                    bankAccount,
                };
            })();
            return {
                customerPaymentProfileId: data.payment_method,
                paymentInfo: payment,
            };
        })();
        const buyer_notes = data.buyer_notes;
        const note = (() => {
            const term = data.term;
            if (!term?.includes("trial")) {
                return buyer_notes;
            }
            const [, numberOfDays] = term.split("/");
            return `
                Trial end date: ${DateTimeUtils.changeFormat(
                    DateTimeUtils.getNext(
                        Number(numberOfDays),
                        "day",
                        false,
                        true
                    ),
                    DateTimeUtils.DATE_FORMAT,
                    DateTimeUtils.WORD_DATE_FORMAT
                )}
                ${buyer_notes || ""}
                `;
        })();
        const payload = {
            ...otherInfo,
            account_id,
            opportunity_id,
            customer_profile_id,
            name: data.invoice_name,
            note,
        };
        try {
            if (isDemo && account_fund_type === "unfunded") {
                await dispatch(
                    paymentActions.updateProcessorToDemo(account_id)
                );
            }
            const invoice_id = await dispatch(
                invoiceActions.create(payload, null, order_id)
            );
            if (account_fund_type === "unfunded") {
                await dispatch(
                    accountActions.updateFundType(account_id, {
                        fund_type: (() => {
                            const term = data.term;
                            if (term?.includes("trial")) {
                                return "trial";
                            }
                            if (term === "demo") {
                                return term;
                            }
                            return "funded";
                        })(),
                    })
                );
            }
            Notification.alert(
                "Successfully processed order!",
                STATUS_TYPES.SUCCESS
            );
            if (isViewingAccount) {
                await dispatch(accountActions.readAccount(account_id));
            }
            onViewInvoice(invoice_id);
            onRefreshAccountInformation();
        } catch (e) {
            console.log(e);
            Notification.alert("Failed to process order", STATUS_TYPES.ERROR);
        }
    };

    const isProcessingPaymentDetails = useSelector(
        (state) =>
            state.payment.isUpdatingPaymentProcessor ||
            state.invoice.isCreating ||
            state.account.isUpdatingFundType
    );

    const { isReadingPaymentInfo } = useSelector((state) => ({
        isReadingPaymentInfo:
            state.payment.isReadingCustomerFormToken ||
            state.payment.isReadingCustomerProfile,
    }));

    return (
        <>
            <Modal.Content scrolling>
                <Form
                    size="small"
                    noValidate
                    loading={isProcessingPaymentDetails || isReadingPaymentInfo}
                >
                    <Segment size="tiny" basic>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <Step.Group horizontal>
                                        {STEPS.map(({ icon, ...step }) => (
                                            <Step {...step} key={step.name}>
                                                {icon}
                                            </Step>
                                        ))}
                                    </Step.Group>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Header color="primary">
                                        Invoice Information
                                    </Header>
                                    <PaymentInformation
                                        onChangeSelectedPaymentMethod={
                                            onChangeSelectedPaymentMethod
                                        }
                                    />
                                    <Header color="primary">
                                        Payment Method
                                    </Header>
                                    <PaymentMethod
                                        onChangeSelectedPaymentMethod={
                                            onChangeSelectedPaymentMethod
                                        }
                                        selectedPaymentMethod={
                                            selectedPaymentMethod
                                        }
                                        account_id={account_id}
                                    />
                                    <Controller
                                        control={control}
                                        name="buyer_notes"
                                        render={({
                                            field: { name, value, onChange },
                                        }) => (
                                            <Form.TextArea
                                                name={name}
                                                value={value}
                                                onChange={(_, { value }) => {
                                                    onChange({
                                                        target: { name, value },
                                                    });
                                                }}
                                                label="Buyer Notes"
                                            />
                                        )}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Segment>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    basic
                    onClick={() => {
                        onEditOrder(order_id, account_id);
                    }}
                    type="button"
                    color="primary"
                    disabled={isProcessingPaymentDetails}
                >
                    Back to Order
                </Button>
                <Button
                    disabled={
                        !isValid || !isDirty || isProcessingPaymentDetails
                    }
                    type="submit"
                    color="primary"
                    onClick={handleSubmit(onProcess)}
                    loading={isProcessingPaymentDetails}
                >
                    Process
                </Button>
            </Modal.Actions>
        </>
    );
};

export default Payment;
