export const KEY_CREATED_ON = 'created_on';
export const KEY_ACCOUNT_TYPE = 'account_type';
export const KEY_REACTIVATED_ON = 'reactivated_on';
export const KEY_CREATED_BY = 'created_by';
export const KEY_OWNER  = 'Owner';
export const KEY_REACTIVATED_BY = 'reactivated_by';
export const KEY_LAST_MODIFIED = 'last_modified';
export const KEY_FUND_TYPE = 'fund_type';
export const KEY_ACCOUNT_STATUS = 'account_status';
export const KEY_LAST_MODIFIED_BY = 'last_modified_by';
export const KEY_CUSTOMER_SINCE = 'customer_since';
export const KEY_ACTIONS = 'actions';
export const KEY_CANCELED_ON = 'canceled_on';
export const KEY_NAME = 'name';
export const KEY_CANCELED_BY = 'canceled_by';
