import contactActions from 'actions/contact';
import { Person, SearchableOptions } from 'dyl-components';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Grid, Icon, Segment, Button, Form } from 'semantic-ui-react';
import { Controller } from 'react-hook-form';
import { PhoneUtil } from 'utils';

import './ContactOptions.scss';
import ContactFields from './ContactFields';

const mapDispatchToProps = dispatch => ({
    onReadContacts: (queryParameters) => {
        return dispatch(contactActions.getContactOptions(queryParameters));
    }
});

const LIMIT = 15;

const ContactSearchResults = ({ loading, search, onSelectContact, index, contact_options, isOptionAlreadySelected, contact, checkIfContactCanBeSelected }) => (
    <React.Fragment>
        {search.trim().length > 1 && (
            <Segment style={{ cursor: 'pointer' }} onClick={() => {
                onSelectContact({
                    first_name: search,
                    email: {},
                    role: 'influencer',
                    phone: {},
                    last_name: '',
                    new: true
                }, index)
            }}>
                + Add '<span className='ContactSearchResults__ContactOptionAsNew'>{search}</span>' as New Contact
            </Segment>
        )}
        <Segment {...{ ...(contact_options.length === 0) && { className: 'ContactSearchResults--hidden' } }} loading={loading}>
            <Grid>
                {contact_options.map(contactOption => {
                    const { first_name, last_name, id, account, email, phone } = contactOption;
                    const name = `${first_name} ${last_name}`;
                    const canAdd = checkIfContactCanBeSelected ? checkIfContactCanBeSelected(contactOption) : !account?.id && !isOptionAlreadySelected(id);
                    return (
                        <Grid.Row verticalAlign='middle' key={id} columns='equal' stretched>
                            <Grid.Column>
                                <Person
                                    username={name}
                                    email={email.email}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                ID: {id}
                            </Grid.Column>
                            <Grid.Column>
                                {PhoneUtil.formatPhoneNumber(phone.phone)}
                            </Grid.Column>
                            <Grid.Column>
                                {account && account.id && (
                                    <span><Icon className={`fas fa-${account.account_type === 'business' ? 'building' : 'house'}`} />{account.name}</span>
                                )}
                            </Grid.Column>
                            <Grid.Column width={1}>
                                <Icon target="_blank" as={Link} to={`/contact/${id}`} className='fas fa-external-link' />
                            </Grid.Column>
                            <Grid.Column width={3}>
                                <Button color='primary' onClick={() => { onSelectContact({ ...contact, ...contactOption, contact_id: contactOption.id }, index) }} disabled={!canAdd}>
                                    {isOptionAlreadySelected(id) ? 'Selected' : 'Add'}
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    );
                })}
            </Grid>
        </Segment>
    </React.Fragment>
)

const ContactOptions = ({
    onSelectContact,
    onReadContacts,
    name,

    control,
    clearErrors,
    setError,
    index,
    contact,
    isOptionAlreadySelected,

    changePrimaryContact,
    hasPrimaryToggle = true,
    hasRoleSelect = false,

    roleSelectOptions = [],

    checkIfContactCanBeSelected,
    selectedContactWidth = 3,
    trigger
}) => {
    const [state, setState] = useState({
        contact_options: [],
        count: 0,
        page: 1,
        search: '',
        open: false,
    });

    const [loading, setLoading] = useState(false);

    const searchFunction = async () => {
        try {
            setLoading(true);
            const { data, count } = await onReadContacts({ page: 1, search: state.search, limit: LIMIT });
            setState({ ...state, contact_options: data, count, open: true, page: 1, search: state.search });
            setLoading(false);
        } catch (e) {
            setLoading(false);
        }
    }

    const cancelFunction = async () => {
        setState({ ...state, contact_options: [], count: 0, open: false, page: 1, search: '' });
    }

    const onChangeSearch = (_, { value }) => {
        setState({ ...state, search: value, open: value?.trim().length >= 2 });
    }

    const primaryContactToggler = (
        <Controller
            name={`${name}[${index}].main`}
            control={control}
            render={({ field: { value } }) => (
                <Form.Radio
                    checked={value}
                    className="ContactOptions__toggler"
                    onClick={async () => {
                        changePrimaryContact();
                    }}
                    label='Primary Contact'
                    width={3}
                />
            )}
        />
    )

    const roleSelect = (
        <Controller
            name={`${name}[${index}].role`}
            control={control}
            render={({ field: { name, onChange, value } }) => (
                <Form.Select
                    name={name}
                    label={"Role"}
                    options={roleSelectOptions}
                    width={3}
                    value={value}
                    className="ContactOptions__roleSelect"
                    onChange={(_, { name, value }) => {
                        onChange({ target: { name, value } });
                    }}
                    required
                    placeholder='Select Role'
                />
            )}
        />
    );

    if (contact.contact_id) {
        const { first_name, last_name, emails } = contact;
        const name = `${first_name} ${last_name}`;
        return (
            <Form.Group>
                <Form.Dropdown
                    text={(
                        <Person
                            username={name}
                            email={emails[0]?.value}
                        />
                    )}
                    value={contact.contact_id}
                    clearable
                    open={false}
                    selection
                    {...(selectedContactWidth ? { width: selectedContactWidth } : {})}
                    onChange={() => {
                        onSelectContact({ contact_id: null, email: {}, first_name: '', last_name: '' }, index);
                    }}
                />
                {hasRoleSelect && roleSelect}
                {hasPrimaryToggle && primaryContactToggler}
            </Form.Group>
        )
    }

    if (contact.new) {
        return [
            <Form.Group widths={4}>
                <Form.Dropdown
                    open={false}
                    text='Add new contact'
                    selection
                    clearable
                    value={true}
                    width={3}
                    onChange={() => {
                        onSelectContact({ contact_id: null, email: {} }, index);
                    }}
                /> {hasPrimaryToggle && primaryContactToggler}
            </Form.Group>,
            <ContactFields
                control={control}
                clearErrors={clearErrors}
                setError={setError}
                fieldNamePrefix={`${name}[${index}].`}
                trigger={trigger}
            />
        ]
    }

    return (
        <SearchableOptions
            loading={loading}
            search={state.search}
            searchFunction={searchFunction}
            cancelSearchFunction={cancelFunction}
            searchPlaceholder={'Search for a contact'}
            onChangeSearch={onChangeSearch}
            searchSubcomponent={hasPrimaryToggle && primaryContactToggler}
            isSearchResultsVisible={state.open}
            renderSearchResults={<ContactSearchResults
                contact={contact}
                contact_options={state.contact_options}
                index={index}
                isOptionAlreadySelected={isOptionAlreadySelected}
                loading={loading}
                onSelectContact={onSelectContact}
                search={state.search}
                checkIfContactCanBeSelected={checkIfContactCanBeSelected}
            />}
            isPaginationVisible={state.contact_options.length > 0}
            paginationProps={{
                boundaryRange: 0,
                activePage: state.page,
                ellipsisItem: null,
                siblingRange: 2,
                totalPages: Math.ceil(state.count / LIMIT),
                onPageChange: async (_, { activePage }) => {
                    setState({ ...state, loading: true, open: true, page: activePage });
                    const { data, count } = await onReadContacts({ page: activePage, search: state.search, limit: LIMIT });
                    setState({ ...state, page: activePage, contact_options: data, count, loading: false, open: true });
                }
            }}
        />
    )
}

export default connect(null, mapDispatchToProps)(ContactOptions);
