import React, { useState } from 'react';
import { Grid, Icon, Popup } from 'semantic-ui-react';

import Avatar from 'react-avatar';

import Controls from './Controls';
import SocialMedia from 'shared/SocialMedia';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ConversionControls from './ConversionControls';
import { ClippedContent } from 'dyl-components';
import { ProfileName } from './ProfileName';
import { JobTitle } from './JobTitle';
import StagesSection from './StagesSection';
import { FAVORITE_TYPE } from 'shared/constants/FAVORITE_TYPE';
import { ProfileFavoriteIcon } from 'shared/ProfileFavoriteIcon';
import SubControls from "../Account/SubControls";

function copy(text) {
    return (e) => {
        e.preventDefault();
        navigator.clipboard.writeText(text);
    };
}

export const ProfileHeader = (({
    name,
    breadcrumbs,
    isInHotlist,

    isSidePanelOpen,
    id,

    onHotlistToggle
}) => {
    const { parent_account, current_stage, contactIsAssociatedWithCustomerPipeline } = useSelector(state => {
        return {
            parent_account: state.contact.account,
            current_stage: state.contact.current_stage,
            contactIsAssociatedWithCustomerPipeline: state.contact.account?.pipeline_person_id === Number(state.contact.contact_id)
        }
    });

    const { account_type, name: title, id: account_id } = parent_account || {};

    const [fieldBeingEdited, setFieldBeingEdited] = useState(null);

    const onEdit = (field_name) => {
        setFieldBeingEdited(field_name);
    }

    const onCancelEdit = () => {
        setFieldBeingEdited(null);
    }

    const isEditing = (field_name) => {
        return field_name === fieldBeingEdited;
    }

    return (
        <React.Fragment>
            <div style={{ paddingBottom: "0.5em" }}>
                {breadcrumbs}
            </div>
            <Grid>
                <Grid.Row columns='equal' verticalAlign='middle'>
                    <Grid.Column width={6}>
                        <Grid columns='equal' verticalAlign='middle'>
                            <Grid.Column className='ProfileHeader__avatarContainer' width={isSidePanelOpen ? 3 : 2} textAlign='center' style={{ padding: '1em 0 1em 0' }}>
                                <Avatar
                                    size='5em'
                                    round
                                    maxInitials={2}
                                    name={name}
                                />
                                <SocialMedia />
                            </Grid.Column>
                            <Grid.Column className='ProfileHeader__nameColumn'>
                                <ProfileName isEditing={isEditing('name')} onEdit={() => { onEdit('name') }} onCancelEdit={onCancelEdit} />
                                <Grid className='ProfileHeader__businessContainer' columns={'equal'} verticalAlign='middle'>
                                    {
                                        title && (
                                            <Grid.Column width={5}>
                                                <ClippedContent>
                                                    <span>
                                                        <Icon className={`fas ${account_type === 'business' ? 'fa-building' : 'fa-solid fa-house-chimney-blank'}`} />
                                                        <Link to={`/account/${account_id}`} target="_blank">
                                                            {title}
                                                        </Link>
                                                    </span>
                                                </ClippedContent>
                                            </Grid.Column>
                                        )
                                    }
                                    <Grid.Column {...(!isEditing('job_title') ? { width: 5 } : {})}>
                                        <JobTitle isEditing={isEditing('job_title')} onEdit={() => { onEdit('job_title') }} onCancelEdit={onCancelEdit} />
                                    </Grid.Column>
                                </Grid>
                                <div className='ProfileHeader__leftContainer'>
                                    <span><Icon className={`fas fa-user`} />Contact</span>
                                    <div className='ProfileHeader__iconsContainer'>
                                        <ProfileFavoriteIcon id={id} type={FAVORITE_TYPE.CONTACT} />
                                        <div className='ProfileHeader__iconsBox' onClick={onHotlistToggle}>
                                            <div className='ProfileHeader__iconOverlay' />
                                            <Icon link className={`${isInHotlist ? "fa-solid fa-fire-flame-curved" : "fa-solid fa-fire-flame"}  ProfileHeader__icon${isInHotlist ? '--red' : '--grey'}`} />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    ID: <Popup
                                        trigger={<a href='#?' role="button" tabIndex="0" onClick={copy(id)}>{id} <Icon className='fas fa-copy' /></a>}
                                        content='Copied!'
                                        on='click'
                                        closeOnTriggerMouseLeave
                                        position='bottom center'
                                        inverted />
                                </div>
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>
                    {current_stage?.account_stage && (
                        <Grid.Column width={6}>
                            <StagesSection id={id} />
                        </Grid.Column>
                    )}
                    <Grid.Column verticalAlign='middle' textAlign='right'>
                        <Grid columns='equal' verticalAlign='top' textAlign='left'>
                            {!contactIsAssociatedWithCustomerPipeline && !current_stage?.account_stage ? <Grid.Column width={isSidePanelOpen ? 11 : 12} textAlign='right'> <ConversionControls /> </Grid.Column> : <Grid.Column width={isSidePanelOpen ? 5 : 7} textAlign='right'></Grid.Column>}

                            <Grid.Column textAlign='left' >

                                <Controls />

                                <SubControls />
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>

                </Grid.Row >
            </Grid >
        </React.Fragment >
    );
});
