import React, { useEffect, useState } from 'react';
import { Grid, Header, Popup, Icon, Form, Checkbox, Portal } from 'semantic-ui-react';
import './index.scss'
import { Controller, useForm } from 'react-hook-form';
import { Button, Notification, STATUS_TYPES, SettingsFooter, generateResolver, yup } from 'dyl-components';
import { MathUtils } from 'utils';
import useWidthListener from 'shared/SettingsFooter/useWidthListener';
import useWindowWidth from 'shared/SettingsFooter/useWindowWidth';
import { useDispatch, useSelector } from 'react-redux';
import pbxConfigActions from "actions/pbx_config";
import { useBlocker } from 'shared/confirmation/UseBlocker';
import FullScreenModalPopUp from 'shared/FullScreenModalPopUp';

const OPTIONS = [
    {key: true, value: true, text: 'Automatic'},
    {key: false, value: false, text: 'Manual'}
]

const CallRecording = () => {
    const [byStateDisabled, setByStateDisabled] = useState(false);
    const width = useWidthListener("settingsSidebar");
    const windowWidth = useWindowWidth();
    const dispatch = useDispatch();
    const { recordings, isReadingRecordings, isUpdatingRecordings } = useSelector((state) => state.pbx_config);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [pendingNavigation, setPendingNavigation] = useState(null);

    const {control, watch, setValue, handleSubmit, reset, formState: { isValid, isDirty }} = useForm({
        mode: 'onChange',
        defaultValues: {
            inbound: recordings?.inbound || true,
            recording: recordings?.recording || true,
            bystate: recordings?.bystate || true
        },
        resolver: generateResolver({
            inbound: yup.boolean().required(),
            recording: yup.boolean().required(),
            bystate: yup.boolean().when('recording', {
                is: (value) => value === false,
                then: () => yup.boolean().test(
                    'is-false',
                    'Enable one party states only must be unchecked when Outbound Calls is manual',
                    (value) => value === false
                ),
                otherwise: () => yup.boolean().required()
            })
        })
    });

    const getDefaultValues = () =>{
        return {
            inbound: recordings?.inbound ,
            recording: recordings?.recording ,
            bystate: recordings?.bystate 
        }
    }

    const onSave = async (data) => {
        try {
            await dispatch(pbxConfigActions.updateRecordings(null, data));
            await dispatch(pbxConfigActions.getRecordings());
            Notification.alert("Saved successfully", STATUS_TYPES.SUCCESS);
        } catch (error) {
            console.log(error);
            Notification.alert("Failed to save recordings", STATUS_TYPES.ERROR);
        }
    }

    const cancelEdit = () => {
        if (isDirty) {
            reset(getDefaultValues())
        }
    }

    const handleConfirmLeave = () => {
        setIsConfirmationOpen(false);
        cancelEdit();
        if (pendingNavigation) {
            pendingNavigation.retry();
        }
    };

    useEffect(() => {
        if (recordings) {
            const recordingsCopy = {...recordings}
            delete recordingsCopy.requestID;
            reset(recordingsCopy);
        }
    }, [recordings, reset])

    useEffect(() => {
        const subscription = watch(({recording}, {name}) => {
            if (name === "recording") {
                setValue("bystate", !!recording);
                setByStateDisabled(!recording);
            } else if (!name && !recording) {
                setByStateDisabled(true);
            }
        })
        return () => subscription.unsubscribe()
    }, [watch, setValue])

    useEffect(() => {
        dispatch(pbxConfigActions.getRecordings());
    }, [dispatch])

       useBlocker(
            (tx) => {
                if (isDirty && !isConfirmationOpen) {
                    setPendingNavigation(tx);
                    setIsConfirmationOpen(true);
                } else {
                    tx.retry(); // Proceed with navigation if no unsaved changes
                }
            },
            isDirty
        );

    return( 
    <React.Fragment>
        <Form loading={isReadingRecordings || isUpdatingRecordings}>
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <Header as='h2' color='primary'>
                            Call Recording 
                            <Popup
                                trigger={<Icon size='small' className='fas fa-circle-info' color='primary' style={{marginLeft: 10}}/>}
                                content={<>
                                    <span>In many states recording calls without consent is against the law. Please see&nbsp;</span>
                                    <a className={"PhoneManagement__callRecordingLink"} href='https://dyl.com/compliance' target='_blank' rel="noreferrer">
                                        <span>DYL Compliance-Call Recordings</span>
                                    </a>
                                    <span>&nbsp;for more information.</span>
                                </>}
                                inverted
                                hoverable
                            />
                        </Header>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Controller 
                            name='inbound'
                            control={control}
                            render={({ field: { name, value, onChange } }) => (
                                <Form.Select
                                    width={4}
                                    name={name}
                                    value={value}
                                    onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                    placeholder='Select inbound calls'
                                    options={OPTIONS}
                                    label="Inbound Calls"
                                    required
                                />
                            )}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Controller 
                            name='recording'
                            control={control}
                            render={({ field: { name, value, onChange } }) => (
                                <Form.Select
                                    width={4}
                                    name={name}
                                    value={value}
                                    onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                    placeholder='Select outbound calls'
                                    options={OPTIONS}
                                    label="Outbound Calls"
                                    required
                                />
                            )}
                        />
                        <Controller 
                            name='bystate'
                            control={control}
                            render={({ field: { name, value, onChange } }) => (
                                <Checkbox
                                    checked={value}
                                    disabled={byStateDisabled}
                                    onChange={(_, { name, checked }) => { onChange({ target: {name, value: checked} }) }}
                                    width={4}
                                    name={name}
                                    label="Enable one party states only"
                                />
                            )}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Portal open>
                <SettingsFooter
                    style={{ width: MathUtils.calculatePercentage(windowWidth, windowWidth - width) }}
                    className={`Webform__menu`}
                        rightOptions={[
                            <Button onClick={() => {
                                isDirty ? setIsConfirmationOpen(true) : cancelEdit()
                            }} status={'cancel'} basic>Cancel</Button>,
                        <Button
                            onClick={handleSubmit(onSave)}
                            disabled={!isValid || !isDirty || isReadingRecordings || isUpdatingRecordings}
                        >
                            Save
                        </Button>,
                    ]}
                />
            </Portal>
        </Form>
            <FullScreenModalPopUp
                header={"Changes not saved"}
                subheader={"Are you sure you want to exit?"}
                isOpen={isConfirmationOpen}
                onConfirm={handleConfirmLeave}
                onFormClose={() => setIsConfirmationOpen(false)}
                closeIcon={false}
            />
    </React.Fragment>
    )
}

export default CallRecording;