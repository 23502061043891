import React, { useState, useEffect } from 'react';
import { Grid, Divider, Header, Form, Checkbox, Loader, Dimmer, Portal, Popup, Icon } from 'semantic-ui-react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams, Link } from 'react-router-dom';
import { Notification, STATUS_TYPES, Button } from 'dyl-components';

import customFieldsGroupsActions from "actions/custom_fields_groups";
import leadProviderActions from 'actions/lead_provider';
import leadIngestionActions from 'actions/lead_ingestion';
import DynamicFields from 'shared/DynamicFields';

import './index.scss';
import SettingsFooter from 'dyl-components/molecules/SettingsFooter';
import useWidthListener from 'shared/SettingsFooter/useWidthListener';
import useWindowWidth from 'shared/SettingsFooter/useWindowWidth';
import { MathUtils } from 'utils';
import { MODULE_OPTIONS } from 'shared/constants/MODULE_OPTIONS'; 
import { METHOD_OPTIONS } from 'shared/constants/METHOD_OPTIONS';
import { useConfirm } from 'shared/confirmation/useConfirm';
import CustomPrompt from 'shared/confirmation/CustomPrompt';
import ConfirmModal from 'shared/confirmation/ConfirmModal';

const DATA_SOURCE_OPTIONS = [
    { key: 'lead_vendor', value: 'Lead Vendor', text: 'Lead Vendor' },
    { key: 'data_broker', value: 'Data Broker', text: 'Data Broker' }
];

const MASTER_SOURCE_OPTIONS = ['Events', 'Social', 'Advertising', 'Direct', 'Media', 'Outbound']; //TODO:  update when standard fields are updated

const SecondarySourceField = ({
    control,
    isExisting,
    watchedMasterSource
}) => {
    const isReadingSecondarySources = useSelector(state => state.lead_provider.isReadingSecondarySources);
    const secondarySourcesOptions = useSelector(state => state.lead_provider.secondary_sources.map(({ id, name }) => ({ key: id, value: id, text: name })));
    return (
        <Controller
            name={'secondarySourceCategory'}
            control={control}
            defaultValue={null}
            render={({ field: { value, name, onChange } }) => (
                <Form.Field>
                    <label>
                        Secondary Source
                        <Popup
                            trigger={<Icon style={{ float: 'right', marginTop: '0.25em' }} name='fas fa-info-circle' color='primary' />}
                            content='Will only be displayed on leads and opportunities.'
                            inverted
                            position='top right' 
                        />
                    </label>
                    <Form.Select
                        value={value}
                        name={name}
                        onChange={(_, { value }) => { onChange({ target: { name, value } }); }}
                        placeholder='Select Secondary Source'
                        clearable
                        disabled={!watchedMasterSource || isReadingSecondarySources || isExisting}
                        options={secondarySourcesOptions}
                        loading={isReadingSecondarySources}
                    />
                </Form.Field>

            )}

        />
    )
}

const DataProviderCreate = () => {
    const { control, formState: { isValid , isDirty }, watch, setValue, handleSubmit, getValues, reset } = useForm({
        mode: 'onChange',
        defaultValues: {
            dataProviderName: '',
            dataSourceCategory: null,
            masterSourceCategory: null,
            secondarySourceCategory: null
        }
    });
    const { isConfirmed } = useConfirm();

    const [params] = useSearchParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const width = useWidthListener("settingsSidebar");
    const windowWidth = useWindowWidth();

    const watchedMasterSource = watch('masterSourceCategory');
    const watchedDataProviderName = watch('dataProviderName');

    const [checkedSecondStep, setChecked] = useState(false);
    const [providerNameOptions, setProviderNameOptions] = useState([]);
    const [isExisting, setIsExisting] = useState(false);
    const [key, setKey] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [hasProviderAdded, setHasProviderAdded] = useState(false);
    const [isStep2FormIsDirty , setIsStep2FormIsDirty] = useState(false)

    const { isCreatingProvider, isReadingProviders, providers, isReadingMappings } = useSelector(state => state.lead_provider);
    const { isReadingProductInterests, product_interests } = useSelector(state => state.lead_ingestion);
    const productInterests = product_interests.map((item) => ({...item, value:item.name, text:item.name}));
    const mappings = useSelector(state => state.lead_provider.mappings.map(({ name, module, product_interests, method }) => [name, module, product_interests, method]));

    const createFields = () => {        
        return [
            { label: 'Data Record Name', required: true, type: 'input', placeholder: 'Type Data Record Name', defaultValue: '', maxLength: 64, width: 3 },
            { label: 'Module', required: true, type: 'select', placeholder: 'Select Module', defaultValue: '', options: MODULE_OPTIONS, width: 3 },
            { label: 'Product Interests', type: 'checkbox_dropdown', placeholder: 'Select', defaultValue: [], options: productInterests, width: 3 },
            { label: 'Method', required: true, type: 'select', placeholder: 'Select', defaultValue: '', options: METHOD_OPTIONS, width: 2 }
        ]
    };

    useEffect(() => {
        setProviderNameOptions(providers.map(({ id, name }) => ({ key: id, value: name, text: <span> {name} <span className='DataProviderCreate__providerNameType'> (existing provider) </span> </span> })))
    }, [providers]);

    useEffect(() => {
        dispatch(customFieldsGroupsActions.readCustomFieldsParentGroups());
        dispatch(leadProviderActions.readProviders({ limit: 100 }));
        dispatch(leadIngestionActions.readProductInterests());
     
    }, [dispatch]);

    const onAddProviderName = async (_, { value }) => {
        const name = value.replace(/\s+/g, ' ').trim().toLowerCase();
        const provider = providerNameOptions.length !== 0 ? providerNameOptions.find(({ value }) => value.toLowerCase() === name) : '';
        if (provider) {
            setValue("dataProviderName", provider.value);
            return;
        }

        if (!!value?.trim()) {
            const toAdd = { key: value, value, text: value, added: true };
            await setHasProviderAdded(true);
            const providerNameOptionsCopy = [...providerNameOptions];
            const index = providerNameOptions.findIndex(provider => provider.added);
            if (index !== -1) {
                providerNameOptionsCopy[index] = toAdd;
                await setProviderNameOptions(providerNameOptionsCopy);
                await setSearchValue(value);
            } else {
                providerNameOptionsCopy.push(toAdd);
            }
            await setProviderNameOptions(providerNameOptionsCopy);
        }
    }

    const customSearch = (options, query) => {
        return options.filter((opt) => opt.value?.toLowerCase().includes(query.replace(/\s+/g, ' ').trim().toLowerCase()))
    }

    const [mappingsValue, setMappings] = useState([]);
    useEffect(() => {
        const setValues = async () => {

            let provider = providers.find(({ name }) => name === watchedDataProviderName);
            if (provider) {
                setIsExisting(true);
                const { id, category, master_source, secondary_source_id, name } = provider;
                setValue('dataProviderName', name);
                setValue('dataSourceCategory', category);
                setValue('masterSourceCategory', master_source);
                if (master_source) {
                    await dispatch(leadProviderActions.readSecondarySources({ master_source: master_source }))
                } 
                setTimeout(() => {
                    setValue('secondarySourceCategory', secondary_source_id);
                }, 700);

                const { data } = await dispatch(leadProviderActions.readProviderMappings(id));
                const existingMappings = data.map(({ name, module, product_interests=[], method }) => [name, module, product_interests, method]);
                setMappings(existingMappings);
                setKey(id);
            } else {
                let { dataSourceCategory, masterSourceCategory, secondarySourceCategory } = isExisting ? {} : getValues();
                setValue('dataSourceCategory', dataSourceCategory);
                setValue('masterSourceCategory', masterSourceCategory);
                setValue('secondarySourceCategory', secondarySourceCategory);
                setIsExisting(false);
                setMappings([]);
                setKey(0);
            }
        }

        setValues();

    }, [dispatch, watchedDataProviderName, providers, getValues, isExisting, setValue]);

    const onSubmit = async (data) => {
        if (!isExisting) {
            const { dataProviderName, dataSourceCategory, masterSourceCategory, secondarySourceCategory } = checkedSecondStep ? data : getValues();

            /* 
                TODO: Add parser_id and product_interest_id properties on mappings when they are finalize.
            */

            let payload = {
                name: dataProviderName.replace(/\s+/g, ' ').trim(),
                ...(dataSourceCategory ? { category: dataSourceCategory } : {}),
                ...(masterSourceCategory ? { master_source: masterSourceCategory } : {}),
                ...(masterSourceCategory ? { secondary_source_id: secondarySourceCategory || 0 } : {}),
                ...(!checkedSecondStep ? { mappings: data.map(item => ({ name: item.value[0].replace(/\s+/g, ' ').trim(), module: item.value[1], method: item.value[3] })) } : {})
            }

            try {
                await dispatch(leadProviderActions.createProvider(payload));
                reset();
                setIsStep2FormIsDirty(false)
                Notification.alert('Successfully created data provider!', STATUS_TYPES.SUCCESS);
                const query = new URLSearchParams(params);
                query.set('page', 1);
                const query_string = query.toString();
                navigate(`/settings/data-providers${query_string ? `?${query_string}` : ''}`,);
            } catch (e) {
                if (e?.Code !== 409) {
                    Notification.alert(`Failed to create data provider`, STATUS_TYPES.ERROR);
                } else {
                    Notification.alert(`Data provider name already exists`, STATUS_TYPES.ERROR);
                }
            }


        } else {
            let dataRecordsToBeAdded = data.slice(mappings.length, data.length);
            let payload = dataRecordsToBeAdded.map(item => ({ name: item.value[0].replace(/\s+/g, ' ').trim(), module: item.value[1], method: item.value[3] }));
            const { dataProviderName, dataSourceCategory, masterSourceCategory, secondarySourceCategory } = getValues();
            try {
                let provider = providers.find(({ name }) => name === watchedDataProviderName);
                await dispatch(leadProviderActions.updateProvider(provider.id, { name: dataProviderName, category: dataSourceCategory, master_source: masterSourceCategory, secondary_source_id: secondarySourceCategory|| 0 }));
                await dispatch(leadProviderActions.createProviderMappings(payload,'',provider.id));
                reset();
                setIsStep2FormIsDirty(false)
                Notification.alert('Successfully added data records!', STATUS_TYPES.SUCCESS);
                const query = new URLSearchParams(params);
                query.set('page', 1);
                const query_string = query.toString();
                navigate(`/settings/data-providers${query_string ? `?${query_string}` : ''}`,);
            } catch (e) {
                if(e?.Code === 400){
                    Notification.alert(`Lead and opportunity only allowed when mastersource set`, STATUS_TYPES.ERROR);
                } else {
                    Notification.alert(`Failed to add data records`, STATUS_TYPES.ERROR);
                }
            }

        }
    }
    const handleDirtyFields = (isDirty)=>{
        if(isDirty === !isStep2FormIsDirty){
            setIsStep2FormIsDirty(isDirty)
        }
    }

    const isReading = isCreatingProvider || isReadingProductInterests || isReadingMappings;

    const isSaveButtonDisabled = () => {
        return !isValid || isExisting;
    }
    CustomPrompt(null, isDirty || isStep2FormIsDirty, isConfirmed, 'Changes not saved', 'Are you sure you want to exit?');
    return (
        <React.Fragment>
            <ConfirmModal />
            <Dimmer active={isReading}>
                <Loader active={isReading} />
            </Dimmer>
            <Grid className='DataProviderCreate' as={ Form } >
                <Grid.Row>
                    <Grid.Column>
                        <Header>Add New Data Provider</Header>
                    </Grid.Column>

                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <Header as='h5' floated='left' className='DataProviderCreate__step'>
                            <span>
                                Step 1 of 2
                            </span>
                            <span>
                                Add a Data Provider
                            </span>
                        </Header>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row columns='equal'>
                    <Grid.Column width={6} >
                        <Controller
                            name={'dataProviderName'}
                            control={control}
                            defaultValue={''}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'This field should not be empty'
                                },
                                maxLength: {
                                    value: 64,
                                    message: 'This field should contain at most 64 characters'
                                },
                                pattern: {
                                    value: /^[A-Za-z0-9:\-\s]+$/,
                                    message: 'This field should contain only alphanumeric characters'
                                },
                                validate: {
                                    no_excessive_whitespaces: (value) => {
                                        return ((value === undefined || value?.length === 0)) ||
                                            (value?.length !== 0 && !!value?.trim()) ||
                                            'This field cannot only contain white spaces'
                                    }
                                }

                            }}
                            render={({ field: { value, name, onChange }, fieldState: { error } }) => (
                                <Form.Dropdown
                                    className={`DataProviderCreate__dataProviderNameDropdown DataProviderCreate__dataProviderNameDropdown${hasProviderAdded ? '--addedProvider' : ''}`}
                                    value={value}
                                    name={name}
                                    selection
                                    allowAdditions
                                    search={customSearch}
                                    onChange={(_, { value }) => { 
                                        onChange({ target: { name, value } });
                                    }}
                                    onSearchChange={(_, { searchQuery }) => { setSearchValue(searchQuery); }}
                                    required
                                    label='Provider Name'
                                    searchQuery={searchValue}
                                    placeholder='Type Your Own Provider Name or Select an Existing Provider'
                                    options={providerNameOptions}
                                    additionLabel='Add new as your own: '
                                    loading={isReadingProviders}
                                    onAddItem={onAddProviderName}
                                    error={error && error.message && {
                                        content: error.message
                                    }}
                                    selectOnBlur={false}
                                />
                            )}
                        />
                    </Grid.Column>
                    <Grid.Column width={3}>
                        <Controller
                            name={'dataSourceCategory'}
                            control={control}
                            defaultValue={null}
                            render={({ field: { value, name, onChange } }) => (
                                <Form.Field>
                                    <label>Data Source Category</label>
                                    <Form.Select
                                        value={value}
                                        name={name}
                                        onChange={(_, { value }) => { onChange({ target: { name, value } }); }}
                                        placeholder='Select Data Source'
                                        options={DATA_SOURCE_OPTIONS}
                                        clearable
                                    />
                                </Form.Field>

                            )}
                        />
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Controller
                            name={'masterSourceCategory'}
                            control={control}
                            defaultValue={null}
                            render={({ field: { value, name, onChange } }) => (
                                <Form.Field>
                                    <label>
                                        Master Source
                                        <Popup
                                            trigger={<Icon style={{ float: 'right', marginTop: '0.25em' }} name='fas fa-info-circle' color='primary' />}
                                            content='Will only be displayed on leads and opportunities.'
                                            inverted
                                            position='top right' 
                                        />
                                    </label>
                                    <Form.Select
                                        value={value}
                                        name={name}
                                        onChange={(_, { value }) => {
                                            setValue('secondarySourceCategory', null);
                                            onChange({ target: { name, value } });
                                            dispatch(leadProviderActions.readSecondarySources({ master_source: value }))
                                        }}
                                        placeholder='Select Master Source'
                                        options={MASTER_SOURCE_OPTIONS.map(name => ({ key: name, value: name, text: name }))}
                                        clearable
                                    />
                                </Form.Field>
                            )}
                        />
                    </Grid.Column>
                    <Grid.Column width={3}>
                        <SecondarySourceField control={control} watchedMasterSource={watchedMasterSource}  />
                    </Grid.Column>
                </Grid.Row>

                <Divider className='DataProviderCreate__divider' />

                <Grid.Row>
                    <Grid.Column>
                        <Header as='h5' floated='left' className='DataProviderCreate__step'>
                            <span>
                                Step 2 of 2
                            </span>
                            <span>
                                Add a Data Record
                            </span>
                        </Header>
                        <br />
                        <Checkbox label='Skip step 2' className='DataProviderCreate__checkbox' checked={checkedSecondStep} onClick={() => setChecked(!checkedSecondStep)} />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            { !checkedSecondStep &&
                <DynamicFields key={ key } isValid={ isValid } fields={ createFields(watchedMasterSource) } values={ mappingsValue } name='data_record' addLabel='Add Data Record' onAdd={ onSubmit } onDirtyFields={handleDirtyFields} /> 
            }
            {checkedSecondStep && <Portal open>
                <SettingsFooter
                    style={{ width: MathUtils.calculatePercentage(windowWidth, windowWidth - width) }}
                    className={`Webform__menu`}
                    rightOptions={[
                        <Button as={Link} to='/settings/data-providers' status={'cancel'} basic>Cancel</Button>,
                        <Button disabled={isSaveButtonDisabled()} onClick={handleSubmit(onSubmit)} primary>Save</Button>
                    ]}
                />
            </Portal>}
        </React.Fragment>
    );
};

export default DataProviderCreate;
