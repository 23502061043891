import { Button, Modal } from "dyl-components";
import React, { useRef, useState } from "react";
import ReactSignatureCanvas from "react-signature-canvas";

import './index.scss';

export default function SignatureCanvasForm({ open, onClose, name, onChange }) {
    const sigCanvas = useRef(null);
    const [isSigned, setIsSigned] = useState(false);

    function clear() {
        sigCanvas.current.clear();
        setIsSigned(false);
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
        >
            <Modal.Header>
                Add Signature
            </Modal.Header>
            <Modal.Content>
                <div className="SignatureCanvasForm">
                    <ReactSignatureCanvas
                        canvasProps={{ className: "SignatureCanvasForm__canvas" }}
                        ref={sigCanvas}
                        onEnd={() => {
                            const isEmpty = sigCanvas.current.isEmpty();
                            setIsSigned(!isEmpty);
                        }}
                    />
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button basic inverted disabled={!isSigned} onClick={clear}>
                    Clear
                </Button>
                <Button
                    disabled={!isSigned}
                    onClick={(e) => {
                        onChange(e, {
                            name,
                            value: sigCanvas.current
                                .getTrimmedCanvas()
                                .toDataURL("image/png"),
                        });
                        onClose();
                        setIsSigned(false);
                    }}
                >
                    Use
                </Button>
            </Modal.Actions>
        </Modal>
    );
}
