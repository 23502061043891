import { RecordsTable } from 'dyl-components';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import Header from './Header';
import Row from './Row';
import { useSelector } from 'react-redux';

const Table = ({ onFilter, onPageChange, onSelectExternalNumber, onDelete, selectVerifyingPhone, selectedShared, selectedType, selectedForwarding }) => {
    const [params] = useSearchParams();
    const { external_numbers, external_numbers_count, isReadingExternalNumbers } = useSelector((state) => state.pbx_config);

    return <RecordsTable
        tableBodyClassName='NumbersTable'
        isTableLoading={isReadingExternalNumbers}
        isSearchResultEmpty={!external_numbers_count && (params.get('search') || params.get('shared') || params.get('type') || params.get('user_id') || params.get('forwarding') || params.get('timeout_destination'))}
        isTableEmpty={!external_numbers_count && !(params.get('search') || params.get('shared') || params.get('type') || params.get('user_id') || params.get('forwarding') || params.get('timeout_destination'))}
        tableEmptyMessage='No Numbers Added'
        TableHeader={<Header onFilter={onFilter} count={external_numbers_count} numbers={external_numbers} selectedShared={selectedShared} selectedType={selectedType} selectedForwarding={selectedForwarding} />}
        TableRows={external_numbers.map((number) => <Row number={number} onSelectExternalNumber={onSelectExternalNumber} onDelete={onDelete} selectVerifyingPhone={selectVerifyingPhone} />)}
        recordsCount={external_numbers_count}
        activePage={params.get('page')}
        onPageChange={onPageChange}
        recordsName='Number'
    />
}

export default Table;