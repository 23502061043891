import React, { useEffect, useState } from 'react';
import { Form, Icon, Popup, Segment, Input } from 'semantic-ui-react';
import { RichTextEditor, TEXT_EDITOR_MODES, Modal, FileInput, VALIDATORS, generateResolver, Notification, STATUS_TYPES, yup, Button } from 'dyl-components';

import { useForm, Controller } from 'react-hook-form';

import { useDispatch, useSelector } from 'react-redux';

import emailTemplatesActions from 'actions/email_templates';

import FileUtils, { FILE_CATEGORIES } from 'utils/FileUtils';

import './index.scss';
import FullScreenModalPopUp from 'shared/FullScreenModalPopUp';

const EmailTemplateModal = ({
    categoryOptions,
    hasUserSignature,
    hasCompanySignature,
    signature_preference_options = [
        {
            key: 'my_signature',
            value: 'my_signature',
            text: 'My Signature'
        },
        {
            key: 'company_signature',
            value: 'company_signature',
            text: 'Company Signature'
        },
        {
            key: 'none',
            value: 'none',
            text: 'None'
        }
    ],

    editorRef = React.createRef(),
    dropzoneRef = React.createRef(),

    onPreview,
    open,
    isSaving,
    onClose,
    id,
    duplicate_id,
    onUpdate,
    onCreate
}) => {
    
    const [editorMode, setEditorMode] = useState(TEXT_EDITOR_MODES.RICH_TEXT);
    const [isPersonalizing, setPersonalize] = useState(false);
    const [originalTemplateAttachments, setOriginalTemplateAttachments] = useState([]);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

    const onSwitchToHTML = () => {
        setEditorMode(TEXT_EDITOR_MODES.HTML);
    }

    const onSwitchToRichText = () => {
        setEditorMode(TEXT_EDITOR_MODES.RICH_TEXT);
    }

    const isSignatureOptionDisabled = (signature_option) => {
        switch (signature_option) {
            case 'my_signature':
                return !hasUserSignature;
            case 'company_signature':
                return !hasCompanySignature;
            default:
                return false;
        }
    }

    const { control, formState: { isValid , isDirty }, getValues, reset, setValue, setError, trigger, watch } = useForm({
        mode: 'onChange',
        resolver: generateResolver({
            name: VALIDATORS.EMAIL_TEMPLATE_NAME(true).required('This field is required'),
            category: VALIDATORS.EMAIL_TEMPLATE_NAME(true).required('This field is required'),
            subject: yup.string().required('This field is required').maxlength(256),
            attachments: FileUtils.getFilesizeValidator(FILE_CATEGORIES.EMAIL)
        })
    });

    const dispatch = useDispatch();

    const isReading = useSelector(state => state.email_template.isReadingTemplate);


    let current_category_value = watch("category");

    useEffect(() => {
        if (id || duplicate_id) {
            dispatch(emailTemplatesActions.getTemplate(id || duplicate_id)).then((data) => {
                const { content = "", attachments, label, subject, signature_choice, template_category_id } = data;

                // Create the default values object
                const defaultValues = {
                    name: id ? label : `Duplicate - ${label}`,
                    subject,
                    category: template_category_id.id,
                    signature_preference: signature_choice,
                    body: content,
                    attachments: attachments.data.map(attachment => ({
                        path: attachment.name,
                        id: attachment.id,
                        file_id: attachment.file_id,
                        name: attachment.name,
                        size: attachment.size
                    })),
                };

                setOriginalTemplateAttachments(attachments.data.map(({ id }) => ({ id })));

                // Reset the form state with new values (prevents isDirty from being true)
                reset(defaultValues);

                // Manually trigger validation if needed
                trigger('name');
                trigger('subject');
            });
        }
        // eslint-disable-next-line
    }, [dispatch]);
    

    const personalize = (variable) => {
        setPersonalize(false);
        const element = editorRef.current;
        const toInsert = variable;
        if (element.getEditorSelection) {
            const selection = element.getEditorSelection();
            const position = selection ? selection.index : 0;
            element.getEditor().insertText(position, toInsert);
        } else {
            const textarea = element.ref.current;
            textarea.focus();
            const selectionStart = textarea.selectionStart;
            const { body } = getValues();
            const beginning = body.substr(0, selectionStart);
            const end = body.substr(selectionStart);
            setValue("body", `${beginning}${toInsert}${end}`);
        }
    }

    const preview = () => {
        const { subject, signature_preference, body, attachments } = getValues();
        onPreview({ subject, signature_preference, body, attachments });
    }

    const close = () => {
        reset({
            name: '',
            category: null,
            isFavorite: false,
            subject: '',
            signature_preference: 'none',
            body: '',
            attachments: []
        });
        onClose();
    }

    const save = () => {
        const { name, ...state } = getValues();
        const payload = {
            name: name ? name.trim() : '',
            ...state
        }
        if (id) {
            onUpdate(id, payload, originalTemplateAttachments).then(() => {
                close();
            }).catch((error) => {
                if (error?.response?.data?.Code === 409) {
                    if (setError) {
                        setError("name", { type: "unique", message: "Template name already exists!" });
                    }
                }
            });
        } else {
            onCreate(payload).then(() => {
                close();
            }).catch((error) => {
                if (error?.Code === 409) {
                    if (setError) {
                        setError("name", { type: "unique", message: "Template name already exists!" });
                    }
                }
            });
        }
    }

    let modalTitle = 'Create Email Template';
    if (id) {
        modalTitle = 'Edit Email Template'
    }

    return (
        <React.Fragment>
            <Modal open={open} onClose={() => {
                isDirty
                    ? setIsConfirmationOpen(true)
                    : close();
            }}>
                <Modal.Header>{modalTitle}</Modal.Header>
                <Modal.Content>
                    <Form loading={isReading || isSaving}>
                        <Form.Group style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <Controller
                                name="name"
                                control={control}
                                defaultValue={""}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <Form.Input
                                        width={10}
                                        label="Template Name"
                                        onChange={onChange}
                                        value={value}
                                        error={error && error.message && {
                                            content: error.message,
                                            pointing: 'below'
                                        }}
                                        required
                                    />
                                )}
                            />
                            <Controller
                                name="category"
                                control={control}
                                defaultValue={null}
                                rules={{
                                    required: {
                                        message: 'This field is required',
                                        value: true
                                    }
                                }}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <Form.Select
                                        width={5}
                                        label="Category"
                                        onChange={(_, { value: selected }) => { onChange({ target: { name: 'category', value: selected } }) }}
                                        value={value}
                                        error={error && error.message && {
                                            content: error.message,
                                            pointing: 'below'
                                        }}
                                        required

                                        placeholder="Select Category"
                                        selectOnBlur={false}
                                        options={categoryOptions}
                                    />
                                )}
                            />
                            <Controller
                                name="isFavorite"
                                control={control}
                                defaultValue={false}
                                render={({ field: { onChange, value } }) => (
                                    <Form.Field
                                        control={Icon}
                                        onClick={() => { onChange({ target: { name: 'isFavorite', value: !value } }); }}
                                        name={!value ? 'star outline' : 'star'}
                                        link
                                        color='blue'
                                        width={1}
                                        label=" "
                                        style={{ marginTop: "0.75em", fontSize: "1.5em" }}
                                    />
                                )}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Controller
                                name="subject"
                                control={control}
                                defaultValue={''}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <Form.Input
                                        width={10}
                                        label="Subject"
                                        value={value}
                                        onChange={onChange}
                                        error={error && error.message && {
                                            content: error.message,
                                            pointing: 'below'
                                        }}
                                        required

                                        placeholder="Type email subject"
                                    />
                                )}
                            />
                            <Form.Field width={5}>
                                <Form.Field label="Signature" />
                                <Controller
                                    name="signature_preference"
                                    control={control}
                                    defaultValue='none'
                                    render={({ field: { onChange, value } }) => (
                                        <Form.Group style={{ marginTop: "0.75em" }}>
                                            {signature_preference_options.map((option) => (
                                                isSignatureOptionDisabled(option.value) ?
                                                    <Popup trigger={
                                                        <Form.Radio
                                                            key={option.key}
                                                            label={option.text}
                                                            value={option.value}
                                                            checked={value === option.value}
                                                            style={{
                                                                opacity: 0.45
                                                            }}
                                                        />
                                                    }
                                                        content='Signature needs to be configured first before using it'
                                                        inverted
                                                        basic
                                                    />
                                                    :
                                                    <Form.Radio
                                                        key={option.key}
                                                        label={option.text}
                                                        value={option.value}
                                                        checked={value === option.value}
                                                        onChange={() => { onChange({ target: { name: "signature_preference", value: option.value } }) }}
                                                    />
                                            ))}
                                        </Form.Group>
                                    )}
                                />
                            </Form.Field>
                        </Form.Group>
                        <Segment.Group>
                            <Controller
                                name="body"
                                control={control}
                                defaultValue={''}
                                render={({ field: { onChange, value }, }) => (
                                    <RichTextEditor
                                        basic
                                        style={{ marginBottom: 0 }}
                                        onChange={(_, { value: new_value }) => { onChange({ target: { name: 'body', value: new_value } }) }}
                                        value={value}
                                        toolbarClassName={"body_toolbar"}
                                        allowSwitchToHTML
                                        onSwitchToHTML={onSwitchToHTML}
                                        onSwitchToRichText={onSwitchToRichText}
                                        mode={editorMode}
                                        editorRef={editorRef}
                                        {...(categoryOptions.find(({ value }) => value === current_category_value)?.text !== 'Events' && (
                                            {
                                                otherControls: [
                                                    <Popup
                                                        trigger={<Button onClick={() => { setPersonalize(!isPersonalizing) }} primary>Personalize</Button>}
                                                        open={isPersonalizing}
                                                        onClose={() => { setPersonalize(false) }}
                                                        content={(
                                                            <Form className="Template__variables">
                                                                <h5>Sender Variables</h5>
                                                                <Form.Group widths='equal'>
                                                                    <Form.Button onClick={() => { personalize('%%My Full Name%%') }} fluid size='mini' basic primary>My Full Name</Form.Button>
                                                                    <Form.Button onClick={() => { personalize('%%My First Name%%') }} fluid size='mini' basic primary>My First Name</Form.Button>
                                                                </Form.Group>
                                                                <Form.Group widths='equal'>
                                                                    <Form.Button onClick={() => { personalize('%%My Phone%%') }} fluid size='mini' basic primary>My Phone</Form.Button>
                                                                    <Form.Button onClick={() => { personalize('%%My Company%%') }} fluid size='mini' basic primary>My Company</Form.Button>
                                                                </Form.Group>
                                                                <h5>Recipient Variables</h5>
                                                                <Form.Group widths='equal'>
                                                                    <Form.Button onClick={() => { personalize('%%Full Name%%') }} fluid size='mini' basic primary>Full Name</Form.Button>
                                                                    <Form.Button onClick={() => { personalize('%%First Name%%') }} fluid size='mini' basic primary>First Name</Form.Button>
                                                                </Form.Group>
                                                                <Form.Group widths='equal'>
                                                                    <Form.Button onClick={() => { personalize('%%Last Name%%') }} fluid size='mini' basic primary>Last Name</Form.Button>
                                                                    <Form.Button onClick={() => { personalize('%%Phone%%') }} fluid size='mini' basic primary>Phone</Form.Button>
                                                                </Form.Group>
                                                                <Form.Group widths='equal'>
                                                                    <Form.Button onClick={() => { personalize('%%Address%%') }} fluid size='mini' basic primary>Address</Form.Button>
                                                                    <Form.Button onClick={() => { personalize('%%City%%') }} fluid size='mini' basic primary>City</Form.Button>
                                                                </Form.Group>
                                                                <Form.Group widths='equal'>
                                                                    <Form.Button onClick={() => { personalize('%%State%%') }} fluid size='mini' basic primary>State</Form.Button>
                                                                    <Form.Button onClick={() => { personalize('%%Zip%%') }} fluid size='mini' basic primary>Zip</Form.Button>
                                                                </Form.Group>
                                                            </Form>
                                                        )}
                                                        position='bottom right'
                                                        on='click'
                                                    />
                                                ]
                                            }
                                        ))}
                                    />
                                )}
                            />
                            <Controller
                                name="attachments"
                                defaultValue={[]}
                                control={control}
                                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                    <Form.Field
                                        control={Input}
                                        error={error && error.message && {
                                            content: error.message,
                                            pointing: 'below'
                                        }}
                                        className='Template__attachments-section'
                                    >
                                        <Segment style={{width: '100%'}}>
                                        <div>
                                            <Icon onClick={() => { dropzoneRef.current.open() }} color='blue' name='plus circle' link /> <b>Attach {value.length > 0 && 'more '}files</b>
                                        </div>
                                        <div className='Template__attachments' style={{marginBottom: 10}}>
                                            <FileInput
                                                onChange={(_, { value: new_value }) => {
                                                    onChange({
                                                        target: {
                                                            name, value: [
                                                                ...value,
                                                                ...new_value.filter(new_file => (
                                                                    value.findIndex(file => file.path === new_file.path) === -1
                                                                ))
                                                            ]
                                                        }
                                                    })
                                                }}
                                                onRemove={(_, { value }) => { onChange({ target: { name, value } }) }}
                                                onReject={(rejected) => {
                                                    if (rejected.length > 0) {
                                                        Notification.alert("File type must be .pdf.", STATUS_TYPES.ERROR, true);
                                                    }
                                                }}
                                                files={value}
                                                name="files"
                                                accept="application/pdf"
                                                icon="file pdf outline"
                                                size="mini"
                                                dropzoneRef={dropzoneRef}
                                                showIcon
                                                hasBrowse
                                            />
                                        </div>
                                        <i>Remaining: {FileUtils.getRemainingSize(value, FILE_CATEGORIES.EMAIL)} MB </i> {error?.message && `(${error.message})`}
                                        </Segment>
                                    </Form.Field>
                                )}
                            />
                        </Segment.Group>
                    </Form>
                </Modal.Content>
                <Modal.Actions
                    hasSaveButton
                    onSave={save}
                    saveDisabled={!isValid}
                    saveOptions={{loading: isSaving}}
                >
                    {!isSaving && <Button basic onClick={preview}>Preview</Button>}
                </Modal.Actions>
            </Modal>
            <FullScreenModalPopUp
                header={"Changes not saved"}
                subheader={"Are you sure you want to exit?"}
                isOpen={isConfirmationOpen}
                onConfirm={() => {
                    close();
                    setIsConfirmationOpen(false);
                }}
                onFormClose={() => setIsConfirmationOpen(false)}
                closeIcon={false}
            />
        </React.Fragment>
    );
};

export default EmailTemplateModal;
