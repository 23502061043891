import React, { useState } from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import Avatar from 'react-avatar';
import { useSelector } from 'react-redux';
import NotesModal from 'shared/modals/NotesModal';
import NewActivityForm from 'shared/forms/NewActivity';
import SendEmailModal from 'shared/modals/SendEmailModal';
import './index.scss';
import { ClippedContent, MessagingIcon } from 'dyl-components';

const Account = ({ name, id, linkToAccount, isActionsVisible, account, isModalOutside = false }) => {

    const [modal, setModal] = useState("");
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);

    const emailIntegrations = useSelector(state => state.oauth_integrations.integrations);

    const canEmail = Boolean(account.email?.id);
    const hasEmailIntegrations = emailIntegrations.length > 0;    

    const getModal = () => {
        switch (modal) {
            case "email":
                return <SendEmailModal
                    open={modal === "email"}
                    onClose={() => { setModal("") }}
                    account_id={account?.id}
                    isAccountModal={true}
                    account={account}
                />;
            case "note":
                return <NotesModal
                    open
                    onClose={() => { setModal("") }}
                    account={account}
                />;
            default:
                <React.Fragment />
        }
    }


    return (
        <>
            <div className='AccountComponent'>
                {name && <Avatar name={name} maxInitials={2} round size='3em' />}
                <div className="AccountComponent__information">
                    <span className='AccountComponent__Link'>
                        <ClippedContent>{linkToAccount}</ClippedContent>
                        <span className="AccountComponent__Subtitle">{id && `ID: ${id}`}</span>
                    </span>
                    {isActionsVisible ? (
                        <div className='AccountComponent__Actions'>
                            <Icon className='fa-solid fa-phone' link />
                            <Icon className='fa-solid fa-messages' link />

                            {canEmail ?
                                hasEmailIntegrations ? <Icon className='fa-solid fa-envelope' link onClick={async () => {
                                    setModal("email");
                                }} /> :
                                    <Popup
                                        className="AddForm"
                                        trigger={
                                            <span style={{ marginRight: 10 }}>
                                                <MessagingIcon size='' icon={'mail'} onClick={() => {
                                                    setIsAddModalOpen(true);
                                                }} />
                                            </span>
                                        }
                                        content={
                                            <NewActivityForm readTimeline={() => { }} emailOnly={true} contact={account} onClose={() => {
                                                setIsAddModalOpen(false);
                                            }} />
                                        }
                                        open={isAddModalOpen}
                                        on="click"
                                        position="bottom left"
                                        pinned
                                        basic
                                        wide
                                    />
                                : <Icon className='fa-solid fa-envelope' link disabled />
                            }

                            <Icon className='fa-sharp fa-solid fa-pen-clip' link onClick={() => { setModal("note") }} />
                        </div>
                    ) : (
                        <div className='AccountComponent__MasterAccountActions'>
                            <Icon className='fa-sharp fa-solid fa-pen-clip' link onClick={() => { setModal("note") }} />
                        </div>
                    )}
                </div>
            </div>
            {!isModalOutside && getModal()}

        </>
    );
}

export default Account;
