import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './index.scss';
import { Header, Icon } from 'semantic-ui-react';
import { OfficeViewContainer, OfficeViewHeader, OfficeViewLeftPanel, OfficeViewDialPad, generateResolver, yup, OfficeViewPhoneMiddle, OfficeViewCompanyDestinations, OfficeViewTeams, Notification, STATUS_TYPES, OfficeViewRecents } from 'dyl-components';
import { useForm } from 'react-hook-form';
import officeView from 'actions/office_view';
import callTypes from "actions/office_view/types";
import contactsActions from 'actions/contacts';
import CallActions  from 'actions/call'
import pbxConfigActions from 'actions/pbx_config';
import userActions from 'actions/user';
import SendEmailModal from 'shared/modals/SendEmailModal';
import AddTaskModal from 'shared/modals/AddTaskModal';
import useTaskForm from 'utils/useTaskForm';
import oauthActions from 'actions/oauth';
import eventActions from "actions/event";
import groupsActions from "actions/groups";
import eventAttendeeActions from "actions/event_attendee";
import eventAttachmentActions from "actions/event_attachment";
import accountActions from 'actions/account';
import uploadActions from "actions/upload";
import officeViewActions from "actions/office_view";
import AddEventModal from 'shared/modals/AddEventModal';
import useEventForm from 'utils/useEventForm';
import Utils from 'shared/EventForm/Utils';
import { FILE_CATEGORIES } from "utils/FileUtils";
import NewActivityForm from 'shared/forms/NewActivity';
import callHistoryActions from 'actions/call_history';
import { useNavigate } from 'react-router-dom';

const LIMIT = 15;
const PINNED_COMPANY_DESTINATIONS_LIMIT = 3;

const OfficeView = ({ isActive, onClose, phone }) => {
    const [search, setSearch] = useState("");
    const [searchMade, setSearchMade] = useState("");
    const [isSearchResultsVisible, setIsSearchResultsVisible] = useState(false);
    const [page, setPage] = useState(1);
    const dispatch = useDispatch();
    const [isDialDisabled, setIsDialDisabled] = useState(false);
    const [isCompanyExtensionExpanded, setIsCompanyExtensionExpanded] = useState(false);
    const [displayedCompanyDestinations, setDisplayedCompanyDestinations] = useState(false);
    const [isPinnedDestinationsMaxed, setIsPinnedDestinationsMaxed] = useState(false);
    const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
    const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
    const [isEventModalOpen, setIsEventModalOpen] = useState(false);
    const [teamsSearchResults, setTeamsSearchResults] = useState([]);
    const [isLogActivityModalOpen, setIsLogActivityModalOpen] = useState(false);
    const [callRecording, setCallRecording] = useState({});
    const navigate = useNavigate();

    const { 
        current_user,
        isSearchingContacts,
        contacts_searched_count,
        contact_lookup,
        viewDestinations,
        isReadingViewDestinations,
        activeIntegration,
        task_labels,
        organizer_id,
        organizer,
        organizer_email,
        isReadingExtensionsTeamsList,
        contactsInHotlistOfficeView,
        accountContacts,
        extensionsTeamsList,
        office_view_teams,
        isReadingOfficeViewTeams,
        selectedTeamsTab,
        pinnedExtensionsWithUsers,
        isReadingPinnedExtensionsWithUsers,
        extensionsByTeam,
        isReadingExtensionsByTeam,
        // extensions, //TODO: might need for populating destinations
        calls,
        callState,
        isRinging,
        currentPBX,
        userLeg,
        contactLeg,
        currentCall,
        duration,
        callRecordings,
        currentCallRecording,
        currentCallPhoneNumber,
        userCurrentCallStatus,
        callDirection,
        userCurrentCallDirection,
        cnam,
        actionButtons,
        recentCalls,
        isReadingRecentCalls,
        isReadingRecentCall,
        recentCall,
        selectedTab
        // userCalls //TODO: additional lines
    } = useSelector((state) => {
        const {
            first_name = "",
            last_name = "",
            email: organizer_email,
        } = state.users.userProfile;
        const userPhoneCreds = state.officeView.creds.extension;
        const userCurrentCall = state.officeView?.calls[state.officeView.currentCall]
        const userLeg = userCurrentCall?.legs?.find(l => l.extension === userPhoneCreds);
        const contactLeg = userCurrentCall?.legs?.find(l => !l.extension);
        const calls = state.officeView.calls;
        const userCalls = [];
        for (const key in calls) {
            if (calls.hasOwnProperty(key)) {
                if (state.officeView.extensions[userPhoneCreds]?.includes(key)) {
                    userCalls.push(calls[key])
                }
            }
        }
        return {
            current_user: state.auth,
            viewDestinations: state.pbx_config.viewDestinations,
            isReadingViewDestinations: state.pbx_config.isReadingViewDestinations,
            activeIntegration: state.oauth_integrations.activeIntegration,
            task_labels: state.task.task_type_labels.map(({ name, id }) => ({
                key: id,
                value: id,
                text: name,
            })),
            organizer_id: state.auth.user_id,
            organizer: `${first_name} ${last_name}`,
            organizer_email,
            isReadingExtensionsTeamsList: state.pbx_config.isReadingExtensionsTeamsList,
            contactsInHotlistOfficeView: state.groups.contactsInHotlistOfficeView,
            accountContacts: state.account.contactIds.map(({ first_name, last_name, email, suffix, id }) => ({
                text: `${first_name || ''} ${last_name || ''}${suffix ? `, ${suffix}` : ''}`,
                email,
                key: id,
                value: id
            })),
            extensionsTeamsList: state.pbx_config.extensionsTeamsList,
            office_view_teams: state.user.office_view_teams,
            isReadingOfficeViewTeams: state.user.isReadingOfficeViewTeams,
            selectedTeamsTab: state.officeView.selectedTeamsTab,
            pinnedExtensionsWithUsers: state.pbx_config.pinnedExtensionsWithUsers,
            isReadingPinnedExtensionsWithUsers: state.pbx_config.isReadingPinnedExtensionsWithUsers,
            extensionsByTeam: state.pbx_config.extensionsByTeam,
            isReadingExtensionsByTeam: state.pbx_config.isReadingExtensionsByTeam,

            // View vars
            calls: state.officeView.calls,
            extensions: state.officeView?.extensions,
            // Current call vars
            currentCall: state.officeView?.currentCall,
            userLeg: userLeg,
            userCurrentCallStatus: userCurrentCall?.status,
            contactLeg: contactLeg,
            currentPBX: userCurrentCall?.pbx,
            callState: state.officeView.callState,
            isRinging: state.officeView.callState === "ringing",
            // Search state vars
            isSearchingContacts: state.contacts.isSearchingContacts,
            contacts_searched_count: state.contacts.contacts_searched_count,
            contact_lookup: state.contacts.contact_lookup,
            duration: state.officeView.duration,
            callRecordings: state.officeView.callRecordings,
            currentCallRecording: state.officeView.currentCallRecording,
            currentCallPhoneNumber: state.officeView.currentCallPhoneNumber,
            callDirection: state.officeView.callDirection,
            userCurrentCallDirection: userCurrentCall?.direction,
            cnam: state.officeView.cnam,
            actionButtons: state.officeView.actionButtons,
            userCalls,
            recentCalls: state.call_history.recent_calls,
            isReadingRecentCalls: state.call_history.isReadingRecentCalls,
            recentCall: state.call_history.recent_call,
            isReadingRecentCall: state.call_history.isReadingRecentCall,
            selectedTab: state.officeView.selectedTab
        }
    });

    const {
        control: eventControl,
        watch: eventWatch,
        isValid: eventIsValid,
        isDirty,
        trigger: eventTrigger,
        setValue: eventSetValue,
        getValues,
        reset,
        isAllowedToModify,
        eventBeingEdited,
        loadEvent,
        handleSubmit: handleSubmitEvent,
        resetField,
    } = useEventForm({
        ...((selectedTab === 0 && contact_lookup?.contact_id) ? { contact_id: contact_lookup.contact_id } : recentCall?.contact_id && { contact_id: recentCall.contact_id })
    });

    const {
        control: taskControl,
        watch: taskWatch,
        isValid: taskIsValid,
        isDirty: taskIsDirty,
        trigger: taskTrigger,
        setValue: taskSetValue,
        getValues: taskGetValues,
        clearErrors: taskClearErrors,
        setError: taskSetError,
        reset: taskReset,
        taskBeingEdited,
        loadTask,
        addTask,
        state,
        setState,
        onDelete: onDeleteTask,
        onUpdate: onUpdateTask,
        handleSubmit,
        isAllowedToModify: isTaskAllowedToModify
    } = useTaskForm({
        isContact: false,
        isModal: true,
        open: isTaskModalOpen,
    });

    const pages = [
            { id: 0, icon: "fa-solid fa-phone" },
            { id: 1, icon: "fa-solid fa-user-clock" },
            { id: 2, icon: "fa-solid fa-comments" },
            { id: 3, icon: "fa-solid fa-gear" }
    ];

    const { formState: { isValid }, control, watch, setValue, trigger } = useForm({
        mode: 'onChange',
        defaultValues: {
            dial: "",
        },
        resolver: generateResolver({
            dial: yup.string().minlength(3).required("")
        })
    });

    const dialedNumber = watch("dial");

    const setSelectedTab = async (newTab) => {
        await dispatch(officeView.setSelectedTab('SET_SELECTED_TAB', newTab));
        if (newTab === 1) {
            await dispatch(callHistoryActions.getRecentCalls());
        }
    }

    const onChangeSearch = (_, { value }) => {
        setSearch(value);
        if (!value) {
            setIsSearchResultsVisible(false);
        }
    }

    const searchFunction = async () => {
        try {
            setPage(1);
            setSearchMade(search);
            setIsSearchResultsVisible(true);
            await dispatch(contactsActions.contactSearch({ page: 1, search, limit: LIMIT }));
            await dispatch(officeView.currentCallPhoneNumber('CALL_CURRENT_NUMBER', null));
        } catch (e) {
            console.log(e)
        }
    }

    const onPersonSelect = async (id) => {
        try {
            await dispatch(contactsActions.viewLookup(id));
            await dispatch(officeView.call(callTypes.IDLE_CALL));
            setPage(1);
            setSearch('');
            setIsSearchResultsVisible(false);
            setSearchMade("");            
        } catch (error) {
            console.log(error)
        }
    }

    const handleNumberClick = (number) => {
        setValue('dial', number);
        trigger('dial');
        dispatch(officeView.currentCallPhoneNumber('CALL_CURRENT_NUMBER', number));
    }

    const cancelFunction = async () => {
        setSearch("");
        setPage(1);
        setIsSearchResultsVisible(false);
        setSearchMade("");
    }

    const onPageChange = async (_, { activePage }) => {
        await dispatch(contactsActions.contactSearch({ page: activePage, search, limit: LIMIT }));
        setIsSearchResultsVisible(true);
        setPage(activePage);
    }

    const onRemovePerson = async () => {
        await dispatch(contactsActions.removeViewLookup());
        await dispatch(officeView.currentCallPhoneNumber('CALL_CURRENT_NUMBER', null));
        await dispatch(officeView.call(callTypes.IDLE_CALL));
        setPage(1);
        setSearch('');
        setIsSearchResultsVisible(false);
        setSearchMade("");  
        setValue('dial', "");
        trigger('dial');
    }

    const onCloseRecentCall = async () => {
        await dispatch(callHistoryActions.removeRecentCall());
    }

    const handleClose = useCallback(() => {
        cancelFunction();
        onClose();
    }, [onClose])

    const toggleIsExpanded = async () => {
        setIsCompanyExtensionExpanded((prevValue) => !prevValue)
        await dispatch(pbxConfigActions.viewDestinations());
    }

    const togglePinned = async (destination_id, pinned) => {
        try {
            await dispatch(pbxConfigActions.setPinned({ destination_id, pinned }));
            await dispatch(pbxConfigActions.viewDestinations());
            Notification.alert(`Company destination ${pinned ? "pinned" : "unpinned"} successfully`, STATUS_TYPES.SUCCESS);
        } catch (error) {
            Notification.alert(`Failed to ${pinned ? "pin" : "unpin"} company destination`, STATUS_TYPES.ERROR);
            console.log(error)
        }
    }

    const togglePinnedTeams = async (destination_id, pinned) => {
        try {
            await dispatch(pbxConfigActions.setPinned({ destination_id, pinned }));
            if (selectedTeamsTab === "pinned") {
                await dispatch(pbxConfigActions.getPinnedExtensionsWithUsers());
            } else {
                await dispatch(pbxConfigActions.getExtensionsByTeam({team_id: Number(selectedTeamsTab)}));
            }
            Notification.alert(`User ${pinned ? "pinned" : "unpinned"} successfully`, STATUS_TYPES.SUCCESS);
        } catch (error) {
            Notification.alert(`Failed to ${pinned ? "pin" : "unpin"} user`, STATUS_TYPES.ERROR);
            console.log(error)
        }
    }

    const onEmailClick = () => {
        setIsEmailModalOpen(true);
    }

    const onTaskClick = () => {
        loadTask({
            contact: selectedTab === 0 ? contact_lookup.contact_id : recentCall?.contact_id
        });
        setIsTaskModalOpen(true);
    }

    const onEventClick = () => {
        loadEvent(null);
        setIsEventModalOpen(true);
    }

    const onAddEvent = async (event) => {
        const id = await dispatch(eventActions.addEvent(Utils.toPayload(event)));
        return id;
    };
    
    const onAddAttendees = (event_id, params) => {
        return dispatch(
            eventAttendeeActions.addAttendees(params, null, event_id)
        );
    };

    const addAttendees = (event_id, usersToAdd) => {
        let params = {
            payload: usersToAdd,
            integration_id: null,
            access_token: null,
        };
        if (usersToAdd.length > 0) {
            return onAddAttendees(event_id, params);
        }
        return Promise.resolve();
    };
    
    const onUpload = (file, file_type) => {
        return dispatch(uploadActions.uploadFiles(file, file_type));
    };

    const addAttachments = async (files, event_id) => {
        if (files && files.length > 0) {
            const toUpload = files.filter((file) => file.id === undefined);
            const toCopy = files.filter(file => file.id).map(file => ({
                file_id: file.file_id,
                name: file.name,
                size: file.size
            }));
            const uploadedFiles = await onUpload(toUpload, FILE_CATEGORIES.EVENT);
            const newFiles = toUpload.map((_, idx) => {    
                return {
                    name: files[idx].name,
                    file_id: uploadedFiles[idx],
                    size: files[idx].size
                }})
            return onAddAttachments(
                [
                    ...newFiles,
                    ...toCopy,
                ],
                current_user.user_id,
                event_id
            );
        }
        return Promise.resolve();
    };

    const onAddAttachments = (attachments, user_id, event_id) => {
        return dispatch(
            eventAttachmentActions.addAttachments(
                attachments,
                { user_id },
                event_id
            )
        );
    };

    const addEvent = async () => {
        const values = getValues();
        const {
            all_day,
            start_date,
            start_time,
            end_date,
            end_time,
            users,
            attachments,
            contacts,
            timezone,
            related_to
        } = values;

        const attendees = [
            ...contacts.map((contact_id) => ({
                accepted: "no",
                contact_id,
                emailed: false
            })),
            ...users.map((user_id) => ({
                accepted: "no",
                user_id,
                emailed: false
            })),
        ];

        // TODO: add recurring event properties
        const payload = {
            complete: false,
            conference_phone: values.phone_number,
            conference_pin: values.pin,
            conference_url: values.conference_line,
            content: values.content,
            content_type: "text/html",
            label: values.label ? values.label : 0,
            location: values.location,
            name: values.name,
            all_day,
            start_date,
            start_time,
            end_date,
            end_time,
            // "recurring_event_id": 0,
            organizer_id,
            organizer,
            organizer_email,
            users: values.users,
            contact_id: values.contacts,
            timezone,
            related_to
        };

        try {
            const { id } = await onAddEvent(payload);
            await addAttendees(id, attendees);
            await addAttachments(attachments, id, "pdf");
            setIsEventModalOpen(false);
            Notification.alert(
                "Successfully created event!",
                STATUS_TYPES.SUCCESS
            );
        } catch (e) {
            console.log(e);
            Notification.alert("Failed to create event", STATUS_TYPES.ERROR);
        }
    };

    const onHotlistClick = async () => {
        try {
            if (contactsInHotlistOfficeView.includes(selectedTab === 0 ? contact_lookup?.contact_id : recentCall?.contacts?.[0]?.contact_id)) {
                await dispatch(groupsActions.removeHotlist(selectedTab === 0 ? contact_lookup?.contact_id : recentCall?.contacts?.[0]?.contact_id));
                await dispatch(groupsActions.checkHotlistOfficeView({ contacts: [selectedTab === 0 ? contact_lookup?.contact_id : recentCall?.contacts?.[0]?.contact_id] }));
                Notification.alert('Successfully removed contact from hotlist!', STATUS_TYPES.SUCCESS, true);
            } else {
                await dispatch(groupsActions.addToHotlist({ contact_id: [selectedTab === 0 ? contact_lookup?.contact_id : recentCall?.contacts?.[0]?.contact_id] }));
                await dispatch(groupsActions.checkHotlistOfficeView({ contacts: [selectedTab === 0 ? contact_lookup?.contact_id : recentCall?.contacts?.[0]?.contact_id] }));
                Notification.alert('Successfully added contact to hotlist!', STATUS_TYPES.SUCCESS, true);
            }
        } catch (error) {
            console.log(error)
            if (contactsInHotlistOfficeView.includes(selectedTab === 0 ? contact_lookup?.contact_id : recentCall?.contacts?.[0]?.contact_id)) {
                Notification.alert('Problem removing contact from hotlist!', STATUS_TYPES.ERROR, true);
            } else {
                Notification.alert('Problem adding contact to hotlist!', STATUS_TYPES.ERROR, true);
            }
        }
    }

    const getTeamsUsers = async (search) => {
        await dispatch(pbxConfigActions.getUsersWithExtensionsAndTeams({ search }));
    }

    const onChangeSelectedTab = async (id) => {
        await dispatch(officeViewActions.changeTeamsTab(id));
    }

    const currentSIPCallID = () => {
        return calls[currentCall]?.sip_call_id;
    }

    const currentCallUUID = () => {
        return userLeg?.channel_uuid;
    }
    
    const hangup = () => {
        // phone.current.HangUp(); //May add in future, incase BE endpoint fails
        dispatch(officeView.hangup({ pbx_hostname: currentPBX, channel_uuid: currentCallUUID() }));
        dispatch(officeView.actionButtons('CALL_ACTION_BUTTONS', { hangup: !actionButtons.hangup }));
    }

    const ignore = () => {
        hangup();
        dispatch(officeView.onClosePhoneNotifications("CLOSE_CALL_NOTIFICATIONS", currentCallUUID()));
        dispatch(officeView.ignore('CALL_IGNORE', true))
        dispatch(contactsActions.removeViewLookup());
    }

    const transfer = () => {
        dispatch(CallActions.transfer({
            pbx_hostname: currentPBX,
            channel_uuid: contactLeg,
            transfer_to: "voicemail_399",
            caller_id_name: "hello"
        }));
        dispatch(officeView.actionButtons('CALL_ACTION_BUTTONS', { transfer: !actionButtons.transfer }));
    }

    const onHold = () => {
        phone.current.phoneHoldButtonPressed(userCurrentCallDirection === "outbound" ? currentSIPCallID() : currentCallUUID());
        dispatch(officeView.actionButtons('CALL_ACTION_BUTTONS', { hold: !actionButtons.hold }));
    }

    const onOutboundDial = () => {
        if(!(Object.keys(phone?.current).length === 0)){
            phone.current.sipCall(dialedNumber)
            dispatch(officeView.call(callTypes.RINGING_CALL));
        }
        dispatch(officeView.currentCallPhoneNumber('CALL_CURRENT_NUMBER', dialedNumber));
    }

    const onKeyPress = (number) => {
        phone.current.sipSendDTMF(number);
    }

    const mute = () => {
        phone.current.phoneMuteButtonPressed(userCurrentCallDirection === "outbound" ? currentSIPCallID() : currentCallUUID());
        dispatch(officeView.actionButtons('CALL_ACTION_BUTTONS', { mute: !actionButtons.mute }));
    }

    const answer = () => {
        phone.current.Answer(currentCallUUID());
        dispatch(officeView.actionButtons('CALL_ACTION_BUTTONS', { answer: !actionButtons.answer }));
        dispatch(officeView.onClosePhoneNotifications("CLOSE_CALL_NOTIFICATIONS", currentCallUUID()));
    }

    const vmDrop = () => {
        dispatch(officeView.actionButtons('CALL_ACTION_BUTTONS', { vmDrop: !actionButtons.vmDrop }));
    }

    const onRecentCallClick = async (call_uuid) => {
        await dispatch(callHistoryActions.getRecentCall(call_uuid));
    }

    const onViewMoreClick = () => {
        navigate("/reports/pbx-log");
        handleClose();
    }

    useEffect(() => {
        let tickerId;
        if(callState === "idle"){
            dispatch(officeView.currentCallTicker('CALL_CURRENT_TICKER', true));
        }
        if(callState === "active"){
            tickerId = setInterval(() => {
                dispatch(officeView.currentCallTicker('CALL_CURRENT_TICKER'));
            }, 1000);
        }
        
        return () => clearInterval(tickerId);
    }, [callState, dispatch]);
    
    useEffect(() => {
        if(currentCallPhoneNumber && callDirection !== "inbound"){
            setValue('dial', currentCallPhoneNumber);
            setIsDialDisabled(false);
            trigger('dial');
            return;
        }
        if (contact_lookup) {
            if (contact_lookup?.contact_id) {
                dispatch(groupsActions.checkHotlistOfficeView({ contacts: [contact_lookup?.contact_id] }));
                if (contact_lookup.account_id) {
                    dispatch(accountActions.readContactIds(contact_lookup.account_id));
                }
            }
            const number = contact_lookup.contact_id ? (
                contact_lookup.contact_details?.phone?.[0]?.phone || null
            ) : (
                contact_lookup.account_details?.phone?.[0]?.phone || null
            )
            
            if (number) {
                if(callDirection !== "inbound"){
                    setValue('dial', number);
                    trigger('dial');
                }
                setIsDialDisabled(false);
            } else {
                setValue('dial', "");
                setIsDialDisabled(true);
            }
        } else {
            setValue('dial', "");
            setIsDialDisabled(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contact_lookup, setValue, trigger, dispatch, currentCallPhoneNumber])

    useEffect(() => {
        if (!isActive) {
            handleClose();
        }
    }, [isActive, handleClose])

    useEffect(() => {
        dispatch(pbxConfigActions.viewDestinations());
        dispatch(oauthActions.getIntegrations({ scopes: 'email', include_disabled: false }));
        dispatch(userActions.getOfficeViewTeams());
    }, [dispatch])

    useEffect(() => {
        const pinnedDestinations = viewDestinations.filter(({ pinned }) => !!pinned);
        const isPinnedDestinationsFull = pinnedDestinations.length >= PINNED_COMPANY_DESTINATIONS_LIMIT;
        setIsPinnedDestinationsMaxed(isPinnedDestinationsFull);
        if (isCompanyExtensionExpanded) {
            setDisplayedCompanyDestinations(viewDestinations)
        } else {
            setDisplayedCompanyDestinations(pinnedDestinations);
        }
    }, [viewDestinations, isCompanyExtensionExpanded])

    useEffect(() => {
        if (extensionsTeamsList && extensionsTeamsList?.length > 0) {
            setTeamsSearchResults(extensionsTeamsList)
        } else {
            setTeamsSearchResults([])
        }
    }, [extensionsTeamsList])

    useEffect(() => {
        if (selectedTeamsTab !== "pinned") {
            dispatch(pbxConfigActions.getExtensionsByTeam({team_id: Number(selectedTeamsTab)}));
        }
        dispatch(pbxConfigActions.getPinnedExtensionsWithUsers());
    }, [selectedTeamsTab, dispatch])

    useEffect(() => {
        const callRecord = callRecordings.find(callRecording => callRecording.call_uuid === currentCallRecording)
        setCallRecording(callRecord);
    }, [callRecordings, currentCallRecording])

    useEffect(() => {
        if(contactLeg && userLeg){
            dispatch(officeView.currentDirection('CALL_DIRECTION', userCurrentCallDirection))
            if(userCurrentCallStatus === "ringing"){
                dispatch(officeView.call(callTypes.RINGING_CALL));
                dispatch(officeView.actionButtons('CALL_ACTION_BUTTONS', { hangup: false, vmDrop: false, transfer: false, hold: false, mute: false, answer: false }));
                if(userCurrentCallDirection === "inbound"){
                    dispatch(officeView.currentCallPhoneNumber('CALL_CURRENT_NUMBER', contactLeg?.phone));
                    dispatch(officeView.cnam('CALL_CNAM', userLeg?.name));
                }
            }
            if(userCurrentCallStatus === "active"){
                dispatch(officeView.call(callTypes.ACTIVE_CALL));
            }
            if(userCurrentCallStatus === "hangup"){
                dispatch(officeView.call(callTypes.HANGUP_CALL));
            }
            if(userCurrentCallStatus === "ignore"){
                dispatch(officeView.call(callTypes.IGNORE_CALL));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contactLeg, userLeg, userCurrentCallStatus, dispatch])

    useEffect(()=>{
        if(contactLeg && userLeg){
            if(userCurrentCallStatus !== "ringing"){
                return;
            }
            if(userCurrentCallStatus !== "ignore"){
                dispatch(contactsActions.viewLookup(contactLeg?.contact_id ? contactLeg?.contact_id : contactLeg?.account_id))
            } 
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userCurrentCallStatus])

    return (
        <div style={{padding: 30}}>
            <div style={{display: 'flex', marginBottom: 10}}>
                <Header style={{flex: 1}} as={"h1"}>Office View</Header>
                <Icon className='fa-solid fa-xmark closeIcon' size="big" onClick={handleClose} />
            </div>
            <div style={{ marginBottom: 25 }}>
                <OfficeViewContainer
                    header={ <OfficeViewHeader state={callState} phoneNumber={currentCallPhoneNumber} currentCallTicker={calls[currentCall]?.timeTicker} callDirection={callDirection}/> }
                    leftPanel={ <OfficeViewLeftPanel pages={pages} selectedTab={selectedTab} setSelectedTab={setSelectedTab} /> }
                    rightPanel={ 
                        <OfficeViewDialPad 
                            control={control} 
                            dialedNumber={dialedNumber} 
                            dialDisabled={isDialDisabled} 
                            dialButtonDisabled={!isValid || isRinging || callState === "active"} 
                            onDial={onOutboundDial} 
                            onKeyPress={onKeyPress}
                        /> 
                    }
                >
                    {selectedTab === 0 && (
                        <OfficeViewPhoneMiddle 
                            pages={pages} 
                            selectedTab={selectedTab} 
                            onNumberClick={handleNumberClick}
                            search={searchMade}
                            isSearchResultsVisible={isSearchResultsVisible}
                            page={page}
                            isSearchingContacts={isSearchingContacts}
                            contacts_searched_count={contacts_searched_count}
                            contact_lookup={contact_lookup}
                            cancelFunction={cancelFunction}
                            searchFunction={searchFunction}
                            onChangeSearch={onChangeSearch}
                            onPersonSelect={onPersonSelect}
                            onPageChange={onPageChange}
                            onRemovePerson={onRemovePerson}
                            callState={callState}
                            control={control}
                            hangup={hangup}
                            // hangupDisabled={hangupDisabled}
                            vmDrop={vmDrop}
                            vmDropDisabled={isRinging}
                            transfer={transfer}
                            transferDisabled={isRinging}
                            hold={onHold}
                            holdDisabled={isRinging}
                            mute={mute}
                            muteDisabled={isRinging}
                            onSaveDisabled={isRinging}
                            callResultsDisabled={isRinging}
                            callTagDisabled={isRinging}
                            relatedToDisabled={isRinging}
                            currentCallPhoneNumber={currentCallPhoneNumber}
                            duration={duration}
                            callRecording={callRecording}
                            onEmailClick={onEmailClick}
                            onTaskClick={onTaskClick}
                            onEventClick={onEventClick}
                            onHotlistClick={onHotlistClick}
                            hasIntegration={Object.keys(activeIntegration || {}).length > 0}
                            isInHotlist={contactsInHotlistOfficeView.includes(contact_lookup?.contact_id)}
                            isLogActivityModalOpen={isLogActivityModalOpen}
                            setIsLogActivityModalOpen={setIsLogActivityModalOpen}
                            logActivityForm={(contact) => {
                                return <NewActivityForm readTimeline={() => {}} emailOnly={true} contact={contact} onClose={() => setIsLogActivityModalOpen(false)} />
                            }}
                            answer={answer}
                            ignore={ignore}
                            callDirection={callDirection}
                            cnam={cnam}
                            actionButtons={actionButtons}
                        />      
                    )}
                    {selectedTab === 1 && (
                        <OfficeViewRecents 
                            recents={recentCalls}
                            count={recentCalls.length}
                            onRecentCallClick={onRecentCallClick}
                            recentCall={recentCall}
                            onCloseRecentCall={onCloseRecentCall}
                            onEmailClick={onEmailClick}
                            setIsLogActivityModalOpen={setIsLogActivityModalOpen}
                            logActivityForm={(contact) => {
                                return <NewActivityForm readTimeline={() => {}} emailOnly={true} contact={contact} onClose={() => setIsLogActivityModalOpen(false)} />
                            }}
                            hasIntegration={Object.keys(activeIntegration || {}).length > 0}
                            onTaskClick={onTaskClick}
                            onHotlistClick={onHotlistClick}
                            isInHotlist={contactsInHotlistOfficeView.includes(recentCall?.contacts?.[0]?.contact_id)}
                            isLogActivityModalOpen={isLogActivityModalOpen}
                            onEventClick={onEventClick}
                            isReadingRecentCalls={isReadingRecentCalls}
                            isReadingRecentCall={isReadingRecentCall}
                            onViewMoreClick={onViewMoreClick}
                        />
                    )}
                </OfficeViewContainer>
            </div>
            <div>
                <OfficeViewCompanyDestinations
                    isExpanded={isCompanyExtensionExpanded}
                    toggleIsExpanded={toggleIsExpanded}
                    companyDestinations={displayedCompanyDestinations}
                    togglePinned={togglePinned}
                    isReadingViewDestinations={isReadingViewDestinations}
                    isPinnedDestinationsMaxed={isPinnedDestinationsMaxed}
                />
            </div>
            <div style={{marginTop: 25}}>
                <OfficeViewTeams 
                    isReadingExtensionsTeamsList={isReadingExtensionsTeamsList}
                    getExtensions={getTeamsUsers}
                    searchResults={teamsSearchResults}
                    teams={office_view_teams}
                    isReadingOfficeViewTeams={isReadingOfficeViewTeams}
                    selectedTeamsTab={selectedTeamsTab}
                    onChangeSelectedTab={onChangeSelectedTab}
                    togglePinnedTeams={togglePinnedTeams}
                    pinnedUsers={selectedTeamsTab === "pinned" ? pinnedExtensionsWithUsers : extensionsByTeam}
                    isReadingUsers={isReadingPinnedExtensionsWithUsers || isReadingExtensionsByTeam}
                    pinsMaxed={pinnedExtensionsWithUsers?.length >= 3}
                />
            </div>
            <SendEmailModal
                open={isEmailModalOpen}
                onClose={() => { setIsEmailModalOpen(false); }}
                contact_id={ selectedTab === 0 ? (contact_lookup?.contact_id || contact_lookup?.account_id) : (recentCall?.contact_id || recentCall?.account_id) }
            />
            <AddTaskModal
                open={isTaskModalOpen}
                onClose={() => setIsTaskModalOpen(false)}
                state={state}
                control={taskControl}
                watch={taskWatch}
                isValid={taskIsValid}
                isDirty={taskIsDirty}
                trigger={taskTrigger}
                setValue={taskSetValue}
                getValues={taskGetValues}
                clearErrors={taskClearErrors}
                setError={taskSetError}
                reset={taskReset}
                taskBeingEdited={taskBeingEdited}
                loadTask={loadTask}
                addTask={addTask}
                setState={setState}
                onDelete={onDeleteTask}
                onUpdate={onUpdateTask}
                task_labels={task_labels}
                organizer_id={organizer_id}
                organizer={organizer}
                organizer_email={organizer_email}
                handleSubmit={handleSubmit}
                onRefresh={() => setIsTaskModalOpen(false)}
                email={{body:"", subject:""}}
                isAllowedToModify={isTaskAllowedToModify}
                hiddenContactSelector
            />
            <AddEventModal
                open={isEventModalOpen}
                onClose={() => setIsEventModalOpen(false)}
                onSave={handleSubmitEvent(addEvent)}
                selected_users={[]}
                isAllowedToModify={isAllowedToModify}
                control={eventControl}
                watch={eventWatch}
                isValid={eventIsValid}
                isDirty={isDirty}
                trigger={eventTrigger}
                setValue={eventSetValue}
                getValues={getValues}
                reset={reset}
                resetField={resetField}
                eventBeingEdited={eventBeingEdited}
                setNotificationContactName={() => {}}
                accountContacts={accountContacts}
                currentContact={(selectedTab === 0 && contact_lookup?.contact_id) ? {
                    text: contact_lookup.name, key: contact_lookup.contact_id, email: contact_lookup.contact_details?.email || ""
                } : (selectedTab === 1 && recentCall?.contacts?.[0]?.contact_id) && {
                    text: recentCall?.contacts?.[0]?.name, key: recentCall?.contacts?.[0]?.contact_id, email: recentCall?.contacts?.[0]?.email || ""
                }}
            />
        </div>
    )
}

export default OfficeView;
