import ACTION_NAMES from '../ACTION_NAMES';
import routes from '../routes';
import { generateCreateActionCreator, generateReadOneActionCreator, generateUpdateActionCreator } from '../crudActionCreators';

export default {
    generateAcceptCustomerFormToken: generateCreateActionCreator(`${routes.API_PAYMENT}/account`, ACTION_NAMES.ACCEPT_CUSTOMER_FORM_TOKEN, () => {}, () => {}, 'accept_customer_form_token'),
    getProfiles: generateReadOneActionCreator(`${routes.API_PAYMENT}/account`, ACTION_NAMES.PAYMENT_PROFILE, 'payment_methods'),
    fund: generateCreateActionCreator(`${routes.API_PAYMENT}/account`, ACTION_NAMES.FUND_ACCOUNT, () => {}, () => {}, 'fund'),
    getBalance: generateReadOneActionCreator(`${routes.API_PAYMENT}/account`, ACTION_NAMES.FUND_ACCOUNT, 'balance'),
    payInvoices: generateUpdateActionCreator(`${routes.API_PAYMENT}/account`, ACTION_NAMES.PAY_INVOICES, () => {}, () => {}, 'pay_invoices'),
    updateProcessorToDemo: generateUpdateActionCreator(`${routes.API_PAYMENT}/account`, ACTION_NAMES.PAYMENT_PROCESSOR, () => {}, () => {}, 'processor'),
    resetFormToken: () => {
        return {
            type: 'PAYMENT_RESET_FORM_TOKEN'
        }
    }
}
