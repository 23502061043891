import React from "react";
import { ClippedContent } from "dyl-components";

import './index.scss';
import { Checkbox } from "semantic-ui-react";

const Location = ({
    location,
    showBillingAndShipping
}) => (
    <div {...!showBillingAndShipping ? ({ className: "Location" }) : {}}>
        <div className="Location__line">
            <ClippedContent popup={false}>
                {location?.street ? `${location.street} ` : ""}
                {location?.additional_street
                    ? `${location.additional_street} `
                    : ""}
            </ClippedContent>
        </div>
        <div className="Location__line">
            <ClippedContent popup={false}>
                {location?.city ? `${location.city}, ` : ""}
                {location?.state ? `${location.state} ` : ""}
                {location?.zip || ""}
            </ClippedContent>
        </div>
        {showBillingAndShipping && (
            <div className="Location__billing-shipping">
                <Checkbox label="Billing" checked={location.billing} disabled /> <Checkbox label="Shipping" checked={location.shipping} disabled />
            </div>
        )}
    </div>
);

export default Location;
