
import types from 'actions/auth/authTypes';
import initialState from 'reducers/auth/authState';
import CRUD_ACTION_TYPES, { getNamedAction } from 'actions/CRUD_ACTION_TYPES';
import ACTION_NAMES from 'actions/ACTION_NAMES';


function authReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.LOGIN, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isAuthenticatingUser: true };
        case getNamedAction(ACTION_NAMES.LOGIN, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return {
                ...state, isAuthenticatingUser: false, authenticateUserMessage: 'success',
                user_id: action.data.user_id,
                customer_id: action.data.customer_id,
                dyl_employee: action.data.dyl_employee,
                access_role: action.data.access_role,
                access_role_id: action.data.access_role_id,
                temporary_password: action.data.temporary_password,
                signed_terms: action.data.signed_terms
            };
        case getNamedAction(ACTION_NAMES.LOGIN, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isAuthenticatingUser: false, authenticateUserMessage: 'error' };

        case types.CHECK_REQUEST:
            return { ...state, isAuthenticatingUser: true };
        case types.CHECK_SUCCESS:
            return {
                ...state, isAuthenticatingUser: false, authenticateUserMessage: 'success',
                name: action.data.name, user_id:
                    action.data.user_id,
                customer_id: action.data.customer_id,
                dyl_employee: action.data.dyl_employee,
                access_role: action.data.access_role,
                access_role_id: action.data.access_role_id,
                timezone: action.data.timezone || "America/New_York",
                signed_terms: action.data.signed_terms
            };
        case types.CHECK_FAILURE:
            return { ...state, isAuthenticatingUser: false, authenticateUserMessage: 'error' };

        case getNamedAction(ACTION_NAMES.LOGOUT, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isLoggingOutUser: true };
        case getNamedAction(ACTION_NAMES.LOGOUT, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isLoggingOutUser: false, logOutUserMessage: 'success', authenticateUserMessage: null, name: null, user_id: null, customer_id: null, dyl_employee: false };
        case getNamedAction(ACTION_NAMES.LOGOUT, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isLoggingOutUser: false, logOutUserMessage: 'error' };
        //PERMISSION SETS--DEPRICATE CALL?
        case getNamedAction(ACTION_NAMES.CHECK_PERMISSION, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, [`isCheckingPermission${action.data}`]: true };
        case getNamedAction(ACTION_NAMES.CHECK_PERMISSION, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, [`isCheckingPermission${action.data}`]: false, [`isPermitted${action.data}`]: true };
        case getNamedAction(ACTION_NAMES.CHECK_PERMISSION, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, [`isCheckingPermission${action.data}`]: false, [`isPermitted${action.data}`]: false };
        
        case types.GET_IP_REQUEST:
            return { ...state, isGettingIP: true };
        case types.GET_IP_SUCCESS:
            return { ...state, isGettingIP: false, ip: action.ip };
        case types.GET_IP_FAILURE:
            return { ...state, isGettingIP: false }

        case getNamedAction(ACTION_NAMES.AUTH_USER, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isCreatingUser: true };
        case getNamedAction(ACTION_NAMES.AUTH_USER, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isCreatingUser: false };
        case getNamedAction(ACTION_NAMES.AUTH_USER, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isCreatingUser: false };

        case getNamedAction(ACTION_NAMES.AUTH_RESET_PASSWORD, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isResettingPassword: true };
        case getNamedAction(ACTION_NAMES.AUTH_RESET_PASSWORD, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isResettingPassword: false };
        case getNamedAction(ACTION_NAMES.AUTH_RESET_PASSWORD, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isResettingPassword: false };

        case getNamedAction(ACTION_NAMES.AUTH_SEND_PASSWORD_RECOVERY_LINK, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isRecoveringPassword: true };
        case getNamedAction(ACTION_NAMES.AUTH_SEND_PASSWORD_RECOVERY_LINK, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isRecoveringPassword: false };
        case getNamedAction(ACTION_NAMES.AUTH_SEND_PASSWORD_RECOVERY_LINK, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isRecoveringPassword: false };

        case getNamedAction(ACTION_NAMES.AUTH_VERIFY_CODE, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isVerifyingCode: true };
        case getNamedAction(ACTION_NAMES.AUTH_VERIFY_CODE, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isVerifyingCode: false };
        case getNamedAction(ACTION_NAMES.AUTH_VERIFY_CODE, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isVerifyingCode: false };

        case getNamedAction(ACTION_NAMES.AUTH_ROLE, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isUpdatingRole: true };
        case getNamedAction(ACTION_NAMES.AUTH_ROLE, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isUpdatingRole: false };
        case getNamedAction(ACTION_NAMES.AUTH_ROLE, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isUpdatingRole: false };

        case getNamedAction(ACTION_NAMES.SIGN_TERMS, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isSigningTerms: true };
        case getNamedAction(ACTION_NAMES.SIGN_TERMS, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isSigningTerms: false, signed_terms: true };
        case getNamedAction(ACTION_NAMES.SIGN_TERMS, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isSigningTerms: false };

        default:
            return state;
    }
}


export default authReducer;
