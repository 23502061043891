import React, { useEffect } from 'react';
import { Modal, Notification, STATUS_TYPES, generateResolver, VALIDATORS, yup } from 'dyl-components';
import { Button, Form, Header, Segment } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';

import { CustomGroupUtils, PhoneUtil } from 'utils';

import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import './AddContactModal.scss';
import contactsActions from 'actions/contacts';
import customFieldsGroupActions from "actions/custom_fields_group";
import AccountOptions from 'shared/forms/AccountOptions';
import ContactFields from 'shared/forms/ContactFields';
import CustomData from 'shared/CustomData';

import { commsSchema } from 'shared/schemas/contact/commsSchema';
import PHONE_TYPE_OPTIONS from 'shared/constants/PHONE_TYPE_OPTIONS';
import EMAIL_TYPE_OPTIONS from 'shared/constants/EMAIL_TYPE_OPTIONS';

const ModalContent = ({
    onClose
}) => {
    const { group, isCreating, isCheckingDuplicates } = useSelector(state => {
        const { isCreatingLocation } = state.account;
        const { isCreating: isCreatingContacts } = state.contacts;
        const custom_groups = (state.custom_fields_group.contact_standard_group.children || []).filter(group => !group.standard);
        return {
            isCreating: isCreatingLocation || isCreatingContacts,
            group: custom_groups,
            isCheckingDuplicates: state.contact_duplicates.isCheckingDuplicates
        };
    });

    const custom_groups = CustomGroupUtils.formatFields(group);

    const { formState: { isDirty, isValid, errors }, trigger, control, handleSubmit, watch, setError, clearErrors, setValue } = useForm({
        mode: 'onChange',
        defaultValues: {
            first_name: '',
            last_name: '',
            suffix: '',
            birthday: '',
            phones: [{ value: '', type: PHONE_TYPE_OPTIONS.keys.CELL, main: true }],
            emails: [{ value: '', type: EMAIL_TYPE_OPTIONS.keys.WORK, main: true }],
            website: '',
            interests: [],
            department: '',
            job_title: '',

            address_label: 'Business',
            street: '',
            apartmentUnitOrFloor: '',
            city: '',
            state: null,
            zip: '',

            account: null,

            business_name: '',
            industry_sector: null,
            sub_industry: null,
            employee_size: 0,
            annual_revenue: '',
            products_sold: '',

            household_name: '',
            household_type: null,
            household_members: 0,
            number_of_children: 0,
            annual_household_income: '',

            children: custom_groups
        },
        resolver: generateResolver({
            first_name: VALIDATORS.FIRST_NAME().no_whitespace_only().required('This field is required'),
            last_name: VALIDATORS.LAST_NAME().no_whitespace_only().required('This field is required'),
            ...commsSchema,
            suffix: VALIDATORS.SUFFIX(),
            state: yup.string().required('This field is required'),
            street: yup.string().maxlength(100),
            apartmentUnitOrFloor: yup.string().maxlength(12),
            city: yup.string().maxlength(60),
            department: yup.string().maxlength(64),
            job_title: yup.string().noemoji().maxlength(60),
            zip: VALIDATORS.US_POSTAL_CODE(),
            website: VALIDATORS.WEBSITE().maxlength(256),
            children: CustomGroupUtils.generateValidationSchema(custom_groups)
        }, ['emails', 'phones'])
    });

    const [watchedPhones, watchedEmails] = watch(['phones', 'emails']);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const onCreateContacts = (contacts, account_id) => {
        return dispatch(contactsActions.createContact(contacts, { account_id }));
    }

    const onCreate = async (data) => {
        const custom_data = {
            fields: CustomGroupUtils.extractPersonFields(data),
            children: {
                ...CustomGroupUtils.extractBusinessAndHouseholdDetails(data),
                ...CustomGroupUtils.groupAndFlatten({ children: data.children }).children
            }
        }

        const { address_label, street, apartmentUnitOrFloor, city, state, zip } = data;

        const payload = [
            {
                custom_data,
                date_of_birth: data.birthday,
                email: watchedEmails.filter(emailItem => emailItem.value).map(({ value: email, main, type }) => ({
                    main,
                    email,
                    email_type: type
                })),
                first_name: data.first_name,
                last_name: data.last_name,
                suffix: data.suffix,
                influence: "influencer",
                location: [
                    {
                        street,
                        additional_street: apartmentUnitOrFloor,
                        city,
                        state,
                        zip,
                        label: address_label,
                        main: true,
                        shipping: true,
                        billing: true
                    }
                ],
                phone: watchedPhones.filter((phone) => phone.value).map(({ value, main, type }) => ({
                    main,
                    phone: PhoneUtil.getUnformatted(value),
                    phone_type: type
                })),
                job_title: data.job_title,
                website: data.website
            }
        ];

        try {
            const [contact_id] = await onCreateContacts(payload, data.account?.id);
            Notification.alert('Successfully created contact!', STATUS_TYPES.SUCCESS);
            onClose();
            if (typeof (contact_id) == 'number') { navigate(`/contact/${contact_id}`); }
        } catch (e) {
            console.log(e);
            Notification.alert('Failed to create contact', STATUS_TYPES.ERROR);
        }
    }

    const hasErrors = Object.keys(errors).length > 0;

    return (
        [
            <Modal.Content scrolling>
                <Form noValidate>
                    <ContactFields
                        control={control}
                        clearErrors={clearErrors}
                        setError={setError}
                        setValue={setValue}
                        trigger={trigger}
                    />
                    <Header as='h3' color='primary'>Account</Header>
                    <Controller
                        name='account'
                        control={control}
                        render={({ field: { name, value, onChange } }) => (
                            <Form.Field
                                name={name}
                                value={value}
                                control={AccountOptions}
                                label='Link to Existing Account'
                                onChange={(_, { value }) => {
                                    onChange({ target: { name, value } });
                                }}
                            />
                        )}
                    />
                    <Header as='h3' color='primary'>Custom Data</Header>
                    <CustomData
                        control={control}
                        isEditing
                        padded
                    />
                </Form>
            </Modal.Content>,
            <Modal.Actions>
                <Button disabled={!isDirty || !isValid || isCheckingDuplicates || hasErrors || isCreating} primary onClick={handleSubmit(onCreate)} loading={isCreating}>Create</Button>
            </Modal.Actions>
        ]
    )
}

const AddContactModal = ({
    onClose
}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(customFieldsGroupActions.getContactStandardGroup({ group_name: 'person' }));
    }, [dispatch]);

    const isReadingCustomFields = useSelector(state => state.custom_fields_group.isReadingContactStandardFieldGroup);

    return (
        <React.Fragment>
            <Modal.Header>
                Add New
            </Modal.Header>
            {isReadingCustomFields ? (
                <Segment loading basic></Segment>
            ) : (
                <ModalContent 
                    onClose={onClose}
                />
            )}
        </React.Fragment>
    )
}

export default AddContactModal;
