import React, { useEffect, useCallback, useState } from 'react';
import { RecordsTable, Notification, STATUS_TYPES, TableWithHeader } from "dyl-components";
import { Header } from 'semantic-ui-react';
import { useSearchParams, useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import UploadsHeader from './subcomponents/Header';
import UploadRow from './subcomponents/Row';
import importActions from 'actions/import_data';
import './index.scss';


const Uploads = () => {

    const [uploadsData, setUploadsData] = useState([]);

    const [params] = useSearchParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { profileUploads, isReadingProfileUploads, profileUploadsCount } = useSelector(state => state.import_data);

        const { contact_id, account_id } = useParams();
        const UPLOAD_URL = `/${contact_id ? "contact" : "account" }/${contact_id ? contact_id : account_id}/uploads`;
    
    const onPageChange = (_, { activePage }) => {
        const query = new URLSearchParams(params);
        query.set('page', activePage);
        const query_string = query.toString();
        navigate(`${UPLOAD_URL}${query_string ? `?${query_string}` : ''}`);
    };

    const onReload = useCallback(() => {
        dispatch(importActions.readProfileUploads({
            ...(contact_id ? { contact_id: [contact_id] } : ''),
            ...(account_id ? { account_id: account_id } : ''), 
            ...Object.fromEntries(params), 
            limit: 6
        }))
    }, [dispatch, params, contact_id, account_id])
    
    useEffect(() => {
        onReload();
    }, [onReload]);


    useEffect(() => {
        setUploadsData(profileUploads);
    }, [profileUploads])

    const onRemove = async (id) => {
        try {
            await dispatch(importActions.deleteProfileUpload(id));
            Notification.alert("Successfully removed file!", STATUS_TYPES.SUCCESS);
            onReload();
        } catch (e) {
            console.log(e);
            Notification.alert("Failed to remove file.", STATUS_TYPES.ERROR);
        }
    } 

    return (
        <div className='UploadsTable'>
            <TableWithHeader
                header={
                    <Header as='h2'>Uploads</Header>
                }
                table={
                    <RecordsTable
                        tableBodyClassName="UploadsTable"
                        isTableLoading={isReadingProfileUploads}
                        isTableEmpty={!profileUploadsCount}
                        TableHeader={<UploadsHeader />}
                        TableRows={uploadsData?.map((upload) => (
                            <UploadRow uploadData={upload} onRemove={onRemove} />
                        ))}
                        recordsCount={profileUploadsCount}
                        recordsName="Uploads"
                        emptySubtitle=" "
                        activePage={params.get('page')}
                        emptyListTitle={"No Files Uploaded"}
                        onPageChange={onPageChange}
                        recordsLimit={6}
                    />
                }
            />
        </div>
    )
}

export default Uploads;