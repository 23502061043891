import {
    DateTimeUtils, Table,
    EllipsisDropdown,
    ControlledPopup,
    LinkedAccount,
    ClippedContent,
    ButtonLink
} from "dyl-components";
import {
    DropdownItem,
    Dropdown,
    Header,
    Icon
} from "semantic-ui-react";
import { Link } from 'react-router-dom';
import FileUtils from 'utils/FileUtils';

const UploadRow = ({ uploadData, onRemove }) => {
    const userName = `${uploadData.first_name} ${uploadData.last_name}`;
    const openDocument = (url) => {
        window.open(
            url,
            "_blank",
            "location=yes,height=770,width=770,scrollbars=yes,status=yes"
        );
    }
    const iconDisplay = (fileName) => {
        const fileExtension = fileName.slice((fileName.lastIndexOf(".") - 1 >>> 0) + 2);
        if(fileExtension === "pdf"){
            return 'file pdf outline';
        }
        if(fileExtension === "jpg" || fileExtension === "jpeg" || fileExtension === "png"){
            return 'image';
        }
    }
    return (
        <Table.Row>
            <Table.Cell>
                <div><b>{DateTimeUtils.formatEpoch(uploadData.created, DateTimeUtils.WORD_DATE_FORMAT)}</b></div>
                <div>{DateTimeUtils.formatEpoch(uploadData.created, DateTimeUtils.TIME_FORMAT)}</div>
                </Table.Cell>
            <Table.Cell>
                <ButtonLink
                    onClick={() => { openDocument(uploadData.url) }}
                    noPadding
                    style={{maxWidth:500}}
                >
                    <ClippedContent popupContent={uploadData.name}>
                        <Icon name={iconDisplay(uploadData.file_id)} color="black"/>
                        {uploadData.name}
                    </ClippedContent>
                </ButtonLink>   
            </Table.Cell>
            <Table.Cell>{FileUtils.getFileSize(uploadData.size)}</Table.Cell>
            <Table.Cell>
                <LinkedAccount
                    maxWidth={'9em'}
                    account={userName}
                    subtitle={uploadData.email}
                    linkToAccount={<Link target={'_blank'} to={`/settings/users/${uploadData.created_user_id}/general`}>{userName}</Link>}
                />
            </Table.Cell>
            <Table.Cell>
                <EllipsisDropdown>
                    <DropdownItem
                        style={{ cursor: "pointer" }}
                        text="Download"
                        icon="fa-solid fa-download"
                        onClick={() => openDocument(uploadData.url)}
                    />
                    <ControlledPopup
                        header={
                            <Header as="h4" textAlign="center">
                                Are you sure?
                            </Header>
                        }
                        trigger={
                            <Dropdown.Item
                                icon="fas fa-trash-alt"
                                text="Delete"
                            />
                        }
                        onConfirm={() => onRemove(uploadData.id)}
                        inverted
                    />
                </EllipsisDropdown>
            </Table.Cell>
        </Table.Row>
    );
};

export default UploadRow;
