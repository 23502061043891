import React from 'react';
import {Grid} from 'semantic-ui-react';
import {useSelector} from "react-redux";
import {ClippedContent, DateTimeUtils} from "dyl-components";
import {CustomGroupUtils} from "../../utils";
import { Link } from 'react-router-dom';

const CustomerInformation = ({
     textAlign = 'right'
 }) => {
    const { customer_since, custom_data, customer_status, close_reason, customer_status_activity, campaign_converted } = useSelector(state => state.account.account?.customer || {});
    
    const fields = CustomGroupUtils.groupAndFlatten(custom_data || {})?.fields;

    const business_model_options = CustomGroupUtils.getFieldOptions(custom_data.fields, 'business_model');
    
    const close_reason_and_type = (() => {
        if (close_reason) {
            if (close_reason.close_type_name) {
                return `${close_reason.close_reason_name}/${close_reason.close_type_name}`
            }
            return close_reason.close_reason_name;
        }
        return 'None';
    })()

    const campaignConvertedDisplay = Object.keys(campaign_converted || {}).length > 1 && (Boolean(campaign_converted.id) && Boolean(campaign_converted.name)) ?
        <Link to={`/campaigns/${campaign_converted.id}/dashboard`}><ClippedContent>{campaign_converted.name}</ClippedContent></Link> : 'None';

    return (
        <Grid className='RecordInfo'>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    Type
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    {business_model_options.find((option) => {
                        return option.value === fields?.business_model;
                    })?.text || 'None'}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    Subscriptions
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    None
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    Total Spent
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    None
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    Total Transactions
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    None
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    Customer Since
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    {DateTimeUtils.formatEpoch(customer_since, DateTimeUtils.WORD_DATE_FORMAT)}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    First Funding Date
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    None
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    Renewal Date
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    None
                </Grid.Column>
            </Grid.Row>
            {(customer_status === 'paused' || customer_status === 'canceled') && [
                <Grid.Row columns='equal'>
                    <Grid.Column>
                        <ClippedContent>{customer_status === 'paused' ? 'Pause' : 'Cancel'} Reason/Type</ClippedContent>
                    </Grid.Column>
                    <Grid.Column textAlign={textAlign}>
                        {close_reason_and_type}
                    </Grid.Column>
                </Grid.Row>,
                <Grid.Row columns='equal'>
                    <Grid.Column>
                        <ClippedContent>{customer_status === 'paused' ? 'Pause' : 'Cancel'} Reason Notes</ClippedContent>
                    </Grid.Column>
                    <Grid.Column textAlign={textAlign} disabled>
                        {close_reason?.note ? <ClippedContent>{close_reason.note}</ClippedContent> : 'None'}
                    </Grid.Column>
                </Grid.Row>
            ]}
			{(customer_status === 'active' && customer_since !== customer_status_activity && (
				<Grid.Row columns='equal'>
					<Grid.Column>
						Reactivated On
					</Grid.Column>
					<Grid.Column disabled textAlign={textAlign}>
						{DateTimeUtils.formatEpoch(customer_status_activity, DateTimeUtils.WORD_DATE_FORMAT)}
					</Grid.Column>
				</Grid.Row>
			))}
            <Grid.Row columns='equal'>
                <Grid.Column>
                    <ClippedContent>Campaign Converted</ClippedContent>
                </Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    {campaignConvertedDisplay}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default CustomerInformation;

