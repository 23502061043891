import React, { useEffect } from 'react';
import { DateTimeUtils } from 'dyl-components';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import usersActions from "actions/users";
import WebsocketConnection from 'actions/websocket';
import authActions from 'actions/auth/auth';
import permissionsActions from 'actions/permissions';
import roleActions from 'actions/role';
import { Loader } from 'semantic-ui-react';
import favoritesActions from 'actions/favorites';
import notificationsActions from 'actions/notifications';
import officeViewActions from 'actions/office_view';
import { Phone } from "shared/Softphone/softphone";

export const RequireAuth = ({phone}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const { isAuthenticatingUser } = useSelector((state) => {  
        return {
            isAuthenticatingUser: state.auth.isAuthenticatingUser
        }
    });

    useEffect(() => {
        const check = async() => {
            const { timezone, temporary_password, isAuthenticated, access_role_id, signed_terms } = await dispatch(authActions.check()) || {};
            if (temporary_password) {
                navigate('password_reset')
            } else if (isAuthenticated) {
                if (signed_terms === false) {
                    navigate('terms');
                } else {
                    if (timezone) {
                        DateTimeUtils.setTimezone(timezone);
                    }
                    //Phone System
                    dispatch(officeViewActions.browserCreds()).then(creds => {
                        if (creds.username && ! phone?.current?.config) {
                            phone.current = Phone(creds,{}, {})
                        }
                    })
                    //user Profile
                    dispatch(usersActions.readUserProfile());
                    //get appPermissions
                    dispatch(permissionsActions.readPermissions());
                    //get userPermissions
                    dispatch(roleActions.readUserRole(access_role_id));
                    dispatch(favoritesActions.readFavorites());
                    dispatch(notificationsActions.onReadNotificationCenter())
                        .then((data) => {
                            //check if user has new notifications
                            if(data?.notifications.length > 0 && data?.enable_bell ){
                                dispatch(notificationsActions.hasUnReadNotifications());
                            }
                        })
                }
            } else {
                navigate('login', {replace: true, state: {from: location}});
            }
        }

        check();
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, navigate, location.pathname]);

    return (
    <>
        {(isAuthenticatingUser) 
                ? <Loader size='large' active>Authenticating</Loader> 
                : <Outlet />}
        <WebsocketConnection phone={phone}/>
    </>
    );
}
