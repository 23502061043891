import quoteActions from "actions/quote";
import { CloseableContent } from "dyl-components";
import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Dropdown,
    Button,
    Popup,
    Header,
    Ref,
    Portal,
} from "semantic-ui-react";
import ConvertNewCustomerPopup from "shared/ConvertNewCustomerPopup";
import UpsellCustomerPopup from "shared/UpsellCustomerPopup";
import QuoteBuilderProvider, {
    ACTIONS,
    QuoteBuilderContext,
} from "shared/context/QuoteBuilderProvider";

import ConvertContactForm from "shared/forms/ConvertContactForm";
import ConvertToCustomerForm from "shared/forms/ConvertToCustomerForm";
import ConvertToOpportunityForm from "shared/forms/ConvertToOpportunityForm";
import QuoteBuilderModal from "shared/modals/QuoteBuilderModal";

const ContactConversionControls = ({
    linkedAccountName,
    linkedAccountPrimaryContact,
    account_id,
    contact_id,
    contactName,
    account_stage,
    pipeline_id,
    linkedAccountIsACustomer,
    master_source,
    secondary_source,
    refresh,
}) => {
    const modalRef = React.createRef();

    const canConvertToLead = account_stage === "contact";
    const canConvertToOpportunity =
        account_stage === "contact" || account_stage === "lead";
    const canUpsell = account_stage !== "lead";

    const [conversionModalOpen, setConversionModalOpen] = useState("");
    const [isConversionFormModified, setIsConversionFormModified] =
        useState(false);

    const onClose = (e) => {
        const clickedElement = e?.target?.className;
        const isDropdownItemClicked =
            clickedElement === "item" || clickedElement === "text";
        if (!isDropdownItemClicked) {
            setConversionModalOpen("");
            setIsConversionFormModified(false);
        }
    };

    const [position, setPosition] = useState({ x: 0, y: 0, width: 0 });
    const [isOpen, setIsOpen] = useState(false);

    const { onOpenAddProducts, onEditQuote } =
        useContext(QuoteBuilderContext);

    const dispatch = useDispatch();

    const recentOpportunityQuote = useSelector((state) => state.quote.recentOpportunityQuote);

    const recentActiveQuoteId = recentOpportunityQuote?.recent_active_quote_id;
    const recentInactiveQuoteId = recentOpportunityQuote?.recent_inactive_quote_id;
    const isUpsellingFromQuote = canUpsell && (recentActiveQuoteId || recentInactiveQuoteId);

    const onUpsell = () => {
        try {
            if (pipeline_id && canUpsell) {
                if (isUpsellingFromQuote) {
                    return onEditQuote(recentInactiveQuoteId || recentActiveQuoteId, ACTIONS.UPSELL);
                }
                return onOpenAddProducts({ isUpsell: true });
            }
            return onOpenAddProducts({ isUpsell: true });
        } catch (e) {
            console.log(e);
        }
    };

    const getPopupContent = () => {
        switch (conversionModalOpen) {
            case "lead":
                return (
                    <React.Fragment>
                        <Header color="primary">Convert to Lead</Header>
                        <ConvertContactForm
                            size="small"
                            account_id={account_id}
                            contact_id={contact_id}
                            onClose={onClose}
                            refresh={refresh}
                            onModify={(isModified) => {
                                setIsConversionFormModified(isModified);
                            }}
                        />
                    </React.Fragment>
                );
            case "opportunity":
                return (
                    <React.Fragment>
                        <Header color="primary">Convert to Opportunity</Header>
                        <ConvertToOpportunityForm
                            size="small"
                            account_id={account_id}
                            contact_id={contact_id}
                            onClose={onClose}
                            refresh={refresh}
                            onModify={(isModified) => {
                                setIsConversionFormModified(isModified);
                            }}
                            account_stage={account_stage}
                            master_source={master_source}
                            secondary_source={secondary_source}
                        />
                    </React.Fragment>
                );
            case "customer":
                return (
                    <React.Fragment>
                        <Header color="primary">
                            Convert{" "}
                            <Popup
                                trigger={
                                    <span className="Convert__account-name">
                                        {linkedAccountName || contactName}
                                    </span>
                                }
                                content={linkedAccountName || contactName}
                                inverted
                            />{" "}
                            to Customer
                        </Header>
                        <ConvertToCustomerForm
                            size="small"
                            account_id={account_id}
                            owner_id={contact_id}
                            onClose={onClose}
                            refresh={refresh}
                            onModify={(isModified) => {
                                setIsConversionFormModified(isModified);
                            }}
                            current_primary_contact_id={
                                linkedAccountPrimaryContact
                            }
                            pipeline_id={pipeline_id}
                        />
                    </React.Fragment>
                );
            case "new-customer":
                return (
                    <React.Fragment>
                        <Header color="primary">
                            Convert{" "}
                            <Popup
                                trigger={
                                    <span className="Convert__account-name">
                                        {linkedAccountName || contactName}
                                    </span>
                                }
                                content={linkedAccountName || contactName}
                                inverted
                            />{" "}
                            to Customer
                        </Header>
                        <ConvertToCustomerForm
                            size="small"
                            account_id={account_id}
                            owner_id={contact_id}
                            onClose={onClose}
                            refresh={(account_id) => {
                                refresh(account_id);
                            }}
                            onModify={(isModified) => {
                                setIsConversionFormModified(isModified);
                            }}
                            pipeline_id={pipeline_id}
                            action="convert-new-customer"
                            current_primary_contact_id={contact_id}
                            isUpsellingFromQuote={isUpsellingFromQuote}
                        />
                    </React.Fragment>
                );
            default:
                return null;
        }
    };

    const isPrimaryContact = linkedAccountPrimaryContact === contact_id;

    const isUpselling = useSelector((state) => {
        if (pipeline_id) {
            return state.quote.opportunityBeingUpsold === pipeline_id;
        }
        return false;
    });
    
    return (
        <React.Fragment>
            <Popup
                content={
                    <CloseableContent onClose={onClose}>
                        {getPopupContent()}
                    </CloseableContent>
                }
                open={conversionModalOpen}
                position="right center"
                context={modalRef}
                onClose={onClose}
                closeOnDocumentClick={!isConversionFormModified}
            />
            <Ref innerRef={modalRef}>
                <Dropdown
                    loading={isUpselling}
                    as={Button}
                    size="tiny"
                    color="blue"
                    text="Convert"
                    style={{ background: "#2635DE" }}
                    onClick={async () => {
                        if (modalRef.current) {
                            if (pipeline_id && canUpsell) {
                                await dispatch(
                                    quoteActions.getRecentQuoteByOpportunityId(pipeline_id, {
                                        account_id
                                    })
                                );
                            }
                            const position =
                                modalRef.current.getBoundingClientRect();
                            const scrollY = window.scrollY;
                            setPosition({
                                x: position.left,
                                y: position.top + scrollY + 10,
                                width: position.width,
                            });
                            setIsOpen(!isOpen);
                        }
                    }}
                    onBlur={() => setIsOpen(false)}
                >
                    <Portal open>
                        <Dropdown
                            open={isOpen}
                            style={{
                                position: "absolute",
                                zIndex: 999,
                                left: position.x - 100,
                                top: position.y,
                                visibility: "hidden",
                            }}
                            onClose={() => {
                                setIsOpen(false);
                            }}
                        >
                            <Dropdown.Menu>
                                {canConvertToLead && (
                                    <Dropdown.Item
                                        style={{ width: 195 }}
                                        onClick={() => {
                                            setConversionModalOpen("lead");
                                            setIsOpen(false);
                                        }}
                                    >
                                        Convert to Lead
                                    </Dropdown.Item>
                                )}
                                {canConvertToOpportunity && (
                                    <Dropdown.Item
                                        style={{ width: 195 }}
                                        onClick={() => {
                                            setConversionModalOpen(
                                                "opportunity"
                                            );
                                            setIsOpen(false);
                                        }}
                                    >
                                        Convert to Opportunity
                                    </Dropdown.Item>
                                )}
                                {!linkedAccountIsACustomer ? (
                                    <Dropdown.Item 
                                        style={{ width: 195 }} 
                                        onClick={() => {
                                            if (!recentActiveQuoteId) {
                                                setConversionModalOpen("customer");
                                                setIsOpen(false);
                                            }
                                        }}
                                        {...(recentActiveQuoteId ? ({ as: Dropdown, size: "tiny", trigger: "Convert to Customer" }) : {})}
                                        text="Convert to Customer"
                                    >
                                        {recentActiveQuoteId ? (
                                            <Dropdown.Menu direction="right">
                                                <Dropdown.Item onClick={() => {
                                                    onEditQuote(recentActiveQuoteId);
                                                }}>
                                                    Use Existing Quote
                                                </Dropdown.Item>
                                                <Dropdown.Item onClick={() => {setConversionModalOpen("customer")}}>
                                                    Don't Use Existing Quote
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        ) : "Convert to Customer"}
                                    </Dropdown.Item>
                                ) : (
                                    <>
                                        <UpsellCustomerPopup 
                                            open={isOpen && isUpsellingFromQuote}
                                            position={"left center"}
                                            trigger={(
                                                <Dropdown.Item
                                                    disabled={!canUpsell}
                                                    style={{ width: 195 }}
                                                    onClick={() => {
                                                        onUpsell();
                                                    }}
                                                >
                                                    Upsell Customer
                                                </Dropdown.Item>
                                            )}
                                        />
                                        <ConvertNewCustomerPopup
                                            open={isOpen && isPrimaryContact}
                                            isPrimaryContact={isPrimaryContact}
                                            account_id={account_id}
                                            trigger={
                                                <Dropdown.Item
                                                    disabled={isPrimaryContact}
                                                    style={{ width: 195 }}
                                                    onClick={() => {
                                                        if (!recentActiveQuoteId) {
                                                            setConversionModalOpen("new-customer");
                                                        }
                                                    }}
                                                    {...(recentActiveQuoteId ? ({ 
                                                        as: Dropdown, 
                                                        size: "tiny", 
                                                        trigger: "Convert New Customer", 
                                                        style: { minWidth: "10em" } 
                                                    }) : {})}
                                                    text="Convert New Customer"
                                                >
                                                    {recentActiveQuoteId ? (
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item onClick={() => {
                                                                onEditQuote(recentActiveQuoteId, ACTIONS.CONVERT_NEW_CUSTOMER);
                                                            }}>
                                                                Use Existing Quote
                                                            </Dropdown.Item>
                                                            <Dropdown.Item onClick={() => {setConversionModalOpen("new-customer")}}>
                                                                Don't Use Existing Quote
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    ) : "Convert New Customer"}
                                                </Dropdown.Item>
                                            }
                                        />
                                    </>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Portal>
                </Dropdown>
            </Ref>
            <QuoteBuilderModal
                account_id={account_id}
                opportunity_id={pipeline_id}
                contact_id={contact_id}
                isLinkedToCustomer={linkedAccountIsACustomer}
            />
        </React.Fragment>
    );
};

const ContactConversionControlsContainer = (props) => (
    <QuoteBuilderProvider>
        <ContactConversionControls {...props} />
    </QuoteBuilderProvider>
);

export default ContactConversionControlsContainer;
