import routes from "actions/routes";
import { generateReadOneActionCreator } from "actions/crudActionCreators";
import ACTION_NAMES from "actions/ACTION_NAMES";

export default {
    readContact: generateReadOneActionCreator(routes.API_CONTACT, ACTION_NAMES.SEND_EMAIL_CONTACT),
    readModalContact: generateReadOneActionCreator(routes.API_CONTACT, ACTION_NAMES.MODAL_SEND_EMAIL_CONTACT),
    getLocations: generateReadOneActionCreator(routes.API_CONTACT, ACTION_NAMES.SEND_EMAIL_LOCATION, 'location'),
    getModalLocations: generateReadOneActionCreator(routes.API_CONTACT, ACTION_NAMES.MODAL_SEND_EMAIL_LOCATION, 'location')
}
