import React, { useEffect } from 'react';
import { Form } from 'semantic-ui-react';
import { Controller } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import customFieldsGroupActions from "actions/custom_fields_group";
import { CustomGroupUtils } from "utils";
import pipelineActions from "actions/pipeline";

const ConvertToCustomerFields = ({ pipelineName, control, stageName, typeName, columns = 3, showType = true, isCampaignConvertedDisabled = false, isEmpty = false}) => {
    const dispatch = useDispatch();
    const { customer_fields, isReadingCustomerStandardFieldGroup, pipelineCategories, isReadingCategories, campaignConvertedOptions } = useSelector(state => {
        return {
            customer_fields: state.custom_fields_group.customer_standard_group.fields?.data || [],
            isReadingCustomerStandardFieldGroup: state.custom_fields_group.isReadingCustomerStandardFieldGroup,

            pipelineCategories: state.pipeline.categories.map(category => ({
                key: category.id,
                value: category.id,
                text: category.name,
                stages: category.stages?.map(stage => ({
                    key: stage.id,
                    value: stage.id,
                    text: stage.name
                })) || []
            })),
            isReadingCategories: state.pipeline.isReadingCategories,
            campaignConvertedOptions: state.campaigns.campaignConvertedOptions
        }
    });

    useEffect(() => {
        dispatch(customFieldsGroupActions.getCustomerStandardGroup({ group_name: 'customer' }));
        dispatch(pipelineActions.getPipelineCategories({ account_stage: 'customer' }));
    }, [dispatch]);

    const typeField = <Controller
        name={typeName}
        control={control}
        render={({ field: { name, value, onChange } }) => (
            <Form.Select
                fluid
                label='Type'
                name={name}
                value={value}
                onChange={(_, { value }) => {
                    onChange({ target: { name, value } })
                }}
                options={CustomGroupUtils.getFieldOptions(customer_fields, 'business_model')}
                search
                selectOnBlur={false}
                placeholder='Select Type'
                loading={isReadingCustomerStandardFieldGroup}
            />
        )}
    />;
    return [
        <Form.Group widths={columns}>
            <Controller
                name={stageName}
                control={control}
                render={({ field: { onChange: onStageChange } }) => (
                    <Controller
                        name={pipelineName}
                        control={control}
                        render={({ field: { name, value: categoryValue, onChange }, fieldState: { error } }) => [
                            <Form.Select
                                fluid
                                label='Pipeline'
                                name={name}
                                value={categoryValue}
                                onChange={(_, { name, value }) => {
                                    onChange({ target: { name, value } });
                                    onStageChange({ target: { name: stageName, value: pipelineCategories.find(category => category.value === value)?.stages[0]?.value || null } });
                                }}
                                options={pipelineCategories}
                                search
                                selectOnBlur={false}
                                placeholder='Select Pipeline'
                                loading={isReadingCategories}
                                error={error && error.message && {
                                    content: error.message,
                                    pointing: 'below'
                                }}
                                clearable
                            />,
                            <Controller
                                name={stageName}
                                control={control}
                                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                    <Form.Select
                                        fluid
                                        label='Stage'
                                        name={name}
                                        value={value}
                                        onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                        options={pipelineCategories.find(category => category.value === categoryValue)?.stages || []}
                                        search
                                        selectOnBlur={false}
                                        placeholder='Select Stage'
                                        error={error && error.message && {
                                            content: error.message,
                                            pointing: 'below'
                                        }}
                                    />
                                )}
                            />
                        ]}
                    />
                )}
            />
            {columns >= 3 ? showType && typeField : null}
        </Form.Group>,
        columns >= 3 ? (
            <Form.Group widths={"equal"}>
                <Controller
                    name={'campaign_converted'}
                    control={control}
                    render={({ field: { name, value, onChange } }) => (
                        <Form.Select
                            fluid
                            label='Campaign Converted'
                            name={name}
                            value={value}
                            onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                            options={isEmpty ? [] : campaignConvertedOptions.map((campaign) => ({key: campaign.id, value: campaign.id, text: campaign.name}))}
                            search
                            selectOnBlur={false}
                            placeholder='Select Campaign'
                            disabled={isCampaignConvertedDisabled}
                        />
                    )}
                />
                <Form.Field />
                <Form.Field />
            </Form.Group>
        ) : null,
        ...(columns === 2 && showType ? [
            <Form.Group widths={2}>
                {typeField}
                <Controller
                    name={'campaign_converted'}
                    control={control}
                    render={({ field: { name, value, onChange } }) => (
                        <Form.Select
                            fluid
                            label='Campaign Converted'
                            name={name}
                            value={value}
                            onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                            options={isEmpty ? [] : campaignConvertedOptions.map((campaign) => ({key: campaign.id, value: campaign.id, text: campaign.name}))}
                            search
                            selectOnBlur={false}
                            placeholder='Select Campaign'
                        />
                    )}
                />
            </Form.Group>
        ] : [])
    ]
}

export default ConvertToCustomerFields;
