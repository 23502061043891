import React from 'react';
import { Form, Header, Icon, List } from 'semantic-ui-react';

import { StringUtils } from 'utils';

import { useFieldArray } from 'react-hook-form';

import ContactOptions from 'shared/forms/ContactOptions';
import { AccountUtils } from 'utils';
import PHONE_TYPE_OPTIONS from 'shared/constants/PHONE_TYPE_OPTIONS';
import EMAIL_TYPE_OPTIONS from 'shared/constants/EMAIL_TYPE_OPTIONS';

const ContactsToLink = ({
    control,
    account_type,
    clearErrors,
    setError,
    watch,
    trigger
}) => {
    const { fields: addedContacts, prepend: addContact, remove: removeContact, update: updateContact, replace } = useFieldArray({
        control,
        name: 'addedContacts'
    });

    const watchedContacts = watch('addedContacts');

    return (
        <>
            <Header as='h3' color='primary'><Icon size='small' className='fas fa-plus-circle' link
                onClick={() => {
                    addContact({
                        first_name: '', last_name: '', main: false,
                        phones: [{ value: '', type: PHONE_TYPE_OPTIONS.keys.CELL, main: true }],
                        emails: [{ value: '', type: EMAIL_TYPE_OPTIONS.keys.WORK, main: true }],
                    });
                }} /> {StringUtils.capitalize(account_type)} Contacts</Header>
            <List divided verticalAlign='middle'>
                {addedContacts.map((contact, index) => (
                    <List.Item key={contact.id}>
                        {addedContacts.length > 1 && (
                            <Icon className='fas fa-times' style={{ float: 'right' }} link onClick={() => {
                                removeContact(index);
                            }} />
                        )}
                        <Form.Field
                            control={'div'}
                            label='Contact Name'
                        >
                            <ContactOptions
                                contact={contact}
                                control={control}
                                index={index}
                                changePrimaryContact={() => {
                                    replace(watchedContacts.map((watchedContact, index) => {
                                        const contactCopy = addedContacts[index];
                                        return ({
                                            ...contactCopy, ...watchedContact, main: contact.id === contactCopy.id ? !watchedContact.main : false
                                        });
                                    }))
                                }}
                                isOptionAlreadySelected={(contact_id) => {
                                    return watchedContacts.find(addedContact => contact_id === addedContact.contact_id);
                                }}
                                onSelectContact={(contact, index) => {
                                    const emails = contact.email?.email ? [{
                                        value: contact.email.email,
                                        type: contact.email.email_type
                                    }] : [{ value: '', type: EMAIL_TYPE_OPTIONS.keys.WORK, main: true }];
                                    const phones = contact.phone?.phone ? [{
                                        value: contact.phone.phone,
                                        type: contact.phone.phone_type
                                    }] : [{ value: '', type: PHONE_TYPE_OPTIONS.keys.CELL, main: true }];
                                    updateContact(index, {
                                        ...watchedContacts[index],
                                        contact_id: contact.id,
                                        first_name: contact.first_name || '',
                                        last_name: contact.last_name || '',
                                        phones,
                                        emails,
                                        role: contact.role || 'influencer',
                                        job_title: contact.job_title,
                                        new: contact.new,
                                        suffix: contact.suffix || '',

                                        ...(contact.new ? {
                                            address_label: account_type === 'business' ? 'Business' : 'Home',
                                            street: '',
                                            apartmentUnitOrFloor: '',
                                            city: '',
                                            state: '',
                                            zip: '',
                                        } : {})
                                    });
                                }}
                                clearErrors={clearErrors}
                                name='addedContacts'
                                setError={setError}
                                hasRoleSelect
                                roleSelectOptions={AccountUtils.roleOptions}
                                trigger={trigger}
                            />
                        </Form.Field>
                    </List.Item>
                ))}
            </List>
        </>
    )
}
export default ContactsToLink;
