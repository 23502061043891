import pipelineActions from 'actions/pipeline';
import { STATUS_TYPES, Notification, generateResolver, yup, NestedDropdown } from 'dyl-components';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'semantic-ui-react';

import accountActions from 'actions/account';

const LostForm = ({ contact_id, account_id, pipeline_id, account_stage, refresh, type = 'pipeline' }) => {
    const dispatch = useDispatch();

    const { isReadingCloseReasons, isSettingAccountAsLost, isClosingCustomerPipeline, isClosingCustomer } = useSelector(state => ({
        isReadingCloseReasons: state.pipeline.isReadingCloseReasons,
        isSettingAccountAsLost: state.pipeline.isSettingAccountAsLost,
        isClosingCustomerPipeline: state.pipeline.isClosingCustomerPipeline,
        isClosingCustomer: state.account.customerBeingPaused || state.account.customerBeingCanceled,
    }));

    const lostReasons = useSelector(state => state.pipeline.close_reasons.map(reason => ({
        key: reason.id,
        value: reason.id,
        text: reason.name,
        options: (
            reason.close_types.map(type => ({
                key: type.id,
                value: type.id,
                text: type.name
            })) || []
        )
    })) || [])

    const isCustomer = account_stage === 'customer';
    const { formState: { isDirty, isValid }, handleSubmit, control } = useForm({
        mode: 'onChange',
        defaultValues: {
            lost_reason: null,
            lost_type: null,
            notes: ''
        },
        resolver: generateResolver({
            lost_reason: yup.string().required(),
            lost_type : yup.string().required()
        })
    });

    const onConfirm = async (data) => {
        const payload = {
            account_id,
            close_reason_id: Number(data.lost_reason),
            close_type_id: Number(data.lost_type),
            contact_id,
            note: data.notes
        }

        const result = (() => {
            if (type === 'pipeline') {
                return 'lost'
            }
            if (type === 'paused') {
                return 'paused'
            }
            return 'canceled'
        })();

        let SuccessMessage = `Set ${account_stage} as ${result}`;
        try {
            if (type === 'pipeline') {
                if (!isCustomer) {
                    await dispatch(pipelineActions.setModuleAsLost(pipeline_id, payload));
                } else {
                    await dispatch(pipelineActions.closeCustomerPipeline(account_id, payload, null, pipeline_id));
                    SuccessMessage = 'Successfully closed pipeline';
                }
            } else if (type === 'paused') {
                await dispatch(accountActions.pauseCustomer(payload, null, account_id));
            } else {
                await dispatch(accountActions.cancelCustomer(payload, null, account_id));
            }
            Notification.alert(SuccessMessage, STATUS_TYPES.SUCCESS);
            if (refresh) {
                refresh(pipeline_id);
            }
        } catch (error) {
            console.log(error);
            Notification.alert(`Failed to set ${account_stage} as ${result}`, STATUS_TYPES.ERROR);
        }
    }

    useEffect(() => {
        dispatch(pipelineActions.readCloseReasons({ account_stage, close_reason_type: type }))
    }, [dispatch, account_stage, type]);

    const action = (() => {
        if (type === 'pipeline') {
            if (isCustomer) {
                return 'Close';
            }
            return 'Lost'
        }
        if (type === 'paused') {
            return 'Pause'
        }
        return 'Cancel'
    })();

    return (
        <Form noValidate style={{ width: "15em" }} loading={isSettingAccountAsLost || isReadingCloseReasons || isClosingCustomerPipeline || isClosingCustomer}>
            <Controller
                name='lost_reason'
                control={control}
                render={({ field: { name: parentName, value: parentValue, onChange: parentOnChange } }) => (
                    <Controller
                        name='lost_type'
                        control={control}
                        render={({ field: { name, value, onChange } }) => (
                            <Form.Field
                                control={NestedDropdown}
                                allowedToSelectParentOnly
                                child_value={value}
                                parent_value={parentValue}
                                loading={isReadingCloseReasons}
                                nested_options={lostReasons}
                                onChange={(_, { parent_value, child_value }) => {
                                    parentOnChange({ target: { name: parentName, value: parent_value } });
                                    onChange({ target: { name, value: child_value } });
                                }}
                                placeholder={`Select ${action} Reason/Type`}
                                display_parent
                                selection
                                label={`${action} Reason/Type`}
                                required
                                pointing='top'
                            />
                        )}
                    />
                )}
            />
            <Controller
                name='notes'
                control={control}
                render={({ field: { name, value, onChange } }) => (
                    <Form.TextArea
                        label={`${action} Reason Notes`}
                        name='notes'
                        onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                        value={value}
                        placeholder='Enter Note...'
                    />
                )}
            />
            <Form.Button onClick={handleSubmit(onConfirm)} loading={isSettingAccountAsLost || isReadingCloseReasons || isClosingCustomerPipeline || isClosingCustomer} disabled={(isSettingAccountAsLost || isReadingCloseReasons || isClosingCustomerPipeline) || (!isValid || (!isDirty && !isCustomer)) || isClosingCustomer} type='button' primary floated='right'>
                Save
            </Form.Button>
        </Form>
    );
};

export default LostForm;
