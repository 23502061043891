import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Checkbox, Icon } from "semantic-ui-react";
import {
    ClippedContent,
    ContactCard,
    DateTimeUtils,
    DYLAvatar,
    OnHoverOnClick,
    Person,
    Table,
} from "dyl-components";

import { BulkActionsContext } from "shared/context/BulkActionsProvider";
import {
    KEY_ACCOUNT_CO_OWNER,
    KEY_ACCOUNT_NAME,
    KEY_OPPORTUNITY_OUTCOME,
    KEY_ACCOUNT_STATUS,
    KEY_ACCOUNT_OWNER,
    KEY_CONTACT_CO_OWNER,
    KEY_CONTACT_NAME,
    KEY_CONTACT_OWNER,
    KEY_CREATED,
    KEY_CREATED_BY,
    KEY_EXPIRES_ON,
    KEY_LAST_MODIFIED,
    KEY_LAST_MODIFIED_BY,
    KEY_NUMBER_PRODUCTS,
    KEY_OPPORTUNITY_NAME,
    KEY_PRODUCTS,
    KEY_PROPOSAL_INVOICE_CREATED,
    KEY_STATUS,
    KEY_SUBTOTAL,
} from "shared/constants/QUOTES_ORDERS_COLUMNS";
import HoverableIconWithList from "shared/HoverableIconWithList";
import Contact from "shared/Contact";
import ModuleLink from "shared/ModuleLink";
import { PhoneUtil, StringUtils } from "utils";
import QuoteID from "shared/QuoteID";
import { QuoteBuilderContext } from "shared/context/QuoteBuilderProvider";

const QuotesAndOrdersRow = ({ entry, isColumnHidden, onView }) => {
    const {
        id_type: type,
        id: entry_id,
        contact,
        account,
        opportunity,
    } = entry;

    const [selectedEntries, setSelectedEntries] =
        useContext(BulkActionsContext);

    const isSelected = (entry_id) => {
        return selectedEntries.includes(entry_id);
    };

    const onToggleContact = (entry_id) => {
        const newSelectedContacts = isSelected(entry_id)
            ? selectedEntries.filter((id) => entry_id !== id)
            : [...selectedEntries, entry_id];
        setSelectedEntries(newSelectedContacts);
    };

    const key = `${type}-${entry_id}`;

    const formatStatus = (status) => {
        if (!status) {
            return "";
        }
        const statusSplit = status.split("/");
        if (statusSplit[0] !== "rejected") {
            return StringUtils.capitalize(statusSplit[0]);
        }
        const rejectReason = statusSplit[1].split("-");
        return `${StringUtils.capitalize(statusSplit[0])}/${
            rejectReason[0] === "price"
                ? "Priced"
                : StringUtils.capitalize(rejectReason[0])
        } ${rejectReason.slice(1, rejectReason.length).join(" ")}`;
    };

    const navigate = useNavigate();

    const hoverContent = (user) => {
        if (!user?.id) {
            return <div> No User Selected </div>;
        }
        return (
            <ContactCard
                userInfo={{
                    ...user,
                    phone: PhoneUtil.formatPhoneNumber(user.phone)
                }}
                toNavigate={() =>
                    navigate(`/settings/users/${user.id}/general`, {
                        target: "_blank",
                    })
                }
            />
        );
    };

    const { onEditQuote, onEditOrder, onViewProposal, onViewInvoice } = useContext(QuoteBuilderContext);

    const onEdit = type === "quote" ? () => {
        onEditQuote(entry_id);
    } : () => {
        onEditOrder(entry_id, account?.id);
    };

    const onViewPdf = type === "quote" ? () => {
        onViewProposal(entry.quote_pdf_id)
    } : () => {
        onViewInvoice(entry.quote_pdf_id)
    }

    return (
        <Table.Row>
            <Table.Cell>
                <Checkbox
                    checked={isSelected(key)}
                    onChange={() => {
                        onToggleContact(key);
                    }}
                />
            </Table.Cell>
            {!isColumnHidden(KEY_CREATED) && (
                <Table.Cell
                    className="QuotesAndOrdersTable__MediumFixedWidth"
                    width={2}
                >
                    <div>
                        <b>
                            {DateTimeUtils.formatEpoch(
                                entry.created,
                                DateTimeUtils.WORD_DATE_FORMAT
                            )}
                        </b>
                    </div>
                    <div>
                        {DateTimeUtils.formatEpoch(
                            entry.created,
                            DateTimeUtils.TIME_FORMAT
                        )}
                    </div>
                </Table.Cell>
            )}
            {!isColumnHidden(KEY_LAST_MODIFIED) && (
                <Table.Cell
                    className="QuotesAndOrdersTable__MediumFixedWidth"
                    width={2}
                >
                    <div>
                        <b>
                            {DateTimeUtils.formatEpoch(
                                entry.last_modified,
                                DateTimeUtils.WORD_DATE_FORMAT
                            )}
                        </b>
                    </div>
                    <div>
                        {DateTimeUtils.formatEpoch(
                            entry.last_modified,
                            DateTimeUtils.TIME_FORMAT
                        )}
                    </div>
                </Table.Cell>
            )}
            {!isColumnHidden(KEY_PROPOSAL_INVOICE_CREATED) && (
                <Table.Cell
                    className="QuotesAndOrdersTable__MediumFixedWidth"
                    width={2}
                >
                    {entry.proposal_invoice_created && (
                        <>
                            <div>
                                <b>
                                    {DateTimeUtils.formatEpoch(
                                        entry.proposal_invoice_created,
                                        DateTimeUtils.WORD_DATE_FORMAT
                                    )}
                                </b>
                            </div>
                            <div>
                                {DateTimeUtils.formatEpoch(
                                    entry.proposal_invoice_created,
                                    DateTimeUtils.TIME_FORMAT
                                )}
                            </div>
                        </>
                    )}
                </Table.Cell>
            )}
            {!isColumnHidden(KEY_EXPIRES_ON) && (
                <Table.Cell
                    className="QuotesAndOrdersTable__MediumFixedWidth"
                    width={2}
                >
                    <div>
                        <b>
                            {entry.expires &&
                                DateTimeUtils.formatEpoch(
                                    entry.expires,
                                    DateTimeUtils.WORD_DATE_FORMAT
                                )}
                        </b>
                    </div>
                </Table.Cell>
            )}
            <Table.Cell
                className="QuotesAndOrdersTable__MediumFixedWidth"
                width={2}
            >
                <div>
                    <strong>{StringUtils.capitalize(type)}</strong>
                </div>
                <div>
                    <QuoteID
                        onEditQuote={() => {
                            onView(entry_id);
                            onEdit();
                        }}
                        id={entry_id}
                        hasPdf={entry.proposal_invoice_created && (entry.quote_pdf_id || entry.invoice_id)}
                        onViewProposal={() => {
                            onView(entry_id)
                            onViewPdf();
                        }}
                    />
                </div>
            </Table.Cell>
            {!isColumnHidden(KEY_NUMBER_PRODUCTS) && (
                <Table.Cell
                    className="QuotesAndOrdersTable__MediumFixedWidth"
                    width={2}
                >
                    {entry.number_products_bundles}
                </Table.Cell>
            )}
            {!isColumnHidden(KEY_PRODUCTS) && (
                <Table.Cell
                    className="QuotesAndOrdersTable__MediumFixedWidth"
                    width={2}
                >
                    <HoverableIconWithList
                        icon={
                            <Icon className="fa-solid fa-box" color="primary" />
                        }
                        iconContent={"Products"}
                        items={entry?.products}
                    />
                    <HoverableIconWithList
                        icon={
                            <Icon
                                className="fa-solid fa-boxes-stacked"
                                color="primary"
                            />
                        }
                        iconContent={"Bundles"}
                        items={entry?.bundles}
                    />
                </Table.Cell>
            )}
            {!isColumnHidden(KEY_CONTACT_OWNER) && (
                <Table.Cell
                    className="QuotesAndOrdersTable__MediumFixedWidth"
                    width={2}
                >
                    <Person
                        username={entry.contact_owner}
                        email={entry.contact_owner_email}
                    />
                </Table.Cell>
            )}
            {!isColumnHidden(KEY_CONTACT_CO_OWNER) && (
                <Table.Cell
                    className="QuotesAndOrdersTable__MediumFixedWidth"
                    width={2}
                >
                    <Person
                        username={entry.contact_co_owner}
                        email={entry.contact_co_owner_email}
                    />
                </Table.Cell>
            )}
            {!isColumnHidden(KEY_ACCOUNT_OWNER) && (
                <Table.Cell
                    className="QuotesAndOrdersTable__MediumFixedWidth"
                    width={2}
                >
                    <Person
                       username={entry?.account?.owner?.username}
                       email={entry?.account?.owner?.email}
                    />
                </Table.Cell>
            )}
            {!isColumnHidden(KEY_ACCOUNT_CO_OWNER) && (
                <Table.Cell
                    className="QuotesAndOrdersTable__MediumFixedWidth"
                    width={2}
                >
                    <Person
                        username={entry.account_co_owner}
                        email={entry.account_co_owner_email}
                    />
                </Table.Cell>
            )}
            {!isColumnHidden(KEY_CONTACT_NAME) && (
                <Table.Cell
                    className="QuotesAndOrdersTable__MediumFixedWidth"
                    width={4}
                >
                    {Boolean(contact?.id) && (
                        <Contact
                            contact={{
                                ...(contact || {}),
                                email: contact?.email,
                                phone: contact?.phone || {},
                            }}
                            name={contact?.name}
                            linkToContact={
                                <Link
                                    to={`/contact/${contact?.id}`}
                                    target="_blank"
                                >{contact?.name}</Link>
                            }
                        />
                    )}
                </Table.Cell>
            )}
            {!isColumnHidden(KEY_ACCOUNT_NAME) && (
                <Table.Cell
                    className="QuotesAndOrdersTable__LargeFixedWidth"
                    width={4}
                >
                    {account?.id && (
                        <Contact
                            contact={{
                                ...(account || {}),
                                email: account?.email || {},
                                phone: account?.phone || {},
                            }}
                            name={account.name}
                            linkToContact={
                                <Link
                                    to={`/account/${account.id}`}
                                    target="_blank"
                                >
                                    {account?.name}
                                </Link>
                            }
                        />
                    )}
                </Table.Cell>
            )}
            {!isColumnHidden(KEY_OPPORTUNITY_NAME) && (
                <Table.Cell
                    className="QuotesAndOrdersTable__MediumFixedWidth"
                    width={3}
                >
                    {opportunity?.id && (
                        <>
                            <div style={{ width: 170 }}>
                                <ClippedContent>
                                    {opportunity.name}
                                </ClippedContent>
                            </div>
                            <div>
                                <ModuleLink
                                    pipeline_id={opportunity.id}
                                    includeLabel
                                    unlinked={opportunity.unlinked}
                                />
                            </div>
                        </>
                    )}
                </Table.Cell>
            )}
            {!isColumnHidden(KEY_CREATED_BY) && (
                <Table.Cell
                    className="QuotesAndOrdersTable__MediumFixedWidth"
                    width={2}
                >
                    <OnHoverOnClick
                        hoverContent={hoverContent(entry.created_by)}
                        clickContent={null}
                        trigger={
                            entry.created_by ? (
                                <DYLAvatar
                                    name={entry.created_by.username}
                                    disabled
                                    size="2.5em"
                                />
                            ) : undefined
                        }
                    />
                </Table.Cell>
            )}
            {!isColumnHidden(KEY_LAST_MODIFIED_BY) && (
                <Table.Cell
                    className="QuotesAndOrdersTable__MediumFixedWidth"
                    width={2}
                >
                    <OnHoverOnClick
                        hoverContent={hoverContent(entry.last_modified_by)}
                        clickContent={null}
                        trigger={
                            entry.last_modified_by ? (
                                <DYLAvatar
                                    name={entry.last_modified_by.username}
                                    disabled
                                    size="2.5em"
                                />
                            ) : undefined
                        }
                    />
                </Table.Cell>
            )}
            {!isColumnHidden(KEY_OPPORTUNITY_OUTCOME) && (
                <Table.Cell
                    className="QuotesAndOrdersTable__MediumFixedWidth"
                    width={3}
                >
                    {Boolean(opportunity?.id) && (
                        opportunity.outcome ? (
                            <h5
                                className={`QuotesAndOrdersTable__Outcome${
                                    opportunity.outcome.split("(")[0] === "won"
                                        ? "--won"
                                        : "--lost"
                                }`}
                            >
                                {opportunity.outcome.charAt(0).toUpperCase() +
                                    opportunity.outcome
                                        .slice(1)
                                        .replace("(", " (")}
                            </h5>
                        ) : (
                            ""
                        )
                    )}
                </Table.Cell>
            )}
            {!isColumnHidden(KEY_ACCOUNT_STATUS) && (
                <Table.Cell
                    className="QuotesAndOrdersTable__MediumFixedWidth"
                    width={3}
                >
                    {Boolean(account?.id) && (
                        <h5>{account?.account_status}</h5>
                    )}
                </Table.Cell>
            )}
            {!isColumnHidden(KEY_SUBTOTAL) && (
                <Table.Cell
                    className="QuotesAndOrdersTable__MediumFixedWidth"
                    width={2}
                >
                    {entry.subtotal &&
                        `$${StringUtils.formatDecimal(
                            String(entry.subtotal),
                            true
                        )}`}
                </Table.Cell>
            )}
            {!isColumnHidden(KEY_STATUS) && (
                <Table.Cell
                    className="QuotesAndOrdersTable__MediumFixedWidth"
                    width={3}
                >
                    {formatStatus(entry.status)}
                </Table.Cell>
            )}
        </Table.Row>
    );
};

export default QuotesAndOrdersRow;
