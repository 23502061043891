import {
    Document,
    Page,
    StyleSheet,
    Text,
    View,
} from "@react-pdf/renderer";
import { DateTimeUtils } from "dyl-components";
import React from "react";
import Logo from "shared/PDFViewer/Logo";
import PersonInformation from "shared/PDFViewer/PersonInformation";
import { MathUtils, PhoneUtil, StringUtils } from "utils";

const eCheckTypes = {
    "savings": "Personal Savings",
    "checking": "Personal Checking",
    "businessChecking": "Business Checking"
}

const columnWidth = "14.28%";
const styles = StyleSheet.create({
    page: { padding: 20 },
    header: {
        fontSize: 12,
        paddingBottom: 12,
        paddingTop: 12,
    },
    grid: {
        display: "flex",
        flexDirection: "row",
    },
    personInfo: {
        paddingRight: 100,
    },
    table: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderColor: "#bfbfbf",
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
    },
    tableRow: {
        margin: "auto",
        flexDirection: "row",
    },
    tableColHeader: {
        width: columnWidth,
        borderStyle: "solid",
        borderColor: "#bfbfbf",
        borderBottomColor: "#000",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableCol: {
        width: columnWidth,
        borderStyle: "solid",
        borderColor: "#bfbfbf",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableCellHeader: {
        fontSize: 8,
        fontWeight: 500,
        padding: 4,
    },
    tableCell: {
        fontSize: 6,
        padding: 4,
    },
    text: {
        fontSize: 8,
        marginBottom: 4,
    },
    textMedium: {
        fontSize: 10,
        paddingTop: 8,
        paddingBottom: 8,
    },
    divider: {
        borderBottom: "1pt solid #bfbfbf",
    },
});

const Addons = ({ addons, index, quantity }) =>
    addons.map((addon) => (
        <View key={`item-${index}-${addon.addon_id}`} style={styles.tableRow}>
            <View style={styles.tableCol}>
                <Text
                    style={{
                        ...styles.tableCell,
                        fontWeight: 750,
                        marginLeft: "10pt",
                    }}
                >
                    {addon.name}
                </Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{addon.description}</Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}></Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                    ${StringUtils.formatDecimal(`${addon.price}`)}
                </Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{quantity}</Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}></Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                    ${StringUtils.formatDecimal(`${addon.price * quantity}`)}
                </Text>
            </View>
        </View>
    ));

const Fees = ({ fees, type = "fee", grossTotal, index, quantity }) =>
    fees.map((fee, feeIndex) => (
        <View key={`item-${index}-${type}-${feeIndex}`} style={styles.tableRow}>
            <View style={styles.tableCol}>
                <Text
                    style={{
                        ...styles.tableCell,
                        fontWeight: 750,
                        marginLeft: "10pt",
                    }}
                >
                    *{fee.name}
                </Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{fee.description}</Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}></Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                    {!fee.percent ? "$" : ""}
                    {fee.amount}
                    {fee.percent ? "%" : ""}
                </Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{quantity}</Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}></Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                    $
                    {StringUtils.formatDecimal(
                        `${MathUtils.roundDecimals(
                            fee.amount *
                            (fee.percent ? 0.01 * grossTotal : quantity)
                        )}`
                    )}
                </Text>
            </View>
        </View>
    ));

const filterAdditions = (meta_data, type) => {
    return meta_data.filter(addition => addition.type === type);
}

// TODO: display due date
const Summary = ({ cart }) => {
    const { grossTotal, totalDiscount, totalTax, totalFee, subtotal } =
        cart.reduce(
            (a, item) => {
                const price = item.item.price;
                const {
                    grossTotal,
                    totalTax,
                    totalFee,
                    subtotal,
                    discountAmount,
                } = MathUtils.calculateItemTotals({
                    price,
                    additional_price: item.item.variant?.additional_price || 0,
                    quantity: item.item.quantity,
                    discount: item.item.discount,
                    addons: filterAdditions(item.meta_data, "addon"),
                    taxes: filterAdditions(item.meta_data, "tax").map(tax => ({
                        ...tax,
                        amount: tax.price
                    })),
                    fees: filterAdditions(item.meta_data, "fee").map(fee => ({
                        ...fee,
                        amount: fee.price
                    })),
                });

                return {
                    grossTotal: a.grossTotal + grossTotal,
                    totalDiscount: a.totalDiscount + discountAmount,
                    totalTax: a.totalTax + totalTax,
                    totalFee: a.totalFee + totalFee,
                    subtotal: a.subtotal + subtotal,
                };
            },
            {
                grossTotal: 0,
                totalDiscount: 0,
                totalTax: 0,
                totalFee: 0,
                subtotal: 0,
            }
        );

    return (
        <View
            style={{
                border: "1pt solid rgba(191, 191, 191)",
                padding: 20,
                width: "40%",
            }}
        >
            <View style={{ ...styles.grid, ...styles.divider }}>
                <View>
                    <Text style={styles.header}>Invoice</Text>
                </View>
            </View>
            <View
                style={{
                    ...styles.grid,
                    ...styles.divider,
                    justifyContent: "space-between",
                }}
            >
                <View>
                    <Text style={{ ...styles.textMedium }}>Total</Text>
                </View>
                <View>
                    <Text style={{ ...styles.textMedium, color: "#2b78ff" }}>
                        $
                        {`${StringUtils.formatDecimal(
                            `${MathUtils.roundDecimals(grossTotal)}`,
                            true
                        )}`}
                    </Text>
                </View>
            </View>
            <View style={{ ...styles.grid, justifyContent: "space-between" }}>
                <View>
                    <Text style={{ ...styles.textMedium }}>Discount</Text>
                </View>
                <View>
                    <Text style={{ ...styles.textMedium, color: "#2b78ff" }}>
                        -$
                        {StringUtils.formatDecimal(
                            `${MathUtils.roundDecimals(totalDiscount)}`,
                            true
                        )}
                    </Text>
                </View>
            </View>
            <View style={{ ...styles.grid, justifyContent: "space-between" }}>
                <View>
                    <Text style={{ ...styles.textMedium }}>Total Fee</Text>
                </View>
                <View>
                    <Text style={{ ...styles.textMedium, color: "#2b78ff" }}>
                        $
                        {StringUtils.formatDecimal(
                            `${MathUtils.roundDecimals(totalFee)}`,
                            true
                        )}
                    </Text>
                </View>
            </View>
            <View
                style={{
                    ...styles.grid,
                    ...styles.divider,
                    justifyContent: "space-between",
                }}
            >
                <View>
                    <Text style={{ ...styles.textMedium }}>Total Tax</Text>
                </View>
                <View>
                    <Text style={{ ...styles.textMedium, color: "#2b78ff" }}>
                        $
                        {StringUtils.formatDecimal(
                            `${MathUtils.roundDecimals(totalTax)}`,
                            true
                        )}
                    </Text>
                </View>
            </View>
            <View style={{ ...styles.grid, justifyContent: "space-between" }}>
                <View>
                    <Text style={{ ...styles.textMedium }}>Subtotal</Text>
                </View>
                <View>
                    <Text style={{ ...styles.textMedium, color: "#2b78ff" }}>
                        $
                        {StringUtils.formatDecimal(
                            `${MathUtils.roundDecimals(subtotal)}`,
                            true
                        )}
                    </Text>
                </View>
            </View>
        </View>
    );
};

const InvoicePDF = ({ invoice }) => {
    const ship_to = invoice.shipping?.ship_to;
    const shipping = invoice.shipping?.location;
    const { payment_type, ...otherPaymentInfo } = invoice.payment_data;
    const isEcheckPayment = payment_type === "e_check";
    const isDemo = Object.keys(invoice?.payment_data || {}).length === 0;
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View
                    style={{ ...styles.grid, justifyContent: "space-between" }}
                >
                    <View>
                        <Logo />
                    </View>
                    <View>
                        <Text style={styles.text}>
                            Date:{" "}
                            {DateTimeUtils.changeFormat(
                                DateTimeUtils.convertUnixTimeToDate(
                                    invoice.created
                                ),
                                "",
                                DateTimeUtils.DATE_FORMAT
                            )}
                        </Text>
                    </View>
                </View>
                <View style={styles.grid}>
                    <PersonInformation
                        info={{
                            heading: `Bill To:`,
                            subheading: invoice.account?.name || "",
                            phone: PhoneUtil.formatPhoneNumber(
                                invoice.billing.phone
                            ),
                            email: invoice.billing.email,
                            location:
                                invoice.billing.address,
                        }}
                    />
                    {ship_to && (
                        <PersonInformation
                            info={{
                                heading: `Ship To:`,
                                subheading: [ship_to.first_name, ship_to.last_name, ship_to.suffix].filter(part => part).join(" "),
                                others: invoice.account?.name || "",
                                phone: PhoneUtil.formatPhoneNumber(
                                    shipping.phone
                                ),
                                email: shipping.email,
                                location: shipping.address,
                            }}
                        />
                    )}
                </View>
                <View>
                    <Text style={{ ...styles.header, color: "#2b78ff" }}>
                        Summary
                    </Text>
                </View>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Products</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>
                                Description
                            </Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>
                                Pricing Schedule
                            </Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Price</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Quantity</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Prorated</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Subtotal</Text>
                        </View>
                    </View>
                    {invoice.invoice_items.map((item, index) => {
                        const price = item.item.price;
                        const addons = filterAdditions(item.meta_data, "addon");
                        const taxes = filterAdditions(item.meta_data, "tax").map(tax => ({
                            ...tax,
                            amount: tax.price
                        }));
                        const fees = filterAdditions(item.meta_data, "fee").map(fee => ({
                            ...fee,
                            amount: fee.price
                        }));
                        const { grossTotal, subtotal } =
                            MathUtils.calculateItemTotals({
                                price,
                                additional_price:
                                    item.item.variant?.additional_price || 0,
                                quantity: item.item.quantity,
                                discount: item.item.discount,
                                addons,
                                taxes,
                                fees,
                            });
                        const variant = item.item?.variant || {};
                        return (
                            <React.Fragment key={`item-${index}`}>
                                <View
                                    style={styles.tableRow}
                                >
                                    <View style={styles.tableCol}>
                                        <Text
                                            style={{
                                                ...styles.tableCell,
                                                fontWeight: 750,
                                            }}
                                        >
                                            <Text>
                                                {index + 1}. {item.item.name}{" "}
                                            </Text>
                                            <Text
                                                style={{
                                                    fontSize: 5,
                                                    marginLeft: "8pt",
                                                }}
                                            >
                                                {Boolean(
                                                    Object.keys(variant).length
                                                ) &&
                                                    `(${variant.name}/${variant.value})`}
                                            </Text>
                                        </Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>
                                            {item.item.description}
                                        </Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>
                                            {StringUtils.capitalize(
                                                item.item.price_schedule
                                            )}
                                        </Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>
                                            $
                                            {StringUtils.formatDecimal(
                                                `${item.item.price}`
                                            )}
                                        </Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>
                                            {item.item.quantity}
                                        </Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>
                                            {item.prorate}
                                        </Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>
                                            ${MathUtils.roundDecimals(subtotal)}
                                        </Text>
                                    </View>
                                </View>
                                <Addons
                                    addons={addons}
                                    index={index}
                                    quantity={item.item.quantity}
                                />
                                <Fees
                                    fees={fees}
                                    grossTotal={grossTotal}
                                    index={index}
                                    quantity={item.item.quantity}
                                />
                                <Fees
                                    fees={taxes}
                                    grossTotal={grossTotal}
                                    index={index}
                                    quantity={item.item.quantity}
                                    type="tax"
                                />
                            </React.Fragment>
                        );
                    })}
                </View>
                <View
                    style={{
                        ...styles.grid,
                        justifyContent: "flex-end",
                        marginTop: 10,
                    }}
                >
                    <Summary cart={invoice.invoice_items} />
                </View>
                <View>
                    <Text style={styles.header}>
                        Payment Info
                    </Text>
                </View>
                <View style={{
                    border: "1pt solid rgba(191, 191, 191)",
                    padding: 10,
                }}>
                    <Text style={styles.text}>
                        {isDemo ? "Demo Account" : `Paid via ${!isEcheckPayment ? 'card' : 'e-check'}`}
                    </Text>
                    <Text style={styles.text}>
                        {/* TODO: replace this with name of cardholder/bank account owner */}
                        {ship_to && [ship_to.first_name, ship_to.last_name, ship_to.suffix].filter(part => part).join(" ")}
                    </Text>
                    <Text style={styles.text}>
                        {isDemo ? "" : `${isEcheckPayment ? eCheckTypes[otherPaymentInfo.payment_network] : StringUtils.toSentenceCase((payment_type)?.split("_").join(" "))} ${`${otherPaymentInfo.payment_account}`.replace("XXXX", "••••")}${!isEcheckPayment ? ` Exp. ${DateTimeUtils.changeFormat(otherPaymentInfo.expiration_date, "YYYY-DD", "DD/YYYY")}` : ''}`}
                    </Text>
                </View>
                <View>
                    <Text style={styles.header}>
                        Buyer Notes
                    </Text>
                </View>
                <View style={{
                    border: "1pt solid rgba(191, 191, 191)",
                    padding: 10,
                }}>
                    <Text style={styles.text}>
                        {invoice.note}
                    </Text>
                </View>
            </Page>
        </Document>
    );
};

export default InvoicePDF;
