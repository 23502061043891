import React, { useEffect, useState } from "react";
import {
    Modal,
    SearchableOptions,
    generateResolver,
    yup,
    Notification,
    STATUS_TYPES,
    VALIDATORS
} from "dyl-components";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import ringGroupActions from "actions/ring_groups";
import callQueueActions from "actions/call_queues";
import { Form } from "semantic-ui-react";
import CustomOptions from "./subcomponents/customOptions";
import { PhoneUtil } from "utils";
import CallQueueModal from './subcomponents/callQueue';
import pbxConfigActions from "actions/pbx_config";
import RingGroup from './subcomponents/ringGroup';
import "./index.scss";
import FullScreenModalPopUp from "shared/FullScreenModalPopUp";

const typeOptions = [
    {
        text: "Ring Group",
        value: "ring_group",
    },
    {
        text: "Call Queue",
        value: "call_queue",
    },
];

const ringModeOptions = [
    {
        text: "Ring All",
        value: "ringall",
    },
    {
        text: "Round Robin",
        value: "roundrobin",
    },
];

const queueStrategyOptions = [
    {
        key: "ringall",
        value: "ringall",
        text: "Ring All"
    },
    {
        key: "roundrobin",
        value: "roundrobin",
        text: "Round Robin"

    },
    {
        key: "random",
        value: "random",
        text: "Random"
    },
    {
        key: "longest-idle-agent",
        value: "longest-idle-agent",
        text: "Longest Idle"
    }
];

const RING_GROUP_EXCLUDED_PARENTS = [
    "destination_group",
    "personal_voicemail",
    "routing_rule",
    "call_queue",
    "conference_room",
    "parking_lot",
];


const defaultData = {
    type: "ring_group",
    label: "",
    ring_strategy: "",
    ring_time: "",
    intro_sound_id: 0,
    audio_while_ringing_sound_id: 0,
    alias: "",
    view: false,
    members: [],
    destination_id: "",
    destination_parent: "hang_up",
    after_destination_id: "hang_up",
    announce_delay: 15,
    announce_sound_id: "none"
};

const RingGroupModal = ({ open, setOpen, close, data, loading, getGroups }) => {
    const LIMIT = 15;
    const PAGE = 1;
    const dispatch = useDispatch();
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    
    const {
        destinations,
        extensionsList,
        isReadingExtensionsList,
        unusedExtensions,
        isReadingUnusedExtensions,
        isReadingDestinations,
        count,
        soundOptions
    } = useSelector((state) => state.pbx_config);

    const { isAddingMembers, isUpdatingRingGroup } = useSelector(
        (state) => state.ring_group
    );

    const { isCreatingCallQueue, groupBeingUpdated } = useSelector(
        (state) => state.call_queue
    );
    
    const announceAudioOptions = [
        { key: 'none', value: 'none', text: 'None' },
        { key: 'position', value: 'position', text: 'Caller Position' },
        ...(soundOptions?.general?.map(option => ({ key: option.id, value: option.id, text: option.name})) || [])
    ];

    const greetingSoundOptions = [
        { key: 0, value: 0, text: 'Default' },
        ...(soundOptions?.general?.map(option => ({ key: option.id, value: option.id, text: option.name})) || [])
    ]

    const [users, setUsers] = useState({
        data: [],
        isOpen: false,
        count: 0,
    });
    const [search, setSearch] = useState("");
    const [destinationOptions, setDestinationOptions] = useState([]);
    const [internalExtensionOptionsList, setInternalExtensionOptionsList] =
        useState([]);
    const [isSearchResultsVisible, setIsSearchResultsVisible] = useState(false);

    const {
        control,
        formState: { isValid, isDirty },
        getValues,
        handleSubmit,
        reset,
        setError,
        watch,
        setValue
    } = useForm({
        mode: "onChange",
        defaultValues: defaultData,
        resolver: generateResolver({
            type: yup.string().required("This field is required"),
            label: VALIDATORS.TEAM_NAME().required("This field is required"),
            ring_strategy: yup.string().required("This field is required"),
            ring_time: yup
                .number()
                .typeError("This field is required")
                .required("This field is required"),
            after_destination_id: yup
                .mixed()
                .typeError("This field is required")
                .required("This field is required"),
            exit_key: yup.string().when("type", {
                    is: (type) => type === 'call_queue',
                    then: schema => schema.required('This field is required'),
                    otherwise: schema => schema.nullable(true)
                }),
        }),
    });

    const [listExtensionsFilters, setListExtensionsFilters] = useState({
        page: PAGE,
        offset: 0,
    });

    const formatNumbers = (members) => {
        const objects = [];
        members?.forEach((member) => {
            objects.push({
                value: member.extension_id,
                text: member.member,
                id: member.extension_id,
            });
        });
        return objects;
    };

    const formatExtensionsOptions = (unusedExtensions)=>{
    let options = unusedExtensions?.map((extension) => ({
        key: extension,
        value: extension,
        text: extension
        }));
        return options;
    }

    useEffect(() => {
        if (!data.after_destination_id) {
            data.after_destination_id = "hang_up";
            data.destination_parent = "hang_up";
        }

        if(data?.after_destination?.destination_id){
            data.after_destination_id = data?.after_destination?.destination_id;
            data.destination_parent = data?.after_destination?.type;
        }

        data.members = formatNumbers(data?.members?.data);
        data.audio_while_ringing_sound_id = data?.audio_while_ringing_sound?.sound_id || 0; 
        data.intro_sound_id = data?.intro_sound?.sound_id || 0;
        data.announce_sound_id = data?.announce_sound?.sound ? data?.announce_sound?.sound?.sound_id : data?.announce_sound?.mode;

        if (data && data.label) {
            const isPresent = internalExtensionOptionsList.some(
                (option) => option.value === data.alias
            );
            if (!isPresent) {
                const updatedExtensions = PhoneUtil.insertExtension(
                    data.alias,
                    internalExtensionOptionsList
                );
                setInternalExtensionOptionsList(updatedExtensions);
            }
            if (data.mode === "duplicate") {
                data.label = "Duplicate of " + data.label;
            }
            reset(data);
        } else {
            reset(defaultData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, reset, defaultData]);

    useEffect(() => {
        dispatch(pbxConfigActions.getDestinations());
        dispatch(pbxConfigActions.getUnusedExtensions({ type: "alias" }));
        dispatch(pbxConfigActions.getSoundOptions({ category: "general" }));
    }, [dispatch]);

    useEffect(() => {
        const extensionsOptions = formatExtensionsOptions(unusedExtensions);
        setInternalExtensionOptionsList(extensionsOptions);
    }, [unusedExtensions]);

    useEffect(() => {
        const formatedExtensionsOptions =
            extensionsList?.length &&
            extensionsList?.map(({ extension_number, id, user_id, user }) => {
                let isSelected = false;
                getValues("members")?.forEach((selected) => {
                    if (selected.value === id) {
                        isSelected = true;
                    }
                });
                return {
                    id,
                    name: extension_number,
                    user_id,
                    userName: `${user?.first_name || ""} ${
                        user?.last_name || ""
                    }`,
                    value: extension_number,
                    isSelected,
                };
            });
        setUsers((prevUsers) => ({
            ...prevUsers,
            data: formatedExtensionsOptions || [],
            count: count,
        }));
    }, [extensionsList, getValues, count]);

    useEffect(() => {
        if (destinations) {
            const destinationTypesAux = [
                ...PhoneUtil.DESTINATION_TYPES_OPTIONS,
            ];
            const destinationOptionsAux = destinationTypesAux.map(
                (destinationType) => {
                    const { key } = destinationType;
                    const options =
                        destinations[key]?.map(({ destination_id, label }) => {
                            return {
                                key: destination_id,
                                value: destination_id,
                                text: label,
                            };
                        }) || [];
                    return { ...destinationType, options };
                }
            );
            const defaultOption = {
                key: "hang_up",
                value: "hang_up",
                text: "Hang Up",
                options: [],
                isSelectable: true,
            };
            destinationOptionsAux.unshift(defaultOption);
            setDestinationOptions(destinationOptionsAux);
        }
    }, [destinations]);

    const searchFunction = async () => {
        callApiToGetContactOptions();
        setIsSearchResultsVisible(true);
        setUsers({
            ...users,
            isOpen: true,
        });
    };

    const cancelFunction = async () => {
        setUsers({ data: [], isOpen: false });
        setSearch("");
        setIsSearchResultsVisible(false);
    };

    const callApiToGetContactOptions = () => {
        if (search.length >= 2) {
            const params = {
                search,
                page: PAGE,
                limit: LIMIT,
                active: true,
            };
            dispatch(pbxConfigActions.getListOfExtensions(params));
            setListExtensionsFilters({
                ...listExtensionsFilters,
                page: PAGE,
            });
        }
    };

    const checkSelectedContacts = (status, selected_contacts) => {
        const allContacts = [...users.data] || [];
        const selectedContacts = selected_contacts ?? getValues("members");
        allContacts.forEach((contact) => {
            if (!selectedContacts.length) contact.isSelected = false;
            selectedContacts.forEach((selected) => {
                if (selected.value === contact.id) {
                    contact.isSelected = status;
                }
            });
        });

        setUsers({ ...users, data: allContacts });
    };

    const onCheckDuplicatedName = async () => {
        const [label, type] = getValues(["label", "type"]);
        try {
            if (
                data.mode !== "edit" ||
                (data.mode === "edit" && label !== data.label)
            ) {
                const isDuplicated = await dispatch(
                    pbxConfigActions.isDuplicatedName({
                        label,
                        type,
                    })
                );
                if (isDuplicated) {
                    setError("label", {
                        type: "unique",
                        message: "Name already exists!",
                    });
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    const successReset = () => {
        reset();
        setSearch("");
        close(false);
        getGroups();
        setIsSearchResultsVisible(false);
        dispatch(pbxConfigActions.getUnusedExtensions({ type: "alias" }));
    }

    const onSubmit = async (formData) => {
        try {
            const formatedData = {
                ...formData,
                members: formData.members.map(
                    (member) => member.id || member.value
                ),
            };

            if (formatedData.after_destination_id === "hang_up") {
                delete formatedData.after_destination_id;
                delete formatedData.destination_parent;
            }

            if (formatedData.announce_sound_id === 'none' || formatedData.announce_sound_id === "position") {
                formatedData.announce_mode = formatedData.announce_sound_id;
                delete formatedData.announce_sound_id;   
            }

            if(!formatedData.intro_sound_id) {
                delete formatedData.intro_sound_id;   
            }

            if(!formatedData.audio_while_ringing_sound_id) {
                delete formatedData.audio_while_ringing_sound_id;   
            }

            if (getValues("type") === "ring_group") {
                let alertText = "Created";
                if (data.mode === "edit") {
                    //update ring group
                    formatedData.group_number = +formatedData.group_number;
                    formatedData?.members?.map(Number);
                    delete formatedData.id;

                    await dispatch(
                        ringGroupActions.updateRingGrp(data.id, formatedData)
                    );
                    alertText = "Updated";
                } else {
                    //add ring group
                    await dispatch(
                        ringGroupActions.addRingGroupMembers(
                            [formatedData],
                            null,
                            null
                        )
                    );
                }
                Notification.alert(
                    `Successfully ${alertText} Ring Group!`,
                    STATUS_TYPES.SUCCESS,
                    true
                );
                successReset();
            } else {
                let alertText = "Created";
                if (data.mode === "edit") {
                    //update call queue
                    formatedData.group_number = +formatedData.group_number;
                    formatedData?.members?.map(Number);
                    delete formatedData.id;
                    await dispatch(
                        callQueueActions.updateCallQue(data.id, formatedData)
                    );
                    alertText = "Updated";
                }else{
                await dispatch(
                    callQueueActions.addCallQueue(
                        [formatedData],
                        null,
                        null
                    )
                );
            }

                Notification.alert(
                    `Successfully ${alertText} Call Queue!`,
                    STATUS_TYPES.SUCCESS,
                    true
                );
                successReset();
            }
        } catch (e) {
            console.log(e);
            Notification.alert(
                "Failed to create Ring Group",
                STATUS_TYPES.ERROR
            );
        }
    };

    return (
        <React.Fragment>
            <Modal
                size='large'
                open={open}
                onClose={() => {
                    if(isDirty){
                        setIsConfirmationOpen(true)
                    }else{
                        setOpen(false);
                        setIsSearchResultsVisible(false);
                        setSearch("");
                    }
                }}
                className="RingGroup_Modal"
            >
                <Modal.Header>
                    {data.mode === "edit" ? "Edit" : "Create"} Ring Group/Call Queue
                </Modal.Header>
                <Modal.Content style={{ minHeight: "27rem" }}>
                    <Form className="callQueue" loading={loading}>
                        <Form.Group>
                            <Controller
                                name={`type`}
                                control={control}
                                render={({ field: { name, value, onChange } }) => {
                                    return data.mode === "edit" || data.mode === "duplicate" ? (
                                        <Form.Input
                                            name={name}
                                            value={
                                                typeOptions.find(
                                                    (extension_type) =>
                                                        data.type ===
                                                        extension_type.value
                                                )?.text
                                            }
                                            label="Type"
                                            required
                                            readOnly
                                        />
                                    ) : (
                                        <Form.Select
                                            name={name}
                                            value={value}
                                            search
                                            onChange={(_, { value }) => {
                                                onChange({
                                                    target: { name, value },
                                                });
                                            }}
                                            label="Type"
                                            placeholder={`Select type`}
                                            selectOnBlur={false}
                                            options={typeOptions}
                                            width={4}
                                            required
                                        />
                                    );
                                }}
                            />
                        </Form.Group>

                    {getValues("type") === "call_queue" ? 
                        <CallQueueModal
                            control={control}
                            onCheckDuplicatedName={onCheckDuplicatedName}
                            queueStrategyOptions={queueStrategyOptions}
                            greetingSoundOptions={greetingSoundOptions}
                            announceAudioOptions={announceAudioOptions}
                            data={data}
                            destinationOptions={destinationOptions}
                            callQueueExcludedParents={RING_GROUP_EXCLUDED_PARENTS}
                            internalExtensionOptionsList={internalExtensionOptionsList}
                            formatExtensionsOptions={formatExtensionsOptions}
                            unusedExtensions={unusedExtensions}
                            isReadingUnusedExtensions={isReadingUnusedExtensions}
                            isReadingDestinations={isReadingDestinations}
                            watch={watch}
                            setValue={setValue}
                        /> 
                        : 
                        <RingGroup 
                            control={control}
                            onCheckDuplicatedName={onCheckDuplicatedName}
                            data={data}
                            destinationOptions={destinationOptions}
                            ringGroupExcludedParents={RING_GROUP_EXCLUDED_PARENTS}
                            isReadingDestinations={isReadingDestinations}
                            ringModeOptions={ringModeOptions}
                            greetingSoundOptions={greetingSoundOptions}
                            isReadingUnusedExtensions={isReadingUnusedExtensions}
                            internalExtensionOptionsList={internalExtensionOptionsList}
                            formatExtensionsOptions={formatExtensionsOptions}
                            unusedExtensions={unusedExtensions}
                        />
                    }

                        <Form.Group className="extension-container">
                            <Controller
                                name={`members`}
                                control={control}
                                render={({ field: { name, value, onChange } }) => {
                                    return (
                                        <SearchableOptions
                                            label="Extensions"
                                            loading={isReadingExtensionsList}
                                            search={search}
                                            searchFunction={searchFunction}
                                            cancelSearchFunction={cancelFunction}
                                            searchPlaceholder={
                                                "Search extensions and users"
                                            }
                                            width={4}
                                            values={value}
                                            onChangeSearch={(_, { value }) => {
                                                setIsSearchResultsVisible(false);
                                                setSearch(value);
                                            }}
                                            onChangeSelected={(
                                                _,
                                                { value: removedContact }
                                            ) => {
                                                checkSelectedContacts(false, [
                                                    removedContact,
                                                ]);
                                                onChange({
                                                    target: {
                                                        name,
                                                        value: value.filter(
                                                            (item) =>
                                                                item !==
                                                                removedContact
                                                        ),
                                                    },
                                                });
                                            }}
                                            isSearchResultsVisible={
                                                isSearchResultsVisible
                                            }
                                            renderSearchResults={
                                                <CustomOptions
                                                    addClick={(extension) => {
                                                        onChange({
                                                            target: {
                                                                name,
                                                                value: [
                                                                    ...value,
                                                                    extension,
                                                                ],
                                                            },
                                                        });
                                                        checkSelectedContacts(true);
                                                    }}
                                                    contactsList={users?.data || []}
                                                    loading={
                                                        isReadingExtensionsList
                                                    }
                                                    isVisible={
                                                        search?.length > 0 &&
                                                        users?.data?.length > 0
                                                    }
                                                />
                                            }
                                            isPaginationVisible={
                                                users?.data?.length > 0
                                            }
                                            paginationProps={{
                                                boundaryRange: 0,
                                                activePage:
                                                    listExtensionsFilters.page,
                                                ellipsisItem: null,
                                                siblingRange: 2,
                                                totalPages: Math.ceil(
                                                    users.count / LIMIT
                                                ),
                                                onPageChange: async (
                                                    _,
                                                    { activePage }
                                                ) => {
                                                    setListExtensionsFilters({
                                                        ...listExtensionsFilters,
                                                        page: activePage,
                                                    });
                                                    dispatch(
                                                        pbxConfigActions.getListOfExtensions(
                                                            {
                                                                page: activePage,
                                                                limit: LIMIT,
                                                            }
                                                        )
                                                    );
                                                },
                                            }}
                                        />
                                    );
                                }}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Content>

                <Modal.Actions
                    hasSaveButton
                    onSave={handleSubmit(onSubmit)}
                    saveDisabled={ (data.mode !== "duplicate") && (!isValid || !isDirty || isUpdatingRingGroup || isCreatingCallQueue || groupBeingUpdated)}
                    saveOptions={{
                        loading: isAddingMembers || isUpdatingRingGroup || isCreatingCallQueue || groupBeingUpdated,
                    }}
                ></Modal.Actions>
            </Modal>
            <FullScreenModalPopUp
                header={"Changes not saved"}
                subheader={"Are you sure you want to exit?"}
                isOpen={isConfirmationOpen}
                onConfirm={() => {
                    setOpen(false);
                    setIsSearchResultsVisible(false);
                    setSearch("");
                    setIsConfirmationOpen(false);
                }}
                onFormClose={() => setIsConfirmationOpen(false)}
                closeIcon={false}
            />
        </React.Fragment>
    );
};

export default RingGroupModal;
