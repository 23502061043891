import { Button, generateResolver, Notification, STATUS_TYPES, yup } from "dyl-components";
import React, { useEffect, useState } from "react";
import { Controller, useController, useForm } from "react-hook-form";
import { Form, Grid, Header, Segment } from "semantic-ui-react";
import SignatureCanvasForm from "shared/forms/SignatureCanvasForm";

import './index.scss';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import authService from "actions/auth/auth";

const TermsOfService = () => {
    const [isSigning, setIsSigning] = useState(false);
    const {
        control,
        formState: { isValid, isDirty },
        handleSubmit,
        reset
    } = useForm({
        defaultValues: {
            accepted: false,
            signature: null,
            name: ""
        },
        resolver: generateResolver({
            accepted: yup.boolean().required("This field is required"),
            name: yup.string().no_whitespace_only().no_excessive_whitespaces().maxlength(64).simple_alphanumeric().required("This field is required"),
            signature: yup.string().required("This field is required")
        }),
        mode: "onChange",
    });

    const { field: signatureField } = useController({
        control,
        name: "signature"
    });

    const dispatch = useDispatch();

    const onAccept = async () => {
        try {
            await dispatch(authService.signTerms(null, {}, { signed: true }));
            dispatch(authService.check());
        } catch (e) {
            Notification.alert("Failed to sign terms", STATUS_TYPES.ERROR);
        }
    }

    const { signed_terms, isSigningTerms } = useSelector(state => state.auth);
    const navigate = useNavigate();

    useEffect(() => {
        if (signed_terms) {
            navigate("/");
        }
    }, [navigate, signed_terms]);

    return (
        <div className="TermsOfServiceWrapper">
            <Segment className="TermsOfService">
                <Form noValidate loading={isSigningTerms}>
                    <Grid>
                        <Grid.Row columns={"equal"}>
                            <Grid.Column textAlign="center" width={12}>
                                <Header color="primary">DYL Terms of Service</Header>
                            </Grid.Column>
                            <Grid.Column>
                                <Header color="primary">Accept Terms of Service</Header>
                                <p>Verify that you accept the DYL Terms of Service</p>
                                <Controller
                                    control={control}
                                    name="accepted"
                                    render={({ field: { name, value, onChange } }) => (
                                        <Form.Checkbox
                                            checked={value}
                                            onChange={(_, { checked }) => {
                                                onChange({
                                                    target: { name, value: checked },
                                                });
                                            }}
                                            label="I accept the DYL Terms of Service."
                                        />
                                    )}
                                />
                                <Controller 
                                    control={control}
                                    name="name"
                                    render={({ field: { name, value, onChange } }) => (
                                        <Form.Input 
                                            label="Name"
                                            required
                                            onChange={(_, { value }) => {onChange({ target: { name, value } })}}
                                            value={value}
                                        />
                                    )}
                                />
                                <Form.Field control='div' label="Signature" required>
                                    {signatureField.value ? (
                                        <>
                                            <img className={"TermsOfService__signature"} alt='signature' src={signatureField.value} />
                                            <Button onClick={() => {setIsSigning(true)}}>
                                                Change Signature
                                            </Button>
                                        </>
                                    ) : (
                                        <Button onClick={() => {setIsSigning(true)}}>Add Signature</Button>
                                    )}
                                </Form.Field>
                                <Form.Group widths={2}>
                                    <Button disabled={!isDirty} onClick={() => {reset()}} inverted basic>Clear</Button>
                                    <Button disabled={!isValid || !isDirty} onClick={handleSubmit(onAccept)} >Accept</Button>
                                </Form.Group>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <SignatureCanvasForm 
                        open={isSigning}
                        onClose={() => {setIsSigning(false)}}
                        name={signatureField.name}
                        onChange={(_, { name, value }) => {signatureField.onChange({ target: { name, value } })}}
                    />
                </Form>
            </Segment>
        </div>
    );
};

export default TermsOfService;
