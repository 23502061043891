import React from 'react';
import { DateInput } from 'dyl-components';
import { Accordion, Form, Icon, Input, Segment } from 'semantic-ui-react';
import { connect, useSelector } from 'react-redux';

import { CustomGroupUtils, RecordUtils } from 'utils';

import { STATES } from 'shared/constants/STATES';
import { Controller, useFieldArray } from 'react-hook-form';

import contactDuplicatesActions from 'actions/contact_duplicates';
import RecordPhonesForm from 'shared/forms/RecordPhonesForm';
import RecordEmailsForm from 'shared/forms/RecordEmailsForm';
import LocationUtils from 'utils/LocationUtils';
import EMAIL_TYPE_OPTIONS from 'shared/constants/EMAIL_TYPE_OPTIONS';
import PHONE_TYPE_OPTIONS from 'shared/constants/PHONE_TYPE_OPTIONS';

const ContactFields = ({
    control,
    clearErrors,
    setError,
    trigger,
    fieldNamePrefix = '',

    checkDuplicates
}) => {
    const accordionIcon = {
        className: `fas fa-circle-chevron-right`,
        color: 'primary'
    };

    const { fields: phones, append: addPhone, remove: removePhone, update: updatePhone } = useFieldArray({
        control,
        name: `${fieldNamePrefix}phones`
    });

    const { fields: emails, append: addEmail, remove: removeEmail, update: updateEmail } = useFieldArray({
        control,
        name: `${fieldNamePrefix}emails`
    });

    const { household_details_fields, business_details_fields, isReadingCustomFields } = useSelector(state => {
        const standard_groups = (state.custom_fields_group.contact_standard_group.children || []).filter(group => group.standard);
        return {
            household_details_fields: standard_groups.find(group => group.group_name === 'household_details')?.fields?.data || [],
            business_details_fields: standard_groups.find(group => group.group_name === 'business_details')?.fields?.data || [],
            isReadingCustomFields: state.custom_fields_group.isReadingContactStandardFieldGroup
        };
    });

    return (
        [
            <Form.Group widths='equal'>
                <Controller
                    name={`${fieldNamePrefix}first_name`}
                    control={control}
                    render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                        <Form.Input
                            name={name}
                            value={value}
                            onChange={(_, { value }) => {
                                onChange({ target: { name, value } })
                            }}
                            required
                            label='First Name'
                            error={error?.message}
                            placeholder='Enter first name'
                        />
                    )}
                />
                <Form.Field>
                    <Form.Group>
                        <Controller
                            name={`${fieldNamePrefix}last_name`}
                            control={control}
                            render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                <Form.Input
                                    name={name}
                                    value={value}
                                    onChange={(_, { value }) => {
                                        onChange({ target: { name, value } })
                                    }}
                                    required
                                    label='Last Name'
                                    error={error?.message}
                                    placeholder='Enter last name'
                                    width={12}
                                />
                            )}
                        />
                        <Controller
                            name={`${fieldNamePrefix}suffix`}
                            control={control}
                            render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                <Form.Input
                                    name={name}
                                    value={value}
                                    onChange={(_, { value }) => {
                                        onChange({ target: { name, value } })
                                    }}
                                    label='Suffix'
                                    placeholder='Suff.'
                                    width={4}
                                    error={error?.message}
                                />
                            )}
                        />
                    </Form.Group>
                </Form.Field>
                <Controller
                    name={`${fieldNamePrefix}birthday`}
                    control={control}
                    render={({ field: { name, value, onChange } }) => (
                        <Form.Field
                            control={DateInput}
                            name={name}
                            value={value}
                            onChange={(_, { value }) => {
                                onChange({ target: { name, value } })
                            }}
                            label='Birthday'
                            placeholder='Enter Birthday'
                        />
                    )}
                />
            </Form.Group>,
            <Form.Group widths='equal'>
                <Form.Field
                    label={(
                        <label>
                            <Icon link onClick={() => { addPhone({ value: '', type: PHONE_TYPE_OPTIONS.keys.CELL, main: false }) }} className='fas fa-plus-circle' color='primary' /> Phone
                        </label>
                    )}
                    required
                    control={'div'}
                    className='ContactForm__list-items'
                >
                    <Controller
                        control={control}
                        name={`${fieldNamePrefix}phones`}
                        render={({ field: { value } }) => (
                            <RecordPhonesForm
                                control={control}
                                phones={phones}
                                onRemove={(index) => {
                                    RecordUtils.removeItem(value, index, updatePhone, removePhone);
                                }}
                                updateMain={(onChange) => {
                                    RecordUtils.updateMain(value, updatePhone, onChange);
                                }}
                                checkDuplicates={checkDuplicates}
                                clearErrors={clearErrors}
                                contact_id={null}
                                setError={setError}
                                fieldNamePrefix={fieldNamePrefix}
                                trigger={trigger}
                            />
                        )}
                    />
                </Form.Field>
                <Form.Field
                    required
                    label={(
                        <label>
                            <Icon link onClick={() => { addEmail({ value: '', type: EMAIL_TYPE_OPTIONS.keys.WORK, main: false }) }} className='fas fa-plus-circle' color='primary' /> Email
                        </label>
                    )}
                    control={'div'}
                    className='ContactForm__list-items'
                >
                    <Controller
                        control={control}
                        name={`${fieldNamePrefix}emails`}
                        render={({ field: { value } }) => (
                            <RecordEmailsForm
                                control={control}
                                emails={emails}
                                onRemove={(index) => {
                                    RecordUtils.removeItem(value, index, updateEmail, removeEmail);
                                }}
                                updateMain={(onChange) => {
                                    RecordUtils.updateMain(value, updateEmail, onChange);
                                }}
                                checkDuplicates={checkDuplicates}
                                clearErrors={clearErrors}
                                contact_id={null}
                                setError={setError}
                                fieldNamePrefix={fieldNamePrefix}
                                trigger={trigger}
                            />
                        )}
                    />
                </Form.Field>
                <Controller
                    name={`${fieldNamePrefix}website`}
                    control={control}
                    render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                        <Form.Input
                            name={name}
                            value={value}
                            onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                            label='Website'
                            placeholder='Enter contact website'
                            error={error?.message}
                        />
                    )}
                />
            </Form.Group>,
            <Form.Group widths='equal'>
                <Controller
                    name={`${fieldNamePrefix}interests`}
                    control={control}
                    render={({ field: { name, onChange, value } }) => (
                        <Form.Select
                            name={name}
                            value={value}
                            onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                            label='Interests'
                            placeholder='Select interest(s)'
                            selectOnBlur={false}
                            options={[]}
                            multiple
                        />
                    )}
                />
                <Controller
                    name={`${fieldNamePrefix}department`}
                    control={control}
                    render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                        <Form.Input
                            name={name}
                            value={value}
                            onChange={(_, { value }) => {
                                onChange({ target: { name, value } })
                            }}
                            label='Department'
                            placeholder='Enter department'
                            error={error?.message}
                        />
                    )}
                />
                <Controller
                    name={`${fieldNamePrefix}job_title`}
                    control={control}
                    render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                        <Form.Input
                            name={name}
                            value={value}
                            onChange={(_, { value }) => {
                                onChange({ target: { name, value } })
                            }}
                            label='Job Title'
                            placeholder='Enter job title'
                            error={error?.message}
                        />
                    )}
                />
            </Form.Group>,
            <Form.Field
                label='Address'
                control={React.Fragment}
            >
                <Form.Group widths='equal'>
                    <Controller
                        name={`${fieldNamePrefix}address_label`}
                        control={control}
                        render={({ field: { name, onChange, value } }) => (
                            <Form.Select
                                name={name}
                                value={value}
                                onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                placeholder='Select Address Label'
                                selectOnBlur={false}
                                options={LocationUtils.getAddressLabelOptions()}
                            />
                        )}
                    />
                    <Controller
                        name={`${fieldNamePrefix}street`}
                        control={control}
                        render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                            <Form.Input
                                name={name}
                                value={value}
                                onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                placeholder='Street Address'
                                error={error?.message}
                            />
                        )}
                    />
                    <Controller
                        name={`${fieldNamePrefix}apartmentUnitOrFloor`}
                        control={control}
                        render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                            <Form.Input
                                name={name}
                                value={value}
                                onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                placeholder='Apt, unit building, floor #'
                                error={error?.message}
                            />
                        )}
                    />
                </Form.Group>
                <Form.Group widths='equal'>
                    <Controller
                        name={`${fieldNamePrefix}city`}
                        control={control}
                        render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                            <Form.Input
                                name={name}
                                value={value}
                                onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                placeholder='City'
                                error={error?.message}
                            />
                        )}
                    />
                    <Controller
                        name={`${fieldNamePrefix}state`}
                        control={control}
                        render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                            <Form.Select
                                required
                                name={name}
                                value={value}
                                onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                placeholder='State *'
                                search
                                error={error?.message}
                                options={STATES.map(state => ({ ...state, value: state.key }))}
                            />
                        )}
                    />
                    <Controller
                        name={`${fieldNamePrefix}zip`}
                        control={control}
                        render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                            <Form.Input
                                name={name}
                                value={value}
                                onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                error={error?.message}
                                placeholder='Zipcode'
                            />
                        )}
                    />
                </Form.Group>
            </Form.Field>,
            <Accordion
                fluid
                styled
                exclusive={false}
                defaultActiveIndex={[]}
                className='AdditionalContactData'
                panels={[
                    {
                        key: 'panel-0',
                        title: {
                            content: 'Business Details',
                            icon: accordionIcon
                        },
                        content: {
                            content: (
                                <Segment basic>
                                    <Form.Group widths='equal'>
                                        <Controller
                                            name={`${fieldNamePrefix}business_name`}
                                            control={control}
                                            render={({ field: { name, onChange, value } }) => (
                                                <Form.Field
                                                    control='div'
                                                    label='Company Name'
                                                >
                                                    <Input
                                                        name={name}
                                                        value={value}
                                                        onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                                        placeholder='Enter company name'
                                                    />
                                                </Form.Field>
                                            )}
                                        />
                                        <Controller
                                            name={`${fieldNamePrefix}industry_sector`}
                                            control={control}
                                            render={({ field: { name: industryName, onChange, value: industryValue } }) => {
                                                const industryOptions = CustomGroupUtils.getFieldOptions(business_details_fields, 'industry_sector');
                                                return [
                                                    <Controller
                                                        control={control}
                                                        name={`${fieldNamePrefix}sub_industry`}
                                                        render={({ field: { name: subIndustryName, onChange: onSubIndustryChange } }) => (
                                                            <Form.Select
                                                                name={industryName}
                                                                value={industryValue}
                                                                onChange={(_, { value }) => {
                                                                    onChange({ target: { name: industryName, value } });
                                                                    onSubIndustryChange({ target: { name: subIndustryName, value: '' } })
                                                                }}
                                                                label='Industry/Sector'
                                                                placeholder='Select industry/Sector'
                                                                selectOnBlur={false}
                                                                options={industryOptions} loading={isReadingCustomFields}
                                                                clearable
                                                            />
                                                        )}
                                                    />,
                                                    <Controller
                                                        name={`${fieldNamePrefix}sub_industry`}
                                                        control={control}
                                                        render={({ field: { name, onChange, value } }) => (
                                                            <Form.Select
                                                                name={name}
                                                                value={value}
                                                                onChange={(_, { value }) => { onChange({ target: { name, value } }); }}
                                                                label='Sub-Industry'
                                                                placeholder='Select sub-industry'
                                                                selectOnBlur={false}
                                                                options={industryOptions?.find(industry => (industry.value === industryValue))?.sub_options || []} loading={isReadingCustomFields}
                                                                clearable
                                                            />
                                                        )} />
                                                ];
                                            }}
                                        />
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Controller
                                            name={`${fieldNamePrefix}employee_size`}
                                            control={control}
                                            render={({ field: { name, value, onChange } }) => (
                                                <Form.Input
                                                    name={name}
                                                    placeholder='Enter number of employees'
                                                    onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                                    label='Employee Size'
                                                    
                                                />
                                            )}
                                        />
                                        <Controller
                                            name={`${fieldNamePrefix}annual_revenue`}
                                            control={control}
                                            render={({ field: { name, value, onChange } }) => (
                                                <Form.Input
                                                    name={name}
                                                    value={value}
                                                    placeholder='Enter annual revenue'
                                                    onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                                    label='Annual Revenue'
                                                />
                                            )}
                                        />
                                        <Controller
                                            name={`${fieldNamePrefix}product_services_sold`}
                                            control={control}
                                            render={({ field: { name, value, onChange } }) => (
                                                <Form.Input
                                                    name={name}
                                                    value={value}
                                                    placeholder='Enter product/services sold'
                                                    onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                                    label='Products/Services Sold'
                                                />
                                            )}
                                        />
                                    </Form.Group>
                                </Segment>
                            )
                        }
                    },
                    {
                        key: 'panel-1',
                        title: {
                            content: 'Household Details',
                            icon: accordionIcon
                        },
                        content: {
                            content: (
                                (
                                    <Segment basic>
                                        <Form.Group widths='equal'>
                                            <Controller
                                                name={`${fieldNamePrefix}household_name`}
                                                control={control}
                                                render={({ field: { name, onChange, value } }) => (
                                                    <Form.Field
                                                        control='div'
                                                        label='Household Name'
                                                    >
                                                        <Input
                                                            name={name}
                                                            value={value}
                                                            onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                                            placeholder='Enter household name'
                                                        />
                                                    </Form.Field>
                                                )}
                                            />
                                            <Controller
                                                name={`${fieldNamePrefix}household_type`}
                                                control={control}
                                                render={({ field: { name, onChange, value } }) => (
                                                    <Form.Select
                                                        name={name}
                                                        value={value}
                                                        onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                                        label='Household Type'
                                                        placeholder='Select household type'
                                                        selectOnBlur={false}
                                                        options={CustomGroupUtils.getFieldOptions(household_details_fields, 'household_type')}
                                                        loading={isReadingCustomFields}
                                                    />
                                                )}
                                            />
                                            <Controller
                                                name={`${fieldNamePrefix}household_members`}
                                                control={control}
                                                render={({ field: { name, value, onChange } }) => (
                                                    <Form.Input
                                                        name={name}
                                                        placeholder='Enter household members count'
                                                        onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                                        label='Household Members'
                                                    />
                                                )}
                                            />
                                        </Form.Group>
                                        <Form.Group widths={3}>
                                            <Controller
                                                name={`${fieldNamePrefix}number_of_children`}
                                                control={control}
                                                render={({ field: { name, value, onChange } }) => (
                                                    <Form.Input
                                                        name={name}
                                                        placeholder='Enter number of children'
                                                        onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                                        label='Number of Children'
                                                    />
                                                )}
                                            />
                                            <Controller
                                                name={`${fieldNamePrefix}annual_household_income`}
                                                control={control}
                                                render={({ field: { name, value, onChange } }) => (
                                                    <Form.Input
                                                        name={name}
                                                        placeholder='Enter annual household income'
                                                        value={value}
                                                        onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                                        label='Annual Household Income'
                                                    />
                                                )}
                                            />
                                        </Form.Group>
                                    </Segment>
                                )
                            )
                        }
                    }
                ]}
            />
        ]
    )
}

const mapDispatchToProps = dispatch => ({
    checkDuplicates: (queryParameters) => {
        return dispatch(contactDuplicatesActions.checkDuplicates(queryParameters));
    }
})

export default connect(null, mapDispatchToProps)(ContactFields);
