import { ButtonLink } from "dyl-components";
import React from "react";
import { Divider, Form, Icon } from "semantic-ui-react";
import '../index.scss'
import { Controller } from "react-hook-form";
import SingleUserSearchField from "shared/forms/SingleUserSearchField";


const SharedSection = ({ control, onAddUser, fields, onDeleteUser }) => {
    const isDeletable = fields?.length > 1;
    return (
        <div className="ExternalNumberModal__sharedSectionComponent">
            <Divider />
            {fields?.map((_, idx) => {
                return (
                    <div className="ExternalNumberModal__controllerContainer">
                        <Controller
                            name={`users[${idx}].user_id`}
                            control={control}
                            style={{flex: 1}}
                            render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                <Form.Field
                                    key={idx}
                                    label="User"
                                    required
                                    className="Event__no-label--Error-fix"
                                    control={SingleUserSearchField}
                                    onChange={(_, {value: newValue }) => {
                                        onChange({ target: { name, value: newValue } });
                                    }}
                                    value={value}
                                    placeholder="Select Users"
                                    name={name}
                                    error={error && error.message && {
                                        content: error.message,
                                        pointing: 'below'
                                    }}
                                />
                            )}
                        />
                        <Controller
                            name={`users[${idx}].extension`}
                            control={control}
                            style={{flex: 1}}
                            render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                <Form.Input
                                    name={name}
                                    value={value}
                                    onChange={(_, { value }) => { 
                                        onChange({ target: { name, value } })
                                    }}
                                    label="External Ext."
                                    placeholder="Type Extension"
                                    error={error?.message}
                                    className="ExternalNumbers__ruleField"
                                />
                            )}
                        />
                        {isDeletable && (
                            <ButtonLink onClick={() => onDeleteUser(idx)}>
                                <Icon className="fa-solid fa-trash redIcon" />
                            </ButtonLink>
                         )}
                    </div>
                )
            })}
            <ButtonLink onClick={onAddUser}>
                <Icon className="fa-solid fa-plus ExternalNumberModal__blackIcon"/> Add New User
            </ButtonLink>
        </div>
    )
}

export default SharedSection;
