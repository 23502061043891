import React from "react";
import {
    ClearableCheckboxDropdown,
    Icon,
    Table,
    TimestampFilters,
} from "dyl-components";
import { Table as SemanticTable } from "semantic-ui-react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
    getData,
    onToggleTimeUnit,
    useRangeCalendar,
} from "shared/CustomDateRange/customDateRange";
import { useSelector } from "react-redux";
import SelectAllCheckbox from "shared/SelectAllCheckbox";

const SortActions = ({ direction = false, onToggleSort = () => {} }) => {
    return (
        <div
            onClick={onToggleSort}
            style={{ display: "inline-flex", cursor: "pointer" }}
        >
            <Icon
                name="fa-solid fa-arrow-down-long"
                style={{
                    marginRight: -7,
                    opacity: !direction ? "100%" : "50%",
                }}
            />
            <Icon
                name="fa-solid fa-arrow-up-long"
                style={{ opacity: direction ? "100%" : "50%" }}
            />
        </div>
    );
};

const TYPE_FILTER = [
    { key: "missed_call", value: "missed_call", text: "Missed Call" },
    { key: "voicemail", value: "voicemail", text: "Voicemail" },
    { key: "fax", value: "fax", text: "Fax" },
    { key: "email", value: "email", text: "Email" },
    { key: "text", value: "text", text: "Text" },
    { key: "task/call", value: "task/call", text: "Call Task" },
    { key: "task/email", value: "task/email", text: "Email Task" },
    { key: "task/text", value: "task/text", text: "Text Task" },
    { key: "task/todo", value: "task/todo", text: "To Do Task" },
    { key: "event", value: "event", text: "Event" },
    { key: "system", value: "system", text: "System" },
];

export const NotificationsHeader = ({ onFilter, subRoute }) => {
    const [params] = useSearchParams();
    const navigate = useNavigate();

    const {
        timestamp,
        setTimestamp,
        isCustomDateRangeOpen,
        setCustomDateRangeOpen,
        userAuthTimezone,
        dateRange,
        setDateRange,
    } = useRangeCalendar(params);

    const createdOrder = params.get("order") === "asc";

    const onDateRangeChange = (_, { value }) => {
        setDateRange(value);
    };

    const createdOrderFilter = () => {
        const query = new URLSearchParams(params);
        const order = params.get("order") !== "asc" ? "asc" : "desc";
        query.set("order", order);
        const query_string = query.toString();
        navigate(`${subRoute}/notifications${query_string ? `?${query_string}` : ""}`);
    };

    const { hubNotifications = [], count } = useSelector(state => state.notifications);

    return (
        <Table.Row>
            <SemanticTable.HeaderCell
                width={1}
                className="NotificationsTable__SmallFixedWidth"
            >
                <SelectAllCheckbox
                    count={count}
                    data={hubNotifications}
                    type={"notifications"}
                    idProperty={"notification_id"}
                />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell
                className="NotificationsTable__SmallFixedWidth"
                width={1}
            >
                <span style={{ marginRight: 5 }}>Date</span>
                <TimestampFilters
                    timestamp={timestamp}
                    onToggleTimeUnit={(timestamp) =>
                        onToggleTimeUnit(
                            timestamp,
                            setTimestamp,
                            params,
                            navigate,
                            `${subRoute}/notifications`
                        )
                    }
                    isCustomDateRangeOpen={isCustomDateRangeOpen}
                    onOpenCustomDateRange={() => {
                        setCustomDateRangeOpen(true);
                    }}
                    onCloseCustomDateRange={() => {
                        setCustomDateRangeOpen(false);
                    }}
                    dateRange={dateRange}
                    onDateRangeChange={onDateRangeChange}
                    getData={() =>
                        getData({
                            setCustomDateRangeOpen,
                            dateRange,
                            userAuthTimezone,
                            params,
                            navigate,
                            url: `${subRoute}/notifications`
                        })
                    }
                />
                <SortActions
                    direction={createdOrder}
                    onToggleSort={() => {
                        createdOrderFilter();
                    }}
                />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell
                className="NotificationsTable__MediumFixedWidth"
                width={4}
            >
                Account/Contact
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell
                className="NotificationsTable__SmallFixedWidth"
                width={2}
            >
                <ClearableCheckboxDropdown
                    text="Type"
                    name="notification_type"
                    options={TYPE_FILTER}
                    onChange={(_, { value }) => {
                        onFilter(_, { name: "notification_type", value });
                    }}
                    value={params.get("notification_type")?.split(",")}
                    closeOnLoad={true}
                />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell
                className="NotificationsTable__MediumFixedWidth"
                width={4}
            >
                Description
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell 
                width={2}
                className="NotificationsTable__SmallFixedWidth"
            >
                Actions
            </SemanticTable.HeaderCell>
        </Table.Row>
    );
};
