import React from 'react';
import { Table, EllipsisDropdown, AvatarGroup, ButtonLink, ControlledPopup } from 'dyl-components';
import { Dropdown, DropdownItem, Header } from 'semantic-ui-react';
import { PhoneUtil, StringUtils } from 'utils';

import '../index.scss';
import Destinations from './Destinations';

const Row = ({ number = {}, onSelectExternalNumber, onDelete, selectVerifyingPhone }) => {
    const { phone, shared, type, label, forward, verified, users, in_use } = number;
    const { cid_display, after_destination } = forward || {};

    return (
        <Table.Row>
            <Table.Cell>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span>
                        {PhoneUtil.formatPhoneNumber(phone)}
                    </span>
                    {!verified && (
                        <ButtonLink onClick={() => selectVerifyingPhone(number)} style={{padding: 0, width: "fit-content"}}>
                            Verify
                        </ButtonLink>
                    )}
                </div>
            </Table.Cell>
            <Table.Cell>
                {shared ? "Yes" : "No"}
            </Table.Cell>
            <Table.Cell>
                {StringUtils.capitalize(type)}
            </Table.Cell>
            <Table.Cell>
                <div style={{ display: 'flex'}}>
                    <AvatarGroup
                        persons={users}
                        limit={3}
                        size='3em'
                    />
                </div>
            </Table.Cell>
            <Table.Cell>
                {label}
            </Table.Cell>
            <Table.Cell>
                {cid_display}
            </Table.Cell>
            <Table.Cell>
                {!!forward ? "Yes" : "No"}
            </Table.Cell>
            <Table.Cell>
                <Destinations destination={after_destination} />
            </Table.Cell>
            <Table.Cell>
                <EllipsisDropdown>
                    <DropdownItem
                        text={"Edit"}
                        icon={"fa-solid fa-pencil"}
                        onClick={() => { onSelectExternalNumber(phone) }}
                    />
                    <ControlledPopup 
                        trigger={<Dropdown.Item icon={'fas fa-trash-alt'} text={"Delete"} disabled={!!in_use} />}
                        header={
                            <Header as='h4' textAlign='center'>
                                Are you sure?
                                <Header.Subheader style={{ marginTop: 5 }}>
                                    You cannot undo delete actions.
                                </Header.Subheader>
                            </Header>
                        }
                        onConfirm={() => onDelete(phone)}
                        inverted
                    />
                </EllipsisDropdown>      
            </Table.Cell>
        </Table.Row>
    );
}

export default Row;


