import React, { useState } from "react";
import { Modal, ButtonLink, generateResolver } from "dyl-components";
import FullScreenModalPopUp from "shared/FullScreenModalPopUp";
import {
    Controller,
    FormProvider,
    useFieldArray,
    useForm,
    useFormContext,
} from "react-hook-form";
import { Form, Header, Icon } from "semantic-ui-react";
import { StringUtils, ValidationUtils } from "utils";

import "./VariantsModal.scss";
import { variantsSchema } from "shared/schemas/products/variantsSchema";
import { useDispatch, useSelector } from "react-redux";
import productsActions from "actions/products";

function VariantValue({ index, control, onRemove, id }) {
    const dispatch = useDispatch();
    const { setError } = useFormContext();

    return (
        <Form.Group>
            <Controller
                name={`values[${index}].value`}
                control={control}
                render={({
                    field: { name, value, onChange },
                    fieldState: { error },
                }) => (
                    <Form.Input
                        name={name}
                        value={value}
                        onChange={(_, { value }) => {
                            onChange({
                                target: { name, value },
                            });
                        }}
                        required
                        label="Value Name"
                        error={error?.message}
                        width={6}
                    />
                )}
            />
            <Controller
                name={`values[${index}].additional_price`}
                control={control}
                render={({
                    field: { name, value, onChange },
                    fieldState: { error },
                }) => (
                    <Form.Input
                        name={name}
                        value={value}
                        onChange={(_, { value }) => {
                            onChange({
                                target: {
                                    name,
                                    value: StringUtils.formatDecimal(value),
                                },
                            });
                        }}
                        label="Additional Price"
                        error={error?.message}
                        width={6}
                        icon={"fas fa-dollar-sign"}
                        iconPosition="left"
                    />
                )}
            />
            <Controller
                name={`values[${index}].sku`}
                control={control}
                render={({
                    field: { name, value, onChange },
                    fieldState: { error },
                }) => (
                    <Form.Input
                        name={name}
                        value={value}
                        onChange={async (_, { value }) => {
                            onChange({ target: { name, value } });
                            if (
                                value !== "" &&
                                (await variantsSchema.name.isValid(value))
                            ) {
                                const isDuplicate = await dispatch(
                                    productsActions.isDuplicate({
                                        name: value,
                                        type: "sku",
                                        ...(id ? { id } : {})
                                    })
                                );
                                if (isDuplicate) {
                                    setError(
                                        `values[${index}].sku`,
                                        {
                                            message: "SKU already exists!",
                                            type: "unique"
                                        }
                                    );
                                }
                            }
                        }}
                        required
                        label="Variant SKU"
                        error={error?.message}
                        width={3}
                    />
                )}
            />
            <Form.Field
                control="div"
                className="VariantValue__field--hidden-label"
                label="Remove"
                width={1}
            >
                <Icon
                    style={{ marginTop: "0.75em" }}
                    className="fas fa-times"
                    link
                    onClick={onRemove}
                />
            </Form.Field>
        </Form.Group>
    );
}

const VariantsModal = ({
    open,
    onClose,

    variantBeingModified,
    onSave,
}) => {
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const { name, values } = variantBeingModified;

    const methods = useForm({
        mode: "onChange",
        defaultValues: {
            name: name || "",

            values:
                values?.map((value) => ({
                    ...value,
                })) || [],
        },
        resolver: generateResolver({
            ...variantsSchema,
            values: variantsSchema.values.test(
                "no_duplicate_skus_allowed",
                "This SKU already exists in the list",
                function (array) {
                    return ValidationUtils.checkForDuplicateItemsInTheList.call(
                        this,
                        array,
                        "SKU",
                        "sku",
                        variantsSchema.name
                    );
                }
            ),
        }),
    });

    const {
        formState: { isValid, isDirty, errors },
        control,
        reset,
        handleSubmit,
    } = methods;

    const confirmationOnClose = () => {
        onClose();
        setIsConfirmationOpen(false);
        reset({
            name: "",
            values: [],
        });
    };

    const { fields, append, remove } = useFieldArray({
        control,
        name: "values",
    });

    const addVariantValue = () => {
        append({ value: "", additional_price: "", sku: "" });
    };

    const removeVariantValue = (index) => {
        remove(index);
    };

    const onSaveChanges = (data) => {
        onSave(data);
        reset({
            name,
            values,
        });
    };

    const isCheckingForDuplicates = useSelector(state => state.products.isCheckingForDuplicates)

    return (
        <>
            <Modal
                open={open}
                onClose={() =>
                    isDirty ? setIsConfirmationOpen(true) : onClose()
                }
                noValidate
            >
                <Modal.Header>
                    {open === "new" ? "Add" : "Edit"} Variant
                </Modal.Header>

                <Modal.Content scrolling>
                    <Form noValidate>
                        <Controller
                            control={control}
                            name="name"
                            render={({
                                field: { name, value, onChange },
                                fieldState: { error },
                            }) => (
                                <Form.Input
                                    name={name}
                                    value={value}
                                    onChange={(_, { name, value }) => {
                                        onChange({ target: { name, value } });
                                    }}
                                    label="Variant Name"
                                    required
                                    error={error?.message}
                                    width={4}
                                />
                            )}
                        />
                        <Header as="h3" color="primary">
                            Variant Values
                        </Header>
                        <FormProvider {...methods}>
                            {fields.map((variantValue, index) => (
                                <VariantValue
                                    key={variantValue.id}
                                    control={control}
                                    index={index}
                                    id={variantValue.id}
                                    onRemove={() => {
                                        removeVariantValue(index);
                                    }}
                                />
                            ))}
                        </FormProvider>
                        <ButtonLink
                            onClick={addVariantValue}
                            disabled={fields.length >= 10}
                        >
                            <Icon className="fa-light fa-plus" color="grey" />{" "}
                            Add New Variant
                        </ButtonLink>
                    </Form>
                </Modal.Content>
                <Modal.Actions
                    hasSaveButton
                    onSave={handleSubmit(onSaveChanges)}
                    saveDisabled={!isValid || !isDirty || errors.values || isCheckingForDuplicates}
                />
            </Modal>
            <FullScreenModalPopUp
                header={"Changes not saved"}
                subheader={"Are you sure you want to exit?"}
                isOpen={isConfirmationOpen}
                onConfirm={confirmationOnClose}
                onFormClose={() => setIsConfirmationOpen(false)}
                closeIcon={false}
            />
        </>
    );
};

export default VariantsModal;
