const initialState = {
    contact: {},
    location: [],
    email: [],
    phone: [],
    isReadingContact: false,
    isReadingContactLocations: false,
    modal: {
        contact: {},
        location: [],
        email: [],
        phone: [],
        isReadingContact: false,
        isReadingContactLocations: false
    }
}

export default initialState;
