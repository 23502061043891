import 'dyl-ui/dist/semantic.min.css';
import 'dyl-components/global.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import { startLogRocket } from './logRocketSetup'; //DISABLING LOG ROCKET UNTIL USED

import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store from './store';
import ConfirmContextProvider from 'shared/confirmation/ConfirmContextProvider';
import FavoritesProvider from 'shared/context/FavoritesProvider';

const Wrapper = () => {
    // startLogRocket(); //DISABLING LOG ROCKET UNTIL USED

    return (
        <Provider store={store}>
            <FavoritesProvider>
                <ConfirmContextProvider>
                    <App />
                </ConfirmContextProvider>
            </FavoritesProvider>
        </Provider>
    )
}

ReactDOM.render(<Wrapper />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
