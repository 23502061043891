import React from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';

export const AccountTabs = ({ account_id, account_type }) => {
    const { pathname } = useLocation();

    const tab = pathname.split("/")[3];

    return (
        <Menu size='small' borderless pointing secondary color={"primary"} stackable>
            <Menu.Item as={Link} active={tab === undefined} name='Overview' to={`/account/${account_id}`} />
            <Menu.Item as={NavLink} name='Data' to={`/account/${account_id}/data`} />
            <Menu.Item as={NavLink} name='Leads' to={`/account/${account_id}/leads`} />
            <Menu.Item as={NavLink} name='Opportunities' to={`/account/${account_id}/opportunities`} />
            <Menu.Item as={NavLink} name='Customer' to={`/account/${account_id}/customer/pipelines`} />
            <Menu.Item as={NavLink} name={`${account_type} Contacts`} to={`/account/${account_id}/contacts`} />
            <Menu.Item as={NavLink} name='Timeline' to={`/account/${account_id}/timeline`} />
            <Menu.Item as={NavLink} name='Texts' to={`/account/${account_id}/texts`} />
            <Menu.Item as={NavLink} name='Emails' to={`/account/${account_id}/emails`} />
            <Menu.Item as={NavLink} name='Events' to={`/account/${account_id}/events`} />
            <Menu.Item as={NavLink} name='Tasks' to={`/account/${account_id}/tasks`} />
            <Menu.Item as={NavLink} name='Notes' to={`/account/${account_id}/notes`} />
            <Menu.Item as={NavLink} name='Uploads' to={`/account/${account_id}/uploads`} />

        </Menu>
    );
};
