import React from "react";
import { Icon } from "semantic-ui-react";
//PERMISSION DISABLED
// import { PermissionUtils } from 'utils';
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { SearchBar, Button } from "dyl-components";

const UserAccountsToolbar = ({ onOpenAddUser, setSearch }) => {
    const [query] = useSearchParams();
    const search = query.get("search") || "";

    const cancelFunction = () => setSearch("");
    const canAddUsers = useSelector(
        (state) => state.auth.dyl_employee || state.subscription.hasOpenSeats
    );
    const { isCreateUserDisabled, isReadingRoles, isReadingUserAccounts } =
        useSelector((state) => {
            //PERMISSION DISABLED
            // const { permissionCheckingProp, isAccessibleProp } = PermissionUtils;
            return {
                //PERMISSION DISABLED
                // isCreateUserDisabled: state.auth[permissionCheckingProp(PERMISSIONS.USER_PERMISSION_CHECKERS.CREATE)] || !state.auth[isAccessibleProp(PERMISSIONS.USER_PERMISSION_CHECKERS.CREATE)],
                isCreateUserDisabled: false,
                isReadingUserAccounts: state.assign.isReadingUserAccounts || state.subscription.isCheckingIfAccountHasOpenSeats,
                isReadingRoles: state.roles.isReadingRoles,
            };
        });

    return (
        <div className="UserOptions">
            <SearchBar
                isSearching={isReadingUserAccounts}
                search={search}
                searchFcn={setSearch}
                cancelFcn={cancelFunction}
                placeholder={"Search for a name or email address"}
            />
            <Button
                loading={isReadingRoles}
                className="UserOptions__NewUserButton"
                onClick={onOpenAddUser}
                disabled={
                    isCreateUserDisabled || isReadingRoles || !canAddUsers
                }
            >
                <Icon name="plus" /> New User
            </Button>
        </div>
    );
};

export default UserAccountsToolbar;
