import React from 'react';
import { RecordsTable } from 'dyl-components';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { AccountsTableHeader } from './Header';
import { AccountsRow } from './Row';
import Forms from './Forms';

export const AccountsTable = ({ isColumnHidden, onFilter, onPageChange, readAccounts, setIsDropDownOpen }) => {
    const [params] = useSearchParams();

    const { accountReducer, accountBeingUnlinked, isDeletingMasterAccount } = useSelector(state => ({
        accountReducer: state.account,
        accountBeingUnlinked: state.master_account.accountBeingUnlinked,
        isDeletingMasterAccount: state.master_account.isDeletingMasterAccount
    }));
    const { accounts = [], isReadingAccounts, accounts_count } = accountReducer;

    const isTableLoading = isReadingAccounts || isDeletingMasterAccount || accountBeingUnlinked !== null;
    return (
        <React.Fragment>
            <Forms readAccounts={readAccounts} />
            <RecordsTable
                tableBodyClassName='AccountsTable'
                isTableLoading={isTableLoading}
                isSearchResultEmpty={accounts_count === 0 && (params.get('search') || params.get('start') || params.get('end') || params.get('account_type') || params.get('owned_by'))}
                isTableEmpty={accounts_count === 0 && !(params.get('search') || params.get('start') || params.get('end') || params.get('account_type') || params.get('owned_by'))}
                TableHeader={<AccountsTableHeader isColumnHidden={isColumnHidden} onFilter={onFilter} />}
                TableRows={accounts.map(account => (
                    <AccountsRow
                        key={account.id}
                        account={account}
                        isColumnHidden={isColumnHidden}
                    />
                ))}
                recordsCount={accounts_count}
                activePage={params.get('page')}
                onPageChange={onPageChange}
                recordsName='Account'
                onClick={()=>{setIsDropDownOpen(true)}}
            />
        </React.Fragment>
    );
}


