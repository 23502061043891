import React, { useEffect, useState } from "react";
import {
    ClearableCheckboxDropdown,
    Icon,
    Table,
} from "dyl-components";
import { Popup, Table as SemanticTable } from "semantic-ui-react";
import { useSearchParams } from "react-router-dom";
import { PhoneUtil } from "utils";
import { useDispatch, useSelector } from "react-redux";
import pbxConfigActions from "actions/pbx_config";

const SHARED_FILTER = [
    { key: "yes", value: "yes", text: "Yes" },
    { key: "no", value: "no", text: "No" },
];

const TYPE_FILTER = [
    { key: "tollfree", value: "tollfree", text: "Toll-free" },
    { key: "local", value: "local", text: "Local" },
];

const ENABLED_AS_FORWARDING_FILTER = [
    { key: "yes", value: "yes", text: "Yes" },
    { key: "no", value: "no", text: "No" },
];

const EXCLUDED_DESTINATIONS = ["call_queue", "destination_group", "routing_rule"];

const NO_ANSWER_DESTINATION_FILTER = [{key: "none", value: "none", text: "None"}, ...PhoneUtil.DESTINATION_TYPES.filter(({key}) => !EXCLUDED_DESTINATIONS.includes(key))]

const Header = ({ onFilter, selectedShared, selectedType, selectedForwarding }) => {
    const [usersOptions, setUsersOptions] = useState([]);
    const [params] = useSearchParams();
    const dispatch = useDispatch();

    const { external_users } = useSelector((state) => state.pbx_config);
    
    useEffect(() => {
        dispatch(pbxConfigActions.getExternalUsers());
    }, [dispatch])

    useEffect(() => {
        const optionsAux = external_users?.map((user) => ({
            value: `${user.id}`,
            key: user.id,
            text: `${user.first_name} ${user.last_name}`,
        })) || []
        setUsersOptions(optionsAux);
    }, [external_users])

    return (
        <Table.Row>
            <SemanticTable.HeaderCell
                width={2}
            >
                Number
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell
                width={1}
            >
                <ClearableCheckboxDropdown
                    text="Shared"
                    name="shared"
                    options={SHARED_FILTER}
                    onChange={(_, { name, value }) => {
                        onFilter(_, { name, value });
                    }}
                    value={selectedShared}
                    closeOnLoad={true}
                />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell
                width={1}
            >
                <ClearableCheckboxDropdown
                    text="Type"
                    name="type"
                    options={TYPE_FILTER}
                    onChange={(_, { name, value }) => {
                        onFilter(_, { name, value });
                    }}
                    value={selectedType}
                    closeOnLoad={true}
                />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell
                width={2}
            >
                <ClearableCheckboxDropdown
                    text="User"
                    name="user_id"
                    options={[{key: "0", value: "0", text: "None"}, ...usersOptions]}
                    onChange={(_, { name, value }) => {
                        onFilter(_, { name, value });
                    }}
                    value={params.get("user_id")?.split(",")}
                    closeOnLoad={true}
                />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell
                width={2}
            >
                Label
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell
                width={2}
            >
                Caller ID Display 
                <Popup
                    trigger={<Icon className='fas fa-circle-info' style={{marginLeft: 10}}/>}
                    content={
                        <span>
                            Inbound display when the number is called. If no caller display
                            is added, then it will populate with the caller ID.
                        </span>
                    }
                    inverted
                    hoverable
                />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell
                width={2}
            >
                <ClearableCheckboxDropdown
                    text="Enabled as Forwarding"
                    name="forwarding"
                    options={ENABLED_AS_FORWARDING_FILTER}
                    onChange={(_, { name, value }) => {
                        onFilter(_, { name, value });
                    }}
                    value={selectedForwarding}
                    closeOnLoad={true}
                />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell
                width={3}
            >
                <ClearableCheckboxDropdown
                    text="No Answer Destination"
                    name="timeout_destination"
                    options={NO_ANSWER_DESTINATION_FILTER}
                    onChange={(_, { name, value }) => {
                        onFilter(_, { name, value });
                    }}
                    value={params.get("timeout_destination")?.split(",")}
                    closeOnLoad={true}
                />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell 
                width={1}
            >
                Actions
            </SemanticTable.HeaderCell>
        </Table.Row>
    );
};

export default Header;
