import {
    Icon,
    Modal,
    NestedDropdown,
    Notification,
    STATUS_TYPES,
    generateResolver,
    yup,
} from "dyl-components";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Checkbox, Form, Popup } from "semantic-ui-react";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import pbxConfigActions from "actions/pbx_config";
import { PhoneUtil } from "utils";
import FullScreenModalPopUp from "shared/FullScreenModalPopUp";

const EXCLUDED_PARENTS = ["call_queue", "destination_group", "personal_voicemail", "parking_lot"];

const Content = ({ onClose, onReload, onDirtyChange }) => {


    const [destinationOptions, setDestinationOptions] = useState([]);
    const [callerIdOptions, setCallerIdOptions] = useState([]);
    const [usersOptions, setUsersOptions] = useState([]);
    const [displayedSounds, setDisplayedSounds] = useState([]);

    const { 
        destinations,
        userExtension,
        cidOptions,
        isReadingCidOptions,
        users,
        isReadingUsers,
        soundOptions,
        isUpdatingUserExtension
    } = useSelector((state) => state.pbx_config);
    const dispatch = useDispatch();

    const { id, extension_number, callerid, ring_duration, user, voicemail, whisper, browser_softphone } = userExtension
    const { box, greeting, exit, id: voicemail_id } = voicemail || {};
    const { sound_id } = greeting || {};
    const { destination_id, type } = exit || {}

    const {
        control,
        formState: { isValid, isDirty },
        handleSubmit,
        watch
    } = useForm({
        mode: "onChange",
        defaultValues: {
            user_id: user ? user.user_id : null,
            callerid: callerid || null,
            sound_id: sound_id || "default",
            ring_duration: ring_duration || 30,
            vm_exit_parent: type || "",
            exit: destination_id || null,
            browser_softphone: browser_softphone || false,
            whisper: whisper || false,
            extension_number: extension_number,
        },
        resolver: generateResolver({
            user_id: yup.number().required("This field is required"),
            callerid: yup.string().required("This field is required"),
            sound_id: yup.string().required("This field is required"),
        }),
    });

    const watchedUser = watch("user");

    const formatData = (data) => {
        const { sound_id, exit } = data;
        const dataCopy = {...data};
        delete dataCopy.sound_id;
        delete dataCopy.exit;
        return {
            ...dataCopy,
            voicemail: {
                ...(sound_id !== "default" ? { sound_id: Number(sound_id) } : {}),
                exit,
                id: voicemail_id
            }
        }
    }

    const onEdit = async (data) => {
        try {
            const payload = formatData(data);
            await dispatch(pbxConfigActions.updateExtension(id, payload));
            onReload();
            onClose();
            Notification.alert("User Extension updated successfully!", STATUS_TYPES.SUCCESS);
        } catch (e) {
            console.log(e);
            Notification.alert("Failed to update User Extension", STATUS_TYPES.ERROR);
        }
    };

    useEffect(() => {
        if (destinations) {
            const destinationsAux = [...PhoneUtil.DESTINATION_TYPES_OPTIONS];
            const destinationOptionsAux = destinationsAux.map((destination) => {
                const { key } = destination;
                const options =
                    destinations[key]?.map(({ destination_id, label }) => ({
                        key: destination_id,
                        value: destination_id,
                        text: label,
                    })) || [];
                return { ...destination, options };
            });
            setDestinationOptions(destinationOptionsAux);
        }
    }, [destinations]);

    useEffect(() => {
        if (cidOptions) {
            const optionsAux = cidOptions.map((option) => ({key: option, value: option, text: PhoneUtil.formatPhoneNumber(option)}))
            setCallerIdOptions(optionsAux);
        } else {
            setCallerIdOptions([])
        }
    }, [cidOptions]);

    useEffect(() => {
        if (users) {
            const optionsAux = users.map((user) => ({key: user.user_id, value: user.user_id, text: `${user.first_name} ${user.last_name}`}))
            setUsersOptions(optionsAux);
        } else {
            setUsersOptions([])
        }
    }, [users]);

    useEffect(() => {
        if (soundOptions) {
            const { personal_voicemail } = soundOptions
            const optionsAux = personal_voicemail?.map(({name, id}) => ({
                key: id,
                value: id,
                text: name
            })) || []
            setDisplayedSounds([{ key: "default", value: "default", text: "Standard voicemail" }, ...optionsAux])
        } else {
            setDisplayedSounds([])
        }
    }, [soundOptions]);

    useEffect(() => {
        dispatch(pbxConfigActions.readCallerIdOptions());
        dispatch(pbxConfigActions.getDestinations());
        dispatch(pbxConfigActions.getUsers())
        dispatch(pbxConfigActions.getSoundOptions({category: "personal_voicemail"}));
    }, [dispatch])

    useEffect(() => {
        if (onDirtyChange) {
            onDirtyChange(isDirty);
        }
        // eslint-disable-next-line
    }, [isDirty])

    return (
        <>
            <Modal.Header>
                Edit User Extension
            </Modal.Header>
            <Modal.Content>
                <Form noValidate loading={isUpdatingUserExtension}>
                    <div className="UserExtensionModal__form">
                        <div className="UserExtensionModal__controllerContainer">
                            <Form.Input
                                style={{flex: 1}}
                                value={extension_number || ""}
                                label={() => {
                                    return (
                                        <div style={{display: 'flex', alignItems: 'center', gap: 7}}>
                                            <Checkbox
                                                checked={true}
                                                size={'small'}
                                                disabled
                                            />
                                            <strong>User Extension</strong>
                                        </div>
                                    )
                                }}
                                readOnly
                            />
                            {user ? (
                                <Form.Input
                                    label={"User"}
                                    value={`${user.first_name} ${user.last_name}`}
                                    required
                                    readOnly
                                />
                            ) : (
                                <Controller
                                    name="user_id"
                                    control={control}
                                    style={{ flex: 1 }}
                                    render={({
                                        field: { name, value, onChange },
                                        fieldState: { error },
                                    }) => (
                                        <Form.Select
                                            name={name}
                                            value={value}
                                            onChange={(_, { value }) => {
                                                onChange({
                                                    target: { name, value },
                                                });
                                            }}
                                            placeholder="Select User"
                                            options={usersOptions}
                                            label="User"
                                            required
                                            error={error?.message}
                                            loading={isReadingUsers}
                                        />
                                    )}
                                />
                            )}
                            <Form.Input
                                style={{flex: 1}}
                                value={`VM ${box}`}
                                label="VM Box"
                                readOnly
                            />
                            <Controller
                                name="callerid"
                                control={control}
                                style={{ flex: 1 }}
                                render={({
                                    field: { name, value, onChange },
                                    fieldState: { error },
                                }) => (
                                    <Form.Select
                                        name={name}
                                        value={value}
                                        onChange={(_, { value }) => {
                                            onChange({
                                                target: { name, value },
                                            });
                                        }}
                                        placeholder="Select DYL number"
                                        options={callerIdOptions}
                                        label="Outbound Caller ID"
                                        required
                                        error={error?.message}
                                        loading={isReadingCidOptions}
                                        selectOnBlur={false}
                                    />
                                )}
                            />
                        </div>
                        <div className="UserExtensionModal__controllerContainer">
                            <Controller
                                name="sound_id"
                                control={control}
                                render={({ field: { name, value, onChange } }) => (
                                    <Form.Select
                                        value={value}
                                        options={displayedSounds}
                                        onChange={(_, { value }) => {
                                            onChange({ target: { name, value } });
                                        }}
                                        placeholder="Select VM Greeting"
                                        label="VM Greeting"
                                        pointing="top"
                                        className="UserExtensions__ruleField"
                                        required
                                        selectOnBlur={false}
                                    />
                                )}
                            />
                            <Controller
                                name="ring_duration"
                                control={control}
                                style={{ flex: 1 }}
                                render={({
                                    field: { name, value, onChange },
                                    fieldState: { error },
                                }) => (
                                    <Form.Select
                                        name={name}
                                        value={value}
                                        onChange={(_, { value }) => {
                                            onChange({
                                                target: { name, value },
                                            });
                                        }}
                                        placeholder="Select ring duration"
                                        options={PhoneUtil.RING_DURATION_OPTIONS}
                                        label="Ring Duration"
                                        error={error?.message}
                                    />
                                )}
                            />
                            <Controller
                                name="vm_exit_parent"
                                control={control}
                                render={({
                                    field: {
                                        name: parentName,
                                        value: parentValue,
                                        onChange: onParentChange,
                                    },
                                }) => (
                                    <Controller
                                        name="exit"
                                        control={control}
                                        render={({ field }) => {
                                            const {
                                                name: childName,
                                                value: childValue,
                                                onChange: onChildChange,
                                            } = field;
                                            return (
                                                <Form.Field
                                                    control={NestedDropdown}
                                                    child_value={childValue}
                                                    parent_value={parentValue}
                                                    nested_options={destinationOptions}
                                                    onChange={(_, { parent_value, child_value }) => {
                                                        onParentChange({ target: { name: parentName, value: parent_value, } });
                                                        onChildChange({ target: { name: childName, value: child_value } });
                                                    }}
                                                    placeholder="Select destination"
                                                    display_parent
                                                    selection
                                                    label={{
                                                        children: (
                                                            <span>
                                                                VM Exit <Popup
                                                                    trigger={
                                                                        <Icon
                                                                            style={{ float: "right", marginTop: "0.25em" }}
                                                                            name="fas fa-info-circle"
                                                                            color="primary"
                                                                        />
                                                                    }
                                                                    inverted
                                                                    content="The caller will be routed to the selected destination if no voicemail has been left."
                                                                />
                                                            </span>
                                                        ),
                                                    }}
                                                    pointing="top"
                                                    className="UserExtensions__ruleField"
                                                    excluded_parents={EXCLUDED_PARENTS}
                                                />
                                            );
                                        }}
                                    />
                                )}
                            />
                            <Form.Field />
                        </div>
                        <Controller
                            name="browser_softphone"
                            control={control}
                            render={({ field: { name, value, onChange } }) => (
                                <Checkbox
                                    checked={value}
                                    onChange={(_, { name, checked }) => {
                                        onChange({
                                            target: { name, value: checked },
                                        });
                                    }}
                                    name={name}
                                    label="Enable browser softphone"
                                    style={{marginRight: 15}}
                                    toggle
                                />
                            )}
                        />
                        <Controller
                            name="whisper"
                            control={control}
                            render={({ field: { name, value, onChange } }) => (
                                <Checkbox
                                    checked={value}
                                    onChange={(_, { name, checked }) => {
                                        onChange({
                                            target: { name, value: checked },
                                        });
                                    }}
                                    name={name}
                                    label="Enable Listen, Whisper, & Barge"
                                    toggle
                                    disabled={!!watchedUser}
                                />
                            )}
                        />
                    </div>
                </Form>
            </Modal.Content>
            <Modal.Actions
                hasSaveButton
                onSave={ handleSubmit(onEdit) }
                saveDisabled={ !isValid || !isDirty }
                saveOptions={{ loading: false }}
            />
        </>
    );
};

const UserExtensionModal = ({ open, onClose, onReload, isEditing }) => {
    const [isDirty, setIsDirty] = useState(false);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    return (
        <React.Fragment>
            <Modal open={open} onClose={() => {
                isDirty
                    ? setIsConfirmationOpen(true)
                    : onClose();
            }} size={'large'}>
                <Content
                    onClose={onClose}
                    onReload={onReload}
                    isEditing={isEditing}
                    onDirtyChange={(dirty) => setIsDirty(dirty)}
                />
            </Modal>
            <FullScreenModalPopUp
                header={"Changes not saved"}
                subheader={"Are you sure you want to exit?"}
                isOpen={isConfirmationOpen}
                onConfirm={() => {
                    onClose();
                    setIsConfirmationOpen(false);
                    setIsDirty(false)
                }}
                onFormClose={() => setIsConfirmationOpen(false)}
                closeIcon={false}
            />
        </React.Fragment>
    );
};

export default UserExtensionModal;
