import React, { useState } from 'react';
import { Notification, STATUS_TYPES, Button, generateResolver, VALIDATORS, SettingsFooter } from 'dyl-components';
import { useDispatch, useSelector } from 'react-redux';
import { Portal, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import TeamForm from 'shared/forms/TeamForm';
import teamsActions from 'actions/teams';
import teamActions from 'actions/team';

import './index.scss';
import { Navigate } from 'react-router';
import { useForm } from 'react-hook-form';
import { MathUtils } from 'utils';
import useWidthListener from 'shared/SettingsFooter/useWidthListener';
import useWindowWidth from 'shared/SettingsFooter/useWindowWidth';
import { useBlocker } from 'shared/confirmation/UseBlocker';
import FullScreenModalPopUp from 'shared/FullScreenModalPopUp';

const AddTeam = () => {
    const isSaving = useSelector((state) => {
        return state.teams.isAddingTeam ||
            state.team.teamWhereUsersAreBeingAdded !== null ||
            state.team.teamBeingUpdated ||
            state.team.teamBeingDeleted;
    })

    const width = useWidthListener("settingsSidebar");
    const windowWidth = useWindowWidth();

    const dispatch = useDispatch();

    const [redirect, setRedirect] = useState(false);
    const [currentTeamId, setCurrentTeamId] = useState(null);
    const [currentTeamUsers, setCurrentTeamUsers] = useState([]);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [pendingNavigation, setPendingNavigation] = useState(null);

    const { control, formState: { isValid, isDirty }, getValues, reset, setError } = useForm({
        mode: 'onChange',
        resolver: generateResolver({
            name: VALIDATORS.TEAM_NAME(true).required('This field is required').trim()
        })
    });

    const onAddTeam = (newTeam) => {
        return dispatch(teamsActions.addTeam([newTeam]));
    }

    const onAddUsersToTeam = (users, team_id) => {
        return dispatch(teamActions.addUsers(users, null, team_id));
    }

    const onSetError = (name, { type, message }) => {
        setError(name, { type, message });
    }

    const onReset = (payload) => {
        reset(payload);
    }

    const onSave = ({ name, show_in_office_view, users }, reset, willAddMore, setError) => {
        onAddTeam({
            name: name.trim(),
            hide_view: !show_in_office_view
        }).then(([newTeam]) => {
            const selectedUsers = (users || []).map(user_id => ({ user_id }));

            setCurrentTeamId(newTeam.id);
            setCurrentTeamUsers(selectedUsers);
            return onAddUsersToTeam(selectedUsers, newTeam.id)
        }).then(() => {
            Notification.alert('Successfully created team!', STATUS_TYPES.SUCCESS, true);
            if (!willAddMore) {
                setRedirect(true);
            }
            if (reset && !willAddMore) {
                reset({ name: '', show_in_office_view: false, users: [] });
            }
        }).catch(error => {
            console.log(error);
            if (error.Code === 409) {
                if (setError) {
                    setError("name", { type: "unique", message: "Team name already exists!" });
                }
            }
            Notification.alert('Failed to create team', STATUS_TYPES.ERROR, true);
        });
    }

    const onSaveEdit = async ({ name, show_in_office_view, users }, reset, willAddMore, setError) => {
        const currentTeamUsersID = currentTeamUsers.map(({ user_id }) => user_id);
        const usersToRemove = currentTeamUsersID.filter(id => !users.includes(id));
        const usersToAdd = users.filter(id => !currentTeamUsersID.includes(id));

        try {
            await dispatch(teamActions.updateTeam(currentTeamId, {
                name: name.trim(),
                hide_view: !show_in_office_view
            }));
            await dispatch(teamActions.addUsers(usersToAdd.map(user_id => ({ user_id })), null, currentTeamId));
            await Promise.all(usersToRemove.map(user_id =>
                dispatch(teamActions.deleteUser(currentTeamId, { user_id }))
            ))
            const newSelectedUsers = new Set([...currentTeamUsersID]);
            usersToAdd.forEach(userId => newSelectedUsers.add(userId));
            setCurrentTeamUsers([...newSelectedUsers].filter(
                selectedUser => !usersToRemove.includes(selectedUser)
            ));
            Notification.alert('Successfully updated team!', STATUS_TYPES.SUCCESS);
            if (!willAddMore) {
                setRedirect(true);
            }
            if (reset && !willAddMore) {
                reset({ name: '', show_in_office_view: false, users: [] });
            }
        } catch (error) {
            console.log(error);
            if (error?.response?.data?.error === "unique_violation") {
                if (setError) {
                    setError("name", { type: "unique", message: "Team name already exists!" });
                }
            }
            Notification.alert('Failed to update team', STATUS_TYPES.ERROR);
        }
    }
    const handleConfirmLeave = () => {
        setIsConfirmationOpen(false);
        if (pendingNavigation) {
            pendingNavigation.retry();
        }
    };
    useBlocker(
        (tx) => {
            if (isDirty && !isConfirmationOpen) {
                setPendingNavigation(tx);
                setIsConfirmationOpen(true);
            } else {
                tx.retry();
            }
        },
        isDirty
    );
    if (redirect) {
        return <Navigate to={`/settings/teams`} />
    }
    return (
        <Segment basic className="AddTeam">
            <TeamForm
                control={control}
                isSaving={isSaving}
                header={'Add New Team'}
                onSave={onSave}
            />
            <Portal open>
                <SettingsFooter
                    style={{ width: MathUtils.calculatePercentage(windowWidth, windowWidth - width) }}
                    className={`Webform__menu`}
                    rightOptions={[
                        <Button as={Link} to='/settings/teams' status={'cancel'} basic>Cancel</Button>,
                        <Button disabled={!isValid || !isDirty}
                            onClick={() => {
                                if (!currentTeamId) {
                                    onSave(getValues(), onReset, false, onSetError);
                                } else {
                                    onSaveEdit(getValues(), onReset, false, onSetError);
                                }
                            }}
                            primary
                        >
                            Save
                        </Button>,
                        <Button disabled={!isValid || !isDirty} onClick={() => {
                            if (!currentTeamId) {
                                onSave(getValues(), onReset, true, onSetError);
                            } else {
                                onSaveEdit(getValues(), onReset, true, onSetError);
                            }
                        }} primary>
                            Save and Keep Editing
                        </Button>
                    ]}
                />
            </Portal>
            <FullScreenModalPopUp
                header={"Changes not saved"}
                subheader={"Are you sure you want to exit?"}
                isOpen={isConfirmationOpen}
                onConfirm={handleConfirmLeave}
                onFormClose={() => setIsConfirmationOpen(false)}
                closeIcon={false}
            />
        </Segment>
    )
}

export default AddTeam;
