const initialState = {
    isReadingCustomerProfile: false,
    profile: null,
    customerFormToken: null,
    isReadingCustomerFormToken: false,
    isFundingAccount: false,
    balanceInfo: { sms_balance: 0, account_balance: 0 },
    isPayingInvoices: false,
    isUpdatingPaymentProcessor: false
}

export default initialState;
