import React, { useState } from "react";
import { TabAccordion } from "dyl-components";
import TaskLabels from "./TaskLabels";

const Tasks = ({ handleFormDirty }) => {
    const [active, setActive] = useState(-1);

    const onChangeTab = (_, { index }) => {
        setActive((prevActive) => (prevActive === index ? -1 : index));
    };

    const items = [
        {
            title: "Tasks Labels",
            description: `Create custom labels as a sub-category for your tasks.`,
            content: <TaskLabels handleFormDirty={handleFormDirty} />,
        },
        {
            title: "Tasks Types",
            description: `Create custom task types fields to use when adding tasks. This will allow you to track more detailed tasks.`,
            content: <React.Fragment />,
        },
    ];

    return (
        <TabAccordion
            header="Tasks"
            items={items}
            active={active}
            onChangeTab={onChangeTab}
        />
    );
};

export default Tasks;
