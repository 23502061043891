import React, { useEffect, useState } from 'react';
import { Form, Icon, Popup, Segment, TextArea, Input } from 'semantic-ui-react';
import { Modal, FileInput, VALIDATORS, generateResolver, yup, Notification, STATUS_TYPES, Button } from 'dyl-components';
import FileUtils, { FILE_CATEGORIES } from 'utils/FileUtils';

import { useForm, Controller } from 'react-hook-form';

import { useDispatch, useSelector } from 'react-redux';

import textTemplateActions from 'actions/text_templates';

import './index.scss';
import FullScreenModalPopUp from 'shared/FullScreenModalPopUp';

const TEXT_CHARACTER_LIMIT = 160;

const TextTemplateModal = ({
    categoryOptions,

    editorRef = React.createRef(),
    dropzoneRef = React.createRef(),

    onPreview,
    open,
    mode,
    isSaving,
    onClose,
    id,
    duplicate_id,
    onUpdate,
    onCreate
}) => {
    const [isPersonalizing, setPersonalize] = useState(false);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    

    const { control, formState: { isValid , isDirty }, getValues, setValue, setError, trigger } = useForm({
        mode: 'onChange',
        resolver: generateResolver({
            name: VALIDATORS.TEXT_TEMPLATE_NAME(true).required('This field is required'),
            category: yup.number().required('This field is required'),
            attachments: FileUtils.getFilesizeValidator(FILE_CATEGORIES.TEXT)
        })
    });

    const dispatch = useDispatch();

    const isReading = useSelector(state => state.text_template.isReadingTemplate);

    useEffect(() => {
        if (id || duplicate_id) {
            dispatch(textTemplateActions.getTemplate(id || duplicate_id)).then(({
                name,
                isFavorite,
                attachments,
                message,
                template_category
            }) => {
                setValue('attachments', attachments?.data.map(attachment => ({
                    path: attachment.name,
                    id: attachment.id,
                    file_id: attachment.file_id,
                    name: attachment.name,
                    size: attachment.size,
                    url: attachment.url,
                })) || []);
                setValue('category', template_category?.id);
                setValue('text', message || '');
                setValue('isFavorite', isFavorite);
                setValue('name', duplicate_id ? `Duplicate - ${name}` : name);

                trigger('name');
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const personalize = (variable) => {
        setPersonalize(false);
        const element = editorRef.current;
        const toInsert = variable;
        if (element.getEditorSelection) {
            const selection = element.getEditorSelection();
            const position = selection ? selection.index : 0;
            element.getEditor().insertText(position, toInsert);
        } else {
            const textarea = element.ref.current;
            textarea.focus();
            const selectionStart = textarea.selectionStart;
            const { text } = getValues();
            const beginning = text.substr(0, selectionStart);
            const end = text.substr(selectionStart);
            setValue("text", `${beginning}${toInsert}${end}`,{shouldDirty:true});
        }
    }

    const preview = () => {
        const { text, attachments } = getValues();
        onPreview({ text, attachments });
    }

    const close = () => {
        onClose();
    }

    const save = () => {
        const { name, ...state } = getValues();
        const payload = {
            name: name ? name.trim() : '',
            ...state
        }
        if (id) {
            onUpdate(id, payload).then(() => {
                close();
            }).catch((error) => {
                if (error?.response?.data?.Code === 409) {
                    if (setError) {
                        setError("name", { type: "unique", message: "Template name already exists!" });
                    }
                }
            });
        } else {
            onCreate(payload).then(() => {
                close();
            }).catch((error) => {
                if (error?.Code === 409) {
                    if (setError) {
                        setError("name", { type: "unique", message: "Template name already exists!" });
                    }
                }
            });
        }
    }
    
    return (
        <React.Fragment>
            <Modal open={open} onClose={() => {
                isDirty
                    ? setIsConfirmationOpen(true)
                    : close();
            }}>
                <Modal.Header>{mode || 'Create'} Text Template</Modal.Header>
                <Modal.Content>
                    <Form loading={isReading || isSaving}>
                        <Form.Group style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <Controller
                                name="name"
                                control={control}
                                defaultValue={''}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <Form.Input
                                        width={10}
                                        label="Template Name"
                                        onChange={onChange}
                                        value={value}
                                        error={error && error.message && {
                                            content: error.message,
                                            pointing: 'below'
                                        }}
                                        required
                                    />
                                )}
                            />
                            <Controller
                                name="category"
                                control={control}
                                defaultValue={null}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <Form.Select
                                        width={5}
                                        label="Category"
                                        onChange={(_, { value: selected }) => { onChange({ target: { name: 'category', value: selected } }) }}
                                        value={value}
                                        error={error && error.message && {
                                            content: error.message,
                                            pointing: 'below'
                                        }}
                                        required

                                        placeholder="Select Category"
                                        selectOnBlur={false}
                                        options={categoryOptions}
                                    />
                                )}
                            />
                            <Controller
                                name="isFavorite"
                                control={control}
                                defaultValue={false}
                                render={({ field: { onChange, value } }) => (
                                    <Form.Field
                                        control={Icon}
                                        onClick={() => { onChange({ target: { name: 'isFavorite', value: !value } }); }}
                                        name={!value ? 'star outline' : 'star'}
                                        link
                                        color='blue'
                                        width={1}
                                        label=" "
                                        style={{ marginTop: "0.75em", fontSize: "1.5em" }}
                                    />
                                )}
                            />
                        </Form.Group>
                        <Segment.Group>
                            <Controller
                                name="text"
                                control={control}
                                defaultValue={''}
                                render={({ field: { onChange, value, name }, }) => (
                                    <Segment>
                                        <Popup
                                            trigger={<Button onClick={() => { setPersonalize(!isPersonalizing) }} type="reset" primary>Personalize</Button>}
                                            open={isPersonalizing}
                                            onClose={() => { setPersonalize(false) }}
                                            content={(
                                                <Form className="Template__variables">
                                                    <h5>Sender Variables</h5>
                                                    <Form.Group widths='equal'>
                                                        <Form.Button onClick={() => { personalize('%%My Full Name%%') }} fluid size='mini' basic primary>My Full Name</Form.Button>
                                                        <Form.Button onClick={() => { personalize('%%My First Name%%') }} fluid size='mini' basic primary>My First Name</Form.Button>
                                                    </Form.Group>
                                                    <Form.Group widths='equal'>
                                                        <Form.Button onClick={() => { personalize('%%My Phone%%') }} fluid size='mini' basic primary>My Phone</Form.Button>
                                                        <Form.Button onClick={() => { personalize('%%My Company%%') }} fluid size='mini' basic primary>My Company</Form.Button>
                                                    </Form.Group>
                                                    <h5>Recipient Variables</h5>
                                                    <Form.Group widths='equal'>
                                                        <Form.Button onClick={() => { personalize('%%Full Name%%') }} fluid size='mini' basic primary>Full Name</Form.Button>
                                                        <Form.Button onClick={() => { personalize('%%First Name%%') }} fluid size='mini' basic primary>First Name</Form.Button>
                                                    </Form.Group>
                                                    <Form.Group widths='equal'>
                                                        <Form.Button onClick={() => { personalize('%%Last Name%%') }} fluid size='mini' basic primary>Last Name</Form.Button>
                                                        <Form.Button onClick={() => { personalize('%%Phone%%') }} fluid size='mini' basic primary>Phone</Form.Button>
                                                    </Form.Group>
                                                    <Form.Group widths='equal'>
                                                        <Form.Button onClick={() => { personalize('%%Address%%') }} fluid size='mini' basic primary>Address</Form.Button>
                                                        <Form.Button onClick={() => { personalize('%%City%%') }} fluid size='mini' basic primary>City</Form.Button>
                                                    </Form.Group>
                                                    <Form.Group widths='equal'>
                                                        <Form.Button onClick={() => { personalize('%%State%%') }} fluid size='mini' basic primary>State</Form.Button>
                                                        <Form.Button onClick={() => { personalize('%%Zip%%') }} fluid size='mini' basic primary>Zip</Form.Button>
                                                    </Form.Group>
                                                </Form>
                                            )}
                                            position='bottom right'
                                            on='click'
                                        />
                                        <TextArea
                                            onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                            value={value}
                                            className="Template__body"
                                            ref={editorRef}
                                        />
                                        <div className="Template__body-character-count">
                                            <section><b>Character count:</b> {value.length}/{TEXT_CHARACTER_LIMIT}</section>
                                            <section><em>{TEXT_CHARACTER_LIMIT} characters max per message. Additional charges apply.</em></section>
                                        </div>
                                    </Segment>
                                )}
                            />
                            <Controller
                                name="attachments"
                                defaultValue={[]}
                                control={control}
                                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                    <Form.Field
                                        control={Input}
                                        error={error && error.message && {
                                            pointing: 'below'
                                        }}
                                        className='Template__attachments-section'
                                    >
                                        <Segment style={{width: '100%' }}>
                                        <div>
                                            <Icon onClick={() => { dropzoneRef.current.open() }} color='blue' name='plus circle' link /> <b>Attach {value.length > 0 && 'more '}files</b>
                                        </div>
                                        <div className='Template__attachments' style={{marginBottom: 10}}>
                                            <FileInput
                                                onChange={(_, { value: new_value }) => {
                                                    onChange({
                                                        target: {
                                                            name, value: [
                                                                ...value,
                                                                ...new_value.filter(new_file => (
                                                                    value.findIndex(file => file.path === new_file.path) === -1
                                                                ))
                                                            ]
                                                        }
                                                    })
                                                }}
                                                onReject={(rejected) => {
                                                    if (rejected.length > 0) {
                                                        Notification.alert("File must be an .png, .jpg, .jpeg or .gif", STATUS_TYPES.ERROR, true);
                                                    }
                                                }}
                                                onRemove={(_, { value }) => { onChange({ target: { name, value } }) }}
                                                files={value}
                                                name="files"
                                                accept="image/*"
                                                icon="image outline"
                                                size="mini"
                                                dropzoneRef={dropzoneRef}
                                                showIcon
                                                hasBrowse
                                            />
                                        </div>
                                        <i>Remaining: {FileUtils.getRemainingSize(value, FILE_CATEGORIES.TEXT)} MB </i> {error?.message && `(${error.message})`}
                                        </Segment>
                                    </Form.Field>
                                )}
                            />
                        </Segment.Group>
                    </Form>
                </Modal.Content>
                <Modal.Actions 
                    hasSaveButton
                    onSave={save}
                    saveDisabled={!isValid}
                    saveOptions={{loading: isSaving}}
                >
                    {!isSaving && <Button basic onClick={preview}>Preview</Button>}
                </Modal.Actions>
            </Modal>
            <FullScreenModalPopUp
                header={"Changes not saved"}
                subheader={"Are you sure you want to exit?"}
                isOpen={isConfirmationOpen}
                onConfirm={() => {
                    close();
                    setIsConfirmationOpen(false);
                }}
                onFormClose={() => setIsConfirmationOpen(false)}
                closeIcon={false}
            />
        </React.Fragment>
    );
};

export default TextTemplateModal;
