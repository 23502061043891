import React, { useState, useEffect } from 'react';
import { TableWithHeader } from 'dyl-components';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { AccountsTable } from './subcomponents/AccountsTable';
import { AccountsToolbar } from './subcomponents/AccountsToolbar';
import oauthActions from 'actions/oauth';
import './index.scss';
import { useDispatch, useSelector } from 'react-redux';
import accountActions from 'actions/account';
import {
    KEY_CREATED_ON, KEY_ACCOUNT_TYPE, KEY_REACTIVATED_ON,
    KEY_CREATED_BY, KEY_OWNER, KEY_REACTIVATED_BY,
    KEY_LAST_MODIFIED, KEY_FUND_TYPE, KEY_ACCOUNT_STATUS,
    KEY_LAST_MODIFIED_BY, KEY_CUSTOMER_SINCE, KEY_ACTIONS,
    KEY_CANCELED_ON, KEY_NAME, KEY_CANCELED_BY
} from './subcomponents/constants';
import FormsProvider from './subcomponents/FormsProvider';

const Accounts = () => {
    const [params] = useSearchParams();
    const dispatch = useDispatch();
    const isDyl = useSelector(state => state.auth.dyl_employee);

    const searchQuery = params.get('search') || '';
    const [search, setSearch] = useState(searchQuery);
    const [isDropDownOpen,setIsDropDownOpen] = useState(false)

    const navigate = useNavigate();

    const onChangeSearch = (_, { value }) => {
        setSearch(value);
    }

    const cancelFunction = () => {
        setSearch("");
        const query = new URLSearchParams(params);
        query.delete('search');
        const query_string = query.toString();
        navigate(`/accounts${query_string ? `?${query_string}` : ''}`,);
    };

    const additionalColumns = isDyl ? [ KEY_FUND_TYPE ] : []

    const columnDisplayOptions = [
        KEY_CREATED_ON, KEY_ACCOUNT_TYPE, KEY_REACTIVATED_ON,
        KEY_CREATED_BY, KEY_OWNER, KEY_REACTIVATED_BY,
        KEY_LAST_MODIFIED, KEY_ACCOUNT_STATUS,
        KEY_LAST_MODIFIED_BY, KEY_CUSTOMER_SINCE, KEY_ACTIONS,
        KEY_CANCELED_ON, KEY_NAME, KEY_CANCELED_BY,
        ...additionalColumns
    ];
    const defaultColumnsDisplayed = [KEY_CREATED_ON, KEY_ACCOUNT_TYPE, KEY_OWNER, KEY_ACCOUNT_STATUS, KEY_ACTIONS, KEY_NAME, ...additionalColumns];
    const columnsParameter = params.get('columns')?.split(',')?.filter(column => columnDisplayOptions.includes(column)) || [];
    const columns = columnsParameter.length ? columnsParameter : defaultColumnsDisplayed;

    const readAccounts = () => {
        let columns = (params.get('columns')?.split(',') || defaultColumnsDisplayed).filter(
            column => column !== KEY_ACTIONS && column !== KEY_OWNER
        );

        if (columns.includes(KEY_ACCOUNT_STATUS) && !columns.includes(KEY_CUSTOMER_SINCE)) columns.push(KEY_CUSTOMER_SINCE);

        dispatch(accountActions.readAccounts({
            ...Object.fromEntries(params),
            columns: columns.toString()
        }));
    }

    useEffect(() => {
        dispatch(oauthActions.getIntegrations({ scopes: 'email', include_disabled: false }));
        readAccounts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);

    const [filters, setFilters] = useState({
        owned_by: params.get('owned_by')?.split(','),
        status: params.get('status')?.split(','),
        filter: params.get('filter'),
        ...isDyl ? { fund_type: params.get('fund_type')?.split(',') } : {}
    })

    const onFilter = async (_, { name, value }) => {
        setFilters({
            ...filters,
            [name]: value
        });
    }

    useEffect(() => {
        const query = new URLSearchParams(params);

        if (search.trim()) {
            query.set('search', search);
        } else {
            query.delete('search');
        }

        const { owned_by, status, fund_type } = filters;
        if (owned_by?.length > 0) {
            query.set('owned_by', owned_by.join(','));
        } else {
            query.delete('owned_by');
        }
        if (status?.length > 0) {
            query.set('status', status.join(','));
        } else {
            query.delete('status');
        }
        if (isDyl) {
            if (fund_type?.length > 0) {
                query.set('fund_type', fund_type.join(','));
            } else {
                query.delete('fund_type');
            }
        }

        const { filter } = filters;
        if (filter?.length > 0) {
            query.set('filter', filter);
        } else {
            query.delete('filter');
        }

        const query_string = query.toString();
        navigate(`/accounts${query_string ? `?${query_string}` : ''}`,);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters])


    const onSearchSubmit = (value) => {
        const query = new URLSearchParams(params);
        query.set('search', value.trim());
        query.set('page', 1);
        const query_string = query.toString();
        navigate(`/accounts${query_string ? `?${query_string}` : ''}`);
    }

    const isColumnHidden = (column) => {
        return !columns.includes(column);
    }

    const onToggleColumn = async (column) => {
        const updatedColumns = isColumnHidden(column) ? [...columns, column] : columns.filter(visibleColumn => visibleColumn !== column);

        const updateColumnsParameter = () => {
            const query = new URLSearchParams(params);
            if (updatedColumns.length) {
                query.set('columns', updatedColumns.join(','));
            } else {
                query.delete('columns');
            }
            const query_string = query.toString();
            navigate(`/accounts${query_string ? `?${query_string}` : ''}`);
        }

        updateColumnsParameter();
    }

    const onPageChange = (_, { activePage }) => {
        const query = new URLSearchParams(params);
        query.set('page', activePage);
        const query_string = query.toString();
        navigate(`/accounts${query_string ? `?${query_string}` : ''}`);
    }

    return (
        <div className='AccountsPanel'>
            <TableWithHeader
                header={(
                    <AccountsToolbar
                        search={searchQuery}
                        onChangeSearch={onChangeSearch}
                        onSearchSubmit={onSearchSubmit}
                        cancelFunction={cancelFunction}
                        isColumnHidden={isColumnHidden}
                        onToggleColumn={onToggleColumn}
                        onFilter={onFilter}
                        isDropDownOpen={isDropDownOpen}
                        setIsDropDownOpen={setIsDropDownOpen}
                    />
                )}
                table={(
                    <FormsProvider>
                        <AccountsTable
                            isColumnHidden={isColumnHidden}
                            onFilter={onFilter}
                            onPageChange={onPageChange}
                            readAccounts={readAccounts}
                            setIsDropDownOpen={setIsDropDownOpen}
                        />
                    </FormsProvider>
                )}
            />
        </div>
    )
}

export default Accounts;
