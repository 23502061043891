import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Modal, RecordsTable } from 'dyl-components';

import LeadsHeader from './Header';
import LeadsRow from './LeadsRow';
import AddLeadModal from 'shared/modals/AddLeadModal';

const LeadsTable = ({ onFilter, isColumnHidden, onPageChange, filterQueryParameters }) => {
    const [params] = useSearchParams();

    const { count, leads = [], isReadingLeads } = useSelector(state => state.leads);

    const [isAddLeadModalOpen, setIsAddLeadModalOpen] = useState(false);

    const onOpenAddLeadModal = () => {
        setIsAddLeadModalOpen(true);
    }

    const onCloseAddLeadModal = () => {
        setIsAddLeadModalOpen(false);
    }
    return (
        <React.Fragment>

            <RecordsTable
                tableBodyClassName='ModulesListTable'
                isTableLoading={isReadingLeads}
                isSearchResultEmpty={count === 0 && (params.get('search') || params.get('start') || params.get('end') || params.get('account_type') || params.get('owned_by'))}
                isTableEmpty={count === 0 && !(params.get('search') || params.get('start') || params.get('end') || params.get('account_type') || params.get('owned_by'))}
                TableHeader={<LeadsHeader isColumnHidden={isColumnHidden} onFilter={onFilter} />}
                TableRows={leads.map(lead => (
                    <LeadsRow
                        key={lead.pipeline_id}
                        lead={lead}
                        isColumnHidden={isColumnHidden}
                        filterQueryParameters={filterQueryParameters}
                    />
                ))}
                recordsCount={count}
                activePage={params.get('page')}
                onPageChange={onPageChange}
                recordsName='Lead'
                onClick={onOpenAddLeadModal}
            />
            <Modal open={isAddLeadModalOpen} onClose={onCloseAddLeadModal}>
                <AddLeadModal onOpen={() => { return Promise.resolve(); }} onClose={onCloseAddLeadModal} />
            </Modal>
        </React.Fragment>
    );
}

export default LeadsTable;
