import React, { useState } from 'react';
import { Grid, Icon, Header, Form, Button, Loader } from 'semantic-ui-react';

import { connect } from 'react-redux';

import { STATUS_TYPES, Notification, generateResolver, yup, VALIDATORS } from 'dyl-components';

import Phone from './Phone';
import contactDuplicatesActions from 'actions/contact_duplicates';
import contactPhoneActions from 'actions/contact_phone';
import contactActions from 'actions/contact';
import { useFieldArray, useForm } from 'react-hook-form';
import { PhoneUtil, RecordUtils, ValidationUtils } from 'utils';
import RecordPhonesForm from 'shared/forms/RecordPhonesForm';
import PHONE_TYPE_OPTIONS from 'shared/constants/PHONE_TYPE_OPTIONS';

const PhonesField = ({
    mainPhones,
    nonMainPhones,

    phones,
    emails,

    checkDuplicates,
    contact_id,
    onUpdateContactPhones,
    onReadContactPhones,
    isCheckingDuplicates,
    isReadingPhones,
    
    shouldCheckDuplicates = true,
    noPadding,
    allowNoPhones,
    
    isEditing,
    onEdit,
    onCancelEdit
}) => {
    const [areOtherPhonesShown, setOtherPhonesShown] = useState(false);

    const onCancel = () => {
        onCancelEdit(() => {
            reset({
                phones: phones.map(phone => ({
                    value: phone.phone,
                    main: phone.main,
                    id: phone.id,
                    type: phone.phone_type
                })),
                emails: emails.map(email => ({
                    value: email.email,
                    main: email.main,
                    id: email.id,
                    type: email.email_type
                }))
            })
        });
    }

    const onToggleOtherPhones = () => {
        setOtherPhonesShown(!areOtherPhonesShown);
    }

    const { formState: { isDirty, isValid }, control, watch, setError, clearErrors, reset } = useForm({
        mode: 'onChange',
        defaultValues: {
            phones: phones.map(phone => ({
                value: phone.phone,
                main: phone.main,
                id: phone.id,
                type: phone.phone_type
            })),
            emails: emails.map(email => ({
                value: email.email,
                main: email.main,
                id: email.id,
                type: email.email_type
            }))
        },
        resolver: generateResolver({
            phones: yup.array().when("emails", {
                is: emails => {
                    return emails?.some((email) => Boolean(email.value)) === true;
                },
                then: schema => schema.of(yup.object().shape({
                    value: VALIDATORS.PHONE_NUMBER().required('This field is required'),
                    type: yup.string().required('This field is required')
                })).test('no_repeating_phone', "Phone already in the list", ValidationUtils.checkForDuplicatePhonesInTheList),
                otherwise: schema => schema.of(yup.object().shape({
                    value: VALIDATORS.PHONE_NUMBER().required('This field is required'),
                    type: yup.string().required('This field is required')
                })).test('no_repeating_phone', "Phone already in the list", ValidationUtils.checkForDuplicatePhonesInTheList).min(1, 'Should have at least one phone'),
            })
        })
    });

    const { fields: phonesToEdit, append: addPhone, remove: removePhone, update: updatePhone } = useFieldArray({
        control,
        name: 'phones'
    });

    const [watchedPhones] = watch(['phones']);

    const [isUpdating, setIsUpdating] = useState(false);

    const onConfirmEdit = () => {
        setIsUpdating(true);
        onUpdateContactPhones(contact_id, phones, watchedPhones).then(() => {
            setIsUpdating(false);
            Notification.alert('Successfully updated phones!', STATUS_TYPES.SUCCESS, true);
            onReadContactPhones(contact_id).then(() => {
                onCancel();
            });
        }).catch(() => {
            setIsUpdating(false);
            Notification.alert('Failed to update phones', STATUS_TYPES.ERROR, true);
        })
    }

    if (isReadingPhones) {
        return <Loader active />
    }

    return (
        !isEditing ? (
            <Grid.Row verticalAlign='top' className={`ContactInfo__value ${noPadding ? 'ContactInfo__row' : ''}`}>
                <Grid.Column width={9} style={{ paddingRight: 0, flex: 1 }}>
                    <div onClick={onEdit}>
                        {mainPhones.length <= 0 && (
                            <span>
                                <Icon name='phone' size='small' color='grey' /> No main phone
                            </span>
                        )}
                        {mainPhones.map(phone => (
                            <div>
                                <Phone phone={phone.phone} phone_type={phone.phone_type} />
                            </div>
                        ))}
                        {areOtherPhonesShown && (
                            <div>
                                {nonMainPhones.map(phone => (
                                    <div>
                                        <Phone phone={phone.phone} phone_type={phone.phone_type} />
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </Grid.Column>
                <Grid.Column width={1} style={{ left: -15 }}>
                    {nonMainPhones.length > 0 && <Icon disabled={nonMainPhones.length <= 0} onClick={onToggleOtherPhones} bordered size='tiny' name={`caret ${areOtherPhonesShown ? 'down' : 'right'}`} link style={{ marginLeft: 5, fontSize: 9, borderRadius: 5 }} />}
                </Grid.Column>
                {isReadingPhones && (
                    <Loader active={isReadingPhones} />
                )}
            </Grid.Row>
        ) : (
            <Grid.Row>
                <Grid.Column>
                    <Form size='mini' loading={isUpdating}>
                        <Header size='small' color='primary'>
                            <span>
                                <Icon link onClick={() => { PhoneUtil.onAddPhone({ value: '', type: PHONE_TYPE_OPTIONS.keys.CELL, new: true }, phonesToEdit, addPhone) }} className='fas fa-plus-circle' color='primary' /> Phones
                            </span>
                        </Header>
                        <RecordPhonesForm
                            control={control}
                            phones={phonesToEdit}
                            onRemove={(index) => {
                                RecordUtils.removeItem(watchedPhones, index, updatePhone, removePhone);
                            }}
                            updateMain={(onChange) => {
                                RecordUtils.updateMain(watchedPhones, updatePhone, onChange);
                            }}
                            checkDuplicates={shouldCheckDuplicates ? checkDuplicates : () => {return []}}
                            clearErrors={clearErrors}
                            contact_id={contact_id}
                            setError={setError}
                            allowNoPhones={allowNoPhones}
                        />
                        <Grid>
                            <Grid.Column textAlign='right'>
                                {!isUpdating && <Button size='mini' basic primary onClick={onCancel}>
                                    Cancel
                                </Button>}
                                <Button size='mini' loading={isUpdating} disabled={!isDirty || !isValid || isCheckingDuplicates} type='button' onClick={onConfirmEdit} primary>
                                    Save
                                </Button>
                            </Grid.Column>
                        </Grid>
                    </Form>
                </Grid.Column>
            </Grid.Row>
        )
    );
};

const mapStateToProps = state => {
    const phones = state.contact_phone.phone ? state.contact_phone.phone.slice(0) : [];
    const mainPhones = phones.filter(phone => phone.main);
    const nonMainPhones = phones.filter(phone => !phone.main);

    return {
        mainPhones,
        nonMainPhones,
        phones,
        isCheckingDuplicates: state.contact_duplicates.isCheckingDuplicates,
        isReadingPhones: state.contact_phone.isReadingPhones,
        emails: state.contact_email.email ? state.contact_email.email.slice(0) : []
    }
};

const mapDispatchToProps = (dispatch) => ({
    onUpdateContactPhones: async (contact_id, phones, phoneValues) => {
        const phonesToDelete = phones.filter(phone => phoneValues.findIndex(phoneValue => phoneValue.id === phone.id) === -1);
        const phonesToAdd = phoneValues.filter(phone => phone.new);
        const phonesToUpdate = phoneValues.filter(phoneValue => !phoneValue.new && phones.findIndex(phone => (
            phone.id === phoneValue.id &&
            phone.main === phoneValue.main &&
            phone.phone === phoneValue.value &&
            phone.phone_type === phoneValue.type
        )) === - 1);
        await Promise.all([
            ...phonesToUpdate.map(phone => {
                return dispatch(contactPhoneActions.updateContactPhone(contact_id, {
                    main: phone.main,
                    phone: phone.value,
                    phone_type: phone.type
                }, null, phone.id));
            }),
            (() => {
                if (phonesToDelete.length) {
                    return Promise.all(phonesToDelete.map(phone => dispatch(contactPhoneActions.deleteContactPhone(contact_id, null, phone.id))));
                }
                return Promise.resolve();
            })()
        ])
        if (phonesToAdd.length > 0) {
            return dispatch(contactPhoneActions.addContactPhone(phonesToAdd.map(phone => ({
                main: phone.main,
                phone: phone.value,
                phone_type: phone.type
            })), null, contact_id));
        }
        return Promise.resolve();
    },
    onReadContactPhones: (contact_id) => {
        return Promise.all([
            dispatch(contactActions.readContact(contact_id)),
            dispatch(contactPhoneActions.getContactPhones(contact_id)),
        ])
    },
    checkDuplicates: (queryParameters) => {
        return dispatch(contactDuplicatesActions.checkDuplicates(queryParameters));
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(PhonesField);
