import { Table } from "dyl-components";
import { Form, Icon, Popup } from "semantic-ui-react";
import { MathUtils, ObjectUtils, StringUtils } from "utils";
import React from "react";
import QuoteItemAddition from "./QuoteItemAddition";
import { useController, useFormContext } from "react-hook-form";

const PricingSchedule = ({ price_data, value, onChange, disabled, error, readOnly }) => {
    const model = price_data?.model;
    if (!model?.includes("recurring")) {
        if (model === "usage") {
            return "Usage";
        }
        if (model === "volume") {
            return "Volume-based";
        }
        return "One-time";
    }
    const frequencies = Object.keys(price_data.price);
    return (
        <Form.Dropdown
            value={value}
            options={frequencies.map((frequency) => {
                const isActive = price_data.price[frequency].active;
                const text = frequency !== "semi-annually" ? StringUtils.capitalize(frequency) : "Semi-Annually";
                return ({
                    key: frequency,
                    value: frequency,
                    text: isActive ? text : <s>{text}</s>,
                    disabled: !isActive
                });
            })}
            onChange={onChange}
            selection
            disabled={disabled}
            fluid
            selectOnBlur={false}
            error={error}
            {...(readOnly ? {
                open: false,
                icon: null
            } : {})}
            style={{ width: "10em" }}
        />
    );
};

const Fees = ({
    fees,
    isInCart,
    keyPrefix,
    quantity,
    type = "fee",
    netTotal,
    onlyRecurring
}) => {
    return fees.map((fee) => {
        const updates = (() => {
            if (fee.status === "inactive") {
                return "Removed";
            }
            if (fee.amount === 0) {
                return "New";
            }
            if (
                fee.tax_fee_item_percent !== fee.tax_fee_percent ||
                fee.amount !== fee.current_amount
            ) {
                return "Price Changed";
            }
            return "";
        })();
        const icon = updates ? (
            <Popup
                size="mini"
                content={updates}
                inverted
                position="top center"
                trigger={<Icon className="fas fa-info-circle" color="orange" />}
            />
        ) : null;
        const disabled = !onlyRecurring && (!isInCart || fee.status === "inactive");
        return (
            <QuoteItemAddition
                disabled={disabled}
                key={`${keyPrefix}-${type}-${fee.id}`}
                label={
                    <>
                        {icon}{" "}
                        <span
                            {...(disabled
                                ? { className: "Quote__disabled-text" }
                                : {})}
                        >
                            <b>*{StringUtils.capitalize(type)}:</b>
                            {fee.name}
                        </span>
                    </>
                }
                description={fee.description}
                quantity={quantity}
                price={
                    <>
                        <div>
                            {!fee.tax_fee_percent ? "$" : ""}
                            {fee.current_amount}
                            {fee.tax_fee_percent ? "%" : ""}
                        </div>
                        {updates === "Price Changed" && (
                            <s>
                                ({!fee.tax_fee_item_percent ? "$" : ""}
                                {fee.tax_fee_item_percent
                                    ? fee.amount
                                    : StringUtils.formatDecimal(
                                          `${fee.amount}`,
                                          true
                                      )}
                                {fee.tax_fee_item_percent ? "%" : ""})
                            </s>
                        )}
                    </>
                }
                sum={`$${StringUtils.formatDecimal(
                    `${MathUtils.roundDecimals(
                        fee.current_amount *
                            (fee.tax_fee_percent ? 0.01 * netTotal : quantity)
                    )}`,
                    true
                )}`}
                hasMargin={!Boolean(icon)}
                onlyRecurring={onlyRecurring}
            />
        );
    });
};

const QuoteItemRow = ({ item, index, quote, disabled: editsNotAllowed, isOrder, onlyRecurring, additionalQuantity = 0, position = 0 }) => {
    const productDetails = quote.quote_summary.find(
        (quoteItem) =>
            quoteItem.product_id === item.id &&
            quoteItem.product_variation_id === item.variation_id
    );

    const keyPrefix = `product-${item.id}${
        item.variation_id ? `-variation-${item.variation_id}` : ""
    }`;

    const { control } = useFormContext();

    const {
        field: quantityField,
        fieldState: { error: quantityError },
    } = useController({
        name: `cart[${index}].quantity`,
        control,
    });

    const {
        field: discountField,
        fieldState: { error: discountFieldError },
    } = useController({
        name: `cart[${index}].discount`,
    });

    const { field: pricingScheduleField } = useController({
        name: `pricing_schedule`,
        control,
    });

    const { field: itemPricingScheduleField } = useController({
        name: `cart[${index}].pricing_schedule`,
        control
    })

    const currentPricingSchedule = isOrder ? itemPricingScheduleField.value : pricingScheduleField.value || itemPricingScheduleField.value;

    const totalQuantity = Number(quantityField.value) + additionalQuantity;

    const price = (() => {
        const isOneTime =
            !productDetails.price_data?.model?.includes("recurring");
        const pricingModel = productDetails.price_data.model;
        if (isOneTime) {
            if (!pricingModel.includes("volume")) {
                return pricingModel === "usage"
                    ? productDetails.price_data.price.price
                    : productDetails.price_data.price;
            }
            return (
                productDetails.price_data.price.find(
                    (range) =>
                        totalQuantity >= range.start &&
                        totalQuantity <=
                            (range.end || Number.POSITIVE_INFINITY)
                )?.price || 0
            );
        }
        if (!pricingModel.includes("volume")) {
            return productDetails.price_data.price[currentPricingSchedule]?.price || 0;
        }
        const frequency =
            productDetails.price_data.price[currentPricingSchedule]?.volumes || [];
        return (
            frequency.find(
                (range) =>
                    totalQuantity >= range.start &&
                    totalQuantity <=
                        (range.end || Number.POSITIVE_INFINITY)
            )?.price || 0
        );
    })();

    const { field: addonsField } = useController({
        name: `cart[${index}].addons`,
        control,
    });

    const { sum, netTotal, subtotal } = MathUtils.calculateItemTotals({
        price,
        additional_price: productDetails.product_additional_price || 0,
        quantity: totalQuantity,
        discount: discountField.value || 0,
        addons: !onlyRecurring ? productDetails.addon
            .filter(
                (addon) =>
                    addonsField.value.includes(addon.id) && !addon.unlinked
            )
            .map((addon) => ({
                price: addon.current_price || 0,
            })) : [],
        taxes: !onlyRecurring ? productDetails.tax.map((tax) => ({
            amount: tax.current_amount,
            percent: tax.tax_fee_percent,
        })) : [],
        fees: !onlyRecurring ? productDetails.fee.map((fee) => ({
            amount: fee.current_amount,
            percent: fee.tax_fee_percent,
        })) : [],
    });

    const { field: itemRemovedField } = useController({
        name: `cart[${index}].removed`,
        control,
    });

    const isInCart = !Boolean(itemRemovedField.value);

    const onToggleItem = () => {
        itemRemovedField.onChange({
            target: {
                name: itemRemovedField.name,
                value: !Boolean(itemRemovedField.value),
            },
        });
    };

    const updates = (() => {
        if (onlyRecurring) {
            return [];
        }
        if (
            productDetails.product_status === "inactive" ||
            productDetails.product_status === "discontinued"
        ) {
            return ["Unavailable"];
        }
        return [
            (() => {
                const isRecurring = productDetails.price_data?.model?.includes("recurring");
                if (!isRecurring) {
                    return null;
                }
                
                if (productDetails.price_data.price[currentPricingSchedule]?.active === false) {
                    return "Pricing Schedule Inactive"
                }
                if (!Boolean(productDetails.price_data.price[currentPricingSchedule]?.active)) {
                    return "Pricing Schedule Unavailable"
                }
                return null;
            })(),
            (() => {
                const isOneTime =
                    !productDetails.price_data?.model?.includes("recurring");
                if (isOneTime) {
                    if (productDetails.price_data?.model !== "volume") {
                        if (price !== productDetails.item_price) {
                            return "Price Changed";
                        }
                        return null;
                    }
                    const defaultQuantityRange =
                        productDetails.price_data.price.find(
                            (range) =>
                                productDetails.quantity >= range.start &&
                                productDetails.quantity <=
                                    (range.end || Number.POSITIVE_INFINITY)
                        );
                    const currentQuantityRange =
                        productDetails.price_data.price.find(
                            (range) =>
                                totalQuantity >= range.start &&
                                totalQuantity <=
                                    (range.end || Number.POSITIVE_INFINITY)
                        );
                    if (
                        ObjectUtils.deepEqual(
                            defaultQuantityRange,
                            currentQuantityRange
                        ) &&
                        currentQuantityRange?.price !==
                            productDetails.item_price
                    ) {
                        return "Price Changed";
                    }
                    return null;
                }

                if (productDetails.price_data?.model !== "volume_recurring") {
                    if (
                        currentPricingSchedule === productDetails.pricing_schedule &&
                        productDetails.item_price !==
                            (productDetails.price_data.price[currentPricingSchedule]?.price || 0)
                    ) {
                        return "Price Changed";
                    }
                    return null;
                }
                const defaultQuantityRange = productDetails.price_data.price[
                    currentPricingSchedule
                ]?.volumes?.find(
                    (range) =>
                        productDetails.quantity >= range.start &&
                        productDetails.quantity <=
                            (range.end || Number.POSITIVE_INFINITY)
                ) || { start: 1, end: 1, price: 0 };
                const currentQuantityRange = productDetails.price_data.price[
                    currentPricingSchedule
                ]?.volumes?.find(
                    (range) =>
                        totalQuantity >= range.start &&
                        totalQuantity <=
                            (range.end || Number.POSITIVE_INFINITY)
                ) || { start: 1, end: 1, price: 0 };
                if (
                    currentPricingSchedule === productDetails.pricing_schedule &&
                    ObjectUtils.deepEqual(
                        defaultQuantityRange,
                        currentQuantityRange
                    ) &&
                    currentQuantityRange?.price !== productDetails.item_price
                ) {
                    return "Price Changed";
                }
                return null;
            })(),
            productDetails.item_discount > productDetails.product_discount &&
                "Max Discount Changed"
        ].filter(update => Boolean(update));
    })();

    const icon = updates.length ? (
        <Popup
            size="mini"
            content={updates.map(update => <div>{update}</div>)}
            inverted
            position="top center"
            trigger={<Icon className="fas fa-info-circle" color="orange" />}
        />
    ) : null;

    const disabled = !onlyRecurring &&
        !(isInCart || updates.includes("Unavailable") || Boolean(editsNotAllowed));

    return (
        <React.Fragment>
            <Table.Row>
                <Table.Cell>
                    {icon}{" "}
                    <span
                        {...(disabled
                            ? {
                                  className: "Quote__disabled-text",
                              }
                            : {})}
                    >
                        {(onlyRecurring ? position : index) + 1}. <b>{productDetails.product_name}</b>
                    </span>
                    {productDetails?.product_variation_name && (
                        <div style={{ marginLeft: "1em" }}>
                            <small
                                {...(disabled
                                    ? {
                                          className: "Quote__disabled-text",
                                      }
                                    : {})}
                            >
                                ({productDetails?.product_variation_name}/
                                {productDetails?.product_variation_value})
                            </small>
                        </div>
                    )}
                </Table.Cell>
                <Table.Cell disabled={disabled}>
                    {productDetails.product_description}
                </Table.Cell>
                <Table.Cell disabled={disabled}>
                    {onlyRecurring ? (StringUtils.capitalize(pricingScheduleField.value)) : (
                        <PricingSchedule
                            onChange={(_, { value }) => {
                                itemPricingScheduleField.onChange({
                                    target: {
                                        name: pricingScheduleField.name,
                                        value,
                                    },
                                });
                            }}
                            price_data={productDetails.price_data}
                            value={currentPricingSchedule}
                            disabled={disabled}
                            readOnly={isOrder || pricingScheduleField.value}
                        />
                    )}
                    {updates.includes("Pricing Schedule Changed") && (
                        <div>
                            <s>
                                {StringUtils.capitalize(productDetails.pricing_schedule)} {(() => {
                                    const price = productDetails.item_price || 0;
                                    if (price) {
                                        return `($${StringUtils.formatDecimal(`${price}`, true)})`
                                    }
                                    return "";
                                })()}
                            </s>
                        </div>
                    )}
                </Table.Cell>
                <Table.Cell disabled={disabled}>
                    <div>${StringUtils.formatDecimal(`${price}`, true)}</div>
                    {updates.includes("Price Changed") && (
                        <div>
                            <s>
                                ($
                                {StringUtils.formatDecimal(
                                    `${productDetails.item_price}`,
                                    true
                                )}
                                )
                            </s>
                        </div>
                    )}
                    {Boolean(productDetails.product_additional_price) && (
                        <small>
                            (+$
                            {StringUtils.formatDecimal(
                                `${productDetails.product_additional_price}`,
                                true
                            )}
                            )
                        </small>
                    )}
                </Table.Cell>
                <Table.Cell disabled={disabled}>
                    {onlyRecurring ? totalQuantity : <Form.Input
                        type="number"
                        step="1"
                        value={quantityField.value}
                        min="1"
                        onChange={(_, { value }) => {
                            quantityField.onChange({
                                target: { name: quantityField.name, value },
                            });
                        }}
                        disabled={disabled}
                        fluid
                        error={quantityError?.message}
                    />}
                </Table.Cell>
                <Table.Cell disabled={disabled}>
                    ${StringUtils.formatDecimal(`${sum}`, true)}
                </Table.Cell>
                <Table.Cell>
                    {onlyRecurring ? `${discountField.value || 0}%` : (
                        <Form.Input
                            type="number"
                            value={discountField.value}
                            min="0"
                            onChange={(_, { value }) => {
                                discountField.onChange({
                                    target: { name: discountField.name, value },
                                });
                            }}
                            disabled={
                                disabled ||
                                !Boolean(productDetails?.product_discount || 0)
                            }
                            fluid
                            error={discountFieldError?.message}
                            max={productDetails?.product_discount}
                            icon={<Icon name="percent" size="small" />}
                            style={{ width: "8em" }}
                        />
                    )}
                    {(productDetails?.product_discount || 0) > 0 && (
                        <div 
                            style={{ marginTop: "-1em" }}
                            {...(disabled
                            ? {
                                  className: "Quote__disabled-text",
                              }
                            : {})}
                        >
                            <small>
                                Max: {productDetails.product_discount || 0}%
                            </small>
                        </div>
                    )}
                </Table.Cell>
                {!onlyRecurring && <Table.Cell></Table.Cell>}
                <Table.Cell disabled={disabled}>
                    {!quantityError &&
                        !discountFieldError &&
                        `$${StringUtils.formatDecimal(
                            `${MathUtils.roundDecimals(subtotal)}`,
                            true
                        )}`}
                </Table.Cell>
                {!onlyRecurring && (
                    <Table.Cell width={1}>
                        {!updates.includes("Unavailable") && !Boolean(editsNotAllowed) && (
                            <Icon
                                className={`fas fa-${
                                    isInCart ? "minus-circle" : "arrow-rotate-left"
                                }`}
                                {...(isInCart ? { color: "red" } : {})}
                                link
                                onClick={onToggleItem}
                            />
                        )}
                    </Table.Cell>
                )}
            </Table.Row>
            {productDetails.addon.map((addon) => {
                const isAddonIncluded = addonsField.value.includes(addon.id);
                const onToggleAddon = () => {
                    const itemAddons = addonsField.value || [];
                    addonsField.onChange({
                        target: {
                            name: addonsField.name,
                            value: isAddonIncluded
                                ? itemAddons.filter(
                                      (includedAddon) =>
                                          includedAddon !== addon.id
                                  )
                                : [...itemAddons, addon.id],
                        },
                    });
                };

                const addonUpdates = (() => {
                    if (addon.unlinked) {
                        return "Unavailable";
                    }
                    if (addon.price !== addon.current_price) {
                        return "Price Changed";
                    }
                    return "";
                })();
                const icon = addonUpdates ? (
                    <Popup
                        size="mini"
                        content={addonUpdates}
                        inverted
                        position="top center"
                        trigger={
                            <Icon
                                className="fas fa-info-circle"
                                color="orange"
                            />
                        }
                    />
                ) : null;

                const disabled = addon.unlinked || !isInCart;
                return (
                    <QuoteItemAddition
                        disabled={disabled}
                        key={`${keyPrefix}-addon-${addon.id}`}
                        label={
                            <>
                                {icon}{" "}
                                <span
                                    {...(disabled
                                        ? { className: "Quote__disabled-text" }
                                        : {})}
                                >
                                    <b>Add-on:</b> {addon.name}
                                </span>
                            </>
                        }
                        description={addon.description}
                        quantity={totalQuantity}
                        price={
                            <>
                                <div>
                                    $
                                    {StringUtils.formatDecimal(
                                        `${addon.current_price}`,
                                        true
                                    )}
                                </div>
                                {addonUpdates === "Price Changed" && (
                                    <s>
                                        ($
                                        {addon.price})
                                    </s>
                                )}
                            </>
                        }
                        sum={`$${StringUtils.formatDecimal(
                            `${addon.current_price * (totalQuantity)}`,
                            true
                        )}`}
                        action={
                            !disabled &&
                            !Boolean(editsNotAllowed) && (
                                <Icon
                                    className={`fas fa-${
                                        isAddonIncluded ? "minus" : "plus"
                                    }-circle`}
                                    color={isAddonIncluded ? "red" : "primary"}
                                    link
                                    onClick={onToggleAddon}
                                    disabled={!isInCart}
                                />
                            )
                        }
                        hasMargin={!Boolean(icon)}
                        onlyRecurring={onlyRecurring}
                    />
                );
            })}
            {!onlyRecurring && (
                <>
                    <Fees
                        fees={productDetails.fee}
                        isInCart={isInCart}
                        netTotal={netTotal}
                        keyPrefix={keyPrefix}
                        quantity={totalQuantity}
                        onlyRecurring={onlyRecurring}
                    />
                    <Fees
                        fees={productDetails.tax}
                        isInCart={isInCart}
                        netTotal={netTotal}
                        keyPrefix={keyPrefix}
                        quantity={totalQuantity}
                        type="tax"
                        onlyRecurring={onlyRecurring}
                    />
                </>
            )}
        </React.Fragment>
    );
};

export default QuoteItemRow;
