import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Icon } from 'semantic-ui-react';

import LocationsForm from 'shared/forms/LocationsForm';
import Location from 'shared/Location';
import { RecordUtils } from 'utils';
import LocationUtils from 'utils/LocationUtils';

const Locations = ({
    control,
    existingLocations,
    isUpdatingLocation,
    isLocationOnEditMode,
    onEditLocation,
    tmpLocation,
    pickedLocationId,
    onConfirmEditLocation,
    onCancelEditLocation,
    location_type,
    isValid = true,
    updateLocation,
    watchedLocations
}) => {
    const onLocationDoubleClick = (id) => {
        !isLocationOnEditMode && onEditLocation(id)
    }

    const contactLocationBeingUpdated = useSelector(state => state.contact_location.contactLocationBeingUpdated);

    return (
        <div className='LocationsView__Locations'>
            {existingLocations.map((existingLocation, index) => {
                const isEditing = pickedLocationId === existingLocation.id;
                return (
                    <div key={index} className={`Location${isEditing ? ` Location--editing` : ''}`} onDoubleClick={() => onLocationDoubleClick(existingLocation.id)} style={{ overflowWrap: 'break-word' }}>
                        {!(isEditing) ?
                            <>
                                <div className='Location__label-line'>
                                    <b className='Location__label'>{LocationUtils.getAddressLabel(existingLocation.label)}</b> {existingLocation.main && <Icon className='fas fa-star' color='teal' />}
                                </div>
                                <Location 
                                    location={existingLocation}
                                    showBillingAndShipping
                                />
                            </>
                            : (
                                <>
                                    <LocationsForm
                                        control={control}
                                        location_type={location_type}
                                        locations={tmpLocation}
                                        onInlineEditPrimary
                                        pickedLocationIndex={existingLocations.findIndex(existingLocation => existingLocation.id === pickedLocationId)}
                                        updateMain={(onChange, property = 'main') => {
                                            RecordUtils.updateMain(watchedLocations, updateLocation, onChange, property);
                                        }}
                                    />
                                    <div className='Location__buttons'>
                                        <Button disabled={!isValid} primary size='tiny' floated='right' loading={isUpdatingLocation || contactLocationBeingUpdated} onClick={onConfirmEditLocation}>
                                            Confirm
                                        </Button>
                                        <Button disabled={isUpdatingLocation || contactLocationBeingUpdated} basic size='tiny' floated='right' color='red' onClick={onCancelEditLocation}>
                                            Cancel
                                        </Button>
                                    </div>
                                </>
                            )
                        }
                    </div>
                )
            })}
        </div>
    )
}

export default Locations;
