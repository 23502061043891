import React, { useContext, useState } from 'react';
import { DateTimeUtils, Table, NotificationType, ClippedContent, VoicemailPlayback } from 'dyl-components';
import Contact from 'shared/Contact';
import { Link } from 'react-router-dom';
import { Icon, Checkbox, Grid, Popup, Dropdown, Loader } from 'semantic-ui-react';
import { BulkActionsContext } from 'shared/context/BulkActionsProvider';
import { StringUtils } from 'utils';

import '../index.scss';

export const NotificationsRow = ({  notification, onOpenNotification, tab, onToggleSnoozeModal, snoozedOptions, remove, archive, markRead, snooze, readSchedule, isReadingSchedule }) => {
    const type = notification.notification_type;
    const url = notification.notification_details?.url;
    const isDescription = (type !== "fax" && type !== "missed_call");

    const [isVoicemailPlaybackOpen, setIsVoicemailPlaybackOpen] = useState(false);
    const [selectedNotifications, setSelectedNotifications] = useContext(BulkActionsContext);
    const notificationType = type === "task" ? `${StringUtils.capitalize(notification.notification_details.task_type)} Task `: type === "missed_call" ? "Missed Call" : StringUtils.capitalize(type);
    const { snoozed: isSnoozed, read: isRead, archived: isArchived, notification_id: notificationId } = notification;
    const snoozeDateTimeFormat = `${DateTimeUtils.formatEpoch(notification.notification_ts, DateTimeUtils.WORD_DATE_FORMAT)} at ${DateTimeUtils.formatEpoch(notification.notification_ts, DateTimeUtils.TIME_FORMAT)}`;
    const contactId = notification.notification_details?.contact_id;
    const accountId = notification.notification_details?.account_id;
    const cnam = notification.notification_details?.cnam_name;


    const isSelected = (notification_id) => {
        return selectedNotifications.includes(notification_id);
    }

    const onToggleContact = (notification_id) => {
        const newSelectedNotifications = isSelected(notification_id) ? selectedNotifications.filter(id => notification_id !== id) : [...selectedNotifications, notification_id];
        setSelectedNotifications(newSelectedNotifications);
    }

    const onOpenMarkReadNotification = () =>{
        onOpenNotification(notification.external_id, type, notificationId);
        !isRead && markRead(notificationId, true);
    }

    return (
        <Table.Row>
            <Table.Cell>
                <Checkbox checked={isSelected(notificationId)} onChange={() => { onToggleContact(notificationId) }} />
            </Table.Cell>
            <Table.Cell >
                <div><b>{DateTimeUtils.getDay(notification.notification_ts) === "Today" ? "Today" : DateTimeUtils.formatEpoch(notification.notification_ts, DateTimeUtils.WORD_DATE_FORMAT)}</b></div>
                <div>{DateTimeUtils.formatEpoch(notification.notification_ts, DateTimeUtils.TIME_FORMAT)}</div>
            </Table.Cell>
            <Table.Cell>
                {(!contactId && !accountId) &&
                    cnam
                }
                {((notification.notification_details.contact_name || notification.notification_details.account_name) && (contactId || accountId)) &&
                    <Contact
                        contact={{id: contactId ? contactId : accountId, contact_name: contactId ? notification.notification_details.contact_name : notification.notification_details.account_name, email: {id:true}}}
                        name={contactId ? notification.notification_details.contact_name : notification.notification_details.account_name}
                        linkToContact={<Link to={`/${contactId ? "contact" : "account"}/${contactId ? contactId : accountId}`}>{contactId ? notification.notification_details.contact_name : notification.notification_details.account_name}</Link>} 
                        account={accountId && notification.notification_details?.account_type }
                        displayIcon
                    />
                } 
            </Table.Cell>
            <Table.Cell>
                <Grid columns='equal'>
                    <Grid.Column width='4'>
                        <NotificationType name={notification.notification_type} /> 
                    </Grid.Column>
                    <Grid.Column verticalAlign='middle' style={{paddingLeft: 0}}>
                        {notificationType}
                    </Grid.Column>
                </Grid>
            </Table.Cell>
            <Table.Cell style={{maxWidth:200}}>
                <a href role="button" onClick={()=> onOpenMarkReadNotification()} ><b style={{marginRight: 7, cursor: 'pointer'}}>{notification.notification_details.title}</b></a>
                {isDescription &&
                    <ClippedContent position="bottom center">
                        {type === "voicemail" ? `VM Box ${notification.notification_details?.voicemail}` : notification.notification_details?.description}
                    </ClippedContent> 
                }
            </Table.Cell>
            <Table.Cell width={1}>
                <div className='Notification__actions'>
                    {tab !== "snoozed" &&
                        <>
                            {isRead ? 
                                <Popup
                                    trigger={
                                        <Icon className="fa-solid fa-circle-dashed" onClick={() => markRead(notificationId, false)} />
                                    }
                                    content={ "Mark as unread"}
                                    position="top center"
                                    inverted
                                />  
                            :
                                <Popup
                                    trigger={
                                        <Icon className="fa-solid fa-circle" onClick={() => markRead(notificationId, true)} />
                                    }
                                    content={ "Mark as read"}
                                    position="top center"
                                    inverted
                                />
                            }
                        </>
                    }
                    {type === "voicemail" &&
                        
                        <Popup
                            style={{minWidth:300}}
                            trigger={
                                <Icon className='fa-solid fa-circle-caret-right audio-button' disabled={!!!url} />                            
                            }
                            on="click"
                            content={
                                <VoicemailPlayback
                                    onClose={() => setIsVoicemailPlaybackOpen(false)}
                                    className={`audio-playback-${notificationId}`}
                                    fileName="Voicemail"
                                    link={url}
                                />
                            }
                            position="bottom center"
                            open={isVoicemailPlaybackOpen}
                            onOpen={() => setIsVoicemailPlaybackOpen(true)}
                        />
                    }
                    {tab !== "archived"  &&
                        <Dropdown
                            icon={false}
                            text={
                                <Popup
                                    trigger={<Icon

                                        className="fa-solid fa-alarm-snooze"
                                    />}
                                    inverted
                                    content={isSnoozed ? `Snoozed: ${snoozeDateTimeFormat}` :"Snooze"}
                                    position="top center"
                                />
                            }
                            onOpen={() => readSchedule()} 
                        >
                            <Dropdown.Menu style={{left: -150}}> 
                                <Dropdown.Header as='h3' content='Snooze until...' style={{textTransform: "unset", fontSize: "unset", marginBottom: isSnoozed ? 0 : 14}}/>
                                { isReadingSchedule ?
                                    <Loader active inline='centered' />
                                :
                                    <>
                                        {isSnoozed && <Dropdown.Header content={snoozeDateTimeFormat} style={{fontStyle: "italic", textTransform: "unset", fontSize: "unset", fontWeight: 500, marginTop: 0}}/> }
                                        {snoozedOptions.map(({ text, ts }, id) => {
                                            return (
                                                <Dropdown.Item
                                                    key={id}
                                                    children={
                                                        <Grid columns='equal'>
                                                            <Grid.Column>
                                                                {text}
                                                            </Grid.Column>
                                                            <Grid.Column textAlign='right'>
                                                                <b>{StringUtils.toUpperCase(DateTimeUtils.formatEpoch(ts, DateTimeUtils.WORD_DAY_FORMAT))}</b>
                                                            </Grid.Column>
                                                            <Grid.Column>
                                                                {DateTimeUtils.formatEpoch(ts)}
                                                            </Grid.Column>
                                                        </Grid>
                                                    }
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => snooze(notificationId, ts)}
                                                />
                                            );
                                        })}
                                        <Dropdown.Divider />
                                        <Dropdown.Item
                                            key='5'
                                            children={<><Icon className="fas fa-calendar" />Pick date and time</>}
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => onToggleSnoozeModal(notificationId)}
                                        />
                                    </>
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    }
                    <Popup
                        trigger={
                            <Icon className="fa-solid fa-box-archive" onClick={() => archive(notificationId, !isArchived)} /> 
                        }
                        content={isArchived ? "Unarchive" : "Archive"}
                        position="top center"
                        inverted
                    />
                    {tab === "archived" && 
                        <Popup
                            trigger={
                                <Icon className="trash" onClick={() => remove(notificationId)} />
                            }
                            content={"Delete"}
                            position="top center"
                            inverted
                        />
                    }
                </div>
            </Table.Cell>
        </Table.Row>
    );
}


