import { Notification, STATUS_TYPES } from "dyl-components";
import types from "./types";
import routes from "actions/routes";
// import { getTeamAgentId } from "pages/OfficeView/subcomponents/Department/Contact";
// import { ArrayUtils } from "utils";
// import { getCallControlId, CALL_CONTROL_NAMES } from "pages/OfficeView/subcomponents/Department/CallControls";
import { generateReadActionCreator, generateCreateActionCreator } from 'actions/crudActionCreators';
import ACTION_NAMES from 'actions/ACTION_NAMES';

const getOfficeView = generateReadActionCreator(routes.API_OFFICE_VIEW, ACTION_NAMES.OFFICE_VIEW)
const getCalls = generateReadActionCreator(routes.API_CALL_HISTORY_CALLS, ACTION_NAMES.CALLS);
const getRecentCalls = generateReadActionCreator(routes.API_CALL_HISTORY_RECENT_CALLS, ACTION_NAMES.RECENT_CALLS);
const getActiveCalls = generateReadActionCreator(routes.API_CALL_HISTORY_ACTIVE_CALLS, ACTION_NAMES.ACTIVE_CALLS);
const hangup = generateCreateActionCreator(routes.API_HANGUP, ACTION_NAMES.HANGUP, () => {}, () => {
    Notification.alert('Failed to hangup', STATUS_TYPES.ERROR, true);
});
// function transferCallToQueue(callId, callQueueName) {
//     return (dispatch, getState) => {
//         let { queues, incomingCalls } = getState().officeView;
//         queues = queues.slice(0);
//         incomingCalls = incomingCalls.slice(0);
//         let indexOfCallQueueToBeAssigned = getIndexOfCallQueueWithName(queues, callQueueName);
//         if (indexOfCallQueueToBeAssigned !== -1) {
//             let incomingCallToAssign = getCallToAssign(incomingCalls, callId);

//             let receivingCallQueue = queues[indexOfCallQueueToBeAssigned];
//             let callsOfCallQueueToBeAssigned = receivingCallQueue.calls;
//             callsOfCallQueueToBeAssigned.push(incomingCallToAssign);
//             dispatch({
//                 type: types.ASSIGN_INCOMING_CALL_TO_QUEUE,
//                 queues,
//                 incomingCalls
//             });
//         }
//     }
// }

// function getIndexOfCallQueueWithName(queues, callQueueId) {
//     return queues.findIndex(callQueue => callQueue.name === callQueueId);
// }

// function getCallToAssign(calls, callId) {
//     let indexOfIncomingCallToAssign = calls.findIndex(call => call.id === callId);
//     let callToAssign = calls[indexOfIncomingCallToAssign];
//     calls.splice(indexOfIncomingCallToAssign, 1);
//     return callToAssign;
// }

// function transferIncomingCallToDepartment(callId, departmentAgentId) {
//     return (dispatch, getState) => {
//         let { extensions, incomingCalls } = getState().officeView;
//         extensions = extensions.slice(0);
//         incomingCalls = incomingCalls.slice(0);

//         let departmentAgentToBeAssigned = getDepartmentAgentToBeAssigned(extensions, departmentAgentId);

//         if (departmentAgentToBeAssigned) {
//             let incomingCallToAssign = getCallToAssign(incomingCalls, callId);

//             dropCallTo(departmentAgentId, departmentAgentToBeAssigned, incomingCallToAssign);

//             dispatch({
//                 type: types.ASSIGN_INCOMING_CALL_TO_DEPARTMENT_AGENT,
//                 extensions,
//                 incomingCalls
//             });
//         }
//     }
// }

// function dropCallTo(departmentAgentId, departmentAgentToBeAssigned, incomingCallToAssign) {
//     // if (departmentAgentId === getCallControlId(departmentAgentToBeAssigned.department, departmentAgentToBeAssigned.user, CALL_CONTROL_NAMES.CELL)) {
//         Notification.alert(`Call dropped to ${departmentAgentToBeAssigned.name}'s ${CALL_CONTROL_NAMES.CELL.toLowerCase()}`, STATUS_TYPES.SUCCESS, true);
//     }
//     else if (departmentAgentId === getCallControlId(departmentAgentToBeAssigned.department, departmentAgentToBeAssigned.user, CALL_CONTROL_NAMES.VOICEMAIL)) {
//         Notification.alert(`Call dropped to ${departmentAgentToBeAssigned.name}'s ${CALL_CONTROL_NAMES.VOICEMAIL.toLowerCase()}`, STATUS_TYPES.SUCCESS, true);
//     }
//     else {
//         departmentAgentToBeAssigned.contact = incomingCallToAssign;
//     }
// }

// function transferCallFromQueue(callId, callQueueName, departmentAgentId) {
//     return (dispatch, getState) => {
//         let { extensions, queues } = getState().officeView;
//         extensions = extensions.slice(0);
//         queues = queues.slice(0);

//         let departmentAgentToBeAssigned = getDepartmentAgentToBeAssigned(extensions, departmentAgentId);

//         if (departmentAgentToBeAssigned) {
//             let indexOfSourceCallQueue = getIndexOfCallQueueWithName(queues, callQueueName);
//             if (indexOfSourceCallQueue !== -1) {
//                 let sourceCallQueue = queues[indexOfSourceCallQueue];
//                 let callFromQueueToAssign = getCallToAssign(sourceCallQueue.calls, callId);

//                 dropCallTo(departmentAgentId, departmentAgentToBeAssigned, callFromQueueToAssign);

//                 dispatch({
//                     type: types.ASSIGN_QUEUED_CALL_TO_DEPARTMENT_AGENT,
//                     extensions,
//                     queues
//                 });
//             }
//         }
//     }
// }

// function getDepartmentAgentToBeAssigned(extensions, departmentAgentId) {
//     return extensions.filter(extension => (departmentAgentId.includes(getTeamAgentId(extension.department, extension.user))))[0];
// }

// function call(contact) {
//     return dispatch => {
//         dispatch({
//             type: types.CALL_SUCCESS,
//             contact
//         })
//     }
// }

// function setActiveCall(contact) {
//     return dispatch => {
//         dispatch({
//             type: types.CLICK_RECENT_CALL,
//             contact
//         })
//     }
// }

// function closeActionsView() {
//     return dispatch => {
//         dispatch({
//             type: types.CLOSE_ACTIONS_VIEW
//         })
//     }
// }

// function clickActiveCallerName(contact) {
//     return dispatch => {
//         dispatch({
//             type: types.CLICK_ACTIVE_CALLER,
//             contact
//         })
//     }
// }

// function reorderCallQueues(sourceIndex, destinationIndex) {
//     return (dispatch, getState) => {
//         let queues = getState().officeView.queues.slice(0);
//         let reorderedQueues = ArrayUtils.reorder(queues, sourceIndex, destinationIndex);
//         dispatch({
//             type: types.REORDER_CALL_QUEUES,
//             queues: reorderedQueues
//         })
//     }
// }

// function hangup() {
//     return {
//         type: types.HANGUP
//     }
// }

function callDuration(type, duration){
    return {
        type: type,
        duration,
    }
}

function changeTeamsTab(teamsTab) {
    return {
        type: types.CHANGE_TEAMS_TAB,
        teamsTab
    }
}

function callRecording(type, callRecordings){
    return {
        type: type,
        callRecordings
    }
}

function currentCallPhoneNumber(type, currentCallPhoneNumber){
    return {
        type: type,
        currentCallPhoneNumber
    }
}

function currentCallTicker(type, uuid){
    return {
        type: type,
        uuid
    }
}

function currentDirection(type, direction){
    return {
        type,
        direction
    }
}

function currentCall(type, currentCall){
    return {
        type,
        currentCall
    }
}

function ignore(type, isIgnore){
    return {
        type,
        isIgnore
    }
}

function cnam(type, cnam){
    return {
        type,
        cnam
    }
}

function actionButtons(type, actionButton){
    return {
        type,
        actionButton
    }
}

function onClosePhoneNotifications(type, call){
    return {
        type,
        call
    }
}

function call(type) {
    return {
        type: types[type]
    }
}

function setSelectedTab(type, selectedTab) {
    return {
        type,
        selectedTab
    }
}

const browserCreds = generateReadActionCreator(routes.API_PROVISION_CREDS, ACTION_NAMES.SOFTPHONE_CREDS);

export default {
    // transferCallToQueue,
    // transferIncomingCallToDepartment,
    // call,
    // setActiveCall,
    // closeActionsView,
    // clickActiveCallerName,
    // reorderCallQueues,
    // transferCallFromQueue,
    getOfficeView,
    getCalls,
    getRecentCalls,
    getActiveCalls,
    hangup,
    changeTeamsTab,
    browserCreds,
    call,
    callDuration,
    callRecording,
    currentCallPhoneNumber,
    currentCallTicker,
    currentDirection,
    ignore,
    cnam,
    actionButtons,
    onClosePhoneNotifications,
    currentCall,
    setSelectedTab
}
