import React from 'react';
import { Icon } from 'semantic-ui-react';
import { STATUS_TYPES, Notification, yup, VALIDATORS, generateResolver } from 'dyl-components';

import AddLocation from 'shared/AddLocationSection';

import { useDispatch, useSelector } from 'react-redux';
import { useFieldArray, useForm } from 'react-hook-form';

import './AddLocationsSection.scss';
import { Link } from 'react-router-dom';
import locationsActions from 'actions/contact_location';

const AddLocationsSectionContainer = ({ onCloseAddLocationsSection, refresh, loading, id, type, location_type }) => {
    const { isCreating, existingLocations } = useSelector((state) => {
        return {
            isCreating: state.contact_location.isCreatingContactLocation,
            existingLocations: state[type][type].locations || []
        };
    });

    const { control, formState: { isValid, isDirty }, handleSubmit, watch } = useForm({
        mode: 'onChange',
        defaultValues: {
            locations: [
                {
                    id: Math.random(),
                    label: location_type === 'home' ? 'Home' : 'Business',
                    street: '',
                    additional_street: '',
                    city: '',
                    state: null,
                    zip: '',
                    billing: false,
                    shipping: false
                }
            ]
        },
        resolver: generateResolver({
            locations: yup.array().of(yup.object().shape({
                label: yup.string().required(),
                street: yup.string().maxlength(100),
                additional_street: yup.string().maxlength(12),
                city: yup.string().maxlength(60),
                state: yup.string().required('This field is required'),
                zip: VALIDATORS.US_POSTAL_CODE(),
            }))
        })
    })

    const { fields, append, update: updateLocation } = useFieldArray({
        control,
        name: 'locations',
    });
    
    const [watchedLocations] = watch(['locations']);
    const dispatch = useDispatch();

    const onAddToArray = (data) => {
        let randomId = null;
        let duplicatedId = null;
        do {
            const randomAux = Math.random();
            duplicatedId = fields.find((locationElement) => {
                return locationElement.id === randomAux;
            });
            randomId = randomAux;
        } while (duplicatedId);
        data = {
            ...data,
            id: randomId,
        }
        append(data);
    }

    const onAddLocations = async (data) => {
        try {
            await dispatch(locationsActions.addContactLocation(data.locations.map(({ id, ...location }) => location), null, id));
            Notification.alert('Successfully added locations!', STATUS_TYPES.SUCCESS);
            refresh();
        } catch (e) {
            console.log(e);
            Notification.alert('Failed to add locations', STATUS_TYPES.ERROR);
        }
    }

    return (
        <AddLocation
            existingLocations={existingLocations}
            locations={fields}
            onAddLocations={handleSubmit(onAddLocations)}
            onAddToArray={onAddToArray}
            isCreating={isCreating}
            isUpdatingLocation={loading}
            onCloseAddLocationsSection={onCloseAddLocationsSection}
            dataTabLink={<Link className='AddLocationsSection__buttonLinks' to={`/${type}/${id}/data`}>
                <Icon name='external' /> <span style={{ fontSize: 10 }}>View/edit my profile data tab</span>
            </Link>}
            location_type={location_type}
            control={control}
            isValid={isValid && isDirty}
            updateLocation={updateLocation}
            watchedLocations={watchedLocations}
        />
    )
}

export default AddLocationsSectionContainer;
