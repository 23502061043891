import { EmptyListPlaceholder, Table } from "dyl-components";
import { Table as SemanticTable } from "semantic-ui-react";
import React from "react";
import { useSelector } from "react-redux";
import RecurringProductRow from "./RecurringProductRow";
import QuoteItemRow from "../Quote/QuoteItemRow";

const RecurringProducts = ({ cart }) => {
    const order = useSelector((state) => state.order.order);
    const { subscriptions: products } = useSelector((state) => ({
        subscriptions: state.subscription.current_products,
    }));
    const order_status = useSelector(
        (state) => state.order.order?.order_status
    );
    const subscribed_items = useSelector(
        (state) => state.order.order?.subscribed_items
    );
    const isEditable =
        order_status && (order_status === "draft" || order_status === "open");

    const nextSubscriptionProducts = products.map((item) => ({
        quantity: item.quantity,
        key: `product-${item.product_id}-variation-${
            item.product_variation_id || 0
        }`,
    }));
    const cartItems = cart
        .map((item, index) => ({
            ...item,
            index,
        }))
        .filter(
            (item) => item.pricing_schedule &&
                !Boolean(item.removed)
        )
        .map((item, position) => {
            return (
                <QuoteItemRow
                    index={item.index}
                    item={item}
                    key={item.key}
                    quote={{
                        quote_summary: order.order_items,
                    }}
                    isOrder
                    onlyRecurring
                    additionalQuantity={nextSubscriptionProducts.find(
                        (currentRecurringProduct) => currentRecurringProduct.key ===
                            `product-${item.id}-variation-${item.variation_id || 0}`
                    )?.quantity || 0}
                    position={position} />
            );
        });
    const otherNextSubscriptionProducts = products
        .filter(
            (item) => !cart.some(
                (cartItem) => !Boolean(cartItem.removed) &&
                    `product-${cartItem.id}-variation-${cartItem.variation_id || 0}` ===
                    `product-${item.product_id}-variation-${item.product_variation_id ||
                    0}`
            )
        )
        .map((item) => ({
            ...item,
            price: item.price || item.prices,
            key: `${item.product_id}-variation-${item.product_variation_id || 0}`,
            product_description: item.description,
        }))
        .map((item, index) => (
            <RecurringProductRow
                index={index + cartItems.length}
                key={item.key}
                discount={item.discount}
                product_description={item.product_description}
                product_name={item.product_name}
                quantity={item.quantity}
                subtotal={item.subtotal}
                variant_name={item.variant_name}
                variant_value={item.variant_value}
                price={item.price}
                pricing_schedule={item.price_schedule}
                additional_price={item.additional_price} />
        ));
    return (
        <Table striped={false}>
            <Table.Header>
                <Table.Row>
                    <SemanticTable.HeaderCell>Product</SemanticTable.HeaderCell>
                    <SemanticTable.HeaderCell width={3}>
                        Description
                    </SemanticTable.HeaderCell>
                    <SemanticTable.HeaderCell>
                        Pricing Schedule
                    </SemanticTable.HeaderCell>
                    <SemanticTable.HeaderCell>Price</SemanticTable.HeaderCell>
                    <SemanticTable.HeaderCell width={2}>
                        Quantity
                    </SemanticTable.HeaderCell>
                    <SemanticTable.HeaderCell>Sum</SemanticTable.HeaderCell>
                    <SemanticTable.HeaderCell>
                        Discount
                    </SemanticTable.HeaderCell>
                    <SemanticTable.HeaderCell>
                        Subtotal
                    </SemanticTable.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {isEditable ? (
                    <>
                        {cartItems}
                        {otherNextSubscriptionProducts}
                        {cartItems.length + otherNextSubscriptionProducts.length === 0 && (
                            <Table.Row>
                                <Table.Cell colspan={8}>
                                    <EmptyListPlaceholder
                                        title={"No Recurring Products Purchased"}
                                        link=""
                                        subtitle={" "}
                                        wrapped={false}
                                    />
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </>
                ) : subscribed_items.length ? (
                    subscribed_items
                        .map((item) => ({
                            ...item,
                            price: item.price || item.prices,
                            key: `${item.product_id}-variation-${
                                item.product_variation_id || 0
                            }`,
                            product_description: item.description,
                        }))
                        .map((item, index) => (
                            <RecurringProductRow
                                index={index}
                                key={item.key}
                                discount={item.discount}
                                product_description={item.product_description}
                                product_name={item.product_name}
                                quantity={item.quantity}
                                subtotal={item.subtotal}
                                variant_name={item.variant_name}
                                variant_value={item.variant_value}
                                price={item.price}
                                pricing_schedule={item.price_schedule}
                                additional_price={item.additional_price}
                            />
                        ))
                ) : (
                    <Table.Row>
                        <Table.Cell colspan={8}>
                            <EmptyListPlaceholder
                                title={"No Recurring Products Purchased"}
                                link=""
                                subtitle={" "}
                                wrapped={false}
                            />
                        </Table.Cell>
                    </Table.Row>
                )}
            </Table.Body>
        </Table>
    );
};

export default RecurringProducts;
