import React from 'react';
import { Form } from 'semantic-ui-react';
import LocationUtils from 'utils/LocationUtils';
import { STATES } from 'shared/constants/STATES';
import './LocationsForm.scss';
import { Controller } from 'react-hook-form';

const LocationsForm = ({ control, locations, size = 'tiny', onInlineEditPrimary = false, DataTabLink, AddLocationButton, pickedLocationIndex, updateMain }) => (
    <Form size={size}>
        {locations.map(({ key }, index) => (
            (pickedLocationIndex === undefined || (index === pickedLocationIndex)) && (
                <React.Fragment key={key}>
                    <Controller
                        name={`locations[${index}].label`}
                        control={control}
                        render={({ field: { name, value, onChange } }) => (
                            <Form.Select
                                name={name}
                                value={value}
                                onChange={(_, { value }) => {
                                    onChange({ target: { name, value } })
                                }}
                                placeholder='Address Label'
                                search
                                options={LocationUtils.getAddressLabelOptions()}
                            />
                        )}
                    />
                    <Controller
                        name={`locations[${index}].street`}
                        control={control}
                        render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                            <Form.Input
                                style={{ marginTop: '0.5em', marginBottom: '0.5em' }}
                                placeholder='Street Address'
                                onChange={(_, { value }) => {
                                    onChange({ target: { name, value } })
                                }}
                                fluid
                                name={name}
                                value={value}
                                error={error?.message}
                            />
                        )}
                    />
                    <Controller
                        name={`locations[${index}].additional_street`}
                        control={control}
                        render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                            <Form.Input
                                style={{ marginTop: '0.5em', marginBottom: '0.5em' }}
                                placeholder='Apt, unit building, floor #'
                                onChange={(_, { value }) => {
                                    onChange({ target: { name, value } })
                                }}
                                fluid
                                name={name}
                                value={value}
                                error={error?.message}
                            />
                        )}
                    />
                    <Controller
                        name={`locations[${index}].city`}
                        control={control}
                        render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                            <Form.Input
                                style={{ marginTop: '0.5em', marginBottom: '0.5em' }}
                                placeholder='City'
                                onChange={(_, { value }) => {
                                    onChange({ target: { name, value } })
                                }}
                                fluid
                                name={name}
                                value={value}
                                error={error?.message}
                            />
                        )}
                    />
                    <Form.Group widths='equal'>
                        <Controller
                            name={`locations[${index}].state`}
                            control={control}
                            render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                <Form.Select
                                    placeholder={'State'}
                                    header={'State'}
                                    options={
                                        STATES.map(({ key, text }) => ({
                                            key, value: key, text
                                        }))
                                    }
                                    fluid
                                    style={{ zIndex: 11 }}
                                    onChange={(_, { value }) => {
                                        onChange({ target: { name, value } })
                                    }}
                                    name={name}
                                    value={value}
                                    error={error?.message}
                                />
                            )}
                        />
                        <Controller
                            name={`locations[${index}].zip`}
                            control={control}
                            render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                <Form.Input
                                    name={name}
                                    onChange={(_, { value }) => {
                                        onChange({ target: { name, value } })
                                    }}
                                    placeholder='Zip Code'
                                    fluid
                                    value={value}
                                    error={error?.message}
                                />
                            )}
                        />
                    </Form.Group>
                    <Form.Group widths={3}>
                        <Controller
                            name={`locations[${index}].main`}
                            control={control}
                            render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                <Form.Checkbox
                                    name={name}
                                    onChange={(_, { checked: value }) => {
                                        updateMain(() => {
                                            onChange({ target: { name, value } });
                                        }, 'main');
                                    }}
                                    label='Primary'
                                    fluid
                                    checked={value}
                                    error={error?.message}
                                />
                            )}
                        />
                        <Controller
                            name={`locations[${index}].billing`}
                            control={control}
                            render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                <Form.Checkbox
                                    name={name}
                                    onChange={(_, { checked: value }) => {
                                        updateMain(() => {
                                            onChange({ target: { name, value } });
                                        }, 'billing');
                                    }}
                                    label='Billing'
                                    fluid
                                    checked={value}
                                    error={error?.message}
                                />
                            )}
                        />
                        <Controller
                            name={`locations[${index}].shipping`}
                            control={control}
                            render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                <Form.Checkbox
                                    name={name}
                                    onChange={(_, { checked: value }) => {
                                        updateMain(() => {
                                            onChange({ target: { name, value } });
                                        }, 'shipping');
                                    }}
                                    label='Shipping'
                                    fluid
                                    checked={value}
                                    error={error?.message}
                                />
                            )}
                        />
                    </Form.Group>

                    {!onInlineEditPrimary && locations.length - 1 === index &&
                        <React.Fragment>
                            <Form.Group>
                                <div>
                                    {AddLocationButton}
                                </div>
                            </Form.Group>
                            {DataTabLink &&
                                <Form.Group>
                                    <div style={{ marginBottom: 15 }}>
                                        {DataTabLink}
                                    </div>
                                </Form.Group>
                            }
                        </React.Fragment>
                    }
                    {(pickedLocationIndex === undefined && index !== locations.length - 1) && <hr className='LocationsForm__separator' />}
                </React.Fragment>
            )
        ))}
    </Form>
);

export default LocationsForm;
