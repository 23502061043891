const initialState = {
    recordings: null,
    isReadingRecordings: false,
    isUpdatingRecordings: false,

    preferences: null,
    isReadingPreferences: false,
    isUpdatingPreferences: false,

    dylNumbers: [],
    dylNumbersCount: 0,
    isReadingDylNumbers: false,

    dylNumber: null,
    isReadingDylNumber: false,

    destinations: null,
    isReadingDestinations: false,

    destinationGroups: [],
    destinationGroup: null,
    destinationGroupsCount: 0,
    isReadingDestinationGroups: false,
    isSavingDestinationGroups: false,
    isDeletingDestinationGroup: false,
    isReadingDestinationGroup: false,
    isEditingDestinationGroup: false,
    
    isUpdatingDylNumber: false,
    
    companyExtensions: [],
    companyVoicemail: null,
    conferenceRoom: null,
    parkingLot: null,
    isReadingCompanyExtensions: false,
    isReadingCompanyVoicemail: false,
    isReadingConferenceRoom: false,
    isReadingParkingLot: false,
    isReadingListPhones: false,
    pbxListPhones: [],

    callRoutingRules: [],
    callRoutingRulesCount: 0,
    isReadingCallRoutingRules: false,

    isCreatingCallRoutingRules: false,

    callRoutingRule: null,
    isReadingCallRoutingRule: false,

    isUpdatingCallRoutingRule: false,
    isReadingExtensionsList:false,
    extensionsList:[],
    isReadingUnusedExtensions:false,
    unusedExtensions:[],
    isReadingUsersWithExtensions: false,
    usersWithExtensions: [],

    userExtensions:[],
    userExtension: null,
    isReadingUserExtensions: false,
    isReadingUserExtension: false,
    isUpdatingUserExtension: false,

    isReadingSounds: false,
    sounds: [],
    soundsCount: 0,
    isCreatingSounds: false,
    isReadingSound: false,
    sound: null,
    isUpdatingSound: false,
    isDeletingSound: false,
    isReadingSoundOptions: false,
    soundOptions: [],

    userGroups: {},
    isReadingUserGroups: false,
    isUpdatingUserGrups: false,

    cidOptions: [],
    cidPrefs: null,
    isReadingCidOptions: false,
    isReadingCidPrefs: false,
    isUpdatingCidPrefs: false,

    users: [],
    isReadingUsers: false,

    ivrs: [],
    ivr: null,
    ivrCount: 0,
    isReadingIvrs: false,
    isReadingIvr: false,
    isCreatingIvrPrompt: false,
    isUpdatingIvrPrompt: false,

    viewDestinations: [],
    isReadingViewDestinations: false,

    isReadingExtensionsTeamsList: false,
    extensionsTeamsList: [],

    isReadingPinnedExtensionsWithUsers: false,
    pinnedExtensionsWithUsers: [],

    isReadingExtensionsByTeam: false,
    extensionsByTeam: [],

    external_numbers: [],
    external_number: null,
    external_numbers_count: 0,
    isReadingExternalNumbers: false,
    isCreatingExternalNumber: false,
    isReadingExternalNumber: false,
    isVerifyingExternalNumberCode: false,

    external_users: [],
    isReadingExternalUsers: false,
}

export default initialState;
