import React, { useState } from "react";
import { Header, Popup } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import {
    CloseableContent,
    Notification,
    STATUS_TYPES,
    VALIDATORS,
    generateResolver,
} from "dyl-components";

import { FormProvider, useForm } from "react-hook-form";
import ProductCategoryForm from "shared/forms/ProductCategoryForm";
import productCategoryActions from "actions/product_category";
import FullScreenModalPopUp from "shared/FullScreenModalPopUp";
import { useBlocker } from "shared/confirmation/UseBlocker";

const EditProductCategoryPopup = ({
    isOpen,
    actionPopupRef,
    id,
    onFormClose,
    refresh,

    name,
    description,
}) => {
    const dispatch = useDispatch();
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const [pendingNavigation, setPendingNavigation] = useState(null);
    const methods = useForm({
        mode: "onChange",
        defaultValues: {
            name: name || "",
            description: description || "",
        },
        resolver: generateResolver({
            name: VALIDATORS.SIMPLE_INPUT()
                .noemoji()
                .minlength(2)
                .maxlength(100)
                .no_whitespace_only()
                .required("This field is required"),
            description: VALIDATORS.DESCRIPTION(),
        }),
    });

    const onSave = async (data) => {
        try {
            await dispatch(
                productCategoryActions.updateProductCategory(id, data)
            );
            Notification.alert(
                "Succesfully updated product category!",
                STATUS_TYPES.SUCCESS
            );
            if (refresh) {
                refresh();
            }
        } catch (e) {
            console.log(e);
            Notification.alert(
                "Failed to update product category",
                STATUS_TYPES.ERROR
            );
        }
    };

    const isSaving = useSelector(
        (state) => state.product_category.categoryBeingUpdated === id
    );

    const onClose = () => {
        onFormClose();
        methods.reset();
    };
    const handleConfirmLeave = () => {
        setIsConfirmationOpen(false);
        if (pendingNavigation) {
            pendingNavigation.retry();
        }
    };
        useBlocker(
            (tx) => {
                if (isDirty && !isConfirmationOpen) {
                    setPendingNavigation(tx);
                    setIsConfirmationOpen(true);
                } else {
                    tx.retry();
                }
            },
            isDirty
        );

    return (
        <React.Fragment>
            <Popup
                open={isOpen}
                position="bottom left"
                context={actionPopupRef}
                onClose={() => {
                    !isDirty && onClose()
                }}
                content={
                    <FormProvider {...methods}>
                        <CloseableContent onClose={() => {
                            isDirty
                                ? setIsConfirmationOpen(true)
                                : onClose();
                        }}>
                            <div style={{ padding: "1em" }}>
                                <Header color="primary">Edit Category</Header>
                                <ProductCategoryForm
                                    onSave={onSave}
                                    isSaving={isSaving}
                                    onDirtyChange={(dirty) => setIsDirty(dirty)}
                                />
                            </div>
                        </CloseableContent>
                    </FormProvider>
                }
            />
            <FullScreenModalPopUp
                header={"Changes not saved"}
                subheader={"Are you sure you want to exit?"}
                isOpen={isConfirmationOpen}
                onConfirm={() => {
                    onClose();
                    setIsConfirmationOpen(false);
                    handleConfirmLeave()
                }}
                onFormClose={() => setIsConfirmationOpen(false)}
                closeIcon={false}
            />
        </React.Fragment>
    );
};

export default EditProductCategoryPopup;
