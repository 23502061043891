import React, { useState } from "react";
import { Icon, Popup} from 'semantic-ui-react';
import UploadFilesModal from "shared/modals/UploadFilesModal";
import { CloseableContent } from 'dyl-components';

const SubControls = () => {

    const [isOpen, setIsOpen] = useState(false);

    const closeModal = () => {
        setIsOpen(false);
    }

    return (
        <div className="SubControls">
            <Icon
                className='Profile__controls--less-margin fas fa-fax'
                disabled
            />
            <Popup
                trigger={(
                    <Icon
                        className='Profile__controls--less-margin fas fa-arrow-up-from-bracket'
                        onClick={() => { setIsOpen(isOpen => !isOpen) }}
                                style={{cursor: 'pointer'}}
                    />
                )}
                open={isOpen}
                content={
                    <CloseableContent onClose={()=>setIsOpen(false)}>
                        <UploadFilesModal closeModal={closeModal}/>
                    </CloseableContent>
                }
                position={`bottom right`}
            />
            <Icon
                className='Profile__controls--less-margin fas fa-voicemail'
                disabled
            />
        </div>
    )
}

export default SubControls;