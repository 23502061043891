import React, { useState } from "react";
import Events from "./Events";
import Tasks from "./Tasks";

import "./index.scss";
import { useConfirm } from "shared/confirmation/useConfirm";
import CustomPrompt from "shared/confirmation/CustomPrompt";
import ConfirmModal from "shared/confirmation/ConfirmModal";

const Activities = () => {

    const [isUnsavedChanges, setIsUnsavedChanges] = useState(false);
    const { isConfirmed } = useConfirm();

    const handleFormDirty = (isFormDirty) => {
        if(isFormDirty === !isUnsavedChanges){
            setIsUnsavedChanges(isFormDirty);
        }
    };

    CustomPrompt(null, isUnsavedChanges , isConfirmed, 'Changes not saved', 'Are you sure you want to exit?');

    return (
        <div className="Activities">
            <ConfirmModal/>
            <Events handleFormDirty={handleFormDirty} />
            <Tasks handleFormDirty={handleFormDirty} />
        </div>
    );
};

export default Activities;
