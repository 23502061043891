import { NestedDropdown } from "dyl-components";
import React from "react";

const TermField = ({ name, value, onChange, isFunded }) => {
    const [parent_value, child_value] = (value || "").split("/");
    return (
        <NestedDropdown
            parent_value={parent_value}
            child_value={child_value}
            label="Status"
            allowedToSelectParentOnly
            onChange={(e, { parent_value, child_value }) => {
                onChange(e, {
                    name,
                    value: child_value
                        ? `${parent_value}/${child_value}`
                        : parent_value,
                });
            }}
            display_parent
            selection
            nested_options={[
                {
                    key: "default",
                    value: "default",
                    text: "Default",
                    options: [],
                },
                ...(!isFunded ? [
                    {
                        key: "trial",
                        value: "trial",
                        text: "Trial",
                        options: [
                            14,
                            30,
                            60,
                            90
                        ].map((numberOfDays) => ({
                            key: numberOfDays,
                            value: numberOfDays,
                            text: `${numberOfDays} Days`,
                        })),
                    },
                    {
                        key: "demo",
                        value: "demo",
                        text: "Demo",
                        options: []
                    }
                ] : []),
                {
                    key: "net",
                    value: "net",
                    text: "Net",
                    options: [
                        "15",
                        "30",
                        "60"
                    ].map((term) => ({
                        key: term,
                        value: term,
                        text: term,
                    })),
                },
            ]}
            selectOnBlur={false}
            clearable={false}
            placeholder="Select term"
        />
    );
};

export default TermField;
