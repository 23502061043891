import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Grid, Icon, Header, Transition } from "semantic-ui-react";
import { StringUtils } from "utils";
import BusinessStandardData from "./BusinessStandardData";
import HouseholdStandardData from "./HouseholdStandardData";

import { BusinessInfo } from "./BusinessInfo";

import Details from "./DetailsLayout";

const AccountData = () => {
    const account = useSelector((state) => state.account.account);

    const account_type = account?.account_type || "";

    const defaultExpandedSections = [
        "account_details",
        ...(account_type === "business"
            ? ["business_registration", "financial_info"]
            : []),
    ];

    const [expandedSections, setExpandedSections] = useState(
        defaultExpandedSections
    );

    const isExpanded = (section) => {
        return expandedSections.includes(section);
    };

    const onToggle = (section) => {
        if (isExpanded(section)) {
            setExpandedSections(
                expandedSections.filter(
                    (expandedSection) => expandedSection !== section
                )
            );
        } else {
            setExpandedSections([...expandedSections, section]);
        }
    };

    const atLeastOneExpanded = expandedSections.length > 0;

    const collapseAllData = () => {
        setExpandedSections([]);
    };

    const expandAllData = () => {
        setExpandedSections(defaultExpandedSections);
    };

    return (
        <Details
            atLeastOneExpanded={atLeastOneExpanded}
            collapseAllData={collapseAllData}
            expandAllData={expandAllData}
            info={[
                <Grid.Column>
                    <Grid className="ContactInfo">
                        <Grid.Row columns={"equal"}>
                            <Grid.Column>
                                <Header color="primary">
                                    <span>
                                        {StringUtils.capitalize(
                                            account.account_type
                                        )}{" "}
                                        Details{" "}
                                        <Icon
                                            onClick={() => {
                                                onToggle("account_details");
                                            }}
                                            link
                                            size="small"
                                            className={`fas fa-circle-chevron-${
                                                isExpanded("account_details")
                                                    ? "up"
                                                    : "down"
                                            }`}
                                        />
                                    </span>
                                </Header>
                                <Transition
                                    visible={isExpanded("account_details")}
                                    animation={"slide down"}
                                    unmountOnHide
                                    duration={"150"}
                                >
                                    <div>
                                        {account_type === "business" && (
                                            <BusinessStandardData />
                                        )}
                                        {account_type === "household" && (
                                            <HouseholdStandardData />
                                        )}
                                    </div>
                                </Transition>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Grid.Column>,
                ...(account_type === "business"
                    ? [
                          <BusinessInfo
                              businessRegistrationExpanded={isExpanded(
                                  "business_registration"
                              )}
                              financialInfoExpanded={isExpanded(
                                  "financial_info"
                              )}
                              onToggleBusinessRegistration={() => {
                                  onToggle("business_registration");
                              }}
                              onToggleFinancialInfo={() => {
                                  onToggle("financial_info");
                              }}
                          />,
                      ]
                    : []),
            ]}
        />
    );
};

export default AccountData;
