import React from 'react';
import { Form, Header, Grid, Portal } from 'semantic-ui-react';

import { useForm, Controller } from 'react-hook-form';
import { VALIDATORS, generateResolver, Button } from 'dyl-components';
import { Link } from 'react-router-dom';

import './PermissionRoleForm.scss'
import SettingsFooter from 'dyl-components/molecules/SettingsFooter';
import useWidthListener from 'shared/SettingsFooter/useWidthListener';
import useWindowWidth from 'shared/SettingsFooter/useWindowWidth';
import { MathUtils } from 'utils';
import { useConfirm } from 'shared/confirmation/useConfirm';
import CustomPrompt from 'shared/confirmation/CustomPrompt';
import ConfirmModal from 'shared/confirmation/ConfirmModal';

const PermissionRolesForm = ({
    isSaving,
    onSave,
    permissionConfigs,
    name,
    description,
    header,
    type,
    checkUpdateButton,
    hasChecksSelected,
    mode = 'Add'
}) => {
    const width = useWidthListener("settingsSidebar");
    const windowWidth = useWindowWidth();
    const { isConfirmed } = useConfirm();

    const { control, formState: { isValid ,isDirty }, getValues, setError } = useForm({
        mode: 'all',
        resolver: generateResolver({
            name: VALIDATORS.TEAM_NAME(true),
            description: VALIDATORS.DESCRIPTION(true)
        })
    });

    const viewOnly = (type === 'view');

    const onSetError = (name, { type, message }) => {
        setError(name, { type, message });
    }

    CustomPrompt(null, isDirty || hasChecksSelected , isConfirmed, 'Changes not saved', 'Are you sure you want to exit?');
    return (
        <>
        <ConfirmModal/>
            <Grid className={((type === 'view') ? 'OnlyViewPermissions' : null) + ' PermissionProfile__container'}>
                <Grid.Row>
                    <Grid.Column>
                        <Form loading={isSaving} size='small'>
                            <Controller
                                name="name"
                                control={control}                          
                                defaultValue={name}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <Form.Input
                                        width={8}
                                        onChange={e=>{
                                            onChange(e);
                                            checkUpdateButton( null, getValues())
                                        }}
                                        disabled={viewOnly}
                                        value={value}
                                        label={'Permission Profile Name'}
                                        error={error && error.message}
                                        required
                                        autoFocus
                                    />
                                )}
                            />


                            <Controller
                                name='description'
                                control={control}
                                defaultValue={description}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <Form.TextArea
                                        width={8}
                                        onChange={e=>{
                                            onChange(e);
                                            checkUpdateButton( null, getValues())
                                        }}
                                        value={value}
                                        label={'Description'}
                                        disabled={viewOnly}
                                        error={error && error.message}
                                        required
                                    />
                                )}
                            />

                            <Header as='h6'>Permission Sets</Header>

                            {permissionConfigs}
                        </Form>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Portal open>
                <SettingsFooter
                    style={{ width: MathUtils.calculatePercentage(windowWidth, windowWidth - width) }}
                    className={`Webform__menu`}
                    rightOptions={[
                        <Button as={Link} to='/settings/permission-profile' status={'cancel'} basic>Cancel</Button>,
                        (type !== 'view') &&
                            <Form.Group>
                                <Form.Button
                                    disabled={(!isValid || !hasChecksSelected)}
                                    onClick={(e) => { e.preventDefault(); onSave(getValues(), onSetError); }}
                                    primary
                                >
                                    Save
                                </Form.Button>
                            </Form.Group>
                    ]}
                />
            </Portal>
        </>
    );
};

export default PermissionRolesForm;
