import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Icon, Header, Transition, Popup } from "semantic-ui-react";
import { StringUtils } from "utils";

import { ButtonLink, ClippedContent } from "dyl-components";
import CustomerInformation from "./CustomerInformation";
import CustomerMetrics from "./CustomerMetrics";
import RevenueInfo from "./RevenueInfo";
import { useParams } from "react-router-dom";
import CustomerRecentOrder from "./CustomerRecentOrder";

import { QuoteBuilderContext } from "shared/context/QuoteBuilderProvider";
import orderActions from "actions/order";
import Details from "./DetailsLayout";

const CustomerData = () => {
    const defaultExpandedSections = [
        "customer_info",
        "customer_order",
        "customer_metrics",
        "products_info",
    ];

    const { onOpenAddProducts, onEditOrder } = useContext(QuoteBuilderContext);

    const [expandedSections, setExpandedSections] = useState(
        defaultExpandedSections
    );

    const isExpanded = (section) => {
        return expandedSections.includes(section);
    };

    const onToggle = (section) => {
        if (isExpanded(section)) {
            setExpandedSections(
                expandedSections.filter(
                    (expandedSection) => expandedSection !== section
                )
            );
        } else {
            setExpandedSections([...expandedSections, section]);
        }
    };

    const atLeastOneExpanded = expandedSections.length > 0;

    const collapseAllData = () => {
        setExpandedSections([]);
    };

    const expandAllData = () => {
        setExpandedSections(defaultExpandedSections);
    };

    const dispatch = useDispatch();

    const { account_id } = useParams();

    useEffect(() => {
        dispatch(orderActions.readRecentOrder(Number(account_id)));
    }, [dispatch, account_id]);

    const recentOrderId = useSelector(
        (state) => state.order.recentOrder?.order_id
    );

    return (
        <Details
            atLeastOneExpanded={atLeastOneExpanded}
            collapseAllData={collapseAllData}
            expandAllData={expandAllData}
            info={[
                <Grid.Column>
                    <Grid className="ContactInfo">
                        <Grid.Row columns={"equal"}>
                            <Grid.Column>
                                <Header color="primary">
                                    <span>
                                        Customer Info{" "}
                                        <Icon
                                            onClick={() => {
                                                onToggle("customer_info");
                                            }}
                                            link
                                            size="small"
                                            className={`fas fa-circle-chevron-${
                                                isExpanded("customer_info")
                                                    ? "up"
                                                    : "down"
                                            }`}
                                        />
                                    </span>
                                </Header>
                                <Transition
                                    visible={isExpanded("customer_info")}
                                    animation={"slide down"}
                                    unmountOnHide
                                    duration={"150"}
                                >
                                    <div>
                                        <CustomerInformation />
                                        <Header
                                            style={{ marginTop: "0.5em" }}
                                            color="primary"
                                        >
                                            Monthly
                                        </Header>
                                        <RevenueInfo />
                                        <Header
                                            style={{ marginTop: "0.5em" }}
                                            color="primary"
                                        >
                                            Yearly
                                        </Header>
                                        <RevenueInfo />
                                    </div>
                                </Transition>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Grid.Column>,
                <Grid.Column>
                    <Grid className="ContactInfo">
                        <Grid.Row columns="equal">
                            <Grid.Column>
                                <Header color="primary">
                                    <span>
                                        <Icon
                                            className={`fas fa-plus-circle`}
                                            link
                                            onClick={() => {
                                                onOpenAddProducts({
                                                    isUpsell: true,
                                                });
                                            }}
                                        />{" "}
                                        Order{" "}
                                        {Boolean(recentOrderId) ? (
                                            <span style={{ color: "initial", fontSize: "small" }}>
                                                ID:
                                                <ButtonLink
                                                    onClick={() => {
                                                        onEditOrder(
                                                            recentOrderId,
                                                            Number(account_id)
                                                        );
                                                    }}
                                                    className="HeaderOrderID"
                                                >
                                                    <ClippedContent>
                                                        {recentOrderId}
                                                    </ClippedContent>
                                                </ButtonLink>
                                                <Popup
                                                    trigger={
                                                        <Icon
                                                            link
                                                            className="fas fa-copy"
                                                            onClick={StringUtils.copy(
                                                                recentOrderId
                                                            )}
                                                            color="primary"
                                                        />
                                                    }
                                                    content='Copied!'
                                                    on='click'
                                                    closeOnTriggerMouseLeave
                                                    position='bottom center'
                                                    inverted
                                                />{" "}
                                            </span>
                                        ) : null}{" "}
                                        <Icon
                                            color="blue"
                                            onClick={() => {
                                                onToggle("customer_order");
                                            }}
                                            link
                                            className={`fas fa-circle-chevron-${
                                                isExpanded("customer_order")
                                                    ? "up"
                                                    : "down"
                                            }`}
                                            size="small"
                                        />
                                    </span>
                                </Header>{" "}
                                <Transition
                                    visible={isExpanded("customer_order")}
                                    animation={"slide down"}
                                    unmountOnHide
                                    duration={"150"}
                                >
                                    <div>
                                        <CustomerRecentOrder />
                                    </div>
                                </Transition>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Grid.Column>,
                <Grid.Column>
                    <Grid className="ContactInfo">
                        <Grid.Row columns={"equal"}>
                            <Grid.Column>
                                <Header color="primary">
                                    <span>
                                        Customer Metrics{" "}
                                        <Icon
                                            onClick={() => {
                                                onToggle("customer_metrics");
                                            }}
                                            link
                                            size="small"
                                            className={`fas fa-circle-chevron-${
                                                isExpanded("customer_metrics")
                                                    ? "up"
                                                    : "down"
                                            }`}
                                        />
                                    </span>
                                </Header>
                                <Transition
                                    visible={isExpanded("customer_metrics")}
                                    animation={"slide down"}
                                    unmountOnHide
                                    duration={"150"}
                                >
                                    <div>
                                        <CustomerMetrics />
                                    </div>
                                </Transition>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Grid.Column>,
            ]}
        />
    );
};

export default CustomerData;
